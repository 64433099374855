import React from 'react';
import PropTypes from 'prop-types';
import polyglot from 'i18n';
import { Field, Button, SelectField, HelpToolTipButton } from 'components';
import styles from './ContractWizard.css';

const ContractWizardStep1 = ({
  buttonClick,
  selectedOrg,
  user,
  lokalerName,
  lokalId,
  kontor,
  onChange,
  onSelectChange,
}) => {
  const helpToolTipButton = text => <HelpToolTipButton title={text} />;
  return (
    <div className={styles.wrapper}>
      <Field
        name="lokalerName"
        label={polyglot.t('newContract.askName')}
        placeholder={polyglot.t('newContract.name')}
        value={lokalerName}
        onChange={onChange}
        required
        helpToolTipButton={helpToolTipButton(
          polyglot.t('helpToolTipButton.newLokale.step1.lokalnavn'),
        )}
      />
      <Field
        name="lokalId"
        label={polyglot.t('newContract.lokalId')}
        placeholder={polyglot.t('newContract.lokalId')}
        value={lokalId}
        onChange={onChange}
        helpToolTipButton={helpToolTipButton(
          polyglot.t('helpToolTipButton.newLokale.step1.lokalid'),
        )}
      />
      <SelectField
        label={polyglot.t('newContract.user')}
        placeholder={polyglot.t('newContract.orgnr')}
        selectedValue={selectedOrg ? selectedOrg.value : undefined}
        matchProp="label"
        testid="selectOrg"
        name="selectOrg"
        clearable={false}
        optionUrl={`/organisasjon/brukere/${user.organisasjon.id}`}
        onChange={e => onSelectChange(e, 'tenant')}
        required
        wide
      />
      <SelectField
        label={polyglot.t('newContract.step1.isOffice')}
        placeholder={polyglot.t('newContract.step1.choose')}
        selectedValue={kontor}
        matchProp="label"
        testid="selectOffice"
        clearable={false}
        choices={[
          {
            id: 'true',
            navn: 'Ja',
          },
          {
            id: 'false',
            navn: 'Nei',
          },
        ]}
        onChange={e => onSelectChange(e, 'kontor')}
        required
        helpToolTipButton={helpToolTipButton(
          polyglot.t('helpToolTipButton.newLokale.step1.kontorlokalet'),
        )}
      />
      <Button
        id="next"
        kind="primary"
        disabled={!(selectedOrg && lokalerName && kontor !== undefined)}
        onClick={buttonClick}
      >
        {'Neste'}
      </Button>
    </div>
  );
};

ContractWizardStep1.propTypes = {
  buttonClick: PropTypes.func,
  onChange: PropTypes.func,
  kontor: PropTypes.bool,
  selectedOrg: PropTypes.shape({ value: PropTypes.number }),
  user: PropTypes.shape({ organisasjon: PropTypes.any }),
  lokalerName: PropTypes.string,
  onSelectChange: PropTypes.func,
};

export default ContractWizardStep1;
