import React from 'react';
import PropTypes from 'prop-types';
import { Button, Field, Icon } from 'components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import polyglot from 'i18n';
import * as buildingActions from 'redux/modules/building/building';
import * as widgetsActions from 'redux/modules/widgets/widgets';
import { setDefault } from 'utils/utils';

import Heading from '../../atoms/Heading/Heading';
import ModalButton from '../../molecules/Modal/ModalButton';
import styles from './BuildingEdit.css';
import Dialog from '../../../containers/Dialog';

class BuildingEdit extends React.PureComponent {
  state = { editMode: false };

  componentDidUpdate(prevProps) {
    const {
      postNameSuccess,
      postFotavtrykkSuccess,
      actions,
      building,
      nameError,
      footprintError,
    } = this.props;
    if (
      (postNameSuccess && !prevProps.postNameSuccess) ||
      (postFotavtrykkSuccess && !prevProps.postFotavtrykkSuccess)
    ) {
      actions.getBuilding(building.bygningsnr);
      actions.displayAlert({ text: 'Lagret OK', kind: 'success' });
    }
    if (nameError)
      actions.displayAlert({
        text: `Lagring av navn feilet: ${nameError}`,
        kind: 'danger',
      });
    if (footprintError)
      actions.displayAlert({
        text: `Lagring av fotavtrykk feilet: ${footprintError}`,
        kind: 'danger',
      });
  }

  toggleModal = () => {
    this.setState(prevState => ({
      editMode: !prevState.editMode,
      inputName: undefined,
      fotavtrykk: undefined,
    }));
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = () => {
    const { inputName, fotavtrykk } = this.state;
    const { building, actions } = this.props;
    if (inputName && inputName !== building.navn) {
      actions.setBuildingAlias({ id: building.bygningsnr, value: inputName });
    }
    if (fotavtrykk && fotavtrykk !== building.fotavtrykk) {
      actions.setFootprint(building.bygningsnr, fotavtrykk);
    }
    this.toggleModal();
  };

  render() {
    const {
      building: { editable, navn, fotavtrykk },
    } = this.props;
    const { editMode, inputName, fotavtrykk: inputFotavtrykk } = this.state;
    return (
      <div className={styles.wrapper}>
        <Heading level={0}>
          {navn || polyglot.t('notFound.metadata.notDefined')}
        </Heading>
        {editable && (
          <ModalButton
            toggle={this.toggleModal}
            link
            showModal={editMode}
            icon={<Icon icon="pencil" size="2" spaced />}
            content={
              <Dialog
                onExit={this.toggleModal}
                render={props => (
                  <div className={styles.modalWrapper}>
                    <Field
                      label="Navn"
                      name="inputName"
                      refFunc={props.firstRef}
                      value={setDefault(inputName, navn)}
                      onChange={this.handleChange}
                    />
                    <Field
                      label="Fotavtrykk"
                      name="fotavtrykk"
                      value={setDefault(inputFotavtrykk, fotavtrykk)}
                      type="number"
                      onChange={this.handleChange}
                    />
                    <Button
                      kind="primary"
                      refFunc={props.lastRef}
                      onClick={this.onSubmit}
                    >
                      {polyglot.t('buttons.save')}
                    </Button>
                  </div>
                )}
              />
            }
          />
        )}
      </div>
    );
  }
}

BuildingEdit.propTypes = {
  actions: PropTypes.shape({
    setFootprint: PropTypes.func,
    setBuildingAlias: PropTypes.func,
  }),
  building: PropTypes.shape({
    navn: PropTypes.string,
    fotavtrykk: PropTypes.number,
  }),
  postNameSuccess: PropTypes.bool,
  postFotavtrykkSuccess: PropTypes.bool,
  nameError: PropTypes.string,
  footprintError: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    propertyEdit: state.properties.property.editable,
    postNameSuccess: state.buildings.postBuildingName.postSuccess,
    postFotavtrykkSuccess: state.buildings.setFootprint.postSuccess,
    buildingEdit: state.buildings.building.building.editable,
    realestateEdit: state.realestates.realestate.editable,
    nameError: state.buildings.postBuildingName.error,
    footprintError: state.buildings.setFootprint.error,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...buildingActions,
        ...widgetsActions,
      },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BuildingEdit);
