export const SEARCH_REQUEST = 'SEARCH_REQUEST';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_ERROR = 'SEARCH_ERROR';

export const GET_STATS = 'GET_STATS';
export const GOT_STATS = 'GOT_STATS';
export const GET_STATS_FAILED = 'GET_STATS_FAILED';

// Export action creators
export const searchRequest = payload => ({
  type: SEARCH_REQUEST,
  path: '/sok',
  payload,
});
export const searchSuccess = response => ({ type: SEARCH_SUCCESS, response });
export const searchError = (query, error) => ({ type: SEARCH_ERROR, error });

export const getStats = (payload, date = '') => ({
  type: GET_STATS,
  path: `/stats/${date}`,
  payload,
});
export const gotStats = response => ({ type: GOT_STATS, response });
export const getStatsFailed = error => ({ type: GET_STATS_FAILED, error });

// Define initial state for this reducer
export const initialState = {
  data: { result: [], hits: 0 },
  stats: { grunneiendom: {}, bygning: {}, eiendom: {}, lokale: {} },
  rapportMeta: {},
  loading: false,
  loadingStats: false,
  error: '',
};

// Export the reducer
export default function searchReducer(state = initialState, action) {
  switch (action.type) {
    case SEARCH_REQUEST: {
      return Object.assign({}, state, {
        loading: true,
        data: { result: [], hits: 0 },
      });
    }
    case SEARCH_SUCCESS: {
      return Object.assign({}, state, {
        data: {
          hits: action.response.hits,
          result: action.response.result,
        },
        loading: false,
        error: '',
      });
    }
    case SEARCH_ERROR: {
      return Object.assign({}, state, {
        loading: false,
        error: action.error,
      });
    }

    case GET_STATS: {
      return Object.assign({}, state, { loadingStats: true });
    }
    case GOT_STATS: {
      return Object.assign({}, state, {
        loadingStats: false,
        stats: action.response.stats,
        rapportMeta: action.response.rapportMeta,
      });
    }
    case GET_STATS_FAILED: {
      return Object.assign({}, state, {
        loadingStats: false,
        error: action.error,
      });
    }
    default:
      return state;
  }
}
