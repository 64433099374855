exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* STATENS LOKALER COLOR PALETTE */\n\n:root {\n\n  /* colors for input fields */\n\n  /* Button colors */\n\n  /* Anchor colors */\n\n  /* STATSBYGG PRIMARY COLORS */\n\n  /* Packet colors */\n  /* Statsbygg Glassblå 01-03 */\n  /* SECONDARY COLORS - NEUTRAL */\n  /* Statsbygg Nøytral grå 01-02 */\n  /* Statsbygg Stein */\n  /* Statsbygg Jord */\n  /* TERTIARY COLORS - MARKER COLORS */\n  /* Statsbygg Sennep */\n  /* Statsbygg Multe */\n  /* Statsbygg Laks */\n  /* Statsbygg Lyng */\n}\n\n.Checkbox__label___2zHhi {\n  position: relative;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  margin: 15px 0;\n  padding-left: 30px;\n  cursor: pointer;\n  font-weight: 600\n}\n\n.Checkbox__label___2zHhi:hover > .Checkbox__fakeBox___3rn9A, .Checkbox__label___2zHhi:focus > .Checkbox__fakeBox___3rn9A {\n  background: lightgrey;\n}\n\n.Checkbox__disabledLabel___2X2m4 {\n  position: relative;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  margin: 15px 0;\n  padding-left: 30px;\n  cursor: pointer;\n  font-weight: 400;\n  font-style: italic;\n}\n\n.Checkbox__inverted___18QLB {\n  padding: 8px\n}\n\n.Checkbox__inverted___18QLB > .Checkbox__fakeBox___3rn9A {\n  margin-left: 12px;\n  position: inherit;\n}\n\n.Checkbox__input___2GtHz {\n  position: absolute;\n  z-index: -1;\n  opacity: 0;\n}\n\n.Checkbox__fakeBox___3rn9A {\n  position: absolute;\n  top: 2px;\n  left: 0;\n  width: 20px;\n  height: 20px;\n  border: 1px solid #c6c6c6;\n}\n\n.Checkbox__disabledFakeBox___IrdGG {\n  position: absolute;\n  top: 2px;\n  left: 0;\n  width: 20px;\n  height: 20px;\n  background-color: #c6c6c6;\n  border: 1px solid #c6c6c6;\n}\n\n/* Checked state */\n\n.Checkbox__label___2zHhi input:checked ~ .Checkbox__fakeBox___3rn9A {\n  background-color: #147e87;\n  border: 2px solid #147e87;\n}\n", ""]);

// exports
exports.locals = {
	"label": "Checkbox__label___2zHhi",
	"fakeBox": "Checkbox__fakeBox___3rn9A",
	"disabledLabel": "Checkbox__disabledLabel___2X2m4",
	"inverted": "Checkbox__inverted___18QLB",
	"input": "Checkbox__input___2GtHz",
	"disabledFakeBox": "Checkbox__disabledFakeBox___IrdGG"
};