import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Heading, Link } from 'components';
import styles from './LogoText.css';

const Text = 'STATENS LOKALER';

const LogoText = ({ frontpage, footer, dashboard, to, ...props }) => {
  const classes = classNames(styles.wrapper, {
    [styles.frontpage]: frontpage,
    [styles.footer]: footer,
    [styles.dashboard]: dashboard,
  });
  const HText = dashboard ? (
    <Heading level={1} noMargin>
      {Text}
    </Heading>
  ) : (
    <Heading noMargin>{Text}</Heading>
  );
  if (to) {
    return (
      <div className={classes} {...props}>
        <Link to={to} logo>
          {HText}
        </Link>
      </div>
    );
  }
  return (
    <div className={classes} {...props}>
      {HText}
    </div>
  );
};

LogoText.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  frontpage: PropTypes.bool,
  footer: PropTypes.bool,
  dashboard: PropTypes.bool,
};

export default LogoText;
