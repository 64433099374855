// @flow

import React from 'react';
import { LeieObjektInput, Heading, ModalButton, ResultItem } from 'components';
import type { Leieobjekt } from 'types';
import { localize } from 'utils/utils';
import Dialog from '../../../containers/Dialog';
import styles from '../../organisms/ContractPanel/ContractPanel.css';

type Props = {
  leieobjekter: Array<Leieobjekt>,
  hasEditAccess: boolean,
  index: number,
  modals: {},
  toggleModal(string): void,
  leieObjectChange(): void,
  leieObjectInput: {},
  kontor: boolean,
  buttonClick(): void,
  deleteItem(): void,
  periodestart: string,
};

const LeieObjektList = ({
  leieobjekter,
  hasEditAccess,
  index,
  modals,
  toggleModal,
  leieObjectChange,
  leieObjectInput,
  kontor,
  buttonClick,
  deleteItem,
  periodestart,
}: Props) => (
  <div className={styles.areas}>
    <Heading green bottomMargin level={4}>
      {'Leieobjekter'}
    </Heading>
    {leieobjekter.map(it => {
      const {
        leieobjektperiode: {
          bruttoareal,
          arbeidsrelatertareal,
          spesialformaalareal,
          parkeringsareal,
          framleieareal,
        } = {},
        totalareal,
      } = it;
      const showArbeidsRel = !it.kontor;
      const showSpesialFor = it.kontor;
      return (
        it.aktiv && (
          <div key={it.id} className={styles.areasWrapper}>
            <ResultItem
              header={it.navn}
              itemArray={[
                it.kontor && 'Kontor',
                `BTA ${localize(bruttoareal) || 0} m\u00B2`,
                showArbeidsRel &&
                  `Arbeidsrelatert ${localize(arbeidsrelatertareal) ||
                    0} m\u00B2`,
                showSpesialFor &&
                  `Til spesialformål ${localize(spesialformaalareal) ||
                    0} m\u00B2`,
                `Parkering ${localize(parkeringsareal) || 0} m\u00B2`,
                `Fremleie ${localize(framleieareal) || 0} m\u00B2`,
                `Totalt ${localize(totalareal)} m\u00B2`,
              ].filter(_ => !!_)}
              button={
                hasEditAccess && (
                  <ModalButton
                    key={it.id}
                    showModal={!!modals[`showModalLeieobjekt${it.id}${index}`]}
                    toggle={() => toggleModal(`showModalLeieobjekt${it.id}`)}
                    link
                    buttonTitle="Rediger"
                  >
                    <Dialog
                      onExit={() => toggleModal(`showModalLeieobjekt${it.id}`)}
                      render={dialogProps => (
                        <LeieObjektInput
                          {...{
                            buttonClick,
                            abortClick: buttonClick,
                            deleteItem,
                            periodestart,
                            ...leieObjectInput,
                            ...dialogProps,
                          }}
                          defaultValues={{ ...it }}
                          onChange={leieObjectChange}
                        />
                      )}
                    />
                  </ModalButton>
                )
              }
            />
          </div>
        )
      );
    })}
    {hasEditAccess && (
      <ModalButton
        className={styles.modal}
        showModal={!!modals[`showModalLeieobjekt${index}`]}
        toggle={() => toggleModal('showModalLeieobjekt')}
        link
        buttonTitle="+ Legg til leieobjekt"
      >
        <Dialog
          onExit={() => toggleModal('showModalLeieobjekt')}
          render={dialogProps => (
            <LeieObjektInput
              {...{
                buttonClick,
                abortClick: buttonClick,
                deleteItem,
                periodestart,
                ...leieObjectInput,
                ...dialogProps,
              }}
              defaultValues={{
                kontor,
                bruttoareal: 0,
              }}
              onChange={leieObjectChange}
            />
          )}
        />
      </ModalButton>
    )}
  </div>
);

LeieObjektList.defaultProps = {
  leieobjekter: [],
};

export default LeieObjektList;
