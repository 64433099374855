exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* VIEWPORTS */\n\n:root {\n}\n\n.LedigeLokalerTemplate__wrapper___1S83F {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n}\n\n.LedigeLokalerTemplate__leftPanel___3nr7j {\n  margin-right: 20px;\n  -webkit-box-flex: 2;\n      -ms-flex: 2 1 35%;\n          flex: 2 1 35%;\n}\n\n.LedigeLokalerTemplate__rightPanel___bP2LY {\n  -webkit-box-flex: 5;\n      -ms-flex: 5 1 55%;\n          flex: 5 1 55%;\n  margin-right: 20px;\n}\n\n.LedigeLokalerTemplate__row___3ujmV {\n  -ms-flex-flow: wrap;\n      flex-flow: wrap;\n  -webkit-box-orient: horizontal;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: row;\n          flex-direction: row;\n  -webkit-box-pack: start;\n      -ms-flex-pack: start;\n          justify-content: flex-start;\n  margin-bottom: 45px;\n}\n", ""]);

// exports
exports.locals = {
	"wrapper": "LedigeLokalerTemplate__wrapper___1S83F",
	"leftPanel": "LedigeLokalerTemplate__leftPanel___3nr7j",
	"rightPanel": "LedigeLokalerTemplate__rightPanel___bP2LY",
	"row": "LedigeLokalerTemplate__row___3ujmV"
};