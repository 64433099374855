// @flow

import React, { Component } from 'react';
import { NewReportTemplate } from 'components';
import type { RouterHistory } from 'types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as rapportActions from 'redux/modules/report/report';
import * as widgetActions from 'redux/modules/widgets/widgets';

type Props = {
  history: RouterHistory,
  match: {
    params: {
      id: number,
    },
  },
  actions: {
    getReport(number): void,
    displayAlert({}): void,
    saveReport({}): void,
    publishReport({}): void,
    deleteReport(number): void,
  },
  report: {
    id: number,
    navn: string,
    url: string,
    synligForUnderenheter: boolean,
    virksomhet: number,
    role: Array<string>,
  },
  loadingPublishReport: boolean,
  loadingSaveReport: boolean,
  loadingDelete: boolean,
  error: string,
};

type State = {
  name?: string,
  url?: string,
  visibleDown?: boolean,
  org?: {
    value: number,
  },
  role?: Array<string>,
  preview?: boolean,
};

class NewReport extends Component<Props, State> {
  state = {};

  componentDidMount() {
    if (this.props.match.params) {
      this.props.actions.getReport(this.props.match.params.id);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      id,
      navn,
      synligForUnderenheter,
      url,
      virksomhet,
      role,
    } = nextProps.report;
    if (id && !this.props.report.id) {
      this.setState({
        name: navn,
        url,
        visibleDown: synligForUnderenheter,
        org: { value: virksomhet },
        role,
      });
    } else if (
      !nextProps.loadingPublishReport &&
      this.props.loadingPublishReport &&
      !nextProps.error
    ) {
      this.props.actions.displayAlert({
        kind: 'success',
        text: 'Rapport er publisert!',
      });
      this.props.history.push('/d/admin/rapport');
    } else if (
      !nextProps.loadingSaveReport &&
      this.props.loadingSaveReport &&
      !nextProps.error
    ) {
      this.props.actions.displayAlert({
        kind: 'success',
        text: 'Rapport er lagret',
      });
      this.props.history.push('/d/admin/rapport');
    } else if (
      !nextProps.loadingDelete &&
      this.props.loadingDelete &&
      !nextProps.error
    ) {
      this.props.actions.displayAlert({
        kind: 'success',
        text: 'Rapport er slettet',
      });
      this.props.history.push('/d/admin/rapport');
    }
  }

  onInputChange = e => {
    if (e.target.name === 'visibleDown')
      this.setState({ visibleDown: !this.state.visibleDown });
    else this.setState({ [e.target.name]: e.target.value });
  };

  onSelectChange = (e, type) => {
    this.setState({ [type]: Array.isArray(e) ? e.map(it => it.value) : e });
  };

  onSave = () => {
    this.props.actions.saveReport({
      ...this.props.report,
      navn: this.state.name,
      synligForUnderenheter: this.state.visibleDown,
      url: this.state.url,
      virksomhet: this.state.org && this.state.org.value,
      role: this.state.role,
    });
  };

  onPublish = () => {
    this.props.actions.publishReport({
      ...this.props.report,
      navn: this.state.name,
      synligForUnderenheter: this.state.visibleDown,
      url: this.state.url,
      virksomhet: this.state.org && this.state.org.value,
      role: this.state.role,
    });
  };

  deleteReport = () => {
    this.props.actions.deleteReport(this.props.report.id);
  };

  togglePreview = () => this.setState({ preview: !this.state.preview });

  render() {
    return (
      <NewReportTemplate
        {...this.props}
        {...this.state}
        onInputChange={this.onInputChange}
        onSelectChange={this.onSelectChange}
        deleteReport={this.deleteReport}
        onSave={this.onSave}
        onPublish={this.onPublish}
        togglePreview={this.togglePreview}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    loadingSaveReport: state.report.loadingSaveReport,
    loadingPublishReport: state.report.loadingPublishReport,
    error: state.report.error,
    report: state.report.report,
    loadingDelete: state.report.loadingDelete,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...rapportActions, ...widgetActions },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewReport);
