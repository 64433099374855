// @flow

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import polyglot from 'i18n';
import * as helpActions from 'redux/modules/help/help';
import * as widgetsActions from 'redux/modules/widgets/widgets';
import { HelpContent, ExpandableList, Heading, Panel } from 'components';
import userSelector from 'redux/modules/user/userSelector';

import type { UserData } from 'types';

type Props = {
  actions: {
    getAllHelp(): void,
    postHelp({
      sti: string,
    }): void,
  },
  userData: UserData,
  allHelp: Array<any>,
  savedHelp: boolean,
};

type State = {
  openPanel: number,
  editMode: boolean,
  helpInput: {
    overskrift?: string,
    hjelpetekst?: string,
    videoUrl?: string,
  },
};

class HelpPage extends Component<Props, State> {
  state = { openPanel: 0, editMode: false, helpInput: {} };

  componentDidMount() {
    this.props.actions.getAllHelp();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.savedHelp && !this.props.savedHelp) {
      this.props.actions.getAllHelp();
    }
  }

  onChange = e => {
    const {
      target: { id, value },
    } = e;
    this.setState(prevState => ({
      helpInput: {
        ...prevState.helpInput,
        [id]: value,
      },
    }));
  };

  postNewHelp = e => {
    if (e) e.preventDefault();
    this.props.actions.postHelp({
      ...this.state.helpInput,
      id: this.props.allHelp[this.state.openPanel].sti,
    });
    this.setState({ [`editMode${this.state.openPanel}`]: false });
  };

  toggleEdit = (it, i) =>
    this.setState(prevState => ({
      [`editMode${i}`]: !prevState[`editMode${i}`],
      helpInput: it,
    }));

  handlePanelClick = i =>
    this.setState({ openPanel: this.state.openPanel === i ? -1 : i });

  handleCancel = () => {
    const openHelp = this.props.allHelp[this.state.openPanel];
    const cancel = () =>
      this.setState({
        [`editMode${this.state.openPanel}`]: false,
        helpInput: {},
      });

    if (
      openHelp.overskrift !== this.state.helpInput.overskrift ||
      openHelp.hjelpetekst !== this.state.helpInput.hjelpetekst
    ) {
      if (confirm(polyglot.t('help.reallyCancel'))) {
        cancel();
      }
    } else cancel();
  };

  render() {
    const canEdit = this.props.userData.tilganger.some(
      it => it === 'ADMINISTRER_HJELP',
    );
    return (
      <Panel>
        <Heading level={0}>{polyglot.t('help.header')}</Heading>
        <ExpandableList
          handleClick={this.handlePanelClick}
          type="help"
          openPanel={this.state.openPanel}
        >
          {this.props.allHelp.map((it, i) => (
            <HelpContent
              editMode={this.state[`editMode${i}`]}
              key={it.sti}
              canEdit={canEdit}
              toggleEdit={() => this.toggleEdit(it, i)}
              id={i}
              helpObj={it}
              onChange={this.onChange}
              helpInput={this.state[`editMode${i}`] ? this.state.helpInput : it}
              onSubmit={this.postNewHelp}
              handleCancel={this.handleCancel}
              open
            />
          ))}
        </ExpandableList>
      </Panel>
    );
  }
}

function mapStateToProps(state) {
  return {
    allHelp: state.help.allHelp.allHelp,
    savedHelp: state.help.pageHelp.loaded,
    loading: state.help.allHelp.loading,
    loadingPost: state.help.pageHelp.loading,
    userData: userSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...helpActions, ...widgetsActions },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HelpPage);
