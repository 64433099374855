import React from 'react';
import PropTypes from 'prop-types';
import polyglot from 'i18n';
import {
  Field,
  Button,
  SelectField,
  Bubbles,
  ResultItem,
  Toggle,
  HelpToolTipButton,
} from 'components';
import { validateOrgnr } from 'utils/utils';
import styles from './ContractWizard.css';

const ContractWizardStep3 = ({
  buttonClick,
  step4,
  onChange,
  onSelectChange,
  user,
  loadingOrg,
}) => {
  const foundOrg = step4.verifiedOrg && (
    <ResultItem
      header={`Utleier: ${step4.verifiedOrg.navn}`}
      itemArray={[
        `Adresse: ${step4.verifiedOrg.forretningsadresse &&
          step4.verifiedOrg.forretningsadresse.adressenavn}`,
        `Orgnr: ${step4.verifiedOrg.organisasjonsnummer}`,
      ]}
    />
  );

  const helpToolTipButton = text => {
    return <HelpToolTipButton title={text} />;
  };

  return (
    <div className={styles.wrapper}>
      <Toggle
        label="Selvforvaltende?"
        name="selvforvaltende"
        id="selvforvaltende"
        value={step4.selvforvaltende}
        onChange={onChange}
        helpToolTipButton={helpToolTipButton(
          polyglot.t('helpToolTipButton.newLokale.step3.selvforvaltende'),
        )}
      />
      <SelectField
        required
        label={polyglot.t('newContract.step4.leietaker')}
        placeholder="Velg"
        disabled={step4.selvforvaltende}
        selectedValue={step4.leietaker && step4.leietaker.value}
        matchProp="label"
        testid="selectLandlord"
        optionUrl={`/organisasjon/brukere/${user.organisasjon.id}`}
        onChange={e => onSelectChange(e, 'leietaker')}
        helpToolTipButton={helpToolTipButton(
          polyglot.t('helpToolTipButton.newLokale.step3.leietaker'),
        )}
      />
      <div className={styles.fieldButtonWrapper}>
        <Field
          required
          name="utleier"
          disabled={step4.selvforvaltende}
          value={step4.inputOrg && step4.inputOrg.value}
          onChange={onChange}
          label={polyglot.t('newContract.step4.utleier')}
          placeholder="Org nr."
          maxLength="9"
          validationState={validateOrgnr(step4.inputOrg)}
          onKeyPress={e =>
            e.key === 'Enter' ? buttonClick({ target: { id: 'getOrg' } }) : ''
          }
          helpToolTipButton={helpToolTipButton(
            polyglot.t('helpToolTipButton.newLokale.step3.utleier'),
          )}
        />
        <div className={styles.buttonWrapper}>
          <Button
            id="getOrg"
            kind="primary"
            disabled={validateOrgnr(step4.inputOrg) !== 'success'}
            onClick={buttonClick}
          >
            {loadingOrg ? <Bubbles /> : 'Søk'}
          </Button>
        </div>
      </div>
      {foundOrg}
      <div className={styles.buttonWrapper}>
        <Button
          id="next"
          kind="primary"
          disabled={!(step4.verifiedOrg && step4.leietaker)}
          onClick={buttonClick}
        >
          {'Neste'}
        </Button>
      </div>
    </div>
  );
};

ContractWizardStep3.propTypes = {
  buttonClick: PropTypes.func,
  step4: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func,
  onSelectChange: PropTypes.func,
  loadingOrg: PropTypes.bool,
  user: PropTypes.objectOf(PropTypes.any),
};

export default ContractWizardStep3;
