import React from 'react';
import { Input, ErrorMessage, Label } from 'components';
import { Field } from 'formik';
import styles from './FormikField.css';

const FormikField = ({
  errors = {},
  name,
  label,
  required,
  feedbackMargin,
  helpToolTipButton,
  ...props
}) => (
  <div className={feedbackMargin && styles.feedbackMargin}>
    {label && (
      <Label htmlFor={name}>
        {label} {required && <span className={styles.required}>*</span>}
        {helpToolTipButton}
      </Label>
    )}
    <Field
      aria-label={label}
      error={errors[name]}
      name={name}
      component={Input}
      {...props}
    />
    <ErrorMessage feedback={errors[name]} />
  </div>
);

export default FormikField;
