import { takeLatest, all } from 'redux-saga/effects';

import * as MELDING from 'redux/modules/melding/melding';

import { handleCallToApi, handlePostToApi } from './sagaHandlers';

export default function* meldingSaga() {
  yield all([
    takeLatest(
      MELDING.GET_MELDING,
      handleCallToApi,
      MELDING.getMeldingSuccess,
      MELDING.getMeldingFail,
    ),
    takeLatest(
      MELDING.SEND_MELDING,
      handlePostToApi,
      MELDING.sendMeldingSuccess,
      MELDING.sendMeldingFail,
    ),
  ]);
}
