import { combineReducers } from 'redux';
import createAsyncRedux from '../../../utils/ReduxHelpers';

const getHelpRedux = createAsyncRedux({
  constantName: 'HELP',
  path: '/hjelp',
  initialState: {
    help: {
      overskrift: '',
      hjelpetekst: '',
    },
  },
  responseName: 'help',
});

const getAllHelpRedux = createAsyncRedux({
  constantName: 'GET_ALL_HELP',
  path: '/hjelp/alle',
  initialState: { allHelp: [] },
  responseName: 'allHelp',
});

// Export action creators
export const getHelp = getHelpRedux.getWithId;
export const postHelp = getHelpRedux.postWithId;
export const getAllHelp = getAllHelpRedux.get;

// Export the reducer
export const helpReducer = combineReducers({
  pageHelp: getHelpRedux.reducer,
  allHelp: getAllHelpRedux.reducer,
});

export const helpSaga = getHelpRedux.sagas;
export const helpAllSaga = getAllHelpRedux.sagas;

export default helpReducer;
