import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import classNames from 'classnames';
import styles from './Link.css';

const Link = ({
  breadcrumb,
  plain,
  logo,
  to,
  nav,
  footer,
  className,
  tabIndex,
  refFunc,
  deleteStyle,
  classic,
  ...props
}) => {
  const classes = classNames(styles.link, className, {
    [styles.breadcrumb]: breadcrumb,
    [styles.hover]: props.hover,
    [styles.focus]: props.focus,
    [styles.logo]: logo,
    [styles.plain]: plain,
    [styles.footer]: footer,
    [styles.nav]: nav,
    [styles.delete]: deleteStyle,
    [styles.classic]: classic,
  });
  if (to) {
    return (
      <RouterLink
        to={to}
        onClick={e => e.stopPropagation()}
        {...props}
        tabIndex={tabIndex}
        className={classes}
      >
        {props.children}
      </RouterLink>
    );
  }
  return (
    <a
      {...props}
      ref={refFunc}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          e.preventDefault();
          props.onClick(e);
        }
      }}
      role="button"
      tabIndex={tabIndex || 0}
      className={classes}
    >
      {props.children}
    </a>
  );
};

Link.propTypes = {
  to: PropTypes.string,
  hover: PropTypes.bool,
  hoverVisited: PropTypes.bool,
  breadcrumb: PropTypes.bool,
  plain: PropTypes.bool,
  logo: PropTypes.bool,
  focus: PropTypes.bool,
  children: PropTypes.node,
  nav: PropTypes.bool,
  footer: PropTypes.bool,
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  refFunc: PropTypes.func,
  deleteStyle: PropTypes.bool,
};

export default Link;
