/* APP MAIN ENTRY POINT */
import 'whatwg-fetch';

import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import StackTrace from 'stacktrace-js';
import configureStore from './redux/configureStore';

import App from './hotModule';
import logPageView from './setupGA';

const store = configureStore();

// Track window.onerror with stacktrace.js
const callback = msg => stackframes => {
  // const stringifiedStack = stackframes.map(sf => sf.toString()).join('\n');
  StackTrace.report(stackframes, '/log', msg);
};

const errback = err => console.log(err.message);

window.onerror = (msg, file, line, col, error) => {
  StackTrace.fromError(error)
    .then(callback(msg))
    .catch(errback);
};

const root = document.getElementById('app');

const renderApp = () => (
  <Provider store={store}>
    <BrowserRouter onUpdate={logPageView}>
      <App />
    </BrowserRouter>
  </Provider>
);

render(renderApp(), root);
