import { takeLatest, all } from 'redux-saga/effects';

import * as LEDIGELOKALER from 'redux/modules/ledigeLokaler/ledigeLokaler';

import { handleCallToApi, handlePostToApi } from './sagaHandlers';

export default function* ledigeLokalerSaga() {
  yield all([
    takeLatest(
      LEDIGELOKALER.SAVE_LEDIG_LOKALE,
      handlePostToApi,
      LEDIGELOKALER.saveLedigLokaleSuccess,
      LEDIGELOKALER.saveLedigLokaleFailed,
    ),
    takeLatest(
      LEDIGELOKALER.GET_LEDIG_LOKALE,
      handleCallToApi,
      LEDIGELOKALER.getLedigLokaleSuccess,
      LEDIGELOKALER.getLedigLokaleFailed,
    ),
  ]);
}
