import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Table,
  Alert,
  FormikField,
  Button,
  Bubbles,
  HelpToolTipButton,
} from 'components';
import polyglot from 'i18n';
import { Formik, Form } from 'formik';
import { keysToIdArray } from 'utils/utils';
import styles from './PeriodicWizard.css';

const allLeieobjektPeriodeFields = {
  bruttoareal: 0,
  spesialformaalareal: 0,
  arbeidsrelatertareal: 0,
  parkeringsareal: 0,
  framleieareal: 0,
};

const allAnsattFields = {
  antallAarsverk: '0',
  antallAnsatte: '0',
  antallInnleide: '0',
};

const PeriodicWizard4 = ({
  lokale,
  aktiveLeieforhold,
  buttonClick,
  actions,
  showHelpText,
  savePeriodicReport,
  getSavedReport,
}) => {
  if (!lokale.lokaleperiode) return null;

  const formRef = useRef();

  useEffect(() => {
    const saveInterval = setInterval(() => {
      savePeriodicReport(formRef.current.values);
    }, 60000);
    return () => clearInterval(saveInterval);
  }, []);

  const helpToolTipButton = text => <HelpToolTipButton title={text} />;

  const initValues = () => {
    const report = getSavedReport();
    if (report) {
      return report;
    }

    return aktiveLeieforhold.reduce((acc, { leieforholdperiodeNy }) => {
      if (leieforholdperiodeNy) {
        acc[`husleiekostnad_${leieforholdperiodeNy.id}`] =
          leieforholdperiodeNy.husleiekostnad;
        acc[`framleiereduksjon_${leieforholdperiodeNy.id}`] =
          leieforholdperiodeNy.framleiereduksjon;
      }
      return acc;
    }, {});
  };

  const onSubmit = values => {
    const arrayWithIdObjects = keysToIdArray(values);
    actions.updateContractPeriod(
      lokale.id,
      lokale.leieforholds.map(leieforhold => {
        if (leieforhold.leieforholdperiodeNy) {
          return {
            ...leieforhold.leieforholdperiodeNy,
            ...arrayWithIdObjects.find(
              it => it.id === leieforhold.leieforholdperiodeNy.id,
            ),
          };
        }
        return {};
      }),
    );
    let newLokalePeriode = {
      ...lokale.lokaleperiode,
      lokalkostnader: values.lokalkostnader,
    };

    // Hvis leieforholdet gikk ut i fjor, skal leieobjektverdiene (bruttoareal, parkeringsareal osv) settes til

    const leieobjekterMedNullVerdier = [];

    lokale.leieforholds.forEach(leieforhold => {
      if (
        leieforhold.haddeKontraktSluttIFjor &&
        Array.isArray(leieforhold.leieobjekter)
      ) {
        leieforhold.leieobjekter.forEach(leieobjekt => {
          if (leieobjekt.leieobjektperiode) {
            leieobjekterMedNullVerdier.push({
              id: leieobjekt.leieobjektperiode.id,
              fra_dato: leieobjekt.leieobjektperiode.fraDato,
              ...allLeieobjektPeriodeFields,
            });
          }
        });
      }
    });

    if (leieobjekterMedNullVerdier.length !== 0) {
      actions.saveLeieobjekt(leieobjekterMedNullVerdier);
    }

    if (lokale.skalKunRapporteresKostnader) {
      newLokalePeriode = {
        ...newLokalePeriode,
        ...allAnsattFields,
      };
    }
    savePeriodicReport(values);
    actions.setLokalePeriode(newLokalePeriode, lokale.id);
  };

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize
      initialValues={{
        ...initValues(),
        lokalkostnader: lokale.lokaleperiode.lokalkostnader,
      }}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, errors, values }) => (
        <Form>
          {aktiveLeieforhold.length > 0 ? (
            <Table
              drilldown={false}
              dataRows={aktiveLeieforhold.map(
                ({ navn, status, leieforholdperiodeNy }) => {
                  const gyldig = status !== 'UTGAATT';
                  return [
                    gyldig ? navn : <i> {navn} </i>,
                    leieforholdperiodeNy ? (
                      <FormikField
                        type="number"
                        errors={errors}
                        name={`husleiekostnad_${leieforholdperiodeNy.id}`}
                        orange={
                          values[`husleiekostnad_${leieforholdperiodeNy.id}`] ==
                          null
                        }
                      />
                    ) : (
                      <div />
                    ),
                    leieforholdperiodeNy ? (
                      <FormikField
                        type="number"
                        errors={errors}
                        name={`framleiereduksjon_${leieforholdperiodeNy.id}`}
                      />
                    ) : (
                      <div />
                    ),
                  ];
                },
              )}
              headerRow={[
                polyglot.t('leideLokaler.periodic.contract'),
                polyglot.t('leideLokaler.periodic.rentCosts'),
                polyglot.t('leideLokaler.periodic.rentSavings'),
              ]}
              helpToolButton={[
                null,
                polyglot.t('helpToolTipButton.periodicReport.step4.rentCosts'),
                polyglot.t(
                  'helpToolTipButton.periodicReport.step4.rentSavings',
                ),
              ]}
            />
          ) : (
            <Alert kind="info">
              {polyglot.t('leideLokaler.periodic.noContracts')}
            </Alert>
          )}
          <FormikField
            type="number"
            errors={errors}
            label={polyglot.t('leideLokaler.lokalkostnader')}
            name="lokalkostnader"
            orange={values.lokalkostnader == null}
            helpToolTipButton={helpToolTipButton(
              polyglot.t(
                'helpToolTipButton.periodicReport.step4.lokalkostnader',
              ),
            )}
          />
          <div className={styles.buttonWrapper}>
            <Button secondaryButton id="cancel" onClick={buttonClick}>
              {'Avbryt'}
            </Button>
            <Button secondaryButton id="prev" onClick={buttonClick}>
              {polyglot.t('leideLokaler.periodic.back')}
            </Button>
            <Button
              kind="primary"
              type="submit"
              disabled={isSubmitting || Object.keys(errors).length > 0}
            >
              {isSubmitting ? (
                <Bubbles />
              ) : (
                polyglot.t('leideLokaler.periodic.saveAndCont')
              )}
            </Button>
          </div>
          {showHelpText && (
            <span className={styles.requiredText}>
              {polyglot.t('leideLokaler.periodic.requiredText')}
            </span>
          )}
        </Form>
      )}
    </Formik>
  );
};

PeriodicWizard4.propTypes = {
  lokale: PropTypes.shape({ lokaleperiode: PropTypes.object }).isRequired,
  aktiveLeieforhold: PropTypes.arrayOf(
    PropTypes.shape({ navn: PropTypes.string }),
  ),
  buttonClick: PropTypes.func.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  showHelpText: PropTypes.bool,
  savePeriodicReport: PropTypes.func,
  getSavedReport: PropTypes.func,
  removeSavedReport: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    lokale: state.leideLokaler.lokaler,
  };
}

export default connect(mapStateToProps)(PeriodicWizard4);
