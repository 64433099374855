// @flow

import * as React from 'react';
import { Toggle, Field, FeedbackDiv, SelectField } from 'components';
import { validateNumber } from 'utils/utils';
import { eierforholdChoices, grunneiendomTypes } from 'utils/searchApiHelpers';
import polyglot from 'i18n';
import styles from './GlobalSearchPropertyFilters.css';

type Props = {
  filters: {
    mineProperties: boolean,
    areal: { value: string, toValue: string },
    antallBygg: { value: string },
    'eierforhold.eierforholdKode': { value: string },
    grunneiendomstype: { value: string },
    visMine: { properties: string },
    harBygninger: boolean,
  },
  isOrg: boolean,
  isPublic: boolean,
  handleFilterChange({ field: string, type?: string }): void,
  onSubmit(): void,
};

const TYPE = 'grunneiendom';

const GlobalSearchPropertyFilters = ({
  filters,
  handleFilterChange,
  onSubmit,
  isOrg,
  isPublic,
}: Props) => {
  const { value: arealFra, toValue: arealTil } = filters.areal || {};
  const { properties: showMyProperties } = filters.visMine || {};
  const { value: eierforhold } = filters['eierforhold.eierforholdKode'] || {};
  const { value: grunneiendomstype } = filters.grunneiendomstype || {};
  const { value: antallBygg } = filters.antallBygg || {};
  return (
    <div className={styles.filterWrapper}>
      {!isPublic &&
        isOrg && (
          <Toggle
            vertical
            onChange={() =>
              handleFilterChange({
                properties: !showMyProperties && TYPE,
                field: 'visMine',
              })
            }
            id="showMyProperties"
            label="Vis bare mine eiendommer?"
            value={showMyProperties}
          />
        )}
      <div className={styles.doubleField}>
        <Field
          value={arealFra || ''}
          onChange={e =>
            handleFilterChange({
              type: TYPE,
              field: 'areal',
              value: e.target.value || undefined,
              prefix: 'fom',
            })
          }
          aria-label="Areal fra"
          aria-describedby="arealFra-error areal-bigger-error"
          label="Areal"
          placeholder="Fra"
          id="arealFra"
          validationState={validateNumber(arealFra)}
          onKeyPress={e => e.key === 'Enter' && onSubmit()}
        />
        <Field
          value={arealTil || ''}
          onChange={e =>
            handleFilterChange({
              type: TYPE,
              field: 'areal',
              toValue: e.target.value || undefined,
              prefix: 'fom',
            })
          }
          aria-label="Areal Til"
          id="arealTil"
          aria-describedby="arealTil-error areal-bigger-error"
          placeholder="Til"
          onKeyPress={e => e.key === 'Enter' && onSubmit()}
          validationState={validateNumber(arealTil)}
        />
      </div>
      {parseInt(arealFra, 10) > parseInt(arealTil, 10) && (
        <FeedbackDiv biggerThan id="areal-bigger-error" />
      )}
      {validateNumber(arealFra) === 'error' && (
        <FeedbackDiv invalidNumber id="arealFra-error" />
      )}
      {validateNumber(arealTil) === 'error' && (
        <FeedbackDiv invalidNumber id="arealTil-error" />
      )}
      <SelectField
        label="Eierforhold"
        name="eierforhold"
        placeholder={polyglot.t('newContract.step1.choose')}
        selectedValue={eierforhold}
        choices={eierforholdChoices}
        onChange={e =>
          handleFilterChange({
            type: TYPE,
            value: e ? e.value : '',
            field: 'eierforhold.eierforholdKode',
          })
        }
        isClearable
      />
      <SelectField
        label="Grunneiendomstype"
        name="grunneiendomstype"
        placeholder={polyglot.t('newContract.step1.choose')}
        selectedValue={grunneiendomstype}
        choices={grunneiendomTypes.map(it => ({
          id: it.toLowerCase(),
          navn: it,
        }))}
        onChange={e =>
          handleFilterChange({
            type: TYPE,
            value: e ? e.value : '',
            field: 'grunneiendomstype',
          })
        }
        isClearable
      />
      <SelectField
        label="Har bygninger"
        name="harBygninger"
        placeholder={polyglot.t('newContract.step1.choose')}
        selectedValue={antallBygg}
        choices={[
          {
            id: '1',
            navn: 'Ja',
          },
          {
            id: '0',
            navn: 'Nei',
          },
        ]}
        onChange={e =>
          handleFilterChange({
            type: TYPE,
            value: e ? e.value : '',
            field: 'antallBygg',
          })
        }
        isClearable
      />
    </div>
  );
};

export default GlobalSearchPropertyFilters;
