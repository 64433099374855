// @flow
import { setDefaultNumber, validateNumber, toGuiDate } from './utils';

export const isValidInt = val => /^[0-9]*$/.test(val);

export const validateInts = values =>
  Object.keys(values).reduce((acc, value) => {
    if (!isValidInt(values[value])) {
      return { ...acc, [value]: 'Ikke et gyldig tall' };
    }
    return acc;
  }, {});

export const validateDecimal = decimal => {
  const valid = /^[0-9]*[.,]?[0-9]*$/.test(decimal);
  return !decimal || valid ? 'success' : 'error';
};

type LeieobjektInput = {
  bruttoareal: number,
  spesialformaalareal: number,
  arbeidsrelatertareal: number,
  framleieareal: number,
  parkeringsareal: number,
};

export const validateAreaSum = (
  inputObj: { kontor: boolean, ...LeieobjektInput },
  existingObj: LeieobjektInput = {},
): 'success' | 'error' => {
  const {
    bruttoareal,
    spesialformaalareal,
    arbeidsrelatertareal,
    framleieareal,
  } = inputObj;
  const spesArea = inputObj.kontor
    ? setDefaultNumber(spesialformaalareal, existingObj.spesialformaalareal)
    : setDefaultNumber(arbeidsrelatertareal, existingObj.arbeidsrelatertareal);
  const fremleieAreal = setDefaultNumber(
    framleieareal,
    existingObj.framleieareal,
  );
  const bruttoAreal = setDefaultNumber(bruttoareal, existingObj.bruttoareal);
  return validateNumber(spesArea + fremleieAreal, {
    lessThan: bruttoAreal,
  });
};

export const validDateFormat = (dateString: ?string) => {
  const regexp = /^\d{2}\.\d{2}\.\d{4}$/;
  if (dateString == null || typeof dateString !== 'string') return undefined;
  if (regexp.test(dateString)) return true;
  return false;
};

export const validateCoreData = values => {
  const errors = {};
  if (!validDateFormat(toGuiDate(values.leieperiodeStart))) {
    errors.leieperiodeStart = 'Ikke gyldig dato format';
  }

  if (!validDateFormat(toGuiDate(values.leieperiodeSlutt))) {
    errors.leieperiodeSlutt = 'Ikke gyldig dato format';
  }

  if (values.leieperiodeStart > values.leieperiodeSlutt) {
    errors.leieperiodeStart = 'Startdato må være før sluttdato!';
    errors.leieperiodeSlutt = 'Sluttdato må være før startdato!';
  }

  return errors;
};

export const validateEmail = (email: ?string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validateDateFormat = (dateString: ?string) =>
  validDateFormat(toGuiDate(dateString));

export default validateInts;
