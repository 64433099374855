exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".TableView__loading___3-DeH {\n  width: 100%;\n  height: 100%;\n  top: 0;\n  left: 0;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  position: absolute;\n  background-color: rgba(255, 255, 255, .5);\n}\n\n.TableView__wrapper___1mkW8 {\n  position: relative;\n  margin-top: 20px;\n}\n\n.TableView__pagination___MWJZb {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n}\n", ""]);

// exports
exports.locals = {
	"loading": "TableView__loading___3-DeH",
	"wrapper": "TableView__wrapper___1mkW8",
	"pagination": "TableView__pagination___MWJZb"
};