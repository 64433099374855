exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".GroupEdit__form___37FmI {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: start;\n      -ms-flex-pack: start;\n          justify-content: flex-start;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n}\n\n.GroupEdit__infoWrapper___LBY3U {\n  padding-top: 15px;\n  padding-bottom: 15px;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n}\n\n.GroupEdit__buttonWrapper___QSxvq {\n  padding-left: 5px;\n}\n", ""]);

// exports
exports.locals = {
	"form": "GroupEdit__form___37FmI",
	"infoWrapper": "GroupEdit__infoWrapper___LBY3U",
	"buttonWrapper": "GroupEdit__buttonWrapper___QSxvq"
};