import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Icon, Checkbox, Tooltip } from 'components';
import polyglot from 'i18n';
import styles from './TableRow.css';

const TableRow = ({
  header,
  data,
  handleChecked,
  dataType,
  bold,
  drilldown,
  feedbackMargin,
  bodyClick,
  rightAlignArray,
  tooltip,
  ...props
}) => {
  const tableData = data.map((it, i) => {
    const scope = i === 0 ? { scope: 'row' } : '';
    if (typeof it === 'boolean') {
      if (dataType === 'matrikkelAlert') {
        return (
          <td {...scope} className={styles.tableData} key={i}>
            {it ? (
              <Icon icon="eye" size="2" read />
            ) : (
              <Icon icon="eye" size="2" unread />
            )}
          </td>
        );
      }
      if (dataType === 'prosjekt')
        return (
          <td {...scope} className={styles.tableData} key={i}>
            {it ? 'Ja' : 'Nei'}
          </td>
        );
      return (
        <td {...scope} className={styles.tableData} key={i}>
          <Checkbox
            checked={it}
            onChange={handleChecked}
            disabled={dataType === 'user'}
            className={styles.checkbox}
          />
        </td>
      );
    }

    return (
      <td
        {...scope}
        className={classnames(
          styles.tableData,
          bold && styles.bold,
          !drilldown && styles.noClick,
          Array.isArray(rightAlignArray) &&
            rightAlignArray[i] &&
            styles.rightAlign,
        )}
        key={i}
      >
        {tooltip ? (
          <Tooltip disabled={!tooltip} title={tooltip}>
            {typeof it === 'string' ? it.toLowerCase() : it}
          </Tooltip>
        ) : typeof it === 'string' &&
        !(dataType === 'leideLokaler' && i === 0) ? (
          it.toLowerCase()
        ) : (
          it
        )}
      </td>
    );
  });

  const classes = classnames(styles.tableRow, {
    [styles.tableRowRead]: data[2] && dataType === 'matrikkelAlert',
    [styles.feedbackMargin]: feedbackMargin,
  });

  if (drilldown === undefined) {
    return (
      <tr {...props} className={classes}>
        {tableData}
      </tr>
    );
  }

  return (
    <tr
      {...props}
      onClick={bodyClick}
      tabIndex={0}
      aria-label={
        data[0] === ''
          ? polyglot.t('table.gaTilTomLink')
          : polyglot.t('table.gaTilLink') + data[0]
      }
      className={classes}
    >
      {tableData}
    </tr>
  );
};

TableRow.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.any,
    ]),
  ).isRequired,
  header: PropTypes.bool,
  id: PropTypes.bool,
  dataType: PropTypes.string,
  handleChecked: PropTypes.func,
  bold: PropTypes.bool,
  drilldown: PropTypes.bool,
  feedbackMargin: PropTypes.bool,
  bodyClick: PropTypes.func,
  rightAlignArray: PropTypes.arrayOf(PropTypes.bool),
};

export default TableRow;
