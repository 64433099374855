import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table, Alert, Button, Bubbles, FormikField } from 'components';
import { sumTotalAreal, keysToIdArray } from 'utils/utils';
import { validateAreaSum, isValidInt } from 'utils/validationHelpers';
import { Formik, Form } from 'formik';
import polyglot from 'i18n';
import styles from './PeriodicWizard.css';

const validation = (values, kontor) => {
  const errors = {};
  const arrayWithIdObjects = keysToIdArray(values);
  arrayWithIdObjects.forEach(it => {
    if (validateAreaSum({ ...it, kontor }) === 'error') {
      errors[`bruttoareal_${it.id}`] = 'Sum går ikke opp!';
    }
    if (it.bruttoareal < it.spesialformaalareal) {
      errors[`spesialformaalareal_${it.id}`] =
        'Spesialformålsareal kan ikke være større enn BTA!';
    }
    if (it.bruttoareal < it.arbeidsrelatertareal) {
      errors[`arbeidsrelatertareal_${it.id}`] =
        'Arbeidsrelatertareal kan ikke være større enn BTA!';
    }
  });
  Object.keys(values).forEach(field => {
    if (values[field] && !isValidInt(values[field]))
      errors[field] = 'Ikke et gyldig heltall';
  });
  return errors;
};

const fieldNames = kontor => [
  'bruttoareal',
  kontor ? 'spesialformaalareal' : 'arbeidsrelatertareal',
  'parkeringsareal',
  'framleieareal',
];

const PeriodicWizard2 = ({
  leieobjekter,
  kontor,
  actions,
  buttonClick,
  showHelpText,
  savePeriodicReport,
  getSavedReport,
}) => {
  const formRef = useRef();

  useEffect(() => {
    const saveInterval = setInterval(() => {
      savePeriodicReport(formRef.current.values);
    }, 60000);
    return () => clearInterval(saveInterval);
  }, []);

  const onSubmit = values => {
    const arrayWithIdObjects = keysToIdArray(values);
    const mappedResult = leieobjekter.map(({ leieobjektperiode }) => ({
      ...leieobjektperiode,
      ...arrayWithIdObjects.find(it => it.id === leieobjektperiode.id),
    }));
    savePeriodicReport(values);
    actions.saveLeieobjekt(mappedResult);
  };

  const initialValues = () => {
    const report = getSavedReport();
    if (report) {
      return report;
    }

    return leieobjekter.reduce((acc, { leieobjektperiode }) => {
      if (leieobjektperiode)
        fieldNames(kontor).forEach(field => {
          acc[`${field}_${leieobjektperiode.id}`] = leieobjektperiode[field];
        });
      return acc;
    }, {});
  };

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize
      initialValues={initialValues()}
      validate={values => validation(values, kontor)}
      onSubmit={onSubmit}
    >
      {({ values, isSubmitting, errors }) => (
        <Form>
          {leieobjekter.length > 0 ? (
            <Table
              responsive
              drilldown={false}
              dataRows={leieobjekter.map(
                ({ navn, leieforholdNavn, leieobjektperiode }) => {
                  if (leieobjektperiode) {
                    return [
                      leieforholdNavn,
                      navn,
                      kontor ? 'Ja' : 'Nei',
                      ...fieldNames(kontor).map(field => {
                        const name = `${field}_${leieobjektperiode.id}`;
                        return (
                          <React.Fragment>
                            <FormikField
                              type="number"
                              errors={errors}
                              name={name}
                              feedbackMargin
                              orange={
                                (field === 'bruttoareal' &&
                                  values[name] == null) ||
                                (field === 'arbeidsrelatertareal' &&
                                  !values[name])
                              }
                            />
                          </React.Fragment>
                        );
                      }),
                      `${sumTotalAreal(
                        keysToIdArray(values).find(
                          it => it.id === leieobjektperiode.id,
                        ),
                      )} kvm`,
                    ];
                  }
                  return [];
                },
              )}
              headerRow={[
                'Leieforhold',
                'Leieobjekt',
                'Kontor',
                'BTA',
                kontor
                  ? polyglot.t('leideLokaler.spesialformaalareal')
                  : polyglot.t('leideLokaler.arbeidsrelatertareal'),
                'Parkeringsareal',
                polyglot.t('leideLokaler.periodic.framleieareal'),
                'Total areal',
              ]}
              helpToolButton={[
                null,
                null,
                null,
                polyglot.t('helpToolTipButton.periodicReport.step2.bta'),
                polyglot.t('helpToolTipButton.periodicReport.step2.typeareal'),
                polyglot.t(
                  'helpToolTipButton.periodicReport.step2.parkeringsareal',
                ),
                polyglot.t('helpToolTipButton.periodicReport.step2.framleie'),
                polyglot.t(
                  'helpToolTipButton.periodicReport.step2.totaltareal',
                ),
              ]}
            />
          ) : (
            <Alert kind="info">
              {polyglot.t('leideLokaler.periodic.noObjects')}
            </Alert>
          )}
          <div className={styles.buttonWrapper}>
            <Button secondaryButton id="cancel" onClick={buttonClick}>
              {'Avbryt'}
            </Button>
            <Button secondaryButton id="prev" onClick={buttonClick}>
              {polyglot.t('leideLokaler.periodic.back')}
            </Button>
            <Button
              kind="primary"
              type="submit"
              disabled={isSubmitting || Object.keys(errors).length > 0}
            >
              {isSubmitting ? (
                <Bubbles />
              ) : (
                polyglot.t('leideLokaler.periodic.saveAndCont')
              )}
            </Button>
          </div>
          {showHelpText && (
            <span className={styles.requiredText}>
              {polyglot.t('leideLokaler.periodic.requiredText')}
            </span>
          )}
        </Form>
      )}
    </Formik>
  );
};

PeriodicWizard2.propTypes = {
  leieobjekter: PropTypes.arrayOf(
    PropTypes.shape({
      leieobjektperiode: PropTypes.object,
    }),
  ).isRequired,
  kontor: PropTypes.bool,
  actions: PropTypes.shape({
    saveLeieobjekt: PropTypes.func,
  }).isRequired,
  buttonClick: PropTypes.func,
  showHelpText: PropTypes.bool,
  savePeriodicReport: PropTypes.func,
  getSavedReport: PropTypes.func,
  removeSavedReport: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    lokale: state.leideLokaler.lokaler,
  };
}

export default connect(mapStateToProps)(PeriodicWizard2);
