// @flow
import React, { PureComponent } from 'react';
import { LedigeLokalerTemplate } from 'components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import type { Lokale, RouterHistory } from 'types';
import { toApiDate } from 'utils/utils';
import { setDataItem } from 'services/api';

import * as widgetsActions from 'redux/modules/widgets/widgets';
import * as leideLokalerActions from 'redux/modules/leideLokaler/leideLokaler';
import * as ledigeLokalerActions from 'redux/modules/ledigeLokaler/ledigeLokaler';

type Props = {
  lokale: Lokale,
  loadingGetLokale: boolean,
  actions: {
    getLokale(string): void,
    saveLedigLokale({}): void,
    displayAlert({ kind: string, text: string }): void,
  },
  match: {
    params: {
      lokalerId: string,
    },
  },
  history: RouterHistory,
};

type State = {
  leieobjekt: ?string,
  leieforhold: ?string,
  lokale: Lokale,
};

class LedigeLokaler extends PureComponent<Props, State> {
  state = {
    leieforhold: null,
    leieobjekt: null,
    lokale: {},
  };

  static getDerivedStateFromProps(props, state) {
    if (props.lokale !== state.lokale) {
      console.log('Lokale prop update', props);
      return {
        lokale: props.lokale,
      };
    }
    return null;
  }

  componentDidMount() {
    const { lokale, actions, match } = this.props;
    if (lokale && !lokale.id) {
      actions.getLokale(match.params.lokalerId);
    }
  }

  handleLeieChange = values => {
    const { name, value } = values;
    this.setState(prevState => {
      if (prevState.leieforhold !== null && prevState.leieforhold !== value) {
        return { leieobjekt: null, [name]: value };
      }
      return { [name]: value };
    });
  };

  handleEditLedigLokale = (leieforhold, leieobjekt) => {
    this.setState({ leieforhold, leieobjekt });
  };

  handleSubmit = async (values, formActions) => {
    const { actions, match } = this.props;

    const ledigLokale = {
      ...values,
      fraDato: toApiDate(values.fraDato),
      tilDato: toApiDate(values.tilDato),
    };

    try {
      await setDataItem('/ledigLokale', ledigLokale);
      formActions.setSubmitting(false);
      formActions.resetForm();
      actions.getLokale(match.params.lokalerId);
      actions.displayAlert({
        kind: 'success',
        text: ledigLokale.id
          ? 'Ledig lokalet er oppdatert'
          : 'Nytt ledig lokale opprettet',
      });
    } catch (error) {
      actions.displayAlert({
        kind: 'danger',
        text: 'Feil ved lagring av ledig lokale',
      });
      formActions.setSubmitting(false);
    }
  };

  render() {
    const { leieforhold, leieobjekt, lokale } = this.state;
    const { loadingGetLokale, history } = this.props;

    return (
      <LedigeLokalerTemplate
        {...{
          history,
          lokale,
          loadingGetLokale,
          leieforhold,
          leieobjekt,
          handleLeieChange: this.handleLeieChange,
          handleSubmit: this.handleSubmit,
          handleEditLedigLokale: this.handleEditLedigLokale,
        }}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    lokale: state.leideLokaler.lokaler,
    loadingGetLokale: state.leideLokaler.loadingGetLokale,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...ledigeLokalerActions, ...leideLokalerActions, ...widgetsActions },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LedigeLokaler);
