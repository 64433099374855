// @flow

import React from 'react';
import L from 'leaflet';
import { Marker, Popup } from 'react-leaflet';
import { withRouter } from 'react-router-dom';
import polyglot from 'i18n';
import { DataItem, Link } from 'components';
import type { Latlng, Lokale, Property, RouterHistory } from 'types';
import { localize } from 'utils/utils';

import styles from './MapMarker.css';

type Props = {
  position: Latlng,
  filled?: boolean,
  item: Lokale | Property,
  history: RouterHistory,
  sensitive: boolean,
};

const MapMarker = ({ position, filled, item, history, sensitive }: Props) => {
  const color =
    item.type === 'lokale' || item.type === 'bygning' ? 'blue' : 'orange';
  const icon = L.icon({
    // $FlowFixMe
    iconUrl: require(`components/atoms/Icon/icons/map-pin-${color}${
      filled ? '-filled' : ''
    }.png`),
    iconSize: [30, 45],
    iconAnchor: [22, 54],
    popupAnchor: [-3, -76],
  });

  const { adressenavn, postnummer, poststed } =
    (item.type === 'lokale' ? item.grunneiendom.adresse : item.adresse) || {};

  const buildingWrapper = item.type === 'bygning' && (
    <div className={styles.popupWrapper}>
      <h5>{item.navn || polyglot.t('notFound.metadata.notDefined')}</h5>
      <div className={styles.popupList}>
        {item.bygningsnr !== undefined && (
          <DataItem
            metaData={polyglot.t('building.bygningsnr')}
            data={item.bygningsnr}
          />
        )}
        {item.bruksareal !== undefined && (
          <DataItem
            metaData={polyglot.t('floors.bruksareal')}
            data={`${localize(item.bruksareal)} m\u00B2`}
          />
        )}
        {item.bruttoareal !== undefined && (
          <DataItem
            metaData={polyglot.t('floors.bruttoareal')}
            data={`${localize(item.bruttoareal)} m\u00B2`}
          />
        )}

        <div className={styles.link}>
          <Link onClick={() => history.push(`/d${item._links.self}`)}>
            {polyglot.t('globalSearch.detaljer')}
          </Link>
        </div>
      </div>
    </div>
  );

  const searchWrapper = item.type !== 'bygning' && (
    <div className={styles.popupWrapper}>
      <h5>{item.navn}</h5>
      <div>
        {adressenavn
          ? `${adressenavn},${postnummer} ${poststed}`
          : polyglot.t('globalSearch.ukjent')}
      </div>
      <div className={styles.popupList}>
        <DataItem
          metaData={polyglot.t('globalSearch.bruker')}
          data={
            item.type === 'lokale'
              ? item.bruker.navn
              : item.eierforhold && item.eierforhold.organisasjon.navn
          }
        />
        {item.gardsnr && (
          <DataItem
            metaData={polyglot.t('globalSearch.gnr')}
            data={`${item.gardsnr} / ${item.bruksnr}`}
          />
        )}
        {item.kontor !== undefined && (
          <DataItem
            metaData={polyglot.t('globalSearch.kontor')}
            data={
              item.kontor
                ? polyglot.t('mapData.true')
                : polyglot.t('mapData.false')
            }
          />
        )}
        {sensitive &&
          item.totalareal !== undefined && (
            <DataItem
              metaData={polyglot.t('globalSearch.areal')}
              data={`${localize(item.totalareal)} m\u00B2`}
            />
          )}
        {sensitive &&
          item.areal !== undefined && (
            <DataItem
              metaData={polyglot.t('globalSearch.areal')}
              data={`${localize(item.areal)} m\u00B2`}
            />
          )}
        <div className={styles.link}>
          <Link onClick={() => history.push(`/d${item._links.self}`)}>
            {polyglot.t('globalSearch.detaljer')}
          </Link>
        </div>
      </div>
    </div>
  );

  return (
    <Marker position={position} icon={icon} className={styles.marker}>
      {item && (
        <Popup>
          {item.type === 'bygning' ? buildingWrapper : searchWrapper}
        </Popup>
      )}
    </Marker>
  );
};

export default withRouter(MapMarker);
