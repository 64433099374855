// @flow

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ReportViewTemplate } from 'components';
import { toGuiDate } from 'utils/utils';
import polyglot from 'i18n';
import * as reportActions from 'redux/modules/report/report';
import * as widgetActions from 'redux/modules/widgets/widgets';

type Props = {
  actions: {
    getReport(?number): void,
    displayAlert({}): void,
  },
  error?: string,
  history: {
    replace(string): void,
  },
  report: {
    url: string,
    publiseringsdato: string,
    navn: string,
  },
  loading: boolean,
  match: {
    params: {
      id?: number,
    },
  },
};

class ReportView extends Component<Props> {
  componentDidMount() {
    this.props.actions.getReport(this.props.match.params.id);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.error === 'access_denied') {
      this.props.actions.displayAlert({
        kind: 'danger',
        text: polyglot.t('warning.noAccess'),
      });
      this.props.history.replace('/d');
    }
  }

  render() {
    const { report, loading } = this.props;
    return (
      <ReportViewTemplate
        url={report.url}
        name={report.navn}
        publisert={toGuiDate(report.publiseringsdato)}
        loading={loading}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    report: state.report.report,
    error: state.report.error,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...reportActions, ...widgetActions },
      dispatch,
    ),
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ReportView),
);
