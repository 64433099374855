import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { DetailPageTemplate, Alert } from 'components';
import polyglot from 'i18n';
import * as buildingActions from 'redux/modules/building/building';
import mapData, { getHeaders, apiFields } from 'utils/mapApiData';

class BuildingDetails extends PureComponent {
  state = {
    showMap: true,
    table: {
      page: 0,
      shown: 5,
    },
    showModal: false,
  };

  componentDidMount() {
    const { actions, match } = this.props;
    actions.getBuilding(match.params.byggId);
  }

  handleClick = i => {
    const { history, building } = this.props;
    const {
      table: { page, shown },
    } = this.state;
    history.push(`/d${building.grunneiendommer[i + page * shown]._links.self}`);
  };

  changePage = e =>
    this.setState(prevState => ({ table: { ...prevState.table, page: e } }));

  toggleModal = () => {
    this.setState(prevState => ({ showModal: !prevState.showModal }));
  };

  mapProperties = property =>
    Array.isArray(property) &&
    property.map(rowItem => [
      ...mapData(
        rowItem,
        apiFields.property.smallTable.map(cellItem => cellItem.field),
      ),
    ]);

  render() {
    const { showModal } = this.state;
    const { building, loading } = this.props;
    const hasGrunneiendommer = building.grunneiendommer
      ? building.grunneiendommer.length > 0
      : false;

    const getAlert = () => {
      if (!Object.keys(building).length > 0) {
        return <Alert kind="info">{polyglot.t('error.noBuilding')}</Alert>;
      }
      return '';
    };

    const powerBIprops = building.rapportMeta
      ? {
          aggregeringsnivaa: building.rapportMeta.aggregeringsnivaa,
          rapportId: building.rapportMeta.rapportId,
          datasetId: building.rapportMeta.datasetId,
          embedUrl: building.rapportMeta.embedUrl,
          token: building.rapportMeta.token,
        }
      : {};

    return (
      <DetailPageTemplate
        {...this.state}
        baseDetailFields={getHeaders(
          apiFields.building.baseDetails,
          'building',
        )}
        hiddenFields={getHeaders(apiFields.building.hiddenDetails, 'building')}
        data={mapData(building, apiFields.building.baseDetails)}
        hiddenData={mapData(building, apiFields.building.hiddenDetails)}
        building={building}
        loading={loading}
        alert={getAlert()}
        datalistHeader={polyglot.t('building.detailsHeader')}
        type="bygning"
        id={building.bygningsnr}
        name={building.navn}
        bodyClick={this.handleClick}
        changePage={this.changePage}
        dataType="property"
        hits={hasGrunneiendommer ? building.grunneiendommer.length : 0}
        headerRow={getHeaders(
          apiFields.property.smallTable.map(it => it.field),
          'property',
        )}
        dataRows={this.mapProperties(building.grunneiendommer) || []}
        showModal={showModal}
        toggleModal={this.toggleModal}
        powerBIprops={powerBIprops}
      />
    );
  }
}

BuildingDetails.propTypes = {
  building: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.object,
      PropTypes.array,
    ]),
  ),
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any),
  loading: PropTypes.bool,
  params: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
};

function mapStateToProps(state) {
  return {
    building: state.buildings.building.building,
    loading: state.buildings.building.loading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...buildingActions }, dispatch),
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(BuildingDetails),
);
