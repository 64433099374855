exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".FrontPageForm__wrapper___34L9s > * {\n  margin-bottom: 10px;\n}\n\n.FrontPageForm__wrapper___34L9s textarea {\n  min-height: 80px;\n}\n\n.FrontPageForm__pictureWrapper___1kgoN {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n}\n\n.FrontPageForm__buttonWrapper___2XTUa {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: end;\n      -ms-flex-pack: end;\n          justify-content: flex-end;\n}\n", ""]);

// exports
exports.locals = {
	"wrapper": "FrontPageForm__wrapper___34L9s",
	"pictureWrapper": "FrontPageForm__pictureWrapper___1kgoN",
	"buttonWrapper": "FrontPageForm__buttonWrapper___2XTUa"
};