exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* STATENS LOKALER COLOR PALETTE */\n\n:root {\n\n  /* colors for input fields */\n\n  /* Button colors */\n\n  /* Anchor colors */\n\n  /* STATSBYGG PRIMARY COLORS */\n\n  /* Packet colors */\n  /* Statsbygg Glassblå 01-03 */\n  /* SECONDARY COLORS - NEUTRAL */\n  /* Statsbygg Nøytral grå 01-02 */\n  /* Statsbygg Stein */\n  /* Statsbygg Jord */\n  /* TERTIARY COLORS - MARKER COLORS */\n  /* Statsbygg Sennep */\n  /* Statsbygg Multe */\n  /* Statsbygg Laks */\n  /* Statsbygg Lyng */\n}\n\n.LogoText__wrapper___mU3Qt {\n  color: #232F3A;\n}\n\n.LogoText__header___3Ny-y {\n  color: #232F3A;\n}\n\n.LogoText__footer___2Ur1g {\n  color: #232F3A;\n}\n\n.LogoText__dashboard___3nxab {\n  color: #232F3A;\n  line-height: 36px;\n}\n", ""]);

// exports
exports.locals = {
	"wrapper": "LogoText__wrapper___mU3Qt",
	"header": "LogoText__header___3Ny-y",
	"footer": "LogoText__footer___2Ur1g",
	"dashboard": "LogoText__dashboard___3nxab"
};