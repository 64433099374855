exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PowerBIReport__wrapper___17cS5 {\n  -ms-flex-item-align: end;\n      align-self: flex-end;\n  margin-top: 10px;\n  margin-right: -20px\n}\n.PowerBIReport__wrapper___17cS5 > button {\n  margin-right: 20px;\n}\n", ""]);

// exports
exports.locals = {
	"wrapper": "PowerBIReport__wrapper___17cS5"
};