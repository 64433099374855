import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import styles from './Button.css';

const Button = ({
  kind,
  to,
  disabled,
  icon,
  iconHeight,
  newItemButton,
  periodicButton,
  borderless,
  transparent,
  minWidth,
  secondaryButton,
  submit,
  className,
  href,
  history,
  refFunc,
  active,
  activeHover,
  staticContext, // eslint-disable-line
  ...props
}) => {
  const classes = classnames(styles.button, className, {
    [styles.default]: !kind,
    [styles.primary]: kind === 'primary',
    [styles.success]: kind === 'success',
    [styles.info]: kind === 'info',
    [styles.warning]: kind === 'waring',
    [styles.danger]: kind === 'danger',
    [styles.icon]: icon,
    [styles.newItemButton]: newItemButton,
    [styles.periodicButton]: periodicButton,
    [styles.iconHeight]: iconHeight,
    [styles.secondaryButton]: secondaryButton,
    [styles.minWidth]: minWidth,
    [styles.borderless]: borderless,
    [styles.disabled]: disabled,
    [styles.active]: active,
    [styles.activeHover]: activeHover,
  });

  // Types of button
  const type = submit ? 'submit' : 'button';
  const toFunc = () => history.push(to);
  const hrefFunc = () => window.open(href);

  // *** To be added:
  // Unless the disabled state is explicitly set, the button is disabled when loading.
  // const disabled = (props.disabled !== undefined ? props.disabled : loading) || false;
  return (
    <button
      className={classes}
      type={type}
      onClick={to ? toFunc : href && hrefFunc}
      disabled={disabled}
      ref={refFunc}
      {...props}
    />
  );
};

Button.propTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.bool,
  iconHeight: PropTypes.bool,
  transparent: PropTypes.bool,
  minWidth: PropTypes.bool,
  secondaryButton: PropTypes.bool,
  borderless: PropTypes.bool,
  large: PropTypes.bool,
  to: PropTypes.string,
  href: PropTypes.string,
  refFunc: PropTypes.func,
  className: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),

  /**
   * Boostrap button style styles
   * @type {("success"|"warning"|"danger"|"info"|"default"|"primary"|"link")}
   */
  kind: PropTypes.oneOf([
    'success',
    'warning',
    'danger',
    'info',
    'default',
    'primary',
    'secondary',
    'link',
    'bsStyle',
    undefined,
  ]),

  /**
   * Custom button class HTML button styles
   */
  submit: PropTypes.bool,
  group: PropTypes.bool,

  /**
   * Defines HTML button type Attribute
   * @type {("button"|"reset"|"submit")}
   * @defaultValue 'button'
   */
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  /**
   * Defines HTML button style
   * @type {("button"|"reset"|"submit")}
   * @defaultValue 'button'
   */
};

export default withRouter(Button);
