// @flow

import React from 'react';
import { Icon, HelpToolTipButton } from 'components';
import classnames from 'classnames';
import styles from './TableRow.css';

type Props = {
  sortCol: boolean,
  sortDir: string,
  data: Array<string | number>,
  helpToolButton: Array<string | number>,
  sortClick: ({}) => void,
  rightAlignArray: boolean,
};

const TableHeader = ({
  helpToolButton,
  sortCol,
  sortDir,
  data,
  sortClick,
  rightAlignArray,
}: Props) => {
  const onEnter = e => {
    if (e.key === 'Enter') sortClick(e);
  };

  const setHelpTipButton = (index, array) => {
    if (array[index] !== null)
      return <HelpToolTipButton title={array[index]} />;
  };

  const sortDirection = sortDir === 'asc' ? 'ascending' : 'descending';

  const headers = data.map((it, i) => (
    <th
      id={`sort${i}`}
      tabIndex={sortClick ? 0 : undefined}
      onClick={sortClick}
      onKeyDown={onEnter}
      className={classnames(
        styles.sort,
        !sortClick && styles.noClick,
        Array.isArray(rightAlignArray) &&
          rightAlignArray[i] &&
          styles.rightAlign,
      )}
      key={i}
      {...(sortCol === i ? { 'aria-sort': sortDirection } : '')}
    >
      {it}
      {helpToolButton !== undefined && setHelpTipButton(i, helpToolButton)}
      {sortCol === i && (
        <Icon
          icon={`arrow-${sortDir === 'asc' ? 'down' : 'up'}`}
          spaced
          size="1"
        />
      )}
    </th>
  ));
  return <tr>{headers}</tr>;
};

export default TableHeader;
