import React from 'react';
import PropTypes from 'prop-types';
import polyglot from 'i18n';
import { Panel } from 'components';
import styles from './ErrorPage.css';

const ErrorPage = ({ error }) => {
  return (
    <div className={styles.wrapper}>
      <h1 className={styles.header}>{polyglot.t('error.critical')}</h1>
      <Panel className={styles.box}>
        <span className={styles.error}>{error.message}</span>
      </Panel>
    </div>
  );
};

ErrorPage.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
};

export default ErrorPage;
