import React from 'react';
import polyglot from 'i18n';
import { ModalButton } from 'components';
import Dialog from '../../../containers/Dialog';
import BuildingPicker from '../../organisms/BuildingPicker/BuildingPicker';
import styles from './propertyData.css';
import { getDataItem } from '../../../services/api';

class EditSelectedBuildings extends React.Component {
  state = { showModal: false, allBuildings: [] };

  componentDidMount() {
    if (!this.props.isWizard) {
      this.fetchBuildings().then(
        response =>
          response !== null &&
          this.setState({ allBuildings: response.bygninger }),
      );
    }
  }

  async fetchBuildings() {
    const { grunneiendom } = this.props;
    const properties = await getDataItem(
      `/grunneiendom/${grunneiendom.matrikkelId}`,
    );
    if (properties !== undefined) {
      return properties;
    }
    return null;
  }

  toggleModal = () =>
    this.setState(prevState => ({ showModal: !prevState.showModal }));

  sortBygninger = (a, b) => a.bygningsnr - b.bygningsnr;

  render() {
    const {
      grunneiendom,
      submitProperty,
      removeGrunneiendom,
      isWizard,
      loading,
      allBuildings,
    } = this.props;

    return (
      <ModalButton
        className={styles.modal}
        showModal={this.state.showModal}
        toggle={this.toggleModal}
        link
        buttonTitle={polyglot.t('leideLokaler.editProperty')}
        {...alert}
      >
        <Dialog
          onExit={this.toggleModal}
          render={dialogProps => (
            <BuildingPicker
              {...dialogProps}
              loading={loading}
              property={grunneiendom}
              allBuildings={
                isWizard
                  ? allBuildings.sort(this.sortBygninger)
                  : this.state.allBuildings.sort(this.sortBygninger)
              }
              submitProperty={e => {
                this.toggleModal();
                submitProperty(e);
              }}
              removeGrunneiendom={removeGrunneiendom}
              closeModal={this.toggleModal}
            />
          )}
        />
      </ModalButton>
    );
  }
}

EditSelectedBuildings.propTypes = {};

export default EditSelectedBuildings;
