import { takeLatest, all } from 'redux-saga/effects';
import {
  handlePostToApi,
  handleCallToApi,
  handlePutToApi,
  handleDeleteCall,
  handlePostToForside,
} from '../redux/sagas/sagaHandlers';

export const createAsyncRedux = ({
  constantName,
  path,
  responseName = 'response',
  initialState = {
    loading: false,
    loaded: false,
    error: '',
  },
}) => {
  const GET = `GET_${constantName}`;
  const GET_SUCCESS = `GET_${constantName}_SUCCESS`;
  const GET_ERROR = `GET_${constantName}_ERROR`;

  const PUT = `PUT_${constantName}`;
  const PUT_SUCCESS = `PUT_${constantName}_SUCCESS`;
  const PUT_ERROR = `PUT_${constantName}_ERROR`;

  const POST = `POST_${constantName}`;
  const POST_FORSIDE = `POST_FORSIDE_${constantName}`;
  const POST_SUCCESS = `POST_${constantName}_SUCCESS`;
  const POST_ERROR = `POST_${constantName}_ERROR`;

  const DELETE = `DELETE_${constantName}`;
  const DELETE_SUCCESS = `DELETE_${constantName}_SUCCESS`;
  const DELETE_ERROR = `DELETE_${constantName}_ERROR`;

  const get = () => ({
    type: GET,
    path,
  });

  const getWithId = id => ({
    type: GET,
    path: `${path}/${id}`,
  });

  const post = payload => ({
    type: POST,
    path,
    payload,
  });

  const postWithId = payload => ({
    type: POST,
    path: typeof path === 'string' ? `${path}/${payload.id}` : path(payload.id),
    payload,
  });

  const postForside = payload => ({
    type: POST_FORSIDE,
    payload,
  });

  const postValue = (id, value) => ({
    type: POST,
    path: path(id, value),
  });

  const putWithId = ({ id, ...rest }) => ({
    type: PUT,
    path: typeof path === 'string' ? `${path}/${id}` : path(id),
    payload: { ...rest },
  });

  const deleteItem = id => ({
    type: DELETE,
    path: typeof path === 'string' ? `${path}/${id}` : path(id),
  });

  const getSuccess = response => ({
    type: GET_SUCCESS,
    [responseName]: response,
  });
  const getError = error => ({
    type: GET_ERROR,
    error,
  });
  const postSuccess = response => ({
    type: POST_SUCCESS,
    [responseName]: response,
  });
  const putSuccess = response => ({
    type: PUT_SUCCESS,
    [responseName]: response,
  });
  const postError = error => ({
    type: POST_ERROR,
    error,
  });
  const putError = error => ({
    type: PUT_ERROR,
    error,
  });
  const deleteSuccess = response => ({
    type: DELETE_SUCCESS,
    [responseName]: response,
  });
  const deleteError = error => ({
    type: DELETE_ERROR,
    error,
  });

  return {
    get,
    getSuccess,
    getWithId,
    putWithId,
    post,
    postWithId,
    postForside,
    postValue,
    deleteItem,
    initialState,
    reducer: (state = initialState, action) => {
      switch (action.type) {
        case GET: {
          return {
            ...state,
            ...initialState,
            loading: true,
            loaded: false,
          };
        }
        case POST: {
          return {
            ...state,
            loading: true,
            postSuccess: false,
          };
        }
        case POST_FORSIDE: {
          return {
            ...state,
            loading: true,
            postSuccess: false,
          };
        }
        case PUT: {
          return {
            ...state,
            loading: true,
            putSuccess: false,
          };
        }
        case DELETE: {
          return {
            ...state,
            loading: true,
            deleted: false,
          };
        }
        case GET_SUCCESS: {
          return {
            ...state,
            [responseName]: action[responseName],
            loading: false,
            loaded: true,
          };
        }
        case GET_ERROR: {
          return {
            ...state,
            error: action.error && action.error.message,
            loading: false,
          };
        }
        case POST_SUCCESS: {
          return {
            ...state,
            [`${responseName}_POST`]: action[responseName],
            loading: false,
            postSuccess: true,
          };
        }
        case PUT_SUCCESS: {
          return {
            ...state,
            [`${responseName}_PUT`]: action[responseName],
            loading: false,
            putSuccess: true,
          };
        }
        case POST_ERROR: {
          return {
            ...state,
            postError: action.error && action.error.message,
            loading: false,
          };
        }
        case PUT_ERROR: {
          return {
            ...state,
            putError: action.error && action.error.message,
            loading: false,
          };
        }
        case DELETE_SUCCESS: {
          return {
            ...state,
            [responseName]: action[responseName],
            loading: false,
            deleted: true,
          };
        }
        case DELETE_ERROR: {
          return {
            ...state,
            error: action.error && action.error.message,
            loading: false,
          };
        }
        default:
          return state;
      }
    },
    sagas: function* saga() {
      yield all([
        takeLatest(GET, handleCallToApi, getSuccess, getError),
        takeLatest(PUT, handlePutToApi, putSuccess, putError),
        takeLatest(POST, handlePostToApi, postSuccess, postError),
        takeLatest(POST_FORSIDE, handlePostToForside, postSuccess, postError),
        takeLatest(DELETE, handleDeleteCall, deleteSuccess, deleteError),
      ]);
    },
  };
};

export default createAsyncRedux;
