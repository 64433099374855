import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Paragraph.css';

const Paragraph = ({
  bold,
  inline,
  zeromargin,
  spaced,
  ingress,
  children,
  className,
}) => {
  const classes = classNames(className, styles.paragraph, {
    [styles.ingress]: ingress,
    [styles.bold]: bold,
    [styles.inline]: inline,
    [styles.spaced]: spaced,
    [styles.zeromargin]: zeromargin,
  });
  return <p className={classes}>{children}</p>;
};

Paragraph.propTypes = {
  bold: PropTypes.bool,
  inline: PropTypes.bool,
  zeromargin: PropTypes.bool,
  ingress: PropTypes.bool,
  spaced: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Paragraph;
