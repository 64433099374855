import React from 'react';
import PropTypes from 'prop-types';
import {
  Heading,
  Panel,
  Field,
  SelectField,
  Button,
  ModalButton,
  Checkbox,
  Bubbles,
  Link,
  Icon,
} from 'components';
import polyglot from 'i18n';
import { isUrlValid } from 'utils/utils';
import styles from './NewReportTemplate.css';

const NewReportTemplate = ({
  onInputChange,
  onSelectChange,
  org,
  name,
  url,
  visibleDown,
  onSave,
  onPublish,
  report: { id },
  loadingPublishReport,
  loadingSaveReport,
  deleteReport,
  preview,
  togglePreview,
  history,
  role,
}) => (
  <div>
    <Heading level={0}>{id ? 'Rediger rapport' : 'Ny rapport'}</Heading>
    <Panel>
      <div className={styles.fieldWrapper}>
        <Field
          horizontal
          label="Rapportnavn"
          name="name"
          value={name || ''}
          onChange={onInputChange}
        />
        <SelectField
          label={polyglot.t('report.visibleForOrg')}
          onChange={e => onSelectChange(e, 'org')}
          selectedValue={org ? org.value : undefined}
          optionUrl="/organisasjon/filter"
          placeholder="Alle"
          horizontal
          className={styles.select}
        />
        <SelectField
          label={polyglot.t('report.visibleForRole')}
          onChange={e => onSelectChange(e, 'role')}
          selectedValue={role}
          multi
          optionUrl="/user/roles"
          labelFunc={responseValue => polyglot.t(`Rolle.${responseValue}`)}
          placeholder="Alle"
          horizontal
          className={styles.select}
        />
        <Checkbox
          label={polyglot.t('report.visibleForSubOrgs')}
          inverted
          name="visibleDown"
          checked={visibleDown}
          onChange={onInputChange}
        />
        <Field
          label="Lenke"
          horizontal
          name="url"
          value={url || ''}
          onChange={onInputChange}
          placeholder="eks https://app.powerbi.com/"
          validationState={isUrlValid(url)}
          feedback={polyglot.t('feedback.invalidUrl')}
        />
      </div>
      <ModalButton
        showModal={preview}
        toggle={togglePreview}
        link
        icon={<Icon icon="files" size="1" />}
        buttonTitle="Forhåndsvisning"
      >
        <div className={styles.previewWrapper}>
          {/* <Link>Lukk forhåndsvisning</Link> */}
          <Panel>
            {isUrlValid(url) === 'success' ? (
              <iframe
                title="preview frame"
                width="100%"
                height="600px"
                src={url}
                className={styles.iframe}
                allowFullScreen="true"
              />
            ) : (
              polyglot.t('feedback.invalidUrl')
            )}
          </Panel>
        </div>
      </ModalButton>

      <div className={styles.buttonWrapper}>
        <Link onClick={deleteReport}>
          <Icon icon="trash" size="1" />
          {'Slett rapport'}
        </Link>
        <Link onClick={onSave}>
          <Icon icon="save" size="1" />
          {loadingSaveReport ? <Bubbles /> : 'Lagre kladd'}
        </Link>
        <Button
          secondaryButton
          onClick={() => history.push('/d/admin/rapport')}
        >
          {'Avbryt'}
        </Button>
        <Button
          kind="primary"
          onClick={onPublish}
          disabled={isUrlValid(url) !== 'success'}
        >
          {loadingPublishReport ? <Bubbles /> : 'Publiser'}
        </Button>
      </div>
    </Panel>
  </div>
);

NewReportTemplate.propTypes = {
  onInputChange: PropTypes.func,
  onSelectChange: PropTypes.func,
  org: PropTypes.shape({ value: PropTypes.number }),
  name: PropTypes.string,
  url: PropTypes.string,
  visibleDown: PropTypes.bool,
  onSave: PropTypes.func,
  role: PropTypes.arrayOf(PropTypes.string),
  onPublish: PropTypes.func,
  deleteReport: PropTypes.func,
  report: PropTypes.shape({ id: PropTypes.number }),
  loadingPublishReport: PropTypes.bool,
  loadingSaveReport: PropTypes.bool,
  preview: PropTypes.bool,
  togglePreview: PropTypes.func,
  history: PropTypes.shape({ push: PropTypes.func }),
};

export default NewReportTemplate;
