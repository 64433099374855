exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Popover__popover___21Ngz {\n  display: block;\n  max-width: 350px;\n  width: 350px;\n}\n\n.Popover__hidden___2jZRn {\n  display: none !important;\n}\n\n.Popover__title___3Uusm {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n}\n\n.Popover__closeButton___VuIc1 {\n  padding: 3px 6px;\n  margin-left: auto;\n}\n", ""]);

// exports
exports.locals = {
	"popover": "Popover__popover___21Ngz",
	"hidden": "Popover__hidden___2jZRn",
	"title": "Popover__title___3Uusm",
	"closeButton": "Popover__closeButton___VuIc1"
};