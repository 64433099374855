// @flow

import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { GlobalSearchTemplate } from 'components';
import searchRequestBuilder, {
  convertSelectionToRedux,
} from 'utils/searchApiHelpers';
import * as searchActions from 'redux/modules/search/search';
import * as selectionActions from 'redux/modules/selections/selection';
import { getSelectionFromState } from 'redux/modules/selections/selectionSelector';
import userSelector from 'redux/modules/user/userSelector';
import * as tableActions from 'redux/modules/search/table';
import * as widgetActions from 'redux/modules/widgets/widgets';
import { recursiveFind } from 'utils/utils';
import type { TableState, Property, Lokale, RouterHistory } from 'types';
import ErrorBoundary from '../ErrorBoundary';

type Props = {
  table: TableState,
  userData: {
    roles: Array<string>,
    uuid: string,
  },
  actions: {
    updateTable(string, TableState): void,
    searchRequest({}): void,
    getSelection(string): {},
    resetTable(string): {},
    displayAlert({ kind: string, text: string }): {},
    deleteSelection(string): {},
    postSelection({
      navn: string,
      brukerUuid: string,
      utvalgtype: string,
      sok: {
        type: string,
        table: {},
        userData: { uuid: string },
      },
      uuid: string | void,
    }): {},
  },
  history: RouterHistory,
  data: {
    result: Property[] | Lokale[],
    hits: number,
  },
  orgnr: number,
  selection: {
    selection: { sok: {}, uuid: string },
    deleted: boolean,
    loading: boolean,
    selection_POST: { uuid: string },
    postSuccess: boolean,
    error: boolean,
  },
  match: {
    params: {
      selectionName?: string,
    },
  },
};

class GlobalSearch extends PureComponent<Props> {
  componentDidMount() {
    const {
      match: { params },
      actions,
    } = this.props;
    this.handleSearch();
    if (params.selectionName) {
      actions.getSelection(params.selectionName);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      table,
      selection,
      history,
      match: { params },
      actions,
    } = this.props;
    const { map, page, sortCol, sortAsc, query } = table;
    if (selection.deleted && !prevProps.selection.deleted) {
      actions.displayAlert({ kind: 'success', text: 'Utvalg er slettet' });
      history.push('/d/sok');
      return;
    }
    if (
      prevProps.table.map !== map ||
      prevProps.table.page !== page ||
      prevProps.table.sortCol !== sortCol ||
      prevProps.table.sortAsc !== sortAsc ||
      prevProps.table.query !== query
    ) {
      this.handleSearch();
    } else if (
      !prevProps.selection.postSuccess &&
      selection.postSuccess &&
      !selection.error
    ) {
      const id = selection.selection_POST.uuid;
      actions.displayAlert({ kind: 'success', text: 'Utvalg er lagret' });
      history.push(`/d/sok/${id}`);
      actions.getSelection(id);
    } else if (
      prevProps.selection.loading &&
      !selection.loading &&
      !selection.error
    ) {
      this.useSelection(selection);
    } else if (prevProps.match.params.selectionName !== params.selectionName) {
      if (!params.selectionName) {
        actions.resetTable('globalTable');
        this.handleSearch();
      } else if (selection.selection.sok) this.useSelection(selection);
    }
  }

  useSelection = selection => {
    const selectionTable = convertSelectionToRedux(selection.selection.sok);
    this.updateTable(selectionTable);
    this.handleSearch(selectionTable);
  };

  onTextChange = e => {
    if (e.target.id === 'search') {
      this.updateTable({
        query: e.target.value,
      });
    }
  };

  toggleMap = isMap => {
    const { table } = this.props;
    this.updateTable({
      shown: isMap ? 10000 : 10,
      map: isMap,
      page: isMap ? 0 : table.page,
    });
  };

  tableClick = e => {
    const { history, data } = this.props;
    history.push(`/d${data.result[e]._links.self}`);
  };

  handleFilterChange = ({ field, ...props }) => {
    const { table } = this.props;
    if (field === 'checked') {
      this.updateType(props.type);
      return;
    }
    this.updateTable({
      filters: {
        ...table.filters,
        [field]: {
          ...table.filters[field],
          ...props,
        },
      },
    });
  };

  updateType = type => {
    const { table } = this.props;
    const active = table.type.includes(type);
    this.updateTable({
      type: active
        ? table.type.replace(type, '').replace(',', '')
        : table.type
          ? `${table.type},${type}`
          : type,
    });
  };

  changePage = e => {
    this.updateTable({
      page: e,
    });
  };

  sortClick = e => {
    const { table } = this.props;
    const newSort = recursiveFind(e.target, 'cellIndex');
    const { sortCol, sortAsc } = table;
    this.updateTable({
      sortAsc: sortCol === newSort ? !sortAsc : true,
      sortCol: newSort,
    });
  };

  saveSelection = (selectionName, selectionType) => {
    const {
      userData,
      actions,
      table,
      match: { params },
    } = this.props;
    actions.postSelection({
      navn: selectionName,
      brukerUuid: userData.uuid,
      utvalgtype: selectionType,
      sok: searchRequestBuilder({
        type: 'globalSearch',
        table,
        userData,
      }),
      uuid: params.selectionName || undefined,
    });
  };

  handleSearch = inputTable => {
    const { userData, actions, table } = this.props;
    actions.searchRequest(
      searchRequestBuilder({
        type: 'globalSearch',
        table: inputTable || table,
        userData,
      }),
    );
  };

  updateTable = object => {
    const { actions } = this.props;
    actions.updateTable('globalTable', object);
  };

  deleteSelection = () => {
    const { actions, selection } = this.props;
    actions.deleteSelection(selection.selection.uuid);
  };

  render() {
    const {
      table: {
        filters: {
          fylke = {},
          kommune = {},
          departement = {},
          organisasjon = {},
        },
      },
      match: {
        params: { selectionName },
      },
      selection,
    } = this.props;
    return (
      <ErrorBoundary>
        <GlobalSearchTemplate
          onSubmit={() => this.handleSearch()}
          toggleMap={this.toggleMap}
          changePage={this.changePage}
          tableClick={this.tableClick}
          sortClick={this.sortClick}
          handleFilterChange={this.handleFilterChange}
          onTextChange={this.onTextChange}
          deleteSelection={this.deleteSelection}
          fylkeFilter={fylke.value}
          organisasjonFilter={organisasjon.value}
          kommuneFilter={kommune.value}
          departementFilter={departement.value}
          saveSelection={this.saveSelection}
          currentSelection={selectionName ? selection.selection : {}}
          {...this.props}
        />
      </ErrorBoundary>
    );
  }
}

function mapStateToProps(state) {
  return {
    userData: userSelector(state),
    orgnr: state.user.data.organisasjon.organisasjonsnummer,
    data: state.search.data,
    loading: state.search.loading,
    table: state.table.globalTable,
    selection: getSelectionFromState(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...searchActions,
        ...tableActions,
        ...selectionActions,
        ...widgetActions,
      },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GlobalSearch);
