import { combineReducers } from 'redux';
import createAsyncRedux from '../../../utils/ReduxHelpers';

const forsideRedux = createAsyncRedux({
  constantName: 'FORSIDE',
  path: '/forside',
  initialState: {
    response: {},
  },
});

const forsidePublicRedux = createAsyncRedux({
  constantName: 'FORSIDE_PUBLIC',
  path: '/forside/public',
  initialState: {
    response: {},
    loading: true,
  },
});

// Export action creators
export const getForside = forsideRedux.get;
export const getForsidePublic = forsidePublicRedux.get;
export const postForside = forsideRedux.postForside;

// Export the reducer
export const forsideReducer = combineReducers({
  frontpage: forsideRedux.reducer,
  frontpagePublic: forsidePublicRedux.reducer,
});

export const forsideSaga = forsideRedux.sagas;
export const forsidePublicSaga = forsidePublicRedux.sagas;

export default forsideReducer;
