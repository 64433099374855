import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import styles from './Logo.css';

const logoFront = require('../../../assets/logo_black.png');
const logo = require('../../../assets/statsbygg.png');

const Logo = ({ footer, frontpage, href, target, to, ...props }) => {
  const classes = classNames(styles.logo, {
    [styles.footer]: footer,
    [styles.frontpage]: frontpage,
  });
  if (href) {
    return (
      <a
        href={href}
        className={frontpage && styles.paddingBottom}
        target={target}
        title="logo link"
      >
        <img
          {...props}
          src={frontpage ? logoFront : logo}
          alt="Statsbygg logo"
          className={classes}
        />
      </a>
    );
  } else if (to) {
    return (
      <Link to={to}>
        <div {...props} className={classes} />
      </Link>
    );
  }
  return <div {...props} className={classes} />;
};

Logo.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  footer: PropTypes.bool,
  frontpage: PropTypes.bool,
  target: PropTypes.string,
};

export default Logo;
