import moment from 'moment';
import polyglot from 'i18n';

export const setDefault = (newIn: ?any, def: ?any) =>
  typeof newIn === 'undefined' ? def : newIn;

export const setDefaultNumber = (newIn: ?number, def: ?number = 0) =>
  typeof newIn === 'undefined' ? def : newIn;

export const localize = (data: ?number, options?: any): string => {
  if (data == null) return '';
  return Number(data).toLocaleString(polyglot.t('locale'), options);
};

export const validateNumber = (
  num: ?number | string,
  options: ?{ lessThan?: number, biggerThan?: number },
) => {
  const { lessThan, biggerThan } = options || {};
  if (!num) return 'success';
  if (isNaN(num)) {
    return 'error';
  }
  if (typeof num === 'string') {
    if (!/^[0-9]*$/.test(num)) {
      return 'error';
    }
  }
  if (biggerThan !== undefined) {
    return parseInt(num, 10) >= biggerThan ? 'success' : 'error';
  }
  if (lessThan !== undefined) {
    return parseInt(num, 10) <= lessThan ? 'success' : 'error';
  }
  return 'success';
};

export const validateDecimal = decimal => {
  const valid = /^[0-9]*[.,]?[0-9]*$/.test(decimal);
  return !decimal || valid ? 'success' : 'error';
};

export const validateOrgnr = (str: ?string) => {
  if (!str || str.length === 0) return undefined;
  if (str.length === 9) {
    if (!Number.isNaN(str)) return 'success';
    return 'error';
  }
  return 'error';
};

export const extractHash = (hash: string): { page?: string } => {
  if (!hash.length === 0) return {};
  const splitArr = hash
    .slice(1)
    .split('&')
    .map(it => it.split('='));

  const hashObj = {};
  splitArr.forEach(it => {
    hashObj[it[0]] = it[1];
  });
  return hashObj;
};

export const isUrlValid = (userInput: ?string) => {
  if (!userInput) return undefined;
  const res = userInput.match(
    /https:\/\/[a-z]{3}\.[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g,
  );
  if (res == null) return 'error';
  return 'success';
};

export const statsDate = () =>
  moment()
    .dayOfYear(1)
    .subtract(1, 'days');

export const toGuiDate = (date: ?string | {}, useHours: boolean = false) =>
  moment(date).format(useHours ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY');

export const toApiDate = (date: string) =>
  date ? moment(date, 'DD.MM.YYYY').format('YYYY-MM-DD') : '';

export const recursiveFind = (target: any, find: string): string | void => {
  if (target[find] || target[find] === 0) return target[find];
  if (target.parentNode) return recursiveFind(target.parentNode, find);
  return undefined;
};

type LeieobjektInput = {
  bruttoareal: number,
  spesialformaalareal: number,
  arbeidsrelatertareal: number,
  framleieareal: number,
  parkeringsareal: number,
};

export const sumTotalAreal = (
  inputObj: LeieobjektInput = {},
  existingObj: LeieobjektInput = {},
): number => {
  const { bruttoareal, parkeringsareal, framleieareal } = inputObj;
  const newBruttoAreal = parseInt(
    setDefaultNumber(bruttoareal, existingObj.bruttoareal),
    10,
  );
  const newParkingAreal = parseInt(
    setDefaultNumber(parkeringsareal, existingObj.parkeringsareal || 0),
    10,
  );
  const newFramleieAreal = parseInt(
    setDefaultNumber(framleieareal, existingObj.framleieareal || 0),
    10,
  );
  return newBruttoAreal + newParkingAreal - newFramleieAreal;
};

export const tableName = type => {
  switch (type) {
    case 'adminRapporter':
      return 'adminRapportTable';
    default:
      return `${type}Table`;
  }
};

export const findObject = (array, name) =>
  array.find(item => item.field === name) || {};

export const sortData = (data, index, asc) =>
  data.sort((a, b) => {
    const aValue = a.table[index];
    const bValue = b.table[index];
    const aa = isNaN(aValue && aValue.replace(/\s/g, ''))
      ? aValue
      : parseInt(aValue.replace(/\s/g, ''), 10);
    const bb = isNaN(bValue && bValue.replace(/\s/g, ''))
      ? bValue
      : parseInt(bValue.replace(/\s/g, ''), 10);

    if (aa > bb) return asc ? 1 : -1;
    if (aa < bb) return asc ? -1 : 1;
    return 0;
  });

export const getLevelFromUrl = url => {
  if (url.indexOf('org') > -1) {
    return 'VIRKSOMHET';
  }
  if (url.indexOf('dep') > -1) {
    return 'DEPARTEMENT';
  }
  if (url.indexOf('gru') > -1) {
    return 'GRUNNEIENDOM';
  }
  if (url.indexOf('byg') > -1) {
    return 'BYGNING';
  }
  if (url.indexOf('pro') > -1) {
    return 'PROSJEKT';
  }
  if (url.indexOf('lok') > -1) {
    return 'LOKALE';
  }
  return 'STATEN';
};

export const dates = () => {
  const prevReport = moment()
    .dayOfYear(1)
    .subtract(1, 'days');
  const lastReport = moment()
    .subtract(1, 'year')
    .dayOfYear(1)
    .subtract(1, 'days');
  return [toGuiDate(lastReport), toGuiDate(prevReport), toGuiDate()];
};

export const recVal = item => {
  if (typeof item === 'object') {
    return Object.keys(item).some(key => recVal(item[key]));
  }
  return validateNumber(item) === 'error';
};

export const keysToIdArray = values => {
  const returnArray = [];
  Object.keys(values).forEach(value => {
    const [field, idString] = value.split('_');
    const id = parseInt(idString, 10);
    const existingObject = returnArray.find(it => it.id === id);
    if (existingObject) {
      existingObject[field] = values[value];
    } else {
      returnArray.push({ id, [field]: values[value] });
    }
  });
  return returnArray;
};

export const dateCompare = (last, today) => {
  const t = new Date(today);
  const l = new Date(last);
  return l < t;
};
