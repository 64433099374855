import React from 'react';
import PropTypes from 'prop-types';
import { InfoLink, Icon } from 'components';
import polyglot from 'i18n';
import styles from './GroupConnectionInfo.css';

const GroupConnectionInfo = ({ to, value, handleRemove, editAccess }) => (
  <div className={styles.wrapper}>
    <span className={styles.infoWrapper}>
      <Icon icon="info-alt" size="4" spaced />
      <span>{polyglot.t('connectionInfo.connected')}</span>
    </span>
    <div className={styles.infolinkWrapper}>
      <InfoLink to={to} removable={editAccess} handleRemove={handleRemove}>
        {value}
      </InfoLink>
    </div>
  </div>
);

GroupConnectionInfo.propTypes = {
  value: PropTypes.string,
  to: PropTypes.string,
  handleRemove: PropTypes.func,
  editAccess: PropTypes.bool,
};

export default GroupConnectionInfo;
