export const SAVE_REPORT = 'SAVE_REPORT';
export const SAVE_REPORT_SUCCESS = 'SAVE_REPORT_SUCCESS';
export const SAVE_REPORT_FAIL = 'SAVE_REPORT_FAIL';

export const PUBLISH_REPORT = 'PUBLISH_REPORT';
export const PUBLISH_REPORT_SUCCESS = 'PUBLISH_REPORT_SUCCESS';
export const PUBLISH_REPORT_FAIL = 'PUBLISH_REPORT_FAIL';

export const GET_REPORT = 'GET_REPORT';
export const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS';
export const GET_REPORT_FAIL = 'GET_REPORT_FAIL';

export const DELETE_REPORT = 'DELETE_REPORT';
export const DELETE_REPORT_SUCCESS = 'DELETE_REPORT_SUCCESS';
export const DELETE_REPORT_FAIL = 'DELETE_REPORT_FAIL';

export const saveReport = obj => ({
  type: SAVE_REPORT,
  path: '/rapport/lagre',
  payload: obj,
});
export const saveReportSuccess = () => ({ type: SAVE_REPORT_SUCCESS });
export const saveReportFail = error => ({ type: SAVE_REPORT_FAIL, error });

export const publishReport = obj => ({
  type: PUBLISH_REPORT,
  path: '/rapport/publiser',
  payload: obj,
});
export const publishReportSuccess = () => ({ type: PUBLISH_REPORT_SUCCESS });
export const publishReportFail = error => ({
  type: PUBLISH_REPORT_FAIL,
  error,
});

export const getReport = id => ({
  type: GET_REPORT,
  path: `/rapport/${id}`,
});
export const getReportSuccess = report => ({
  type: GET_REPORT_SUCCESS,
  report,
});
export const getReportFail = error => ({ type: GET_REPORT_FAIL, error });

export const deleteReport = id => ({
  type: DELETE_REPORT,
  path: `/rapport/${id}`,
});
export const deleteReportSuccess = () => ({ type: DELETE_REPORT_SUCCESS });
export const deleteReportFail = error => ({ type: DELETE_REPORT_FAIL, error });

const initialState = {
  report: {},
};

export default function reportReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_REPORT:
      return { ...state, loadingSaveReport: true, error: '' };
    case SAVE_REPORT_SUCCESS:
      return { ...state, loadingSaveReport: false };
    case SAVE_REPORT_FAIL:
      return { ...state, loadingSaveReport: false, error: action.error };
    case PUBLISH_REPORT:
      return { ...state, loadingPublishReport: true, error: '' };
    case PUBLISH_REPORT_SUCCESS:
      return { ...state, loadingPublishReport: false };
    case PUBLISH_REPORT_FAIL:
      return { ...state, loadingPublishReport: false, error: action.error };
    case GET_REPORT:
      return { ...state, loadingGetReport: true, error: '', report: {} };
    case GET_REPORT_SUCCESS:
      return { ...state, loadingGetReport: false, report: action.report };
    case GET_REPORT_FAIL:
      return { ...state, loadingGetReport: false, error: action.error };
    case DELETE_REPORT:
      return { ...state, loadingDelete: true, error: '' };
    case DELETE_REPORT_SUCCESS:
      return { ...state, loadingDelete: false };
    case DELETE_REPORT_FAIL:
      return { ...state, loadingDelete: false, error: action.error };
    default:
      return state;
  }
}
