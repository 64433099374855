import React, { useEffect, useState } from 'react';
import { Button, Link, Checkbox, MapLegend } from 'components';
import { Formik, Form, Field } from 'formik';
import LeafletMap from 'containers/Map/LeafletMap';
import styles from './BuildingPicker.css';
import { getDataItem } from '../../../services/api';

const BuildingPicker = ({
  property: {
    id,
    matrikkelId,
    navn,
    bruksnr,
    kommunenr,
    gardsnr,
    bygninger = [],
  },
  notEditMode,
  submitProperty,
  removeGrunneiendom,
  loading,
  closeModal,
  allBuildings = [],
}) => {
  const [databaseBuildings, setDatabaseBuildings] = useState([]);

  const fetchDatabaseBuildings = async () => {
    const properties = await getDataItem(`/grunneiendom/${matrikkelId}`);
    if (properties !== undefined) {
      return properties.bygninger;
    }
    return [];
  };

  useEffect(() => {
    fetchDatabaseBuildings().then(res => {
      if (res.length > 0 && notEditMode) {
        setDatabaseBuildings(res);
      }
    });
  }, []);

  return (
    <div key={id} className={styles.result}>
      <div className={styles.property}>
        {`${navn} - ${kommunenr} / ${gardsnr} / ${bruksnr}`}
      </div>
      <Formik
        initialValues={allBuildings.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.bygningsnr]: !!bygninger.find(
              bygg => bygg.bygningsnr.toString() === curr.bygningsnr.toString(),
            ),
          }),
          {},
        )}
        enableReinitialize
        onSubmit={values => submitProperty({ values, matrikkelId })}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <LeafletMap
              markers={allBuildings.map(building => ({
                ...building,
                type: values[building.bygningsnr] ? 'bygning' : '',
              }))}
              onBuildingPick={bygningsnr =>
                setFieldValue(bygningsnr, !values[bygningsnr])
              }
            />
            {allBuildings.map(
              ({
                bygningsnr,
                bygningtype,
                id,
                navn,
                antallEtasjer,
                bruttoareal,
                matrikkelId,
              }) => {
                const currObject = databaseBuildings.find(
                  obj => obj.matrikkelId === matrikkelId,
                );
                const showBygningstype =
                  currObject === undefined
                    ? bygningtype === undefined
                      ? 'Tilgjengelig etter klokka 06.00 siste lørdagen denne måneden'
                      : bygningtype
                    : currObject.bygningtype;
                return (
                  <div key={bygningsnr} className={styles.bygning}>
                    <Field name={bygningsnr}>
                      {({ field }) => (
                        <Checkbox
                          {...field}
                          checked={field.value}
                          label={`${bygningsnr} - ${showBygningstype}`}
                          disabled={currObject === undefined && notEditMode}
                        />
                      )}
                    </Field>
                  </div>
                );
              },
            )}
            <div className={styles.buttonWrapper}>
              {removeGrunneiendom && (
                <Link
                  deleteStyle
                  onClick={() =>
                    confirm('Vil du virkelig slette grunneiendommen?') &&
                    removeGrunneiendom(matrikkelId)
                  }
                >
                  {'X Fjern grunneiendom'}
                </Link>
              )}
              <div className={styles.tinyWrapper}>
                <Button secondaryButton onClick={closeModal}>
                  Avbryt
                </Button>
                <Button disabled={loading} kind="primary" type="submit">
                  Lagre
                </Button>
                <MapLegend icon="map-pin-blue-filled" text="Valgt bygning" />
                <MapLegend icon="map-pin-orange" text="Ikke valgt bygning" />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default BuildingPicker;
