// Export action constants

export const GET_REALESTATE = 'GET_REALESTATE';
export const GET_REALESTATE_SUCCESS = 'GET_REALESTATE_SUCCESS';
export const GET_REALESTATE_FAIL = 'GET_REALESTATE_FAIL';
export const GET_REALESTATE_ALL = 'GET_REALESTATE_ALL';
export const GET_REALESTATE_ALL_SUCCESS = 'GET_REALESTATE_ALL_SUCCESS';
export const GET_REALESTATE_ALL_FAIL = 'GET_REALESTATE_ALL_FAIL';
export const SET_REALESTATE_NAME = 'SET_REALESTATE_NAME';
export const SET_REALESTATE_NAME_SUCCESS = 'SET_REALESTATE_NAME_SUCCESS';
export const SET_REALESTATE_NAME_FAIL = 'SET_REALESTATE_NAME_FAIL';
export const CONNECT_REALESTATE = 'CONNECT_REALESTATE';
export const CONNECT_REALESTATE_SUCCESS = 'CONNECT_REALESTATE_SUCCESS';
export const CONNECT_REALESTATE_FAIL = 'CONNECT_REALESTATE_FAIL';
export const DISCONNECT_REALESTATE = 'DISCONNECT_REALESTATE';
export const DISCONNECT_REALESTATE_SUCCESS = 'DISCONNECT_REALESTATE_SUCCESS';
export const DISCONNECT_REALESTATE_FAIL = 'DISCONNECT_REALESTATE_FAIL';
// Export action creators

export const getRealEstate = id => ({
  type: GET_REALESTATE,
  path: `/eiendom/${id}`,
});
export const getRealEstateSuccess = response => ({
  type: GET_REALESTATE_SUCCESS,
  response,
});
export const getRealEstateFail = (id, error) => ({
  type: GET_REALESTATE_FAIL,
  error,
});

export const getAllRealEstate = owner => ({
  type: GET_REALESTATE_ALL,
  path: `/eiendom/org/${owner.orgnr}`,
});
export const getAllRealEstateSuccess = response => ({
  type: GET_REALESTATE_ALL_SUCCESS,
  response,
});
export const getAllRealEstateFail = error => ({
  type: GET_REALESTATE_ALL_FAIL,
  error,
});

export const setRealEstateName = payload => ({
  type: SET_REALESTATE_NAME,
  path: `/eiendom/${payload.id}/navn`,
  payload,
});
export const setRealEstateNameSuccess = query => ({
  type: SET_REALESTATE_NAME_SUCCESS,
  query,
});
export const setRealEstateNameFail = (query, error) => ({
  type: SET_REALESTATE_NAME_FAIL,
  error,
});

export const connectRealEstate = payload => ({
  type: CONNECT_REALESTATE,
  path: '/eiendom/connect',
  payload,
});
export const connectRealEstateSuccess = response => ({
  type: CONNECT_REALESTATE_SUCCESS,
  response,
});
export const connectRealEstateFail = (query, error) => ({
  type: CONNECT_REALESTATE_FAIL,
  error,
});

export const disconnectRealEstate = payload => ({
  type: DISCONNECT_REALESTATE,
  path: '/eiendom/disconnect',
  payload,
});
export const disconnectRealEstateSuccess = query => ({
  type: DISCONNECT_REALESTATE_SUCCESS,
  query,
});
export const disconnectRealEstateFail = (query, error) => ({
  type: DISCONNECT_REALESTATE_FAIL,
  error,
});

export const initialState = {
  realestate: {},
  realestateId: '',
  all: [],
  loading: false,
  loadingAllRealEstate: false,
  loadingName: false,
  loadingConnectRealEstate: false,
  loadingDisconnectRealEstate: false,
  error: false,
};

// Export the reducer
export default function realestateReducer(state = initialState, action) {
  switch (action.type) {
    case GET_REALESTATE: {
      return Object.assign({}, state, {
        loading: true,
      });
    }
    case GET_REALESTATE_SUCCESS: {
      return Object.assign({}, state, {
        realestate: action.response,
        loading: false,
      });
    }
    case GET_REALESTATE_FAIL: {
      return Object.assign({}, state, {
        loading: false,
        error: action.error,
      });
    }
    case GET_REALESTATE_ALL: {
      return Object.assign({}, state, {
        loadingAllRealEstate: true,
      });
    }
    case GET_REALESTATE_ALL_SUCCESS: {
      return Object.assign({}, state, {
        all: action.response,
        loadingAllRealEstate: false,
      });
    }
    case GET_REALESTATE_ALL_FAIL: {
      return Object.assign({}, state, {
        loadingAllRealEstate: false,
        error: action.error,
      });
    }
    case SET_REALESTATE_NAME: {
      return Object.assign({}, state, {
        loadingName: true,
        realestate: Object.assign({}, state.realestate, {
          // server returns only true, should return the new name
          navn: action.payload.value,
          visningsnavn: action.payload.value,
        }),
      });
    }
    case SET_REALESTATE_NAME_SUCCESS: {
      return Object.assign({}, state, {
        realestate: Object.assign({}, state.realestate, {
          // server returns only true, should return the new name
          // navn: action.query.value,
        }),
        loadingName: false,
      });
    }
    case SET_REALESTATE_NAME_FAIL: {
      return Object.assign({}, state, {
        loadingName: false,
        error: action.error,
      });
    }
    case CONNECT_REALESTATE: {
      return Object.assign({}, state, {
        loadingConnectRealEstate: true,
        realestateId: '',
      });
    }
    case CONNECT_REALESTATE_SUCCESS: {
      return Object.assign({}, state, {
        loadingConnectRealEstate: false,
        realestateId: action.response,
      });
    }
    case CONNECT_REALESTATE_FAIL: {
      return Object.assign({}, state, {
        loadingConnectRealEstate: false,
        error: action.error,
      });
    }
    case DISCONNECT_REALESTATE: {
      return Object.assign({}, state, {
        loadingDisconnectRealEstate: true,
      });
    }
    case DISCONNECT_REALESTATE_SUCCESS: {
      return Object.assign({}, state, {
        realestate: Object.assign({}, state.realestate, {
          grunneiendommer: action.query.grunneiendomId, // TODO: remove from array
        }),
        realestateId: '',
        loadingDisconnectRealEstate: false,
      });
    }
    case DISCONNECT_REALESTATE_FAIL: {
      return Object.assign({}, state, {
        loadingDisconnectRealEstate: false,
        error: action.error,
      });
    }
    default:
      return state;
  }
}
