import { apiFields } from 'utils/mapApiData';
import polyglot from 'i18n';

export const lokaleBrukerFields = [
  'bruker.organisasjonsnummer',
  'bruker.overordnet.organisasjonsnummer',
  'bruker.overordnet.overordnet.organisasjonsnummer',
  'bruker.overordnet.overordnet.overordnet.organisasjonsnummer',
  'bruker.overordnet.overordnet.overordnet.overordnet.organisasjonsnummer',
  'bruker.overordnet.overordnet.overordnet.overordnet.overordnet.organisasjonsnummer',
  'bruker.overordnet.overordnet.overordnet.overordnet.overordnet.overordnet.organisasjonsnummer',
];

export const eierFilterFields = [
  'organisasjon.organisasjonsnummer',
  'grunneiendom.eierforhold.organisasjon.organisasjonsnummer',
  'grunneiendom.eierforhold.organisasjon.overordnet.organisasjonsnummer',
  'grunneiendom.eierforhold.organisasjon.overordnet.overordnet.organisasjonsnummer',
  'eierforhold.organisasjon.organisasjonsnummer',
  'eierforhold.organisasjon.overordnet.organisasjonsnummer',
  'eierforhold.organisasjon.overordnet.overordnet.organisasjonsnummer',
];

const organisasjonFields = [
  'eierforhold.organisasjon.id',
  'eierforhold.organisasjon.overordnet.id',
  'eierforhold.organisasjon.overordnet.overordnet.id',
  'grunneiendom.eierforhold.organisasjon.id',
  'grunneiendom.eierforhold.organisasjon.overordnet.id',
  'grunneiendom.eierforhold.organisasjon.overordnet.overordnet.id',
  'organisasjon.id',
  'bruker.id',
  'bruker.overordnet.id',
  'bruker.overordnet.overordnet.id',
  'bruker.overordnet.overordnet.overordnet.id',
  'bruker.overordnet.overordnet.overordnet.overordnet.id',
  'virksomhet.id',
];

const departementFields = [
  'eierforhold.organisasjon.departementId',
  'grunneiendom.eierforhold.organisasjon.departementId',
  'organisasjon.departementId',
  'bruker.departementId',
  ...organisasjonFields,
];

const organizationViewFields = [
  'overordnet.id',
  'id',
  'overordnet.overordnet.id',
];

const kommuneFields = [
  'kommunenr',
  'grunneiendommer.kommunenr',
  'forretningsadresse.kommunenr',
  'forretningsadresse.kommune.kommunenr',
  'leieforholds.grunneiendommer.kommune.kommunenr',
];

const fylkeFields = [
  'kommune.fylke.fylkenr',
  'grunneiendommer.kommune.fylke.fylkenr',
  'forretningsadresse.kommune.fylke.fylkenr',
  'leieforholds.grunneiendommer.kommune.fylke.fylkenr',
];

export const eierforholdChoices = [
  {
    id: 'H',
    navn: polyglot.t('eierforholdkoder.H'),
  },
  {
    id: 'F',
    navn: polyglot.t('eierforholdkoder.F'),
  },
];

export const grunneiendomTypes = [
  'Seksjon',
  'AnnenMatrikkelenhet',
  'Anleggseiendom',
  'Grunneiendom',
  'Festegrunn',
  'Jordsameie',
];

const fieldMapper = (field, type) => {
  switch (field) {
    case 'organisasjon':
      return type === 'organization'
        ? organizationViewFields
        : organisasjonFields;
    case 'departement':
      return type === 'organization'
        ? organizationViewFields
        : departementFields;
    case 'fylke':
      return fylkeFields;
    case 'kommune':
      return kommuneFields;
    default:
      return [field];
  }
};

export const convertSelectionToRedux = ({ filter, query, ...searchProps }) => ({
  ...searchProps,
  query: query.slice(0, -1),
  filters: filter.reduce((acc, current) => {
    if (current.field[0] === organisasjonFields[0]) {
      return {
        ...acc,
        organisasjon: current,
      };
    }
    if (current.field[0] === departementFields[0]) {
      return {
        ...acc,
        departement: current,
      };
    }
    if (current.field[0] === fylkeFields[0]) {
      return {
        ...acc,
        fylke: current,
      };
    }
    if (current.field[0] === kommuneFields[0]) {
      return {
        ...acc,
        kommune: current,
      };
    }
    if (current.field[0] === 'rapporteringStatusNy') return acc;
    return {
      ...acc,
      [current.field[0]]: current,
    };
  }, {}),
});

export default ({
  type,
  table,
  userData: {
    organisasjon: { organisasjonsnummer },
    roles,
    isGlobal,
    isOrg,
    isPublic,
  },
}) => {
  const {
    filters,
    aktiv,
    query,
    page,
    shown,
    sortAsc,
    sortCol,
    objektTypeFilter,
  } = table;
  const apiName = () => {
    switch (type) {
      case 'building':
        return 'bygg';
      case 'property':
        return 'grunneiendom';
      case 'realestate':
        return 'eiendom';
      case 'organization':
        return 'organisasjon';
      case 'matrikkelAlert':
        return 'varsling';
      case 'leideLokaler':
        return 'lokale';
      case 'ledigeLokaler':
        return 'lokale';
      case 'rapporter':
        return 'rapport';
      case 'adminRapporter':
        return 'rapport';
      case 'globalSearch':
        return table.type;
      default:
        return type;
    }
  };

  const filtrerRapporter = type === 'rapporter';
  const filterMyOrg = filter =>
    !isPublic &&
    isOrg &&
    !filtrerRapporter &&
    (filter.value || filter.lokaler || filter.properties);

  const mappedFilter = filters
    ? Object.keys(filters)
        .map(field => {
          const filter = filters[field];
          if (field === 'visMine') {
            return (
              filterMyOrg(filter) && {
                type:
                  filter.lokaler && filter.properties
                    ? undefined
                    : filter.lokaler || filter.properties,
                field: [...eierFilterFields, ...lokaleBrukerFields],
                value: organisasjonsnummer,
              }
            );
          }
          if (
            field === 'visLedige' ||
            field === 'visAktive' ||
            field === 'visUtgaatte'
          ) {
            return undefined;
          }
          if (!filter.value && !filter.toValue) return undefined;

          return { ...filter, field: fieldMapper(field, type) };
        })
        .filter(it => !!it)
    : [];

  const rapportFilter = filtrerRapporter
    ? [
        {
          field: ['synlig'],
          value: `alle|${organisasjonsnummer}`,
          group: 'rapport',
        },
        {
          field: ['rolle'],
          value: `alle|${roles}`,
          group: 'rapport',
        },
        { field: ['publisert'], value: true },
      ]
    : [];

  const aktivFilter = filters.visAktive
    ? [{ field: ['aktiv'], value: filters.visAktive.value }]
    : [];
  const ledigFilter =
    filters.visLedige && filters.visLedige.value
      ? [{ field: ['harLedigeLokaler'], value: filters.visLedige.value }]
      : [];

  const redigertFilter =
    objektTypeFilter === 'etasje' || type === 'etasje'
      ? [{ field: ['redigert'], value: true }]
      : [];

  const objekttypeFilter = objektTypeFilter
    ? [{ field: ['objekttype'], value: objektTypeFilter }]
    : [];

  const rapporteringStatusFilter =
    objektTypeFilter === 'leideLokaler'
      ? [{ field: ['rapporteringStatusNy'], value: objektTypeFilter }]
      : [];

  const expiredFilter = [];

  if (type === 'globalSearch') {
    expiredFilter.push({
      field: ['rapporteringStatusNy'],
      value: 'green|orange|red|white',
    });
  } else if (apiName() === 'lokale' && filters.visUtgaatte) {
    if (filters.visUtgaatte.value) {
      expiredFilter.push({
        field: ['rapporteringStatusNy'],
        value: 'blue',
      });
    } else {
      expiredFilter.push({
        field: ['rapporteringStatusNy'],
        value: 'green|orange|red|white',
      });
    }
  }

  const ledigeLokalerFilter =
    type === 'ledigeLokaler'
      ? [
          {
            field: ['harLedigeLokaler'],
            value: true,
          },
        ]
      : [];

  // Predefined list view filters:
  const varslingSort2 = [{ field: 'lagringsdato', direction: 'asc' }];

  // add star to query text if not number
  const textQuery = isNaN(query) ? `${query}*` : query;

  const globalFields = [
    ...apiFields.globalSearch.searchTable
      .filter(it => it.searchable)
      .map(it => it.lokaleField),
    ...apiFields.globalSearch.searchTable
      .filter(it => it.searchable)
      .map(it => it.propertyField),
  ];

  const globalPaths = globalFields
    .concat('_links')
    .concat('plassering')
    .concat('totalareal')
    .concat('grunneiendom')
    .concat('eier.navn')
    .concat('eierforhold.organisasjon.organisasjonsnummer')
    .concat('type')
    .concat('bruker.organisasjonsnummer')
    .concat('bruker.overordnet.organisasjonsnummer')
    .concat('bruker.overordnet.overordnet.organisasjonsnummer')
    .concat('bruker.overordnet.overordnet.overordnet.organisasjonsnummer')
    .concat('adresse')
    .concat('gardsnr')
    .concat('kontor')
    .concat('bruksnr')
    .concat('*.adresse');

  const allFields = [...apiFields[type].searchTable.map(it => it.field)];

  const allPaths = allFields
    .concat('_links')
    .concat('uuid')
    .concat('plassering')
    .concat('totalareal')
    .concat('grunneiendom')
    .concat('eier.navn')
    .concat('eierforhold.organisasjon.organisasjonsnummer')
    .concat('type')
    .concat('bruker.organisasjonsnummer')
    .concat('bruker.overordnet.organisasjonsnummer')
    .concat('bruker.overordnet.overordnet.organisasjonsnummer')
    .concat('bruker.overordnet.overordnet.overordnet.organisasjonsnummer')
    .concat('adresse')
    .concat('gardsnr')
    .concat('kontor')
    .concat('bruksnr')
    .concat('*.adresse');

  return {
    type: apiName(),
    query: query ? textQuery : '*',
    fields:
      type === 'globalSearch'
        ? globalFields
        : apiFields[type].searchTable
            .filter(it => it.searchable)
            .map(it => it.field),
    paths: type === 'globalSearch' ? globalPaths : allPaths,
    page,
    size: shown,
    sort: [
      {
        field: apiFields[type].searchTable.map(it => it.sort)[sortCol],
        direction: sortAsc ? 'asc' : 'desc',
      },
      ...(apiName() === 'varsling' ? varslingSort2 : ''),
    ],
    filter: [
      ...mappedFilter,
      ...aktivFilter,
      ...ledigFilter,
      ...rapportFilter,
      ...expiredFilter,
      ...objekttypeFilter,
      ...redigertFilter,
      ...ledigeLokalerFilter,
      ...rapporteringStatusFilter,
    ],
  };
};
