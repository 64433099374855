import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { DetailPageTemplate, Alert } from 'components';
import polyglot from 'i18n';
import * as propertyActions from 'redux/modules/property/property';
import mapData, { apiFields, getHeaders } from 'utils/mapApiData';

class PropertyDetails extends PureComponent {
  state = {
    table: {
      page: 0,
      shown: 10,
    },
    showModal: false,
  };

  componentDidMount() {
    const { actions, match } = this.props;
    actions.getProperty(match.params.grunneiendomId);
  }

  handleClick = i => {
    const {
      table: { page, shown },
    } = this.state;
    const { property, history } = this.props;
    const clickedBuilding = property.bygninger[i + page * shown];
    history.push(`/d${clickedBuilding._links.self}`);
  };

  mapBuildings = buildings =>
    Array.isArray(buildings) &&
    buildings.map(rowItem => [
      ...mapData(
        rowItem,
        apiFields.building.smallTable.map(cellItem => cellItem.field),
      ),
    ]);

  changePage = e =>
    this.setState(prevState => ({ table: { ...prevState.table, page: e } }));

  toggleModal = () => {
    this.setState(prevState => ({ showModal: !prevState.showModal }));
  };

  render() {
    const { showModal } = this.state;
    const { property, loading, loadingRealestateConnection } = this.props;
    const hasBuildings = property.bygninger
      ? property.bygninger.length > 0
      : false;

    const getAlert = () => {
      if (!Object.keys(property).length > 0) {
        return <Alert kind="warning">{polyglot.t('error.noProperty')}</Alert>;
      }
      if (!hasBuildings) {
        return (
          <Alert kind="info">{polyglot.t('warning.propertyNoBuildings')}</Alert>
        );
      }
      return '';
    };

    const powerBIprops = property.rapportMeta
      ? {
          aggregeringsnivaa: property.rapportMeta.aggregeringsnivaa,
          rapportId: property.rapportMeta.rapportId,
          datasetId: property.rapportMeta.datasetId,
          embedUrl: property.rapportMeta.embedUrl,
          token: property.rapportMeta.token,
        }
      : {};

    return (
      <DetailPageTemplate
        baseDetailFields={getHeaders(
          apiFields.property.baseDetails,
          'property',
        )}
        data={mapData(property, apiFields.property.baseDetails)}
        loading={loading || loadingRealestateConnection}
        property={
          parseInt(this.props.match.params.grunneiendomId, 10) ===
          property.matrikkelId
            ? property
            : {}
        }
        showMap
        alert={!loading ? getAlert() : ''}
        datalistHeader={polyglot.t('property.detailsHeader')}
        type="grunneiendom"
        id={property.id}
        name={property.navn}
        hits={hasBuildings ? property.bygninger.length : 0}
        headerRow={getHeaders(
          apiFields.building.smallTable.map(it => it.field),
          'building',
        )}
        dataRows={this.mapBuildings(property.bygninger) || []}
        dataType="building"
        changePage={this.changePage}
        table={this.state.table}
        bodyClick={this.handleClick}
        toggleModal={this.toggleModal}
        showModal={showModal}
        powerBIprops={powerBIprops}
      />
    );
  }
}

PropertyDetails.propTypes = {
  property: PropTypes.objectOf(PropTypes.any),
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any),
  loading: PropTypes.bool,
  params: PropTypes.objectOf(PropTypes.any),
  loadingRealestateConnection: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    property: state.properties.property,
    loading: state.properties.loading,
    layers: state.properties.layers,
    loadingRealestateConnection: state.realestates.loadingConnectRealEstate,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...propertyActions }, dispatch),
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PropertyDetails),
);
