// @flow

import * as React from 'react';
import polyglot from 'i18n';
import { recursiveFind, dates } from 'utils/utils';
import {
  Panel,
  Heading,
  KpiButton,
  LoadingOverlay,
  Button,
  PowerBIButtonModal,
} from 'components';
import styles from './KpiPanels.css';

type Props = {
  type: 'lokale' | 'dashboard',
  areaPanel: React.Node,
  nonOfficeAreaPanel: React.Node,
  costPanel: React.Node,
  humanPanel: React.Node,
  kontor: boolean,
  outdatedDate: string,
  futureDate: string,
  loadingStats: boolean,
  outdated: boolean,
  future: boolean,
  isPublic: boolean,
  powerBIprops: {
    aggregeringsnivaa: string,
    rapportId: string,
    datasetId: string,
    embedUrl: string,
    token: string,
  },
};

type TabType = 'areal' | 'ikkeKontorAreal' | 'kostnader' | 'ansatte' | '';
type State = {
  currentTab: TabType,
  panelOpen: boolean,
  showModal: boolean,
};

class KpiPanels extends React.Component<Props, State> {
  state = {
    currentTab: '',
    panelOpen: false,
    showModal: false,
  };

  toggleTabs = (e: { target: { id?: TabType } }) => {
    const target: TabType = recursiveFind(e.target, 'id') || '';
    const { currentTab } = this.state;
    const transition = target === currentTab ? 250 : 0;
    this.setState(prevState => ({
      panelOpen: target !== prevState.currentTab,
    }));
    setTimeout(
      () =>
        this.setState(prevState => ({
          currentTab: target === prevState.currentTab ? '' : target,
        })),
      transition,
    );
  };

  toggleModal = () => {
    this.setState(prevState => ({ showModal: !prevState.showModal }));
  };

  render() {
    const {
      areaPanel,
      nonOfficeAreaPanel,
      costPanel,
      humanPanel,
      kontor,
      outdatedDate,
      futureDate,
      loadingStats,
      changeKpiDate,
      kpiDate,
      powerBIprops,
      ...props
    } = this.props;
    const { currentTab, panelOpen, showModal } = this.state;

    return (
      <Panel className={styles.keyNumbers}>
        {loadingStats && <LoadingOverlay relative />}
        {props.outdated && (
          <div className={styles.outdated}>
            {polyglot.t('kpi.lastActive', { outdatedDate })}
          </div>
        )}
        {props.future && (
          <div className={styles.outdated}>
            {polyglot.t('kpi.future', { futureDate })}
          </div>
        )}
        <div className={styles.buttonRow}>
          <div role="radiogroup" className={styles.dateButtons}>
            {dates().map(date => (
              <Button
                key={date}
                role="radio"
                activeHover
                active={kpiDate === date}
                aria-checked={kpiDate === date}
                onClick={changeKpiDate}
              >
                {date}
              </Button>
            ))}
          </div>
          {!(props.type === 'lokale' && !kontor) && (
            <KpiButton
              name="areal"
              toggleTabs={this.toggleTabs}
              {...props}
              {...this.state}
            />
          )}
          {(props.type === 'dashboard' || !kontor) && (
            <KpiButton
              name="ikkeKontorAreal"
              toggleTabs={this.toggleTabs}
              {...props}
              {...this.state}
            />
          )}
          <KpiButton
            name="kostnader"
            toggleTabs={this.toggleTabs}
            {...props}
            {...this.state}
          />
          <KpiButton
            name="ansatte"
            toggleTabs={this.toggleTabs}
            {...props}
            {...this.state}
          />
          {!props.isPublic &&
            powerBIprops &&
            powerBIprops.rapportId && (
              <PowerBIButtonModal
                toggle={this.toggleModal}
                showModal={showModal}
                fullScreen
                token={powerBIprops.token}
                embedUrl={powerBIprops.embedUrl}
                id={powerBIprops.rapportId}
              />
            )}
        </div>
        <div
          role="region"
          id="kpiPanelContent"
          className={panelOpen ? styles.panelOpen : styles.panelClosed}
        >
          <div className={styles.contentWrapper}>
            {currentTab === 'areal' && areaPanel}
            {currentTab === 'ikkeKontorAreal' && nonOfficeAreaPanel}
            {currentTab === 'kostnader' && costPanel}
            {currentTab === 'ansatte' && humanPanel}
          </div>
        </div>
      </Panel>
    );
  }
}

export default KpiPanels;
