import React from 'react';
import PropTypes from 'prop-types';
import NameEditForm from 'containers/NameEditForm/NameEditForm';
import { Button, Icon } from 'components';
import classnames from 'classnames';
import polyglot from 'i18n';
import styles from './Popover.css';

// NOT used anymore, was created for popovers on openlayers map.
// May be useful if edit builings on map later on

const Popover = ({ name, id, hidden, hidePop, children }) => (
  <div
    id="popover"
    placement="top"
    className={classnames(styles.popover, hidden && styles.hidden)}
    title={
      <NameEditForm
        id={id}
        type="bygning"
        currentName={name || polyglot.t('notFound.metadata.notDefined')}
        closeButton={
          <Button className={styles.closeButton} onClick={hidePop}>
            <Icon icon="close" size="1" />
          </Button>
        }
      />
    }
  >
    {children}
  </div>
);

Popover.propTypes = {
  name: PropTypes.string,
  id: PropTypes.number,
  hidden: PropTypes.bool,
  hidePop: PropTypes.func,
};

export default Popover;
