// @flow
import React from 'react';
import polyglot from 'i18n';
import { toGuiDate } from 'utils/utils';
import { Heading, DataItem, Panel, Button, Icon } from 'components';

import styles from './LedigeLokalerListPanel.css';

type Props = {
  leieforholdChoices: Array<Object>,
  isReadOnly: boolean,
  handleEditLedigLokale({ leieforhold: string, leieobjekt: string }): void,
  close: func,
};

const LedigeLokalerListPanel = ({
  handleEditLedigLokale,
  leieforholdChoices,
  isReadOnly,
  close,
}: Props) => {
  const ledigeLokalerList = Array.isArray(leieforholdChoices)
    ? leieforholdChoices.map(lforhold =>
        lforhold.leieobjekts
          .filter(lobj => lobj.ledigLokale.id)
          .map(lobjekt => (
            <div key={lobjekt.id} className={styles.ledigeLokalerList}>
              <DataItem
                vertical
                small
                margin
                paddingWide={false}
                metaData={polyglot.t('ledigeLokaler.leieforholdnavn')}
                data={lforhold.navn}
              />
              <DataItem
                vertical
                small
                margin
                paddingWide={false}
                metaData={polyglot.t('ledigeLokaler.leieobjektnavn')}
                data={lobjekt.navn}
              />
              <DataItem
                vertical
                small
                margin
                paddingWide={false}
                metaData={polyglot.t('ledigeLokaler.ledigeLokalerType')}
                data={lobjekt.ledigLokale.ledigLokaleType}
              />
              <DataItem
                vertical
                small
                margin
                paddingWide={false}
                metaData={polyglot.t('ledigeLokaler.leieperiode')}
                data={`${toGuiDate(lobjekt.ledigLokale.fraDato)} - ${toGuiDate(
                  lobjekt.ledigLokale.tilDato,
                )}`}
              />
              <DataItem
                vertical
                small
                margin
                paddingWide={false}
                metaData={polyglot.t('ledigeLokaler.kontaktperson')}
                data={lobjekt.ledigLokale.kontaktperson}
              />
              <DataItem
                vertical
                small
                margin
                paddingWide={false}
                metaData={polyglot.t('ledigeLokaler.kontaktEpost')}
                data={lobjekt.ledigLokale.kontaktEpost}
              />
              <DataItem
                vertical
                small
                margin
                paddingWide={false}
                metaData={polyglot.t('ledigeLokaler.kontaktMobil')}
                data={lobjekt.ledigLokale.kontaktMobil}
              />
              {!isReadOnly && (
                <Button
                  borderless
                  onClick={() => handleEditLedigLokale(lforhold.id, lobjekt.id)}
                >
                  <Icon size="3" spaced icon="pencil" />
                </Button>
              )}
            </div>
          )),
      )
    : [];

  return (
    <Panel>
      <Heading level={3} green bottomMargin>
        {polyglot.t('ledigeLokaler.availableHeader')}
      </Heading>
      {ledigeLokalerList}
    </Panel>
  );
};

export default LedigeLokalerListPanel;
