exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".WizardStep1__badges___1_7P- {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: horizontal;\n  -webkit-box-direction: normal;\n      -ms-flex-flow: row wrap;\n          flex-flow: row wrap;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n}\n\n.WizardStep1__buttonWrapper___3_BGz {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  margin-bottom: 5px;\n}\n\n.WizardStep1__button___3jU_t,\n.WizardStep1__button___3jU_t:hover,\n.WizardStep1__button___3jU_t:active,\n.WizardStep1__button___3jU_t:focus,\n.WizardStep1__button___3jU_t:active:focus {\n  min-width: 200px;\n  width: -webkit-max-content;\n  width: -moz-max-content;\n  width: max-content;\n  min-height: 40px;\n  height: -webkit-max-content;\n  height: -moz-max-content;\n  height: max-content;\n}\n", ""]);

// exports
exports.locals = {
	"badges": "WizardStep1__badges___1_7P-",
	"buttonWrapper": "WizardStep1__buttonWrapper___3_BGz",
	"button": "WizardStep1__button___3jU_t"
};