// @flow

import * as React from 'react';
import {
  Field,
  DateTimePicker,
  SelectField,
  Toggle,
  FeedbackDiv,
} from 'components';
import polyglot from 'i18n';
import { validateNumber } from 'utils/utils';

import styles from './GlobalSearchLokaleFilters.css';

type Props = {
  filters: {
    totalareal: { value: string, toValue: string },
    fraDato: { value: string, toValue: string },
    tilDato: { value: string, toValue: string },
    kostnaderPerKvm: { value: string, toValue: string },
    kostnaderPerAarsverk: { value: string, toValue: string },
    arealPerAnsatt: { value: string, toValue: string },
    visMine: { lokaler: boolean },

    mineLokaler: boolean,
    harLedigeLokaler: { value: boolean },
    isAktiv: { value: boolean },
    kontor: boolean,
    kostFra: string,
    kostTil: string,
  },
  isPublic: boolean,
  isOrg: boolean,
  handleFilterChange({
    value?: string,
    field: string,
    prefix?: string,
    type?: 'lokale' | 'grunneiendom',
  }): void,
  onSubmit(): void,
};

const TYPE = 'lokale';

const GlobalSearchLokaleFilters = ({
  filters,
  handleFilterChange,
  onSubmit,
  isPublic,
  isOrg,
}: Props) => {
  const convertToDate = dateString => {
    if (!dateString) return undefined;
    return new Date(dateString);
  };
  const { value: totalArealFra, toValue: totalArealTil } =
    filters.totalareal || {};
  const { lokaler: showMyLokaler } = filters.visMine || {};
  const { value: showLedigeLokaler } = filters.harLedigeLokaler || false;
  const { value: contractStartFra, toValue: contractStartTil } =
    filters.fraDato || {};
  const { value: contractEndFra, toValue: contractEndTil } =
    filters.tilDato || {};
  const { value: kostnadPerKvmFra, toValue: kostnadPerKvmTil } =
    filters.kostnaderPerKvm || {};
  const { value: kostnaderPerAarsverkFra, toValue: kostnaderPerAarsverkTil } =
    filters.kostnaderPerAarsverk || {};
  const { value: arealPerAnsattFra, toValue: arealPerAnsattTil } =
    filters.arealPerAnsatt || {};
  return (
    <div className={styles.filterWrapper}>
      {!isPublic &&
        isOrg && (
          <Toggle
            label="Vis bare mine lokaler?"
            value={!!showMyLokaler}
            vertical
            id="showMyLokaler"
            onChange={() =>
              handleFilterChange({
                lokaler: !showMyLokaler && TYPE,
                field: 'visMine',
              })
            }
          />
        )}
      {!isPublic &&
        !isOrg && (
          <div className={styles.doubleField}>
            <Field
              value={totalArealFra || ''}
              onChange={e =>
                handleFilterChange({
                  field: 'totalareal',
                  value: e.target.value || undefined,
                  type: TYPE,
                  prefix: 'fom',
                })
              }
              maxLength={9}
              placeholder="Fra"
              label={polyglot.t('searchFilter.areal')}
              id="totalArealFra"
              onKeyPress={e => e.key === 'Enter' && onSubmit()}
              validationState={validateNumber(totalArealFra)}
            />
            <Field
              value={totalArealTil || ''}
              onChange={e =>
                handleFilterChange({
                  field: 'totalareal',
                  toValue: e.target.value || undefined,
                  type: TYPE,
                  prefix: 'fom',
                })
              }
              maxLength={9}
              placeholder="Til"
              id="totalArealTil"
              aria-label={polyglot.t('searchFilter.arealAnsattTil')}
              onKeyPress={e => e.key === 'Enter' && onSubmit()}
              validationState={validateNumber(totalArealTil)}
            />
          </div>
        )}
      {parseInt(totalArealFra, 10) > parseInt(totalArealTil, 10) && (
        <FeedbackDiv biggerThan id="totalAreal-bigger-error" />
      )}
      {(validateNumber(totalArealFra) === 'error' ||
        validateNumber(totalArealTil) === 'error') && (
        <FeedbackDiv invalidNumber id="totalAreal-error" />
      )}
      <SelectField
        label={polyglot.t('searchFilter.kontorLabel')}
        name="kontor"
        placeholder={polyglot.t('newContract.step1.choose')}
        selectedValue={filters.kontor ? filters.kontor.value : undefined}
        choices={[
          {
            id: 'true',
            navn: 'Ja',
          },
          {
            id: 'false',
            navn: 'Nei',
          },
        ]}
        onChange={e =>
          handleFilterChange({
            type: TYPE,
            value: e ? e.value : '',
            field: 'kontor',
          })
        }
        isClearable
      />
      {!isPublic && (
        <Toggle
          label={polyglot.t('searchFilter.visLedigeLokaler')}
          value={showLedigeLokaler}
          vertical
          id="harLedigeLokaler"
          onChange={() =>
            handleFilterChange({
              value: !showLedigeLokaler,
              field: 'harLedigeLokaler',
            })
          }
        />
      )}
      {!isPublic &&
        !isOrg && (
          <React.Fragment>
            <div className={styles.doubleField}>
              <DateTimePicker
                label={polyglot.t('searchFilter.leieperiodeStart')}
                inputProps={{
                  'aria-label': polyglot.t('searchFilter.leieperiodeStart'),
                }}
                onChange={date => {
                  handleFilterChange({
                    field: 'fraDato',
                    value: date,
                    type: TYPE,
                    prefix: 'fom',
                  });
                }}
                placeholder="Fra"
                max={convertToDate(contractStartTil)}
                value={convertToDate(contractStartFra)}
                onEnter={onSubmit}
              />
              <DateTimePicker
                inputProps={{
                  'aria-label':
                    polyglot.t('searchFilter.leieperiodeStart') +
                    polyglot.t('searchFilter.til'),
                }}
                onChange={date => {
                  handleFilterChange({
                    field: 'fraDato',
                    toValue: date,
                    type: TYPE,
                    prefix: 'fom',
                  });
                }}
                placeholder="Til"
                min={convertToDate(contractStartFra)}
                value={convertToDate(contractStartTil)}
                onEnter={onSubmit}
              />
            </div>
            <div className={styles.doubleField}>
              <DateTimePicker
                label={polyglot.t('searchFilter.LeieperiodeSlutt')}
                inputProps={{
                  'aria-label': polyglot.t('searchFilter.LeieperiodeSlutt'),
                }}
                onChange={date => {
                  handleFilterChange({
                    field: 'tilDato',
                    value: date,
                    type: TYPE,
                    prefix: 'fom',
                  });
                }}
                placeholder="Fra"
                value={convertToDate(contractEndFra)}
                max={convertToDate(contractEndTil)}
                onEnter={onSubmit}
              />
              <DateTimePicker
                inputProps={{
                  'aria-label':
                    polyglot.t('searchFilter.LeieperiodeSlutt') +
                    polyglot.t('searchFilter.til'),
                }}
                onChange={date => {
                  handleFilterChange({
                    field: 'tilDato',
                    toValue: date,
                    type: TYPE,
                    prefix: 'fom',
                  });
                }}
                placeholder="Til"
                value={convertToDate(contractEndTil)}
                min={convertToDate(contractEndFra)}
                onEnter={onSubmit}
              />
            </div>
            <div className={styles.doubleField}>
              <Field
                value={arealPerAnsattFra || ''}
                onChange={e =>
                  handleFilterChange({
                    field: 'arealPerAnsatt',
                    value: e.target.value || undefined,
                    type: TYPE,
                    prefix: 'fom',
                  })
                }
                maxLength={9}
                placeholder="Fra"
                label={polyglot.t('searchFilter.arealPrAnsatt')}
                onKeyPress={e => e.key === 'Enter' && onSubmit()}
                validationState={validateNumber(arealPerAnsattFra)}
              />
              <Field
                value={arealPerAnsattTil || ''}
                onChange={e =>
                  handleFilterChange({
                    field: 'arealPerAnsatt',
                    toValue: e.target.value || undefined,
                    type: TYPE,
                    prefix: 'fom',
                  })
                }
                maxLength={9}
                placeholder="Til"
                aria-label={polyglot.t('searchFilter.arealAnsattTil')}
                onKeyPress={e => e.key === 'Enter' && onSubmit()}
                validationState={validateNumber(arealPerAnsattTil)}
              />
            </div>
            <div>
              {parseInt(arealPerAnsattFra, 10) >
                parseInt(arealPerAnsattTil, 10) && (
                <FeedbackDiv biggerThan id="arealPerAnsatt-bigger-error" />
              )}
              {(validateNumber(arealPerAnsattFra) === 'error' ||
                validateNumber(arealPerAnsattTil) === 'error') && (
                <FeedbackDiv invalidNumber id="arealPerAnsatt-error" />
              )}
            </div>
            <div className={styles.doubleField}>
              <Field
                value={kostnadPerKvmFra || ''}
                onChange={e =>
                  handleFilterChange({
                    field: 'kostnaderPerKvm',
                    value: e.target.value || undefined,
                    type: TYPE,
                    prefix: 'fom',
                  })
                }
                maxLength={9}
                placeholder="Fra"
                label={polyglot.t('searchFilter.kostnadPrKvm')}
                onKeyPress={e => e.key === 'Enter' && onSubmit()}
                validationState={validateNumber(kostnadPerKvmFra)}
              />
              <Field
                value={kostnadPerKvmTil || ''}
                onChange={e =>
                  handleFilterChange({
                    field: 'kostnaderPerKvm',
                    toValue: e.target.value || undefined,
                    type: TYPE,
                    prefix: 'fom',
                  })
                }
                maxLength={9}
                placeholder="Til"
                aria-label={
                  polyglot.t('searchFilter.kostnadPrKvm') +
                  polyglot.t('searchFilter.til')
                }
                onKeyPress={e => e.key === 'Enter' && onSubmit()}
                validationState={validateNumber(kostnadPerKvmTil)}
              />
            </div>
            <div>
              {parseInt(kostnadPerKvmFra, 10) >
                parseInt(kostnadPerKvmTil, 10) && (
                <FeedbackDiv biggerThan id="kostnadPerKvm-bigger-error" />
              )}
              {(validateNumber(kostnadPerKvmFra) === 'error' ||
                validateNumber(kostnadPerKvmTil) === 'error') && (
                <FeedbackDiv invalidNumber id="kostnadPerKvm-error" />
              )}
            </div>
            <div className={styles.doubleField}>
              <Field
                value={kostnaderPerAarsverkFra || ''}
                onChange={e =>
                  handleFilterChange({
                    field: 'kostnaderPerAarsverk',
                    value: e.target.value || undefined,
                    type: TYPE,
                    prefix: 'fom',
                  })
                }
                maxLength={9}
                placeholder="Fra"
                label={polyglot.t('searchFilter.kostnadPrArsverk')}
                onKeyPress={e => e.key === 'Enter' && onSubmit()}
                validationState={validateNumber(kostnaderPerAarsverkFra)}
              />
              <Field
                value={kostnaderPerAarsverkTil || ''}
                onChange={e =>
                  handleFilterChange({
                    field: 'kostnaderPerAarsverk',
                    toValue: e.target.value || undefined,
                    type: TYPE,
                    prefix: 'fom',
                  })
                }
                maxLength={9}
                placeholder="Til"
                aria-label={
                  polyglot.t('searchFilter.kostnadPrArsverk') +
                  polyglot.t('searchFilter.til')
                }
                onKeyPress={e => e.key === 'Enter' && onSubmit()}
                validationState={validateNumber(kostnaderPerAarsverkTil)}
              />
            </div>
            <div>
              {parseInt(kostnaderPerAarsverkFra, 10) >
                parseInt(kostnaderPerAarsverkTil, 10) && (
                <FeedbackDiv
                  biggerThan
                  id="kostnaderPerAarsverk-bigger-error"
                />
              )}
              {(validateNumber(kostnaderPerAarsverkFra) === 'error' ||
                validateNumber(kostnaderPerAarsverkTil) === 'error') && (
                <FeedbackDiv invalidNumber id="kostnaderPerAarsverk-error" />
              )}
            </div>
          </React.Fragment>
        )}
    </div>
  );
};

export default GlobalSearchLokaleFilters;
