exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".NotFound__wrapper___3Sg2Q {\n  padding: 120px;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n}\n\n.NotFound__heading___1r59t {}\n\n.NotFound__paragraph___nsgTk {}\n", ""]);

// exports
exports.locals = {
	"wrapper": "NotFound__wrapper___3Sg2Q",
	"heading": "NotFound__heading___1r59t",
	"paragraph": "NotFound__paragraph___nsgTk"
};