import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './DataItem.css';
import { HelpToolTipButton } from '../../molecules';

const DataItem = ({
  metaData,
  data,
  vertical,
  inverted,
  wrap,
  children,
  tooltip,
  feedback,
  outdated,
  fieldStyle,
  alignCenter,
  ellipsis,
  paddingWide,
  heading,
  small,
  margin,
  id,
  isButton,
  ...props
}) => {
  const metaDataDiv = (
    <div
      className={classnames(styles.metadata, styles.vertical, {
        [styles.inverted]: inverted,
        [styles.fieldStyle]: fieldStyle,
        [styles.alignCenter]: alignCenter,
        [styles.ellipsis]: ellipsis,
        [styles.small]: small,
      })}
    >
      {metaData}
    </div>
  );

  const dataDiv = (
    <div
      className={classnames(styles.data, styles.vertical, {
        [styles.inverted]: inverted,
        [styles.wrap]: wrap,
        [styles.outdated]: outdated,
        [styles.fieldStyle]: fieldStyle,
        [styles.alignCenter]: alignCenter,
        [styles.small]: small,
      })}
    >
      {data}
    </div>
  );

  if (vertical) {
    return (
      <div
        className={classnames(paddingWide && styles.paddingWide, {
          [styles.margin]: margin,
          [styles.tooltipDiv]: tooltip,
        })}
        {...props}
      >
        <div className={styles.dataDiv}>
          {inverted ? dataDiv : metaDataDiv}
          {inverted ? metaDataDiv : dataDiv}
        </div>
        <div className={styles.child}>{children}</div>
        {tooltip && (
          <div className={styles.tooltipDiv}>
            <HelpToolTipButton title={tooltip} />
          </div>
        )}
      </div>
    );
  }
  return (
    <div
      className={classnames(styles.row, paddingWide && styles.paddingWide)}
      {...props}
    >
      {metaData && (
        <div
          className={classnames(styles.metadata, styles.defaultEllipsis, {
            [styles.inverted]: inverted,
            [styles.fieldStyle]: fieldStyle,
            [styles.alignCenter]: alignCenter,
            [styles.ellipsis]: ellipsis,
            [styles.heading]: heading,
            [styles.small]: small,
          })}
        >
          {metaData}
        </div>
      )}
      <div
        className={classnames(
          styles.data,
          metaData && ellipsis && styles.ellipsis,
          feedback && styles.feedback,
          outdated && styles.outdated,
          {
            [styles.inverted]: inverted,
            [styles.wrap]: wrap,
            [styles.outdated]: outdated,
            [styles.fieldStyle]: fieldStyle,
            [styles.alignCenter]: alignCenter,
            [styles.heading]: heading,
            [styles.small]: small,
          },
        )}
      >
        {data}
      </div>
    </div>
  );
};

DataItem.propTypes = {
  metaData: PropTypes.string,
  data: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.element,
  ]),
  inputCost: PropTypes.bool,
  vertical: PropTypes.bool,
  inverted: PropTypes.bool,
  children: PropTypes.node,
  tooltip: PropTypes.string,
  wrap: PropTypes.bool,
  feedback: PropTypes.bool,
  outdated: PropTypes.bool,
  fieldStyle: PropTypes.bool,
  paddingWide: PropTypes.bool,
  heading: PropTypes.bool,
  small: PropTypes.bool,
  margin: PropTypes.bool,
};

DataItem.defaultProps = {
  paddingWide: true,
};

export default DataItem;
