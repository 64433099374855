exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* VIEWPORTS */\n\n:root {\n}\n\n.GlobalSearchTemplate__wrapper___2cytY {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: no-wrap;\n      flex-wrap: no-wrap;\n}\n\n@media (max-width: 800px) {\n\n  .GlobalSearchTemplate__wrapper___2cytY {\n    -ms-flex-wrap: wrap;\n        flex-wrap: wrap;\n  }\n}\n\n.GlobalSearchTemplate__filterBox___2RG4n {\n  -webkit-box-flex: 0;\n      -ms-flex: 0 1 430px;\n          flex: 0 1 430px;\n  margin-right: 30px;\n  min-width: 0;\n}\n\n@media (max-width: 800px) {\n\n  .GlobalSearchTemplate__filterBox___2RG4n {\n    margin-right: 0;\n  }\n}\n\n.GlobalSearchTemplate__resultBox___1-zBq {\n  -webkit-box-flex: 3;\n      -ms-flex: 3 1 auto;\n          flex: 3 1 auto;\n  height: -webkit-max-content;\n  height: -moz-max-content;\n  height: max-content;\n  min-width: 0;\n}\n\n.GlobalSearchTemplate__legend___2nfMH {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: end;\n      -ms-flex-pack: end;\n          justify-content: flex-end;\n}\n\n.GlobalSearchTemplate__headerWrapper___39F4p {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n}\n", ""]);

// exports
exports.locals = {
	"wrapper": "GlobalSearchTemplate__wrapper___2cytY",
	"filterBox": "GlobalSearchTemplate__filterBox___2RG4n",
	"resultBox": "GlobalSearchTemplate__resultBox___1-zBq",
	"legend": "GlobalSearchTemplate__legend___2nfMH",
	"headerWrapper": "GlobalSearchTemplate__headerWrapper___39F4p"
};