exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* VIEWPORTS */\n\n:root {\n}\n\n.Modal__error___W1Xam {\n  border: 2px solid #d0021b;\n}\n\n.Modal__errorMessage___244mP {\n  color: #d0021b;\n  white-space: pre-line;\n}\n\n.Modal__dialogWrapper___yRc5Q {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  padding: 40px 80px 20px\n}\n\n.Modal__dialogWrapper___yRc5Q > * {\n  margin-bottom: 20px;\n}\n\n.Modal__bottomLine___HC94g {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n  -webkit-box-align: end;\n      -ms-flex-align: end;\n          align-items: flex-end;\n}\n\n.Modal__buttonWrapper___35xP9 {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: end;\n      -ms-flex-pack: end;\n          justify-content: flex-end;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center\n}\n\n.Modal__buttonWrapper___35xP9 > button:first-child {\n  margin-right: 10px;\n}\n", ""]);

// exports
exports.locals = {
	"error": "Modal__error___W1Xam",
	"errorMessage": "Modal__errorMessage___244mP",
	"dialogWrapper": "Modal__dialogWrapper___yRc5Q",
	"bottomLine": "Modal__bottomLine___HC94g",
	"buttonWrapper": "Modal__buttonWrapper___35xP9"
};