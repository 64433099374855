import React from 'react';
import {
  WizardSteps,
  Panel,
  DashboardBadge,
  Heading,
  Button,
} from 'components';
import polyglot from 'i18n';
import styles from './WizardStep1.css';

const WizardStep1 = ({
  eiendommer,
  bygninger,
  grunneiendommer,
  buttonClick,
}) => (
  <Panel packet>
    <WizardSteps steps={3} currentStep={1} onClick={buttonClick} />
    <Heading green level={1}>
      {' '}
      {polyglot.t('report.step1Header')}
    </Heading>
    <div className={styles.badges}>
      <DashboardBadge
        title={polyglot.t('realestate.title')}
        number={eiendommer}
      />
      <DashboardBadge
        title={polyglot.t('property.title')}
        number={grunneiendommer}
      />
      <DashboardBadge title={polyglot.t('building.title')} number={bygninger} />
    </div>
    <div className={styles.buttonWrapper}>
      <Button
        name="next"
        onClick={buttonClick}
        className={styles.button}
        kind="primary"
      >
        {polyglot.t('report.start')}
      </Button>
    </div>
  </Panel>
);

export default WizardStep1;
