import ReactGA from 'react-ga';
import { getVersion } from 'services/api';

// Setup Google analytics
export const getGA = () => {
  getVersion().then(res => {
    if (res.analytics.enabled) {
      ReactGA.initialize(res.analytics.id.toString(), {
        debug: false,
        gaOptions: {
          anonymizeIp: true,
        },
      });
      // Send location on initialization
      ReactGA.set({
        page: window.location.pathname + window.location.search,
      });
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
    // console.log(`Google Analytics status: ${res.analytics.enabled}`);
    return res.analytics.enabled;
  });
};

export default () => {
  ReactGA.set({ page: window.location.pathname + window.location.search });
  ReactGA.pageview(window.location.pathname + window.location.search);
  // console.log('GA Path: ' + window.location.pathname + window.location.search);
};
