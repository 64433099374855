import { delay } from 'redux-saga';
import { put, call, takeLatest } from 'redux-saga/effects';
import {
  DISPLAY_ALERT,
  HIDE_NOTIFICATIONS,
} from 'redux/modules/widgets/widgets';

export function* delayNotification() {
  yield call(delay, 5000);
  yield put({ type: HIDE_NOTIFICATIONS });
}

export default function* widgetsSaga() {
  yield takeLatest(DISPLAY_ALERT, delayNotification);
}
