import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Dropdown as BsDropdown, MenuItem } from 'react-bootstrap';
import { Icon } from 'components';

import styles from './Dropdown.css';

const Dropdown = ({
  buttonStyle,
  mastHead,
  profile,
  useCaret,
  bsStyle,
  title,
  content,
  label,
  children,
  ...rest
}) => {
  const items = content
    ? content.map((it, i) => <MenuItem key={i}>{it}</MenuItem>)
    : children;
  const classes = classnames({
    [styles.button]: buttonStyle,
    [styles.profile]: profile,
    [styles.nav]: mastHead,
  });

  return (
    <BsDropdown
      {...rest}
      className={classnames(
        rest.className,
        profile ? styles.profileWrapper : undefined,
      )}
    >
      <BsDropdown.Toggle
        noCaret
        bsStyle={bsStyle ? 'default' : null}
        aria-label={label}
        title={label}
        role={undefined}
        className={classes}
      >
        {title}
        {useCaret && <Icon icon="angle-down" size="1" />}
      </BsDropdown.Toggle>
      <BsDropdown.Menu className={styles.menu}>{items}</BsDropdown.Menu>
    </BsDropdown>
  );
};

Dropdown.propTypes = {
  // also insert possible link items css class?
  buttonStyle: PropTypes.bool,
  mastHead: PropTypes.bool,
  label: PropTypes.string,
  bsStyle: PropTypes.bool,
  profile: PropTypes.bool,
  useCaret: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  id: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(PropTypes.string),
};

export default Dropdown;
