exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* VIEWPORTS */\n\n:root {\n}\n\n.Panel__default___K-H5m {\n  -webkit-box-shadow: 0 10px 20px 0 rgba(72, 101, 111, .05);\n          box-shadow: 0 10px 20px 0 rgba(72, 101, 111, .05);\n  background-color: #fff;\n  padding: 21px;\n  margin-bottom: 30px;\n}\n\n.Panel__dashboardtable___3nvhA {\n  max-width: 1000px;\n}\n", ""]);

// exports
exports.locals = {
	"default": "Panel__default___K-H5m",
	"dashboardtable": "Panel__dashboardtable___3nvhA"
};