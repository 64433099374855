// @flow

import * as React from 'react';
import {
  Icon,
  Button,
  GlobalSearchPropertyFilters,
  GlobalSearchLokaleFilters,
  SearchFilterCheckbox,
  Link,
} from 'components';
import polyglot from 'i18n';
import { Collapse } from 'react-bootstrap';
import { toGuiDate } from 'utils/utils';
import type { TableState } from 'types';

import styles from './GlobalSearchFilter.css';
import SaveSelection from '../../molecules/SaveSelection/index';

type Props = {
  toggleMap(boolean): void,
  table: TableState,
  isPublic: boolean,
  handleFilterChange({
    value?: string,
    field: string,
    prefix?: string,
    type?: 'lokale' | 'grunneiendom',
  }): void,
  onDateChange(Date, string, string): void,
  onSubmit(): void,
  onCurrentDateChange(Date, string): void,
  onSelectChange(): void,
  saveSelection(): void,
  currentDateStartFra: Date,
  currentDateStartTil: Date,
  currentDateSluttFra: Date,
  currentDateSluttTil: Date,
  currentSelection: {
    navn?: string,
    sistOppdatert: string,
  },
  deleteSelection(): void,
  isOrg: boolean,
};

const GlobalSearchFilter = ({
  toggleMap,
  table: { filters, type, visMine },
  handleFilterChange,
  onSubmit,
  saveSelection,
  isOrg,
  isPublic,
  currentSelection,
  deleteSelection,
  ...props
}: Props) => (
  <div className={styles.wrapper}>
    <SearchFilterCheckbox
      onChange={value =>
        handleFilterChange({ value, field: 'checked', type: 'lokale' })
      }
      checked={type.includes('lokale')}
      label={polyglot.t('leideLokaler.title')}
    />
    <Collapse
      id={polyglot.t('leideLokaler.title')}
      role="region"
      in={type.includes('lokale')}
    >
      <div>
        <GlobalSearchLokaleFilters
          {...{
            onSubmit,
            handleFilterChange,
            isPublic,
            isOrg,
            filters,
            visMine,
            ...props,
          }}
        />
      </div>
    </Collapse>
    <SearchFilterCheckbox
      onChange={value =>
        handleFilterChange({ value, field: 'checked', type: 'grunneiendom' })
      }
      checked={type.includes('grunneiendom')}
      label="Statlige eiendommer"
    />
    <Collapse
      id="Statlige eiendommer"
      role="region"
      in={type.includes('grunneiendom')}
    >
      <div>
        <GlobalSearchPropertyFilters
          {...{
            onSubmit,
            handleFilterChange,
            isPublic,
            isOrg,
            filters,
            visMine,
            ...props,
          }}
        />
      </div>
    </Collapse>
    <div className={styles.buttonWrapper}>
      {!isPublic &&
        !isOrg && (
          <SaveSelection
            saveSelection={saveSelection}
            currentSelection={currentSelection}
          />
        )}
      <Button
        kind="primary"
        aria-label="Søkeknapp"
        onClick={onSubmit}
        className={styles.searchButton}
      >
        <Icon icon="search" size="1" />
      </Button>
    </div>
    {currentSelection.navn && (
      <div className={styles.selectionInfo}>
        <span>{currentSelection.navn}</span>
        <span>{`Sist oppdatert: ${toGuiDate(
          currentSelection.sistOppdatert,
          true,
        )}`}</span>
        <Link
          onClick={() => {
            if (confirm('Vil du virkelig slette utvalget?')) {
              deleteSelection();
            }
          }}
          deleteStyle
        >
          X Slett utvalg
        </Link>
      </div>
    )}
  </div>
);

export default GlobalSearchFilter;
