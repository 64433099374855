import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FieldEdit, InlineEdit } from 'components';
import polyglot from 'i18n';

import * as propertyActions from 'redux/modules/property/property';
import * as buildingActions from 'redux/modules/building/building';
import * as realestateActions from 'redux/modules/realestate/realestate';
import * as widgetsActions from 'redux/modules/widgets/widgets';
import * as leideLokalerActions from 'redux/modules/leideLokaler/leideLokaler';

class NameEditForm extends PureComponent {
  state = {
    originalName: this.props.currentName,
    isEdit: false,
    isChanged: false,
  };

  UNSAFE_componentWillReceiveProps = nextProps => {
    if (
      nextProps.currentName &&
      nextProps.currentName !== this.state.originalName
    ) {
      this.setState({
        originalName: nextProps.currentName,
        isEdit: false,
        isChanged: false,
      });
    }
  };

  componentWillUnmount = () => {
    if (this.state.isChanged && this.state.isEdit) {
      if (confirm(polyglot.t('warning.unsavedName'))) this.handleSubmit();
    }
  };

  handleEdit = () => {
    // Gets called when you start editing
    this.setState({
      currentInput:
        this.props.currentName === polyglot.t('notFound.metadata.notDefined')
          ? ''
          : this.props.currentName,
      isEdit: true,
    });
  };

  handleCancel = e => {
    if (e) e.preventDefault();
    this.setState({
      isEdit: false,
      isChanged: false,
    });
  };

  handleSubmit = e => {
    const fireChange = () => {
      if (this.props.type === 'grunneiendom')
        this.props.actions.setPropertyAlias({
          id: this.props.id,
          value: this.state.currentInput,
        });
      if (this.props.type === 'bygning')
        this.props.actions.setBuildingAlias({
          id: this.props.id,
          value: this.state.currentInput,
        });
      if (this.props.type === 'eiendom')
        this.props.actions.setRealEstateName({
          id: this.props.id,
          value: this.state.currentInput,
        });
      if (this.props.type === 'lokale')
        this.props.actions.setLokaleName({
          id: this.props.id,
          value: this.state.currentInput,
        });
      this.props.actions.displayAlert({
        kind: 'success',
        text: polyglot.t('alert.updateSuccess'),
      }); // TODO: Premature, should fire after successful update
    };
    // Only submit if actual change
    if (this.state.isChanged) {
      if (e) e.preventDefault();
      fireChange();
      this.setState({
        isEdit: false,
        isChanged: false,
      });
    } else {
      this.handleCancel(e);
    }
  };

  handleKeyPress = e => {
    if (e.key === 'Enter') {
      e.stopPropagation();
      this.handleSubmit(e);
    }
    if (e.key === 'Escape') {
      e.stopPropagation();
      this.handleCancel(e);
    }
  };

  handleChange = e => {
    if (e.target.value !== this.state.originalName)
      this.setState({ isChanged: true });
    if (e.target.value === this.state.originalName)
      this.setState({ isChanged: false });
    // if validation is needed, insert here
    this.setState({ currentInput: e.target.value });
  };

  render() {
    const {
      currentName,
      type,
      propertyEdit,
      buildingEdit,
      realestateEdit,
      ...props
    } = this.props;
    const editAccess =
      type === 'grunneiendom'
        ? propertyEdit
        : type === 'bygning'
          ? buildingEdit
          : realestateEdit;
    if (this.state.isEdit) {
      return (
        <FieldEdit
          maxLength="255"
          name={polyglot.t('navnEdit.field.name')}
          placeholder={polyglot.t('navnEdit.field.placeholder')}
          handleCancel={this.handleCancel}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyPress}
          value={this.state.currentInput}
          handleSubmit={this.handleSubmit}
          autoFocus
        />
      );
    }
    return (
      <InlineEdit
        {...props}
        editAccess={editAccess}
        level={0}
        icon="pencil"
        size="lg"
        handleEdit={this.handleEdit}
      >
        {currentName}
      </InlineEdit>
    );
  }
}

NameEditForm.propTypes = {
  id: PropTypes.number,
  type: PropTypes.oneOf(['eiendom', 'grunneiendom', 'bygning', 'lokale']),
  currentName: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  propertyEdit: PropTypes.bool,
  buildingEdit: PropTypes.bool,
  realestateEdit: PropTypes.bool,
};

NameEditForm.defaultProps = {
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
};

function mapStateToProps(state) {
  return {
    propertyEdit: state.properties.property.editable,
    buildingEdit: state.buildings.building.building.editable,
    realestateEdit: state.realestates.realestate.editable,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...realestateActions,
        ...propertyActions,
        ...buildingActions,
        ...widgetsActions,
        ...leideLokalerActions,
      },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NameEditForm);
