exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* VIEWPORTS */\n\n:root {\n}\n\n.HelpModal__wrapper___195w2 {\n  position: absolute;\n  right: -30vw;\n  min-width: 25vw;\n  max-width: 30vw;\n  z-index: 1001;\n  padding: 50px 20px 50px 14px;\n  background-color: #ffffff;\n  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .5);\n          box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .5);\n  margin-bottom: 10px;\n}\n\n@media (max-width: 800px) {\n\n  .HelpModal__wrapper___195w2 {\n    min-width: 320px;\n    max-width: 360px;\n    right: -366px;\n  }\n}\n\n.HelpModal__open___3VpRK {\n  -webkit-transition: 0.5s;\n  transition: 0.5s;\n  right: 0;\n  display: block;\n}\n\n.HelpModal__close___2Coji {\n  -webkit-transition: 0.5s;\n  transition: 0.5s;\n  right: -30vw;\n}\n\n@media (max-width: 800px) {\n\n  .HelpModal__close___2Coji {\n    right: -366px;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"wrapper": "HelpModal__wrapper___195w2",
	"open": "HelpModal__open___3VpRK",
	"close": "HelpModal__close___2Coji"
};