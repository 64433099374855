// Export action constants
export const DISPLAY_ALERT = 'ENABLE_ALERT';
export const HIDE_NOTIFICATIONS = 'HIDE_NOTIFICATIONS';

// Export action creators
export const displayAlert = payload => ({ type: DISPLAY_ALERT, payload });
export const hideNotifications = () => ({ type: HIDE_NOTIFICATIONS });

const initialState = {
  alert: {
    enabled: false,
    kind: '',
    text: '',
  },
};

// Export the reducer
export default function alertReducer(state = initialState, action) {
  switch (action.type) {
    case DISPLAY_ALERT: {
      return Object.assign({}, state, {
        alert: {
          kind: action.payload.kind,
          text: action.payload.text,
          enabled: true,
        },
      });
    }
    case HIDE_NOTIFICATIONS: {
      return Object.assign({}, state, {
        alert: {
          enabled: false,
          kind: '',
          text: '',
        },
      });
    }
    default:
      return state;
  }
}
