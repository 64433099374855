// @flow

import * as React from 'react';
import { TabButton, DataItem } from 'components';
import type { Kpi } from 'types';
import polyglot from 'i18n';

type Props = {
  name: 'areal' | 'ikkeKontorAreal' | 'kostnader' | 'ansatte',
  type: 'lokale' | 'dashboard',
  currentTab: string,
  toggleTabs(): void,
  currentVirksomhet: string,
  currentLevel: string,
  kostPrAarsverk: ?number,
  kvmPrAnsatt: number,
  arbeidsrelatertarealPerAnsatt: number,
  ansatte: string,
  totalKontorareal: string,
  totalareal: string,
  totalIkkeKontorareal: string,
  aarsverk: string,
  kostPrKvm: string,
  totalKostnad: string,
  kpi: Kpi,
  panelOpen: boolean,
  outdated: boolean,
  future: boolean,
  isPubic: boolean,
};

const KpiButton = ({
  name,
  type,
  currentTab,
  panelOpen,
  toggleTabs,
  kpi,
  currentVirksomhet,
  currentLevel,
  outdated,
  future,
  isPublic,
  ...props
}: Props) => {
  const dataWrapper = (
    data:
      | 'totalKontorareal'
      | 'totalareal'
      | 'kvmPrAnsatt'
      | 'totalIkkeKontorareal'
      | 'arbeidsrelatertarealPerAnsatt'
      | 'totalKostnad'
      | 'kostPrKvm'
      | 'aarsverk'
      | 'kostPrAarsverk'
      | 'ansatte',
  ): React.Node => (
    <DataItem
      outdated={outdated || future}
      tooltip={polyglot.t(`tooltips.${type}.${data}`, { currentVirksomhet })}
      data={props[data]}
      style={{ width: '100%' }}
      metaData={polyglot.t(`kpi.metaData.${data}`)}
      vertical
      inverted
      alignCenter
      ellipsis
      paddingWide={false}
    />
  );

  const showToggle = currentLevel !== 'VIRKSOMHET' || !isPublic;

  const title =
    type === 'lokale'
      ? polyglot.t(`leideLokaler.headers.${name}`)
      : polyglot.t(`kpi.${name}`);

  const officeAreaItems = (
    <React.Fragment>
      {type === 'lokale'
        ? dataWrapper('totalareal')
        : dataWrapper('totalKontorareal')}
      {props.kvmPrAnsatt && dataWrapper('kvmPrAnsatt')}
    </React.Fragment>
  );
  const nonOfficeAreaItems = (
    <React.Fragment>
      {type === 'lokale'
        ? dataWrapper('totalareal')
        : dataWrapper('totalIkkeKontorareal')}
      {dataWrapper('arbeidsrelatertarealPerAnsatt')}
    </React.Fragment>
  );
  const costItems = (
    <React.Fragment>
      {dataWrapper('totalKostnad')}
      {dataWrapper('kostPrKvm')}
    </React.Fragment>
  );
  const employeeItems = (
    <React.Fragment>
      {dataWrapper('aarsverk')}
      {props.kostPrAarsverk
        ? dataWrapper('kostPrAarsverk')
        : dataWrapper('ansatte')}
    </React.Fragment>
  );
  return (
    <TabButton
      title={title}
      id={name}
      active={currentTab === name && panelOpen}
      onClick={toggleTabs}
      showToggle={showToggle}
      type={type}
      aria-labelledby={`${name}_label`}
      aria-describedby={`${name}_toggle`}
      name={name}
    >
      {name === 'areal' && officeAreaItems}
      {name === 'ikkeKontorAreal' && nonOfficeAreaItems}
      {name === 'kostnader' && costItems}
      {name === 'ansatte' && employeeItems}
    </TabButton>
  );
};

export default KpiButton;
