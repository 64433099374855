import React from 'react';
import PropTypes from 'prop-types';
import { DataItem } from 'components';
import styles from './DataList.css';

const DataList = ({ fields, data }) => {
  const list = fields.map((it, i) => {
    if (Array.isArray(data) && data[i] === undefined) return undefined;
    return (
      <DataItem
        key={i}
        metaData={it}
        data={Array.isArray(data) ? data[i] : data[it] || ''}
      />
    );
  });
  return <dl className={styles.wrapper}>{list}</dl>;
};

DataList.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

export default DataList;
