import { takeLatest, all } from 'redux-saga/effects';

import * as SEARCH from 'redux/modules/search/search';
import * as TABLE from 'redux/modules/search/table';

import { delayCallToApi, handlePostToApi } from './sagaHandlers';

export default function* propertySaga() {
  yield all([
    takeLatest(
      SEARCH.SEARCH_REQUEST,
      handlePostToApi,
      SEARCH.searchSuccess,
      SEARCH.searchError,
    ),
    takeLatest(
      TABLE.TOGGLE_LIST_ITEM,
      delayCallToApi,
      TABLE.toggleListItemSuccess,
      TABLE.toggleListItemFail,
    ),
    takeLatest(
      SEARCH.GET_STATS,
      handlePostToApi,
      SEARCH.gotStats,
      SEARCH.getStatsFailed,
    ),
  ]);
}
