// @flow
import React from 'react';
import polyglot from 'i18n';
import {
  Heading,
  Panel,
  Table,
  SelectField,
  Field,
  Bubbles,
  Button,
} from 'components';
import type { PowerBI } from 'types';
import styles from './PowerBIAdminTemplate.css';

type Props = {
  handleOnChange: (
    { id: string, value: string },
    aggregeringsnivaa: string,
    column: string,
  ) => void,
  handleOnSubmit: () => void,
  reports: PowerBI.reports,
  initialReportlLevels: PowerBI.reportLevels,
  reportLevels: PowerBI.reportLevels,
  loadingReports: boolean,
  loadingReportLevels: boolean,
};

const PowerBIAdminTemplate = ({
  handleOnChange,
  handleOnSubmit,
  reports,
  initialReportlLevels,
  reportLevels,
  loadingReports,
  loadingReportLevels,
}: Props) => {
  const headerRow = [
    polyglot.t('powerbi.aggregatelevel'),
    polyglot.t('powerbi.report'),
    polyglot.t('powerbi.parameter'),
    polyglot.t('powerbi.rolle'),
  ];

  const reportsSelectField = level => (
    <SelectField
      placeholder={polyglot.t('powerbi.selectReport')}
      selectedValue={level.rapport}
      matchProp="label"
      testid="powerbiReportSelect"
      name="powerbiReportSelect"
      onChange={choice =>
        handleOnChange(choice, level.aggregeringsnivaa, 'rapport')
      }
      choices={reports.map(reportItem => ({
        id: reportItem.id,
        navn: reportItem.navn,
      }))}
    />
  );

  const reportField = level => (
    <Field
      placeholder={polyglot.t('powerbi.reportParameters')}
      value={level.parameter}
      testid="powerbiReportParamenter"
      name="powerbiReportParamenter"
      onChange={choice =>
        handleOnChange(
          choice.target.value,
          level.aggregeringsnivaa,
          'parameter',
        )
      }
    />
  );

  const reportRole = level => (
    <Field
      placeholder={polyglot.t('powerbi.reportRole')}
      value={level.rolle}
      testid="powerbiReportRole"
      name="powerbiReportRole"
      onChange={choice =>
        handleOnChange(choice.target.value, level.aggregeringsnivaa, 'rolle')
      }
    />
  );

  const rows = reportLevels.map(level => [
    level.aggregeringsnivaa,
    reportsSelectField(level),
    reportField(level),
    reportRole(level),
  ]);

  return (
    <div>
      <Heading level={0}>{polyglot.t('powerbi.header')}</Heading>
      <Panel>
        {(loadingReports || loadingReportLevels) && <Bubbles />}
        {reportLevels.length > 0 &&
          reports.length > 0 && <Table dataRows={rows} headerRow={headerRow} />}
        <div className={styles.buttonWrapper}>
          <Button
            kind="primary"
            disabled={
              JSON.stringify(reportLevels) ===
              JSON.stringify(initialReportlLevels)
            }
            onClick={handleOnSubmit}
          >
            {loadingReportLevels ? <Bubbles /> : 'Lagre'}
          </Button>
        </div>
      </Panel>
    </div>
  );
};

export default PowerBIAdminTemplate;
