import React from 'react';
import PropTypes from 'prop-types';
import {
  RealestateNamebar,
  ExpandableList,
  Table,
  LoadingOverlay,
  Panel,
} from 'components';
import polyglot from 'i18n';
import NameEditForm from 'containers/NameEditForm/NameEditForm';
import mapData, { apiFields, getHeaders } from 'utils/mapApiData';
import styles from './RealestateTemplate.css';

const RealestateTemplate = ({ data, buildingClick, loading }) => (
  <div className={styles.wrapper}>
    <div className={styles.header}>
      <NameEditForm
        id={data.id}
        type="eiendom"
        currentName={data.navn || polyglot.t('notFound.metadata.notDefined')}
      />
    </div>
    <Panel>
      {loading && <LoadingOverlay relative />}
      <RealestateNamebar data={data} />
    </Panel>
    <Panel className={styles.properties}>
      <ExpandableList type="realestate">
        {data.grunneiendommer &&
          data.grunneiendommer.map((it, i) => {
            const dataRows = Array.isArray(it.bygninger)
              ? it.bygninger.map(rowItem =>
                  mapData(
                    rowItem,
                    apiFields.building.smallTable.map(
                      cellItem => cellItem.field,
                    ),
                  ),
                )
              : [];
            const headerRow = getHeaders(
              apiFields.building.smallTable.map(item => item.field),
              'building',
            );

            return (
              <Table
                buttonProps={it}
                id={it.id}
                dataRows={dataRows}
                headerRow={headerRow}
                bodyClick={e => buildingClick(e, i)}
                className={styles.padding}
                responsive
                drilldown
                hover
              />
            );
          })}
      </ExpandableList>
    </Panel>
  </div>
);

RealestateTemplate.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  loading: PropTypes.bool,
  buildingClick: PropTypes.func,
};

export default RealestateTemplate;
