exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* STATENS LOKALER COLOR PALETTE */\n\n:root {\n\n  /* colors for input fields */\n\n  /* Button colors */\n\n  /* Anchor colors */\n\n  /* STATSBYGG PRIMARY COLORS */\n\n  /* Packet colors */\n  /* Statsbygg Glassblå 01-03 */\n  /* SECONDARY COLORS - NEUTRAL */\n  /* Statsbygg Nøytral grå 01-02 */\n  /* Statsbygg Stein */\n  /* Statsbygg Jord */\n  /* TERTIARY COLORS - MARKER COLORS */\n  /* Statsbygg Sennep */\n  /* Statsbygg Multe */\n  /* Statsbygg Laks */\n  /* Statsbygg Lyng */\n}\n\n.DatePicker__wrapper___204dV {\n  max-width: 310px\n}\n\n.DatePicker__wrapper___204dV input {\n  min-height: 45px;\n}\n\n.DatePicker__wrapper___204dV .rw-select {\n  vertical-align: middle;\n}\n\n.DatePicker__wrapper___204dV .rw-widget {\n  border-radius: 0;\n}\n\n.DatePicker__fullWidth___81K9y {\n  width: 100%;\n}\n\n.DatePicker__required___6Zo_T {\n  color: #b1b1b1;\n  margin-left: 3px;\n  font-weight: 300;\n}\n\n.DatePicker__error___3XiGL > label {\n  color: #d0021b;\n}\n\n.DatePicker__error___3XiGL .rw-widget-picker {\n  border: 2px solid #d0021b;\n}\n\n.DatePicker__error___3XiGL > .rw-state-focus > input {}\n\n.DatePicker__padding___3_0Uz {\n  padding: 10px;\n}\n\n.DatePicker__feedbackMargin___1hd0N {\n  margin-bottom: 34px;\n}\n\n.rw-datetimepicker.rw-widget.rw-state-focus {\n  border: solid 2px rgb(20, 126, 135);\n  -webkit-box-shadow: none;\n          box-shadow: none;\n}\n", ""]);

// exports
exports.locals = {
	"wrapper": "DatePicker__wrapper___204dV",
	"fullWidth": "DatePicker__fullWidth___81K9y",
	"required": "DatePicker__required___6Zo_T",
	"error": "DatePicker__error___3XiGL",
	"padding": "DatePicker__padding___3_0Uz",
	"feedbackMargin": "DatePicker__feedbackMargin___1hd0N"
};