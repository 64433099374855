import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import polyglot from 'i18n';

import { toApiDate, validateOrgnr } from 'utils/utils';
import { validateCoreData } from 'utils/validationHelpers';
import {
  Bubbles,
  Button,
  DateTimePicker,
  FormikField,
  Link,
  ResultItem,
  SelectField,
} from 'components';
import { getDataItem } from 'services/api';
import { Form, Formik } from 'formik';
import classnames from 'classnames';
import styles from './CoreDataInput.css';
import { Heading, Label } from '../..';
// import { setDataItem } from '../../../services/api';

// <div>
// <a>utleier value: ${utleier}</a>
// <a>leietaker value: ${leietaker}</a>
// </div>
const CoreDataInput = ({
  leieforhold,
  buttonClick,
  innloggetOrg,
  loadingOrg,
  deleteItem,
  firstRef,
  lastRef,
  lokaleId,
  actions,
}) => {
  const {
    aktiv,
    id,
    navn,
    saksnummer,
    leieperiodeSlutt,
    leieperiodeStart,
    leieforholdtype,
    leietaker,
    utleier,
  } = leieforhold;

  const [show, setShow] = useState(false);
  const [updatedUtleierNavn, setupdatedUtleierNavn] = useState();
  const [updatedUtleier, setupdatedUtleier] = useState();
  const [newUtleierUrl, setnewUtleierUrl] = useState();
  const [utleierIsChanged, setUtleierIsChanged] = useState(false);
  const [newUtleierId, setNewUtleierId] = useState();
  const [canceled, setCanceled] = useState(false);

  useEffect(
    () => {
      async function fetchnewUtleierUrl() {
        const data = await getDataItem(newUtleierUrl);
        setupdatedUtleier(data.organisasjonsnummer);
        setupdatedUtleierNavn(data.navn);
        setNewUtleierId(data.id);
      }
      fetchnewUtleierUrl();
    },
    [newUtleierUrl],
  );

  /* useEffect(
    () => {
      async function changeUtleier() {
        if (updatedUtleierNavn !== undefined) {
          const data = await setDataItem(newUtleierUrl, updatedUtleierNavn);
        }
      }
      changeUtleier();
    },
    [utleierIsChanged],
  );
  */

  const utleierId = utleierIsChanged ? newUtleierId : utleier.id;

  return (
    <Formik
      initialValues={{
        navn,
        saksnummer,
        leieperiodeStart: new Date(leieperiodeStart),
        leieperiodeSlutt: new Date(leieperiodeSlutt),
        leieforholdtype,
        leietakerId: leietaker.id,
        utleierOrgnummer: utleier.organisasjonsnummer,
      }}
      onSubmit={values =>
        actions.saveContract({
          ...leieforhold,
          ...values,
          leieperiodeStart: toApiDate(values.leieperiodeStart),
          leieperiodeSlutt: toApiDate(values.leieperiodeSlutt),
          lokale: lokaleId,
          utleierId,
        })
      }
      validate={validateCoreData}
    >
      {({ values, errors, isSubmitting, handleChange }) => (
        <Form className={styles.modalInput}>
          {utleierIsChanged && canceled ? (
            <div className={styles.buttonWrapper}>
              <a>Ønsker du å lagre endringene du har gjort?</a>
              <Button id="cancel" secondaryButton onClick={buttonClick}>
                {polyglot.t('buttons.cancel')}
              </Button>
              <Button
                type="submit"
                kind="primary"
                disabled={Object.keys(errors).length > 0}
              >
                {isSubmitting ? <Bubbles /> : polyglot.t('newContract.lagre')}
              </Button>
            </div>
          ) : (
            <div>
              <div className={styles.modalInput}>
                <div className={styles.box}>
                  <FormikField
                    label={polyglot.t('newContract.step1.name')}
                    refFunc={firstRef}
                    errors={errors}
                    name="navn"
                  />
                  <FormikField
                    label={polyglot.t('newContract.step1.internalNumber')}
                    name="saksnummer"
                  />
                  <DateTimePicker
                    name="leieperiodeStart"
                    value={values.leieperiodeStart}
                    max={values.leieperiodeSlutt}
                    error={errors.leieperiodeStart}
                    label={polyglot.t('newContract.step1.contractStart')}
                    onChange={date =>
                      handleChange({
                        target: { name: 'leieperiodeStart', value: date },
                      })
                    }
                  />
                  <DateTimePicker
                    name="leieperiodeSlutt"
                    value={values.leieperiodeSlutt}
                    min={values.leieperiodeStart}
                    error={errors.leieperiodeSlutt}
                    label={polyglot.t('newContract.step1.contractEnd')}
                    onChange={date =>
                      handleChange({
                        target: { name: 'leieperiodeSlutt', value: date },
                      })
                    }
                  />
                </div>
                <div className={styles.box}>
                  <SelectField
                    label={polyglot.t('newContract.step4.leietaker')}
                    name="leietakerId"
                    clearable={false}
                    disabled={values.leieforholdtype === 'SELVFORVALTENDE'}
                    selectedValue={values.leietakerId}
                    matchProp="label"
                    optionUrl={`/organisasjon/brukere/${innloggetOrg.id}`}
                    onChange={({ value }) =>
                      handleChange({ target: { name: 'leietakerId', value } })
                    }
                  />
                  <SelectField
                    label={polyglot.t('newContract.step1.typeContract')}
                    name="leieforholdtype"
                    placeholder={polyglot.t('newContract.step1.choose')}
                    selectedValue={values.leieforholdtype}
                    choices={[
                      {
                        id: polyglot.t('newContract.step1.self'),
                        navn: polyglot.t('newContract.step1.self'),
                      },
                      {
                        id: polyglot.t('newContract.step1.tenant'),
                        navn: polyglot.t('newContract.step1.tenant'),
                      },
                    ]}
                    clearable={false}
                    onChange={({ value }) => {
                      handleChange({
                        target: { name: 'leieforholdtype', value },
                      });
                      if (value === 'SELVFORVALTENDE') {
                        actions.getOrg(leietaker.organisasjonsnummer);
                      }
                    }}
                  />
                  <ResultItem
                    header={
                      utleierIsChanged && updatedUtleierNavn !== undefined
                        ? updatedUtleierNavn
                        : `${utleier.navn.split(' - ')[0]}`
                    }
                    itemArray={[
                      ` - Orgnr: ${
                        utleierIsChanged && updatedUtleierNavn !== undefined
                          ? `${newUtleierUrl.split('/')[3]}`
                          : utleier.organisasjonsnummer
                      }`,
                    ]}
                    label="Utleier"
                  />
                  <div className={styles.fieldButtonWrapper}>
                    <FormikField
                      name="utleierOrgnummer"
                      disabled={values.leieforholdtype === 'SELVFORVALTENDE'}
                      label={polyglot.t('leideLokaler.addUtleierLabel')}
                      placeholder={`${polyglot.t('newContract.orgnr')}`}
                      maxLength="9"
                      validationState={validateOrgnr(values.utleierOrgnummer)}
                    />

                    <div className={styles.buttonWrapper}>
                      <Button
                        id="getOrg"
                        kind="primary"
                        disabled={
                          validateOrgnr(values.utleierOrgnummer) !== 'success'
                        }
                        onClick={() => (
                          // eslint-disable-next-line no-sequences
                          setShow(true),
                          setnewUtleierUrl(
                            actions.getOrg(values.utleierOrgnummer).path,
                          ),
                          actions.getOrg(values.utleierOrgnummer)
                        )}
                      >
                        {loadingOrg ? (
                          <Bubbles />
                        ) : (
                          polyglot.t('newContract.step1.search')
                        )}
                      </Button>
                    </div>
                    <div className={styles.buttonWrapper}>
                      <Button
                        id="setOrg"
                        kind="primary"
                        disabled={
                          !show ||
                          validateOrgnr(values.utleierOrgnummer) !== 'success'
                        }
                        onClick={() => {
                          setUtleierIsChanged(true);
                        }}
                      >
                        {loadingOrg ? <Bubbles /> : polyglot.t('Endre utleier')}
                      </Button>
                    </div>
                  </div>
                  {utleierIsChanged && (
                    <a style={{ fontStyle: 'italic', color: 'darkred' }}>
                      * Du må trykke på lagre-knappen for en varig endring i
                      utleier.
                    </a>
                  )}

                  <div>
                    {show && (
                      <div className={styles.wrapper}>
                        <Label>Resultat</Label>
                        <div className={classnames(styles.listHeader)}>
                          <Heading level={2}>{updatedUtleierNavn}</Heading>{' '}
                          <span> Orgnr: {updatedUtleier}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div className={styles.buttonWrapper}>
                  <Button id="cancel" secondaryButton onClick={buttonClick}>
                    {polyglot.t('buttons.cancel')}
                  </Button>
                  <Button
                    type="submit"
                    kind="primary"
                    disabled={Object.keys(errors).length > 0}
                  >
                    {isSubmitting ? (
                      <Bubbles />
                    ) : (
                      polyglot.t('newContract.lagre')
                    )}
                  </Button>
                </div>
                {aktiv && (
                  <div className={styles.buttonWrapper}>
                    <Link
                      onClick={() =>
                        confirm(polyglot.t('newContract.confirmDelete')) &&
                        deleteItem('leieforhold', id, !aktiv)
                      }
                      refFunc={lastRef}
                    >
                      {polyglot.t('newContract.delete')}
                    </Link>
                  </div>
                )}
              </div>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};

CoreDataInput.propTypes = {
  leieforhold: PropTypes.shape({
    navn: PropTypes.string,
    saksnummer: PropTypes.string,
    leieforholdtype: PropTypes.string,
    leieperiodeStart: PropTypes.string,
    leieperiodeSlutt: PropTypes.string,
    utleierOrgnummer: PropTypes.shape({
      navn: PropTypes.string,
    }),
    leietakerId: PropTypes.number,
    id: PropTypes.number,
    aktiv: PropTypes.bool,
  }),
  innloggetOrg: PropTypes.shape({
    id: PropTypes.number,
  }),
  loadedOrg: PropTypes.shape({
    navn: PropTypes.string,
    organisasjonsnummer: PropTypes.number,
  }),
  buttonClick: PropTypes.func,
  loadingOrg: PropTypes.bool,
  deleteItem: PropTypes.func,
  lokaleId: PropTypes.number,
  firstRef: PropTypes.func,
  lastRef: PropTypes.func,
};

CoreDataInput.defaultProps = {
  innloggetOrg: {},
};

export default CoreDataInput;
