import React from 'react';
import {
  Heading,
  Panel,
  SelectField,
  Button,
  Link,
  Field,
  Icon,
  ResultItem,
  GenericModal,
  ModalButton,
  Lightbox,
  DataItem,
  PowerBIButtonModal,
} from 'components';
import polyglot from 'i18n';
import { toGuiDate } from 'utils/utils';
import styles from './ProjectTemplate.css';

type Props = {
  navn: string,
  prosjektnummer: string,
  utvalg: [{ navn: string, uuid: string }],
  selected: { value: string },
  onSelectChange: () => {},
  toggleModal(): {},
  editProjectModal: boolean,
  saveSelection: () => {},
  saveProject: () => void,
  deleteModal: boolean,
  removeSelection: () => void,
  showReport: boolean,
  toggleReport: () => void,
  powerBIprops: () => void,
};

const ProjectTemplate = ({
  navn,
  prosjektnummer,
  utvalg,
  selected,
  onSelectChange,
  saveSelection,
  toggleModal,
  saveProject,
  editProjectModal,
  deleteModal,
  removeSelection,
  showReport,
  toggleReport,
  powerBIprops,
}: Props) => (
  <div>
    <div className={styles.headerWrapper}>
      <div className={styles.headerWrapper}>
        <Heading bottomMargin level={0}>
          {navn}
        </Heading>
        <ModalButton
          link
          showModal={editProjectModal}
          toggle={() => toggleModal('editProjectModal')}
        >
          <GenericModal
            renderInputs={({ firstRef }) => (
              <React.Fragment>
                <Field
                  refFunc={firstRef}
                  name="projectName"
                  defaultValue={navn}
                  label="Navn"
                />
                <Field
                  name="prosjektnummer"
                  label="Prosjektnummer"
                  defaultValue={prosjektnummer}
                />
              </React.Fragment>
            )}
            deleteComponent={
              <Link
                className={styles.deleteLink}
                onClick={() => toggleModal('deleteModal')}
                deleteStyle
              >
                X Slett prosjekt
              </Link>
            }
            handleSubmit={saveProject}
            onCancel={() => toggleModal('editProjectModal')}
          />
        </ModalButton>
      </div>
      {powerBIprops &&
        powerBIprops.rapportId && (
          <PowerBIButtonModal
            toggle={toggleReport}
            panel
            fullScreen
            showModal={showReport}
            token={powerBIprops.token}
            embedUrl={powerBIprops.embedUrl}
            id={powerBIprops.rapportId}
          />
        )}
    </div>
    <div className={styles.spacing}>
      <DataItem
        metaData={polyglot.t('prosjekt.prosjektnummer')}
        data={prosjektnummer}
        vertical
      />
    </div>
    <Panel>
      <Heading level={1} bottomMargin green>
        Utvalg
      </Heading>
      {utvalg.map(it => (
        <ResultItem
          header={it.navn}
          key={it.uuid}
          itemArray={[
            it.utvalgtype,
            <span>{`Sist oppdatert: ${toGuiDate(
              it.sistOppdatert,
              true,
            )}`}</span>,
          ]}
          button={
            <React.Fragment>
              <Link to={`/d/sok/${it.uuid}`}>Gå til utvalg</Link>
              <Button
                borderless
                className={styles.closeButton}
                onClick={() => toggleModal('deleteModal', it.uuid)}
              >
                <Icon size="2" icon="close" />
              </Button>
            </React.Fragment>
          }
        />
      ))}
      {deleteModal && (
        <Lightbox close={() => toggleModal('deleteModal')}>
          <div className={styles.deleteModal}>
            {polyglot.t(
              editProjectModal
                ? 'prosjekt.confirmDeleteProject'
                : 'prosjekt.confirmDeleteSelection',
            )}
            <div className={styles.buttonWrapper}>
              <Button onClick={() => toggleModal('deleteModal')}>Nei</Button>
              <Button kind="primary" onClick={removeSelection}>
                Ja
              </Button>
            </div>
          </div>
        </Lightbox>
      )}
    </Panel>
    <Panel className={styles.addBox}>
      <SelectField
        label={polyglot.t('prosjekt.addSelection')}
        placeholder={polyglot.t('prosjekt.addSelection')}
        name="addSelection"
        selectedValue={selected}
        optionUrl="/utvalg"
        matchProp="label"
        onChange={onSelectChange}
      />
      <Button kind="primary" onClick={saveSelection}>
        Lagre
      </Button>
    </Panel>
  </div>
);

export default ProjectTemplate;
