import React from 'react';
import PropTypes from 'prop-types';
import { MapMarker } from 'components';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { isFilled } from 'utils/leafletHelpers';

const MarkerCluster = ({ markerArray, myOrg }) => (
  <MarkerClusterGroup maxClusterRadius={50} disableClusteringAtZoom={17}>
    {markerArray.map(it => (
      <MapMarker
        position={[
          parseFloat(it.plassering.lat),
          parseFloat(it.plassering.lon),
        ]}
        filled={
          it.type === 'bygning'
            ? true
            : it.type === 'lokale'
              ? isFilled(myOrg, it.bruker)
              : it.eierforhold && isFilled(myOrg, it.eierforhold.organisasjon)
        }
        item={it}
        key={it._links ? it._links.self : it.bygningsnr}
        sensitive={it.sensitive}
      />
    ))}
  </MarkerClusterGroup>
);

MarkerCluster.propTypes = {
  markerArray: PropTypes.arrayOf(PropTypes.object),
  myOrg: PropTypes.string,
};

export default MarkerCluster;
