import type { Organization } from 'types';
import L from 'leaflet';
import { setDataItem } from 'services/api';

export const isFilled = (
  organisasjonsnummer?: number,
  organisasjon: Organization,
): boolean => {
  if (organisasjonsnummer === organisasjon.organisasjonsnummer) {
    return false;
  }
  if (organisasjon.overordnet) {
    return isFilled(organisasjonsnummer, organisasjon.overordnet);
  }
  return true;
};

export const zoomToClusterBounds = (markers, map) => {
  const bounds = L.latLngBounds(
    markers.map(mark => [mark.plassering.lat, mark.plassering.lon]),
  );
  setTimeout(() => {
    if (map && bounds.isValid()) {
      map.leafletElement.fitBounds(bounds, { duration: 1 });
    }
  }, 500);
};

export const fitFeaturesToMap = (features, map) => {
  setTimeout(() => {
    map.leafletElement.fitBounds([
      [features.bbox[1], features.bbox[0]],
      [features.bbox[3], features.bbox[2]],
    ]);
  }, 500);
};

export default (wfsType, propValues) => {
  const filterString = propValues.reduce((acc, prop) => {
    const key = Object.keys(prop);
    if (key.toString() === 'flate') {
      const bboxString = prop[key].reduce((acc2, coord, i) => {
        if (i === 2) return `${acc2} ${coord}`;
        if (i === 0) return acc2 + coord;
        return `${acc2},${coord}`;
      }, '');
      return (
        acc +
        encodeURIComponent(
          `<ogc:BBOX><ogc:PropertyName>${key
            .toString()
            .toUpperCase()}</ogc:PropertyName><gml:Envelope srsName="urn:x-ogc:def:crs:EPSG:3857">` +
            `<gml:coordinates>${bboxString}</gml:coordinates>` +
            `</gml:Envelope></ogc:BBOX>`,
        )
      );
    }
    return (
      acc +
      encodeURIComponent(
        `<ogc:PropertyIsEqualTo><ogc:PropertyName>${key
          .toString()
          .toUpperCase()}</ogc:PropertyName><ogc:Literal>${prop[key] ||
          0}</ogc:Literal></ogc:PropertyIsEqualTo>`,
      )
    );
  }, '');

  const query =
    `service=WFS&REQUEST=GetFeature&TYPENAME=${wfsType}&outputFormat=application/json&` +
    `Filter=%3Cogc:Filter%20xmlns:ogc=%22http://www.opengis.net/ogc%22%20xmlns:gml=%22http://www.opengis.net/gml%22%3E%3CAnd%3E${filterString}%3C/And%3E%3C/ogc:Filter%3E`;

  return setDataItem('/kart/wfs', query); // .then(res => (new ol.format.GeoJSON()).readFeatures(res, { featureProjection: 'EPSG:3857' }));
};
