import React from 'react';
import PropTypes from 'prop-types';
import { WizardButton, Heading } from 'components';
import styles from './WizardSteps.css';

const WizardSteps = ({
  currentStep,
  onClick,
  steps,
  labels,
  box,
  boxLabel,
}) => {
  const wizButtons = [];
  const lineSteps = [];
  for (let i = 1; i <= steps; i++) {
    wizButtons.push(
      <WizardButton
        label={Array.isArray(labels) && labels.length >= i ? labels[i - 1] : ''}
        onClick={onClick}
        key={i}
        active={i <= currentStep}
        disabled={currentStep < i}
        number={i}
      />,
    );
    lineSteps.push(
      <div
        key={i}
        className={i <= currentStep ? styles.activeLine : styles.inactiveLine}
      />,
    );
  }

  if (box)
    return (
      <div className={styles.boxWrapper}>
        <Heading level={3} green bottomMargin className={styles.boxLabel}>
          {boxLabel}
        </Heading>
        <div className={styles.line}>
          {lineSteps}
          <div className={styles.buttonContainer}>{wizButtons}</div>
        </div>
      </div>
    );
  return (
    <div className={styles.wrapper}>
      <div className={styles.line}>
        {lineSteps}
        <div className={styles.buttonContainer}>{wizButtons}</div>
      </div>
    </div>
  );
};

WizardSteps.propTypes = {
  currentStep: PropTypes.number.isRequired,
  steps: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  labels: PropTypes.arrayOf(PropTypes.string),
};

WizardSteps.defaultProps = {
  labels: [],
};

export default WizardSteps;
