exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".LokaleCostModal__modalWrapper___3N847 {\n  padding: 40px;\n}\n\n.LokaleCostModal__buttonWrapper___3atdO {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: end;\n      -ms-flex-pack: end;\n          justify-content: flex-end;\n  margin: 10px;\n}\n", ""]);

// exports
exports.locals = {
	"modalWrapper": "LokaleCostModal__modalWrapper___3N847",
	"buttonWrapper": "LokaleCostModal__buttonWrapper___3atdO"
};