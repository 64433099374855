import React from 'react';
import { Icon } from 'components';
import classnames from 'classnames';
import styles from './ErrorMessage.css';

const ErrorMessage = ({ feedback, feedbackMargin }) => {
  return (
    <div
      className={classnames(
        styles.feedback,
        !feedbackMargin && styles.absolute,
      )}
    >
      {feedback && <Icon icon="alert" red size="2" />}
      {feedback}
    </div>
  );
};

export default ErrorMessage;
