// @flow

import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { LeideLokalerDetailTemplate, Alert } from 'components';
import type {
  User,
  Lokale,
  MatrikkelResults,
  Leieobjekt,
  Contract,
} from 'types';
import polyglot from 'i18n';
import userSelector from 'redux/modules/user/userSelector';

import { setDefault, toGuiDate, toApiDate } from 'utils/utils';
import * as lokalerActions from 'redux/modules/leideLokaler/leideLokaler';
import * as leieobjektActions from 'redux/modules/leideLokaler/leieobjekt';
import * as contractActions from 'redux/modules/leideLokaler/contracts';
import * as widgetsActions from 'redux/modules/widgets/widgets';
import ErrorBoundary from '../ErrorBoundary';

type Props = {
  user: User,
  lokale: Lokale,
  actions: {
    getLokale(string): void,
    displayAlert({ kind: string, text: string }): void,
    deleteProperty(number, number): void,
    addProperty(number, MatrikkelResults): void,
    matrikkelGet({}): void,
    saveLeieobjekt(Leieobjekt): void,
    deletePeriod(string, number): void,
    deleteItem(string, number, boolean): void,
    updateContractPeriod(number, {}): void,
    createLokaler({}): void,
    setLokalePeriode({}, number): void,
    getOrg(number | void): void,
    setOrg(number | void): void,
    saveContract(Contract): void,
    getPowerBIReportsAdminList(): void,
  },
  loading: boolean,
  match: {
    params: {
      lokalerId: string,
    },
  },
  loadedOrg: {
    id: number,
  },
  loadingLeieobjekt: boolean,
  loadingSaveContract: boolean,
  loadingMatrikkel: boolean,
  loadingOrg: boolean,
  loadingDelete: boolean,
  loadingSetLokalePeriode: boolean,
  leieobjektError: string,
  contractError: string,
  lokaleError: string,
  matrikkelResult: Array<MatrikkelResults>,
  errorMessage: string,
  deleteType: string,
};

type State = {
  openContract: number,
  leieObjectInput: {
    navn?: string,
    bruttoareal?: number,
    parkeringsareal?: number,
    framleieareal?: number,
  },
  costInput: {},
  modals: {},
  lokaleInput: {
    lokalnavn?: string,
    kontor?: boolean,
    selectedOrg?: {
      value: number,
    },
  },
  propertyInput: {},
  comment?: string,
};

class LeideLokalerDetails extends React.PureComponent<Props, State> {
  static defaultProps = {
    access: [],
  };

  inputState = {
    modals: {},
    leieObjectInput: {},
    lokaleInput: {},
    costInput: {},
    propertyInput: {},
    matrikkelFeedback: '',
  };

  state = {
    openContract: -1,
    modals: {},
    kpiDate: toGuiDate(),
    fullRefresh: true,
    ...this.inputState,
  };

  componentDidMount() {
    const { actions, match } = this.props;
    actions.getLokale(match.params.lokalerId);
  }

  componentDidUpdate(prevProps) {
    const {
      loading,
      loadingLeieobjekt,
      loadingSaveContract,
      loadingSetLokalePeriode,
      loadingCreateLokale,
      loadingDelete,
      leieobjektError,
      contractError,
      lokaleError,
    } = this.props;
    if (
      (!loadingLeieobjekt && prevProps.loadingLeieobjekt && !leieobjektError) ||
      (!loadingSaveContract &&
        prevProps.loadingSaveContract &&
        !contractError) ||
      (!loadingDelete && prevProps.loadingDelete && !lokaleError)
    ) {
      this.refresh();
    }

    if (
      (!loadingSetLokalePeriode &&
        prevProps.loadingSetLokalePeriode &&
        !lokaleError) ||
      (!loadingCreateLokale && prevProps.loadingCreateLokale && !lokaleError)
    ) {
      this.setState({ fullRefresh: true });
      this.refresh();
    }

    if (!loading && prevProps.loading) {
      this.setState({ fullRefresh: false });
    }

    this.handleOrgProps(prevProps);

    this.handleDelete(prevProps);
  }

  onLokaleChange = e => {
    const { lokaleInput } = this.state;
    if (!e.target) {
      this.setState({
        lokaleInput: {
          ...lokaleInput,
          selectedOrg: e,
        },
      });
    } else if (e.target.name === 'kontor') {
      this.setState({
        lokaleInput: {
          ...lokaleInput,
          kontor:
            typeof lokaleInput.kontor === 'undefined'
              ? !this.props.lokale.kontor
              : !lokaleInput.kontor,
          aktiv: true,
        },
      });
    } else {
      const {
        target: { name, value },
      } = e;
      this.setState({
        lokaleInput: {
          ...lokaleInput,
          [name]: value,
        },
      });
    }
  };

  onCostChange = e => {
    const {
      target: { name, value },
    } = e;
    this.setState(prevState => ({
      costInput: {
        ...prevState.costInput,
        [name]: value,
      },
    }));
  };

  onPropertyChange = e => {
    const { name, value } = e.target;
    this.setState(prevState => ({
      propertyInput: {
        ...prevState.propertyInput,
        [name]: value,
      },
    }));
  };

  onCommentChange = e => {
    this.setState({
      comment: e.target.value,
    });
  };

  handleDelete = ({ loadingDelete: wasLoading }) => {
    const {
      loadingDelete,
      lokaleError,
      actions,
      deleteType,
      lokale,
    } = this.props;
    if (!loadingDelete && wasLoading) {
      if (lokaleError)
        actions.displayAlert({
          kind: 'danger',
          text: `kunne ikke slette ${deleteType}: ${lokaleError}`,
        });
      else
        actions.displayAlert({
          kind: 'success',
          text: lokale.aktiv
            ? `${deleteType} er slettet`
            : 'Lokale er aktivert igjen',
        });
    }
  };

  refresh = () => {
    const { kpiDate } = this.state;
    const {
      actions,
      match: { params },
    } = this.props;
    this.setState(this.inputState);
    const isCurrentDate = kpiDate === toGuiDate();
    actions.getLokale(
      `${params.lokalerId}/${isCurrentDate ? '' : toApiDate(kpiDate)}`,
    );
  };

  leieObjectChange = e => {
    const { name, value: val } = e.target;
    const value = name === 'navn' || !val ? val : parseInt(val, 10);
    this.setState({
      leieObjectInput: {
        ...this.state.leieObjectInput,
        [name]: value,
      },
    });
  };

  changeKpiDate = e => {
    this.setState({ kpiDate: e.target.innerText }, () => this.refresh());
  };

  handleOrgProps = ({ loadingOrg: wasLoadingOrg }) => {
    const { contractError, loadingOrg, actions } = this.props;
    if (wasLoadingOrg && !loadingOrg) {
      if (contractError) {
        actions.displayAlert({
          kind: 'danger',
          text: `Kunne ikke laste organisasjon: ${contractError}`,
        });
      }
    }
  };

  submitProperty = ({ values, matrikkelId }) => {
    this.props.actions.addProperty(this.state.openContract, {
      matrikkelId,
      bygninger: Object.keys(values)
        .map(bygningsnr => ({
          bygningsnr,
        }))
        .filter(bygg => values[bygg.bygningsnr]),
    });
  };

  handleMatrikkelProps = ({
    loadingMatrikkel,
    matrikkelResult,
    error,
    errorMessage,
  }) => {
    if (!loadingMatrikkel && this.props.loadingMatrikkel) {
      // new result from matrikkel, check if error, empty or result
      if (error) {
        window.scrollTo(0, 0);
        this.props.actions.displayAlert({
          kind: 'danger',
          text: `Problem med henting fra matrikkelen: ${error}: ${errorMessage}`,
        });
      } else if (matrikkelResult.length === 0)
        this.props.actions.displayAlert({
          kind: 'danger',
          text: 'Ingen grunneiendom funnet!',
        });
      else {
        this.setState(prevState => ({
          step5: {
            ...this.state.step5,
            matrikkelResult: matrikkelResult[0],
            addedProperties: [
              ...this.state.step5.addedProperties,
              {
                ...matrikkelResult[0],
                allBuildings: matrikkelResult[0].bygninger,
              },
            ],
          },
        }));
      }
    }
  };

  buttonClick = (e, values) => {
    const { lokaleInput, propertyInput } = this.state;
    const { lokale, actions } = this.props;
    switch (e.target.id) {
      case 'saveLeieobjekt':
        this.saveLeieobjekt(values);
        break;
      case 'cancel': {
        this.setState({
          modals: {},
        });
        break;
      }
      case 'changeUtleier': {
        actions.setOrg();
      }
      case 'saveCosts':
        this.updateCosts();
        break;
      case 'hentMatrikkelen':
        this.props.actions.matrikkelGet(this.state.propertyInput);
        break;
      case 'lokalkostnader':
        const inputConvertedToDot = {
          ...lokaleInput,
          antallAarsverk: lokaleInput.antallAarsverk
            ? lokaleInput.antallAarsverk.replace(',', '.')
            : lokale.lokaleperiode.antallAarsverk,
        };
        actions.setLokalePeriode(
          { ...lokale.lokaleperiode, ...inputConvertedToDot },
          lokale.id,
        );
        break;
      case 'lokalnavn': {
        this.updateLokale();
        break;
      }
      case 'saveComment': {
        this.updateLokale();
        break;
      }
      default:
        console.log('Whups, no button handler invoked!');
    }
  };

  updateLokale = () => {
    const { actions, lokale } = this.props;
    const { lokaleInput, comment } = this.state;
    actions.createLokaler({
      brukerId: setDefault(
        lokaleInput.selectedOrg && lokaleInput.selectedOrg.value,
        parseInt(lokale.bruker.id, 10),
      ),
      id: lokale.id,
      navn: setDefault(lokaleInput.lokalnavn, lokale.navn),
      kontor: setDefault(lokaleInput.kontor, lokale.kontor),
      aktiv: true,
      kommentar: setDefault(comment, lokale.kommentar),
      lokalId: lokaleInput.lokalId,
    });
  };

  handleContractClick = i => {
    // if (this.state.openContract === -1) setTimeout(() => this[`contractDiv${i}`].scrollIntoView(), 300);
    this.setState({ openContract: this.state.openContract === i ? -1 : i });
  };

  removeGrunneiendom = matrikkelId => {
    this.props.actions.deleteProperty(this.state.openContract, matrikkelId);
  };

  saveLeieobjekt = values => {
    this.props.actions.saveLeieobjekt({
      leieforhold: this.state.openContract,
      aktiv: true,
      ...values,
      ...this.state.leieObjectInput,
      leieobjektperioder: [
        {
          ...(values && values.leieobjektperiode),
          ...this.state.leieObjectInput,
          kontor: this.props.lokale.kontor,
        },
      ],
    });
  };

  openNewLeieobject = () => {
    const { id } = this.props.lokale.leieforholds.find(
      it => it.status === 'AKTIV',
    );
    this.setState({
      openContract: id,
      modals: {
        [`showModalLeieobjekt${id}`]: true,
      },
    });
  };

  deleteItem = (type, id, aktiv) => {
    this.props.actions.deleteItem(type, id, aktiv);
  };

  deletePeriod = (type, id) => {
    this.props.actions.deletePeriod(type, id);
  };

  updateCosts = () => {
    const id = this.state.openContract;
    this.props.actions.updateContractPeriod(id, {
      ...this.props.lokale.leieforholds.find(it => it.id === id)
        .leieforholdperiodeNy,
      ...this.state.costInput,
    });
  };

  toggleModal = modal => {
    const { modals, openContract } = this.state;
    if (!modals[`${modal}${openContract}`])
      this.setState({
        leieObjectInput: {},
        costInput: {},
        propertyInput: {},
      });
    if (
      modal === 'showModalLokaleCost' ||
      modal === 'showModalHumanCost' ||
      modal === 'showNameModal' ||
      modal === 'showCommentModal' ||
      modal === 'showLedigeLokalerModal'
    )
      this.setState({ modals: { [modal]: !modals[modal] } });
    else
      this.setState({
        modals: {
          [`${modal}${openContract}`]: !modals[`${modal}${openContract}`],
        },
      });
  };

  render() {
    return !this.props.loading && !this.props.lokale.id ? (
      <Alert kind="warning">{polyglot.t('error.noLokale')}</Alert>
    ) : (
      <ErrorBoundary>
        <LeideLokalerDetailTemplate
          {...this.props}
          {...this.state}
          deleteItem={this.deleteItem}
          deletePeriod={this.deletePeriod}
          onCostChange={this.onCostChange}
          onPropertyChange={this.onPropertyChange}
          handleContractClick={this.handleContractClick}
          leieObjectChange={this.leieObjectChange}
          toggleModal={this.toggleModal}
          removeGrunneiendom={this.removeGrunneiendom}
          buttonClick={this.buttonClick}
          onLokaleChange={this.onLokaleChange}
          onCommentChange={this.onCommentChange}
          openNewLeieobject={this.openNewLeieobject}
          changeKpiDate={this.changeKpiDate}
          submitProperty={this.submitProperty}
          matrikkelResult={this.props.matrikkelResult[0] || []}
        />
      </ErrorBoundary>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: userSelector(state),
    lokale: state.leideLokaler.lokaler,
    access: state.user.data.tilganger,
    loading: state.leideLokaler.loadingGetLokale,
    loadingCreateLokale: state.leideLokaler.loadingCreateLokale,
    lokaleError: state.leideLokaler.error,
    contractError: state.contracts.error,
    errorMessage: state.contracts.errorMessage,
    leieobjektError: state.leieobjekt.error,
    loadingLeieobjekt: state.leieobjekt.loadingSave,
    loadingSaveContract: state.contracts.loadingSaveContract,
    loadingMatrikkel: state.contracts.loadingMatrikkel,
    matrikkelResult: state.contracts.matrikkelResult,
    loadingSetLokalePeriode: state.leideLokaler.loadingSetLokalePeriode,
    innloggetOrg: state.user.data.organisasjon,
    alert: state.widgets.alert,
    loadingOrg: state.contracts.loadingOrg,
    loadedOrg: state.contracts.org,
    loadingDelete: state.leideLokaler.loadingDelete,
    deleteType: state.leideLokaler.itemType,
    periodestart: state.leideLokaler.dates.periodestart,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...lokalerActions,
        ...leieobjektActions,
        ...contractActions,
        ...widgetsActions,
      },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LeideLokalerDetails);
