// @flow

import React, { Fragment } from 'react';
import {
  Heading,
  Button,
  SelectField,
  LoadingOverlay,
  Bubbles,
  Link,
  Checkbox,
  FormikField,
} from 'components';
import polyglot from 'i18n';
import { Formik, Form } from 'formik';
import { validateEmail } from 'utils/validationHelpers';
import styles from './UserForm.css';

type EditUserType = {
  name: string,
  uuid: string,
  organisasjonId: string,
  roles: Array<string>,
  aktiv: boolean,
};
type Props = {
  editUser: EditUserType,
  organization: string,
  uuid: string,
  role: string,
  email: string,
  phone: string,
  aktiv: boolean,
  loading: boolean,
  loadingSubmit: boolean,
  editMode: boolean,
  deleteUser(): void,
  handleSubmit(EditUserType): void,
};

const UserForm = ({
  editMode,
  uuid,
  aktiv,
  loading,
  deleteUser,
  loadingSubmit,
  organization,
  role,
  email,
  phone,
  editUser,
  handleSubmit,
}: Props) => (
  <Formik
    initialValues={{
      organization,
      role,
      aktiv,
      email,
      phone,
    }}
    onSubmit={values => handleSubmit(values)}
    enableReinitialize
    validateOnChange={false}
    validate={values => {
      const errors = {};

      if (values.email && !validateEmail(values.email)) {
        errors.email = polyglot.t('user.emailInvalid');
      }
      return errors;
    }}
  >
    {({ values, errors, handleChange, handleBlur }) => (
      <Fragment>
        {loading && <LoadingOverlay />}
        {!loading && (
          <Fragment>
            <Heading level={0}>{`${editUser.name}`}</Heading>
            <Form>
              <Heading level={2}>
                {' '}
                {editMode && !uuid
                  ? polyglot.t('users.noUser')
                  : polyglot.t('users.edit')}
              </Heading>
              <div className={styles.form}>
                <SelectField
                  required
                  id="organization"
                  name="organization"
                  optionUrl="/organisasjon/all"
                  getOptionValue={option => option.label}
                  onChange={({ value }) =>
                    handleChange({ target: { name: 'organization', value } })
                  }
                  selectedValue={values.organization}
                  label={polyglot.t('users.organization')}
                  wide
                />
                <SelectField
                  required
                  id="role"
                  name="role"
                  labelFunc={responseValue =>
                    polyglot.t(`Rolle.${responseValue}`)
                  }
                  optionUrl="/user/roles"
                  onChange={({ value }) =>
                    handleChange({ target: { name: 'role', value } })
                  }
                  selectedValue={values.role}
                  label={polyglot.t('users.role')}
                  wide
                />
                <FormikField
                  label={polyglot.t('user.email')}
                  placeholder={polyglot.t('user.emailPlaceholder')}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  errors={errors}
                  disabled={loading}
                  name="email"
                />
                <FormikField
                  label={polyglot.t('user.phone')}
                  placeholder={polyglot.t('user.phonePlaceholder')}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={loading}
                  errors={errors}
                  name="phone"
                />
                {editMode && (
                  <div className={styles.checkboxWrapper}>
                    <Checkbox
                      checked={values.aktiv}
                      name="aktiv"
                      disabled={loading}
                      onChange={handleChange}
                      label={polyglot.t('user.aktiv')}
                    />
                  </div>
                )}
                {editMode && (
                  <Link
                    deleteStyle
                    onClick={() => {
                      if (confirm(polyglot.t('user.confirmDelete')))
                        deleteUser();
                    }}
                  >
                    {polyglot.t('user.delete')}
                  </Link>
                )}
                <div className={styles.buttonWrapper}>
                  <Button
                    name="save"
                    submit
                    disabled={
                      loading ||
                      Object.keys(errors).length > 0 ||
                      !(values.organization && values.role)
                    }
                    className={styles.button}
                    kind="primary"
                  >
                    {loadingSubmit ? <Bubbles /> : polyglot.t('users.save')}
                  </Button>
                </div>
              </div>
            </Form>
          </Fragment>
        )}
      </Fragment>
    )}
  </Formik>
);

export default UserForm;
