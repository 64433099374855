import React from 'react';
import PropTypes from 'prop-types';
import { Field, Button, DataItem, SelectField } from 'components';
import polyglot from 'i18n';
import Dialog from '../../../containers/Dialog';
import styles from './EditFloor.css';

const EditFloor = ({
  floor = {},
  egenBta,
  egenBra,
  etasjenr,
  etasjeplanSelected,
  handleChange,
  handleSubmit,
  onSelectChange,
  onSelectInputChange,
  onExit,
}) => {
  const onKeyDown = e => {
    if (e.key === 'Enter') handleSubmit();
  };
  const disabled = floor.id ? false : !etasjenr || !etasjeplan;
  return (
    <Dialog
      onExit={onExit}
      render={props => (
        <div className={styles.wrapper}>
          {floor.id ? (
            <DataItem
              data={floor.etasjenr}
              metaData={polyglot.t('floors.etasjenr')}
              vertical
              fieldStyle
            />
          ) : (
            <Field
              name="etasjenr"
              refFunc={props.firstRef}
              value={etasjenr || ''}
              onChange={handleChange}
              type="number"
              onKeyDown={onKeyDown}
              required
              label={polyglot.t('floors.etasjenr')}
            />
          )}
          {floor.id ? (
            <DataItem
              data={floor.etasjeplan}
              metaData={polyglot.t('floors.etasjeplan')}
              vertical
              fieldStyle
            />
          ) : (
            <SelectField
              label={polyglot.t('floors.etasjeplan')}
              selectedValue={etasjeplanSelected || ''}
              testid="selectOrg"
              required
              keepInput
              simpleValue
              name="etasjeplan"
              optionUrl="/etasje/etasjeplan"
              onChange={onSelectChange}
              onInputChange={onSelectInputChange}
            />
          )}
          <Field
            name="egenBra"
            refFunc={floor.id ? props.firstRef : undefined}
            value={egenBra === undefined ? floor.egenBra || 0 : egenBra}
            onChange={handleChange}
            onKeyDown={onKeyDown}
            type="number"
            label={polyglot.t('floors.egenBra')}
          />
          <Field
            name="egenBta"
            value={egenBta === undefined ? floor.egenBta || 0 : egenBta}
            onChange={handleChange}
            onKeyDown={onKeyDown}
            type="number"
            label={polyglot.t('floors.egenBta')}
            refFunc={disabled ? props.lastRef : undefined}
          />
          <Button
            kind="primary"
            disabled={disabled}
            refFunc={!disabled ? props.lastRef : undefined}
            onKeyDown={onKeyDown}
            onClick={handleSubmit}
          >
            Lagre
          </Button>
        </div>
      )}
    />
  );
};

EditFloor.propTypes = {
  floor: PropTypes.shape({
    bruksareal: PropTypes.number,
    bruttoareal: PropTypes.number,
  }),
  egenBra: PropTypes.number,
  egenBta: PropTypes.number,
  etasjenr: PropTypes.number,
  etasjeplan: PropTypes.string,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  onSelectChange: PropTypes.func,
  onSelectInputChange: PropTypes.func,
  onExit: PropTypes.func,
};

export default EditFloor;
