import React from 'react';
import PropTypes from 'prop-types';
import {
  CoreData,
  ContractCost,
  PropertyData,
  LeieObjektList,
} from 'components';
import styles from './ContractPanel.css';

const ContractPanel = ({ navn, ...props }) => (
  <div className={styles.wrapper}>
    <CoreData {...{ navn, ...props }} />

    <ContractCost {...props} />

    <PropertyData {...props} />

    <LeieObjektList {...props} />
  </div>
);

ContractPanel.propTypes = {
  navn: PropTypes.string,
  refFunc: PropTypes.func,
};

export default ContractPanel;
