import React from 'react';
import PropTypes from 'prop-types';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { isFilled } from 'utils/leafletHelpers';
import BuildingPickerMarker from '../MapMarker/BuildingPickerMarker';

const BuildingPickerCluster = ({ markerArray, myOrg, onBuildingPick }) => (
  <MarkerClusterGroup maxClusterRadius={40} disableClusteringAtZoom={15}>
    {markerArray.map(it => (
      <BuildingPickerMarker
        position={[
          parseFloat(it.plassering.lat),
          parseFloat(it.plassering.lon),
        ]}
        filled={
          it.type === 'bygning'
            ? true
            : it.type === 'lokale'
              ? isFilled(myOrg, it.bruker)
              : it.eierforhold && isFilled(myOrg, it.eierforhold.organisasjon)
        }
        item={it}
        key={it._links ? it._links.self : it.bygningsnr}
        onBuildingPick={onBuildingPick}
      />
    ))}
  </MarkerClusterGroup>
);

BuildingPickerCluster.propTypes = {
  onBuildingPick: PropTypes.func,
  markerArray: PropTypes.arrayOf(PropTypes.object),
  myOrg: PropTypes.string,
};

export default BuildingPickerCluster;
