// @flow

import React from 'react';
import { Button } from 'components';
import styles from './ContractWizard.css';

const ContractWizardStep7 = ({
  contract,
  buttonClick,
}: {
  contract: { navn: string },
  buttonClick(): void,
}) => (
  <div className={styles.wrapper}>
    <div>
      {`Registrering av leieforhold med navn ${contract &&
        contract.navn} er ferdig.`}
    </div>
    <div className={styles.buttonWrapper}>
      <Button id="nyttleieforhold" kind="primary" onClick={buttonClick}>
        {'Opprett nytt leieforhold'}
      </Button>
      <Button id="nyttLokale" kind="primary" onClick={buttonClick}>
        {'Opprett nytt lokale'}
      </Button>
      <Button id="visLokale" kind="primary" onClick={buttonClick}>
        {'Vis lokalet'}
      </Button>
    </div>
  </div>
);

export default ContractWizardStep7;
