exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* FONTS */\n\n:root {\n}\n\n/* STATENS LOKALER COLOR PALETTE */\n\n:root {\n\n  /* colors for input fields */\n\n  /* Button colors */\n\n  /* Anchor colors */\n\n  /* STATSBYGG PRIMARY COLORS */\n\n  /* Packet colors */\n  /* Statsbygg Glassblå 01-03 */\n  /* SECONDARY COLORS - NEUTRAL */\n  /* Statsbygg Nøytral grå 01-02 */\n  /* Statsbygg Stein */\n  /* Statsbygg Jord */\n  /* TERTIARY COLORS - MARKER COLORS */\n  /* Statsbygg Sennep */\n  /* Statsbygg Multe */\n  /* Statsbygg Laks */\n  /* Statsbygg Lyng */\n}\n\n.TableRow__tableRow___37yYN {\n  color: #232f3a;\n  font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;\n  -webkit-text-size-adjust: none;\n  -ms-text-size-adjust: none;\n  -moz-text-size-adjust: none;\n  text-size-adjust: none;\n  font-weight: normal;\n}\n\n.TableRow__tableCell___3jZeV {\n  vertical-align: middle !important;\n  height: 55px;\n  cursor: pointer;\n}\n\n.TableRow__tableData___BwV-Y {\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n\n.TableRow__tableData___BwV-Y::first-letter {\n  text-transform: capitalize;\n}\n\n.TableRow__sort___1kWQC {\n  white-space: nowrap;\n  font-weight: 600;\n  color: #147e87;\n  border: none !important;\n}\n\n.TableRow__tableRowRead___2wZXQ {\n  color: #945e88;\n}\n\n.TableRow__bold___2SxFn {\n  font-weight: 600;\n}\n\n.TableRow__noClick___1pxD5 {\n  cursor: default;\n}\n\n.TableRow__checkbox___JmiTb > div {\n  position: relative;\n}\n\n.TableRow__feedbackMargin___1k5ov > td {\n  padding: 8px 8px 34px !important;\n}\n\n.TableRow__rightAlign___fQACp {\n  text-align: right;\n}\n\na, a:hover, a:focus {\n  color: inherit;\n  text-decoration: none;\n}\n", ""]);

// exports
exports.locals = {
	"tableRow": "TableRow__tableRow___37yYN",
	"tableCell": "TableRow__tableCell___3jZeV",
	"tableData": "TableRow__tableData___BwV-Y TableRow__tableCell___3jZeV",
	"sort": "TableRow__sort___1kWQC TableRow__tableCell___3jZeV",
	"tableRowRead": "TableRow__tableRowRead___2wZXQ",
	"bold": "TableRow__bold___2SxFn",
	"noClick": "TableRow__noClick___1pxD5",
	"checkbox": "TableRow__checkbox___JmiTb",
	"feedbackMargin": "TableRow__feedbackMargin___1k5ov",
	"rightAlign": "TableRow__rightAlign___fQACp"
};