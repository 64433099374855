// @flow
import React, { Fragment } from 'react';
import polyglot from 'i18n';
import { Formik, Form } from 'formik';
import {
  validateDateFormat,
  validateEmail,
  isValidInt,
} from 'utils/validationHelpers';
import type { Node } from 'react';

import {
  Button,
  Bubbles,
  FormikField,
  Field as FormField,
  DateTimePicker,
  SelectField,
  HelpToolTipButton,
} from 'components';
import styles from './RegisterLedigeLokaler.css';

type LeideLokalerType = {
  id: number,
  ledigAreal: number,
  parkeringsAreal: number,
  fraDato: Date,
  tilDato: Date,
  kommentar: string,
  kontaktperson: string,
  kontaktEpost: string,
  kontaktMobil: string,
  ledigLokaleType: 'FREMLEIE' | 'TOMGANGSLEIE',
  maxAreal: number,
  maxParkeringsareal: number,
};

type Props = {
  handleSubmit(LeideLokalerType, {}): void,
  handleLeieChange({ name: string, value: string }): void,
  leieforholdChoices: [{ id: string, navn: string }],
  leieobjektChoices: [{ id: string, navn: string }],
  selectedLedigLokale: LeideLokalerType,
  leieforhold: string,
  leieobjekt: string,
  goBackButton: Node,
  loadingGetLokale: boolean,
};

const helpToolTipButton = text => <HelpToolTipButton title={text} />;

const RegisterLedigeLokaler = ({
  handleSubmit,
  leieforhold,
  leieobjekt,
  loadingGetLokale,
  leieforholdChoices,
  leieobjektChoices,
  selectedLedigLokale,
  handleLeieChange,
  goBackButton,
}: Props) => (
  <Formik
    initialValues={{
      id: selectedLedigLokale.id,
      leieforhold,
      leieobjekt,
      ledigAreal: selectedLedigLokale.ledigAreal || 0,
      parkeringsAreal: selectedLedigLokale.parkeringsAreal || 0,
      fraDato: new Date(selectedLedigLokale.fraDato),
      tilDato: new Date(selectedLedigLokale.tilDato),
      kommentar: selectedLedigLokale.kommentar || '',
      kontaktperson: selectedLedigLokale.kontaktperson || '',
      kontaktEpost: selectedLedigLokale.kontaktEpost || '',
      kontaktMobil: selectedLedigLokale.kontaktMobil || '',
      ledigLokaleType: selectedLedigLokale.ledigLokaleType,
    }}
    onSubmit={(values, actions) => handleSubmit(values, actions)}
    enableReinitialize
    validate={values => {
      const errors = {};
      if (values.fraDato && !validateDateFormat(values.fraDato)) {
        errors.fraDato = polyglot.t('ledigeLokaler.form.invalidDate');
      }

      if (values.tilDato && !validateDateFormat(values.tilDato)) {
        errors.tilDato = polyglot.t('ledigeLokaler.form.invalidDate');
      }

      if (values.fraDato && values.tilDato && values.fraDato > values.tilDato) {
        errors.fraDato = polyglot.t('ledigeLokaler.form.invalidStartDate');
        errors.tilDato = polyglot.t('ledigeLokaler.form.invalidEndDate');
      }

      if (values.kontaktEpost && !validateEmail(values.kontaktEpost)) {
        errors.kontaktEpost = polyglot.t('user.emailInvalid');
      }

      if (
        values.ledigAreal &&
        values.ledigAreal > selectedLedigLokale.maxAreal
      ) {
        errors.ledigAreal = polyglot.t('ledigeLokaler.form.invalidMaxAreal');
      }

      if (values.ledigAreal && !isValidInt(values.ledigAreal)) {
        errors.ledigAreal = polyglot.t('ledigeLokaler.form.invalidWholeNumber');
      }

      if (
        values.parkeringsAreal &&
        values.parkeringsAreal > selectedLedigLokale.maxParkeringsareal
      ) {
        errors.parkeringsAreal = polyglot.t(
          'ledigeLokaler.form.invalidMaxparkeringsareal',
        );
      }

      if (values.parkeringsAreal && !isValidInt(values.parkeringsAreal)) {
        errors.parkeringsAreal = polyglot.t(
          'ledigeLokaler.form.invalidWholeNumber',
        );
      }

      if (
        values.ledigAreal &&
        values.parkeringsAreal &&
        values.ledigAreal + values.parkeringsAreal >
          selectedLedigLokale.maxAreal
      ) {
        errors.ledigAreal = polyglot.t('ledigeLokaler.form.invalidSumAreal');
        errors.parkeringsAreal = polyglot.t(
          'ledigeLokaler.form.invalidSumAreal',
        );
      }

      return errors;
    }}
  >
    {({ values, errors, handleChange, handleBlur, isSubmitting }) => (
      <Form>
        <div className={styles.row}>
          <SelectField
            label={polyglot.t('ledigeLokaler.form.leieforhold')}
            id="leieforhold"
            name="leieforhold"
            large
            placeholder={polyglot.t('ledigeLokaler.form.inputLeieforhold')}
            onChange={({ value }) =>
              handleLeieChange({ name: 'leieforhold', value })
            }
            selectedValue={values.leieforhold}
            required
            loading={loadingGetLokale}
            choices={leieforholdChoices.map(leieforholdChoice => ({
              value: leieforholdChoice.id,
              label: leieforholdChoice.navn,
            }))}
            isClearable
            wide
          />
          {leieforhold && (
            <SelectField
              label={polyglot.t('ledigeLokaler.form.leieobjekt')}
              id="leieobjekt"
              name="leieobjekt"
              large
              placeholder={polyglot.t('ledigeLokaler.form.inputLeieobjekt')}
              onChange={({ value }) =>
                handleLeieChange({ name: 'leieobjekt', value })
              }
              selectedValue={values.leieobjekt}
              choices={leieobjektChoices.map(leieobjektChoice => ({
                value: leieobjektChoice.id,
                label: leieobjektChoice.navn,
              }))}
              isClearable
              wide
            />
          )}
        </div>
        {leieforhold &&
          leieobjekt && (
            <Fragment>
              <div className={styles.row}>
                <FormikField
                  label={polyglot.t('ledigeLokaler.form.ledigAreal')}
                  type="number"
                  errors={errors}
                  name="ledigAreal"
                  min={0}
                  max={selectedLedigLokale.maxAreal}
                  required
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.ledigAreal}
                  helpToolTipButton={helpToolTipButton(
                    polyglot.t('helpToolTipButton.ledigelokaler.bta'),
                  )}
                />
                <FormikField
                  label={polyglot.t('ledigeLokaler.form.parkeringsAreal')}
                  type="number"
                  errors={errors}
                  min={0}
                  max={selectedLedigLokale.maxAreal}
                  name="parkeringsAreal"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.parkeringsAreal}
                  helpToolTipButton={helpToolTipButton(
                    polyglot.t(
                      'helpToolTipButton.ledigelokaler.parkeringsareal',
                    ),
                  )}
                />
                <FormField
                  readOnly
                  label={polyglot.t('ledigeLokaler.form.restTotaltAreal')}
                  value={`${selectedLedigLokale.maxAreal -
                    (values.ledigAreal + values.parkeringsAreal)} kvm`}
                  helpToolTipButton={helpToolTipButton(
                    polyglot.t('helpToolTipButton.ledigelokaler.restareal'),
                  )}
                />
                <FormField
                  readOnly
                  label={polyglot.t('ledigeLokaler.form.maxAreal')}
                  value={`${selectedLedigLokale.maxAreal} kvm`}
                  helpToolTipButton={helpToolTipButton(
                    polyglot.t('helpToolTipButton.ledigelokaler.maksareal'),
                  )}
                />
              </div>
              <div className={styles.row}>
                <FormField
                  readOnly
                  label={polyglot.t('ledigeLokaler.form.type')}
                >
                  <div className={styles.fremleieType}>
                    {values.ledigLokaleType}
                  </div>
                </FormField>
                <div className={styles.row.column}>
                  <DateTimePicker
                    label={polyglot.t('ledigeLokaler.form.fraDato')}
                    name="fraDato"
                    required
                    value={values.fraDato}
                    max={values.tilDato}
                    placeholder={polyglot.t(
                      'ledigeLokaler.form.fraDatoPlaceholder',
                    )}
                    error={errors.fraDato}
                    onBlur={handleBlur}
                    onChange={date =>
                      handleChange({
                        target: { name: 'fraDato', value: date },
                      })
                    }
                  />
                  <DateTimePicker
                    label={polyglot.t('ledigeLokaler.form.tilDato')}
                    name="tilDato"
                    required
                    value={values.tilDato}
                    min={values.fraDato}
                    error={errors.tilDato}
                    placeholder={polyglot.t(
                      'ledigeLokaler.form.tilDatoPlaceholder',
                    )}
                    onBlur={handleBlur}
                    onChange={date =>
                      handleChange({
                        target: { name: 'tilDato', value: date },
                      })
                    }
                  />
                </div>
              </div>
              <div className={styles.row}>
                <FormikField
                  label={polyglot.t('ledigeLokaler.form.kommentar')}
                  id="kommentar"
                  name="kommentar"
                  type="textarea"
                  value={values.kommentar}
                  maxLength="4000"
                  errors={errors}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={polyglot.t(
                    'ledigeLokaler.form.kommentarPlaceholder',
                  )}
                />
              </div>
              <div className={styles.row}>
                <FormikField
                  label={polyglot.t('ledigeLokaler.form.kontaktperson')}
                  errors={errors}
                  name="kontaktperson"
                  required
                  medium
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={polyglot.t(
                    'ledigeLokaler.form.kontaktpersonPlaceholder',
                  )}
                />
                <FormikField
                  label={polyglot.t('ledigeLokaler.form.kontaktEpost')}
                  errors={errors}
                  name="kontaktEpost"
                  medium
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={polyglot.t(
                    'ledigeLokaler.form.kontaktEpostPlaceholder',
                  )}
                />
                <FormikField
                  label={polyglot.t('ledigeLokaler.form.kontaktMobil')}
                  errors={errors}
                  name="kontaktMobil"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={polyglot.t(
                    'ledigeLokaler.form.kontaktMobilPlaceholder',
                  )}
                />
              </div>
            </Fragment>
          )}
        <div className={styles.row}>
          {goBackButton}
          {leieforhold &&
            leieobjekt && (
              <Button
                name="save"
                submit
                disabled={
                  isSubmitting ||
                  Object.keys(errors).length > 0 ||
                  !(
                    leieforhold &&
                    leieobjekt &&
                    values.ledigAreal &&
                    values.ledigAreal + values.parkeringsAreal > 0 &&
                    values.fraDato &&
                    values.tilDato &&
                    values.kontaktperson
                  )
                }
                className={styles.button}
                kind="primary"
              >
                {isSubmitting ? (
                  <Bubbles />
                ) : (
                  polyglot.t('ledigeLokaler.form.save')
                )}
              </Button>
            )}
        </div>
      </Form>
    )}
  </Formik>
);

export default RegisterLedigeLokaler;
