import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Label, Input, ErrorMessage } from 'components';
import styles from './Field.css';

const Field = ({
  helpToolTipButton,
  required,
  name,
  label,
  validationState,
  tabNumber,
  feedback,
  error,
  type,
  className,
  lg,
  tall,
  horizontal,
  feedbackMargin,
  refFunc,
  readOnly,
  ...props
}) => {
  const inputProps = {
    id: name,
    tabIndex: tabNumber,
    name,
    type,
    lg,
    tall,
    validationState,
    refFunc,
    ...props,
  };
  const classes = classnames(className, {
    [styles.tall]: tall,
    [styles.horizontal]: horizontal,
    [styles.feedbackMargin]:
      feedbackMargin && !(validationState === 'error' && feedback),
    [styles.relative]: !feedbackMargin,
  });

  return (
    <div className={classes}>
      {label && (
        <Label htmlFor={inputProps.id}>
          {label}
          {required && <span className={styles.required}>*</span>}
          {helpToolTipButton}
        </Label>
      )}
      {!readOnly && <Input aria-label={label} {...inputProps} />}
      {readOnly &&
        (props.value ? (
          <p className={styles.readOnly}>{props.value}</p>
        ) : (
          props.children
        ))}
      {feedback &&
        validationState === 'error' && (
          <ErrorMessage feedback={feedback} feedbackMargin={feedbackMargin} />
        )}
      {error && (
        <div id={`${name}Error`} role="alert">
          {error}
        </div>
      )}
    </div>
  );
};

Field.propTypes = {
  helpToolTipButton: PropTypes.node,
  children: PropTypes.node,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  feedback: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  lg: PropTypes.bool,
  tall: PropTypes.bool,
  horizontal: PropTypes.bool,
  feedbackMargin: PropTypes.bool,
  tabNumber: PropTypes.number,
  required: PropTypes.bool,
  refFunc: PropTypes.func,

  validationState: PropTypes.oneOf([
    'success',
    'error',
    'warning',
    null,
    false,
  ]),
};

Field.defaultProps = {
  type: 'text',
};

export default Field;
