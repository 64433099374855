// @flow
import * as React from 'react';
import {
  Field,
  Button,
  Panel,
  Paragraph,
  Heading,
  ModalButton,
} from 'components';
import { setDefault } from 'utils/utils';
import Dialog from '../../../containers/Dialog';
import styles from './CommentBox.css';

type Props = {
  comment: string,
  originalComment: string,
  onCommentChange(): void,
  buttonClick(): void,
  editable: boolean,
  modals: {
    showCommentModal: boolean,
  },
  toggleModal(string): void,
  className?: string,
};

const CommentBox = ({
  comment,
  originalComment,
  onCommentChange,
  buttonClick,
  editable,
  modals,
  toggleModal,
  className,
}: Props) => (
  <Panel className={className}>
    <div className={styles.header}>
      <Heading level={1}>Kommentarer</Heading>
      {editable && (
        <ModalButton
          showModal={!!modals.showCommentModal}
          toggle={() => toggleModal('showCommentModal')}
          link
        >
          <Dialog
            onExit={() => toggleModal('showCommentModal')}
            render={({ firstRef, lastRef }) => (
              <div className={styles.wrapper}>
                <Field
                  type="textarea"
                  maxLength="4000"
                  value={setDefault(comment, originalComment)}
                  onChange={onCommentChange}
                  className={styles.input}
                  id="comment"
                  refFunc={firstRef}
                />
                <div className={styles.buttons}>
                  <Button kind="primary" id="saveComment" onClick={buttonClick}>
                    {'Lagre'}
                  </Button>
                  <Button
                    secondaryButton
                    onClick={() => toggleModal('showCommentModal')}
                    refFunc={lastRef}
                  >
                    {'Avbryt'}
                  </Button>
                </div>
              </div>
            )}
          />
        </ModalButton>
      )}
    </div>

    <Paragraph className={styles.paragraph}>
      {originalComment || <i>Ingen kommentarer enda</i>}
    </Paragraph>
  </Panel>
);

export default CommentBox;
