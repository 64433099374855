exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".HitCount__hitCount___3YLIk {\n  margin-left: 10px;\n}\n", ""]);

// exports
exports.locals = {
	"hitCount": "HitCount__hitCount___3YLIk"
};