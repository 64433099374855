exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".RealestateNamebar__wrapper___2xjXG {\n  min-height: 160px;\n  padding: 20px 40px;\n  background-color: #FFFFFF;\n  margin-bottom: 14px;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n}\n\n.RealestateNamebar__header___316Ia {\n  text-align: center;\n  line-height: 22px;\n  color: #232F3A;\n  min-height: 22px;\n}\n\n.RealestateNamebar__text___1UJpI {\n  font-size: 1.5em;\n  max-width: 260px;\n  min-height: 33px;\n  font-weight: bold;\n  text-align: center;\n  line-height: 33px;\n  color: #232F3A;\n  overflow:hidden;\n}\n\n.RealestateNamebar__block___OjWyG {\n  min-height: 120px;\n  min-width: 260px;\n  margin-bottom: 5px;\n  background-color: rgba(20, 126, 135, .05);\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n}\n", ""]);

// exports
exports.locals = {
	"wrapper": "RealestateNamebar__wrapper___2xjXG",
	"header": "RealestateNamebar__header___316Ia",
	"text": "RealestateNamebar__text___1UJpI",
	"block": "RealestateNamebar__block___OjWyG"
};