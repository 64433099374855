exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* FONTS */\n\n:root {\n}\n\n/* VIEWPORTS */\n\n:root {\n}\n\n.Breadcrumbs__breadcrumb___FRAJM {\n  background-color: transparent;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;\n  font-size: 1rem;\n  font-weight: 300;\n  line-height: 22px;\n  color: #232f3a;\n  list-style: none;\n  padding: 30px 55px 20px;\n  margin-bottom: 0;\n}\n\n@media (max-width: 800px) {\n\n  .Breadcrumbs__breadcrumb___FRAJM {\n    padding: 0;\n    -ms-flex-wrap: wrap;\n        flex-wrap: wrap;\n  }\n\n  .Breadcrumbs__breadcrumb___FRAJM > li {\n    padding: 5px 0;\n  }\n}\n\n.Breadcrumbs__homeIcon___NJypd {\n  margin-right: 5px;\n}\n\n.Breadcrumbs__angle___1mE9L {\n  margin: 0 10px 0 20px;\n}\n", ""]);

// exports
exports.locals = {
	"breadcrumb": "Breadcrumbs__breadcrumb___FRAJM",
	"homeIcon": "Breadcrumbs__homeIcon___NJypd",
	"angle": "Breadcrumbs__angle___1mE9L"
};