// @flow

import * as React from 'react';
import classNames from 'classnames';
import styles from './Heading.css';

type Props = {
  level?: number,
  children: React.Node,
  frontPage: ?boolean,
  noMargin: ?boolean,
  bottomMargin: ?boolean,
  noPadding: ?boolean,
  green: ?boolean,
  italic: ?boolean,
  className: ?string,
  capital: ?boolean,
  bold: ?boolean,
};

const Heading = ({
  level,
  children,
  frontPage,
  noMargin,
  bottomMargin,
  noPadding,
  green,
  italic,
  className,
  capital,
  bold,
  id,
}: Props) => {
  const classes = classNames(styles.heading, className, {
    [styles.level0]: level === 0,
    [styles.level1]: level === 1,
    [styles.level2]: level === 2,
    [styles.level3]: level === 3,
    [styles.level4]: level === 4,
    [styles.level5]: level === 5,
    [styles.noMargin]: noMargin,
    [styles.noPadding]: noPadding,
    [styles.bottomMargin]: bottomMargin,
    [styles.frontPage]: frontPage,
    [styles.italic]: italic,
    [styles.green]: green,
    [styles.capital]: capital,
    [styles.bold]: bold,
  });
  return React.createElement(
    `h${level + 1}`,
    { className: classes, id: id },
    children,
  );
};

Heading.defaultProps = {
  level: 0,
};

export default Heading;
