import React from 'react';
import PropTypes from 'prop-types';
import { setDefault, validateNumber, toGuiDate } from 'utils/utils';
import polyglot from 'i18n';
import { ModalButton, Field, DataItem, Button, Table } from 'components';
import styles from './LokaleCostModal.css';
import Dialog from '../../../containers/Dialog';

const LokaleCostModal = ({
  modals,
  toggleModal,
  lokaleInput,
  lokaleperiode,
  lokaleperioder,
  onLokaleChange,
  buttonClick,
}) => {
  const input = firstRef => [
    <DataItem data={toGuiDate(new Date(lokaleperiode.fraDato))} />,
    <Field
      refFunc={firstRef}
      name="lokalkostnader"
      value={setDefault(
        lokaleInput.lokalkostnader,
        lokaleperiode.lokalkostnader,
      )}
      validationState={validateNumber(lokaleInput.lokalkostnader)}
      feedback="Må være et tall"
      onChange={onLokaleChange}
      placeholder="Fyll in lokalkostnad"
      onKeyPress={e =>
        e.key === 'Enter' &&
        buttonClick({
          target: { id: 'lokalkostnader' },
        })
      }
    />,
  ];

  const dataRows = firstRef => [
    input(firstRef),
    ...lokaleperioder
      .filter(all => all.id !== lokaleperiode.id)
      .map(it => [
        <DataItem data={toGuiDate(new Date(it.fraDato))} />,
        it.lokalkostnader,
      ]),
  ];

  return (
    <ModalButton
      showModal={!!modals.showModalLokaleCost}
      toggle={() => toggleModal('showModalLokaleCost')}
      link
      buttonTitle="Rediger"
    >
      <Dialog
        onExit={() => toggleModal('showModalLokaleCost')}
        onlyOneFocus={validateNumber(lokaleInput.lokalkostnader) === 'error'}
        render={({ firstRef, lastRef }) => (
          <div className={styles.modalWrapper}>
            <Table
              responsive
              dataRows={dataRows(firstRef)}
              headerRow={[
                polyglot.t('newContract.step6.kostnaderFra'),
                'Andre lokalkostnader',
              ]}
            />
            <div className={styles.buttonWrapper}>
              <Button
                id="lokalkostnader"
                kind="primary"
                disabled={
                  validateNumber(lokaleInput.lokalkostnader) === 'error'
                }
                onClick={buttonClick}
                refFunc={
                  validateNumber(lokaleInput.lokalkostnader) !== 'error'
                    ? lastRef
                    : undefined
                }
              >
                {'Lagre'}
              </Button>
            </div>
          </div>
        )}
      />
    </ModalButton>
  );
};

LokaleCostModal.propTypes = {
  modals: PropTypes.shape({
    showNameModal: PropTypes.bool,
  }),
  toggleModal: PropTypes.func,
  lokaleInput: PropTypes.shape({
    lokalekostnaderFromObj: PropTypes.object,
    lokalkostnader: PropTypes.string,
  }),
  lokaleperiode: PropTypes.shape({
    lokalkostnader: PropTypes.number,
    fraDato: PropTypes.string,
  }),
  onLokaleChange: PropTypes.func,
  buttonClick: PropTypes.func,
  lokaleperioder: PropTypes.array,
};

export default LokaleCostModal;
