import React from 'react';
import GenericList from 'containers/GenericList';
import { bindActionCreators } from 'redux';
import { TableTemplate, Button, Link, Icon } from 'components';
import { connect } from 'react-redux';
import * as widgetsActions from 'redux/modules/widgets/widgets';
import * as searchActions from 'redux/modules/search/search';
import * as tableActions from 'redux/modules/search/table';
import searchSelector from 'redux/modules/search/searchSelector';
import userSelector from 'redux/modules/user/userSelector';

type Props = {
  userData: {
    tilganger: [],
  },
  data: {
    result: [],
    idArray: [],
  },
};

const MeldingList = reduxProps => (
  <GenericList
    type="melding"
    {...reduxProps}
    render={({ data, userData, ...props }: Props) => (
      <TableTemplate
        {...props}
        userData={userData}
        enableSearch
        aktivValg={[{ id: 'true', navn: 'Ja' }, { id: 'false', navn: 'Nei' }]}
        data={data}
        newItemButton={
          <Button kind="primary" newItemButton to="/d/admin/melding/nymelding">
            {'+ Send melding'}
          </Button>
        }
      />
    )}
  />
);

function mapStateToProps(state) {
  return {
    data: searchSelector('melding')(state),
    loading: state.search.loading,
    toggleFlag: state.table.toggleFlag,
    loadingToggle: state.table.loadingToggle,
    error: state.search.error,
    toggleError: state.table.error,
    userData: userSelector(state),
    table: state.table.meldingTable,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...searchActions, ...tableActions, ...widgetsActions },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MeldingList);
