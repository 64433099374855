import React from 'react';
import {
  KpiPanels,
  LokaleCostModal,
  LokaleEmployeeModal,
  Table,
  DataItem,
} from 'components';
import { localize } from 'utils/utils';
import polyglot from 'i18n';
import styles from './LokaleKpiPanels.css';

const areaTypes = kontor => [
  'sumBruttoareal',
  kontor ? 'spesialformaalareal' : 'arbeidsrelatertareal',
  'sumParkeringsareal',
  'sumFramleieareal',
  'totalareal',
];
const costTypes = [
  'husleiekostnad',
  'framleiereduksjon',
  'lokalkostnader',
  'kostnaderPerAar',
];

const LokaleKpiPanels = ({
  showKpiAndContracts,
  hasEditAccess,
  lokale,
  aktiveLeieforhold,
  ...props
}) => {
  const { lokaleperiode = {}, lokaleperioder = [], kpi } = lokale;
  if (!showKpiAndContracts || !kpi) return null;
  const validLeieforhold = aktiveLeieforhold.filter(
    lf => lf.statusNy === 'AKTIV',
  );

  const contToLokaleCost = string => {
    if (string === 'husleiekostnad') return localize(lokale.sumHusleiekostnad);
    if (string === 'framleiereduksjon')
      return localize(lokale.sumFramleiereduksjon);
    if (string === 'lokalkostnader')
      return (
        <div className={styles.tableButton}>
          {localize(lokaleperiode[string])}
          {hasEditAccess && (
            <LokaleCostModal
              {...{
                lokaleperiode,
                lokaleperioder,
                ...props,
              }}
            />
          )}
        </div>
      );
    if (string === 'kostnaderPerAar') return localize(lokale.totalkostnad);
    return localize(lokale[string]);
  };

  const areaPanel = (
    <Table
      responsive
      drilldown={false}
      bold
      dataRows={areaTypes(lokale.kontor).map(it => [
        polyglot.t(`leideLokaler.${it}`),
        ...validLeieforhold.map(kont => [localize(kont[it])]),
        localize(lokale[it]),
      ])}
      headerRow={[
        polyglot.t('leideLokaler.typeAreal'),
        ...validLeieforhold.map(it => it.navn),
        polyglot.t('leideLokaler.total'),
      ]}
    />
  );

  const costPanel = (
    <Table
      drilldown={false}
      responsive
      bold
      dataRows={[
        ...costTypes.map(it => [
          polyglot.t(`leideLokaler.${it}`),
          ...validLeieforhold.map(kont => [
            kont.leieforholdperiodeNy &&
              localize(kont.leieforholdperiodeNy[it]),
          ]),
          contToLokaleCost(it),
        ]),
      ]}
      headerRow={[
        polyglot.t('leideLokaler.typeKostnad'),
        ...validLeieforhold.map(it => it.navn),
        polyglot.t('leideLokaler.total'),
      ]}
    />
  );

  const humanPanel = (
    <div className={styles.humanPanel}>
      <DataItem
        data={localize(lokaleperiode.antallAnsatte)}
        metaData={polyglot.t('leideLokaler.numberEmp')}
      />
      <DataItem
        data={localize(lokaleperiode.antallAarsverk)}
        metaData={polyglot.t('leideLokaler.numberYears')}
      />
      <DataItem
        data={localize(lokaleperiode.antallInnleide)}
        metaData={polyglot.t('leideLokaler.numberConsultants')}
      />
      {hasEditAccess && (
        <LokaleEmployeeModal {...{ lokaleperiode, lokaleperioder, ...props }} />
      )}
    </div>
  );

  return (
    <KpiPanels
      type="lokale"
      kontor={!!lokale.kontor}
      outdated={lokale.rapporteringStatusNy === 'blue'}
      future={lokale.rapporteringStatusNy === 'white'}
      outdatedDate={lokale.tilDato}
      futureDate={lokale.fraDato}
      {...{
        areaPanel,
        nonOfficeAreaPanel: areaPanel,
        costPanel,
        humanPanel,
        kpi,
        ...props,
      }}
      totalareal={`${localize(lokale.totalareal)} m\u00B2`}
      kvmPrAnsatt={
        lokale.kontor ? `${localize(lokale.arealPerAnsatt)} m\u00B2` : ''
      }
      arbeidsrelatertarealPerAnsatt={
        !lokale.kontor
          ? `${localize(lokale.arbeidsrelatertarealPerAnsatt)} m\u00B2`
          : ''
      }
      totalKostnad={`kr ${localize(lokale.totalkostnad)}`}
      kostPrKvm={`kr ${localize(lokale.kostnaderPerKvm)}`}
      aarsverk={localize(lokale.aarsverk)}
      ansatte={localize(lokale.ansatte)}
      kostPrAarsverk={
        lokale.kontor ? `kr ${localize(lokale.kostnaderPerAarsverk)}` : ''
      }
    />
  );
};

export default LokaleKpiPanels;
