import React from 'react';
import { MenuItem } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import type { RouterHistory } from 'types';
import classnames from 'classnames';
import polyglot from 'i18n';
import { Dropdown, Icon, Button } from 'components';
import profileImgPath from 'assets/placeholder-th.png';
import styles from './NavHeader.css';

type Props = {
  user: {
    roles: Array<string>,
    tilganger: Array<string>,
    name: string,
  },
  history: RouterHistory,
  logout(): void,
  collapseToggle(): void,
  collapsed: boolean,
  navRef(): void,
};

const NavHeader = ({
  user: { roles, name, tilganger },
  history,
  logout,
  collapseToggle,
  collapsed,
  navRef,
}: Props) => {
  const propertiesSubPaths = ['/d/eiendom', '/d/grunneiendom', '/d/bygning'];
  const premiseSubPaths = ['/d/lokale', '/d/lediglokale'];

  const profileButton = (
    <Dropdown
      id="profile"
      profile
      useCaret
      title={
        <div className={styles.span}>
          <img
            className={styles.img}
            alt={polyglot.t('navbar.profile')}
            src={profileImgPath}
          />
          <div className={styles.dropdownText}>{name}</div>
        </div>
      }
    >
      <MenuItem eventKey="4.1" onSelect={() => history.push('/d/profil')}>
        Din profil
      </MenuItem>
      <MenuItem
        eventKey="4.2"
        onClick={() => {
          logout();
        }}
      >
        {polyglot.t('navbar.loggUt')}
      </MenuItem>
    </Dropdown>
  );

  const homeButton = (
    <div
      className={classnames(
        styles.item,
        new RegExp('/d/hjem', 'g').test(window.location.pathname) &&
          styles.active,
      )}
    >
      <Link to="/d/hjem">{polyglot.t('navbar.home')}</Link>
    </div>
  );

  const propertiesDropdown = (
    <Dropdown
      className={classnames(
        styles.item,
        !propertiesSubPaths.every(
          p => !new RegExp(`${p}`, 'g').test(window.location.pathname),
        ) && styles.active,
      )}
      id="eiendommer"
      title={polyglot.t('navbar.eidelokalermeny')}
    >
      {propertiesSubPaths.map(it => (
        <MenuItem key={it} onSelect={() => history.push(it)}>
          {polyglot.t(`navbar.${it}`)}
        </MenuItem>
      ))}
    </Dropdown>
  );

  const premisesDropdown = roles.indexOf('ROLE_PUBLIKUM') === -1 && (
    <Dropdown
      className={classnames(
        styles.item,
        !premiseSubPaths.every(
          premise =>
            !new RegExp(`${premise}`, 'g').test(window.location.pathname),
        ) && styles.active,
      )}
      id="lokaler"
      title={polyglot.t('navbar.leidelokalermeny')}
    >
      {premiseSubPaths.map(premise => (
        <MenuItem key={premise} onSelect={() => history.push(premise)}>
          {polyglot.t(`navbar.${premise}`)}
        </MenuItem>
      ))}
    </Dropdown>
  );

  const premisesButton = roles.indexOf('ROLE_PUBLIKUM') !== -1 && (
    <div
      className={classnames(
        styles.item,
        new RegExp('/d/lokale', 'g').test(window.location.pathname) &&
          styles.active,
      )}
    >
      <a
        role="button"
        tabIndex={0}
        onKeyPress={e => e.key === 'Enter' && history.push('/d/lokale')}
        onClick={() => history.push('/d/lokale')}
      >
        {polyglot.t('navbar.leidelokalermeny')}
      </a>
    </div>
  );

  const lokalebutton = (
    <div
      className={classnames(
        styles.item,
        new RegExp('/d/lokale', 'g').test(window.location.pathname) &&
          styles.active,
      )}
    >
      <a
        role="link"
        tabIndex={0}
        onKeyPress={e => e.key === 'Enter' && history.push('/d/lokale')}
        onClick={() => history.push('/d/lokale')}
      >
        {polyglot.t('navbar.leidelokalermeny')}
      </a>
    </div>
  );
  const faqbutton = (
    <div
      className={classnames(
        styles.item,
        new RegExp('/d/hjelp', 'g').test(window.location.pathname) &&
          styles.active,
      )}
    >
      <a
        role="link"
        tabIndex={0}
        onKeyPress={e => e.key === 'Enter' && history.push('/d/hjelp')}
        onClick={() => window.open('/d/hjelp')}
      >
        {'FAQ'}
      </a>
    </div>
  );
  const reportButton = (
    <div
      className={classnames(
        styles.item,
        new RegExp('/d/rapport', 'g').test(window.location.pathname) &&
          styles.active,
      )}
    >
      <a
        role="link"
        tabIndex={0}
        onKeyPress={e => e.key === 'Enter' && history.push('/d/rapport')}
        onClick={() => history.push('/d/rapport')}
      >
        {polyglot.t('navbar.rapportermeny')}
      </a>
    </div>
  );

  const searchButton = (
    <div
      className={classnames(
        styles.item,
        new RegExp('/d/sok', 'g').test(window.location.pathname) &&
          styles.active,
      )}
    >
      <a
        role="link"
        tabIndex={0}
        onKeyPress={e => e.key === 'Enter' && history.push('/d/sok')}
        onClick={() => history.push('/d/sok')}
      >
        {polyglot.t('navbar.sok')}
      </a>
    </div>
  );

  const projectButton = tilganger.indexOf('ADMINISTRER_UTVALG') > -1 && (
    <div
      className={classnames(
        styles.item,
        new RegExp('/d/prosjekt', 'g').test(window.location.pathname) &&
          styles.active,
      )}
    >
      <a
        role="link"
        tabIndex={0}
        onKeyPress={e => e.key === 'Enter' && history.push('/d/prosjekt')}
        onClick={() => history.push('/d/prosjekt')}
      >
        {polyglot.t('navbar.project')}
      </a>
    </div>
  );

  const utvalgButton = tilganger.indexOf('ADMINISTRER_UTVALG') > -1 && (
    <div
      className={classnames(
        styles.item,
        new RegExp('/d/admin/utvalg', 'g').test(window.location.pathname) &&
          styles.active,
      )}
    >
      <a
        role="link"
        tabIndex={0}
        onKeyPress={e => e.key === 'Enter' && history.push('/d/admin/utvalg')}
        onClick={() => history.push('/d/admin/utvalg')}
      >
        {polyglot.t('selection.header')}
      </a>
    </div>
  );

  return (
    <nav
      className={classnames(styles.wrapper, !collapsed && styles.toggle)}
      ref={navRef}
    >
      <Button
        className={classnames(styles.collapseButton)}
        icon
        borderless
        onClick={collapseToggle}
        aria-label={polyglot.t('navbar.openMenu')}
      >
        <Icon icon="list" size="1" />
      </Button>
      {homeButton}
      {lokalebutton}
      {propertiesDropdown}
      {reportButton}
      {projectButton}
      {utvalgButton}
      {searchButton}
      {faqbutton}
      {profileButton}
    </nav>
  );
};

NavHeader.defaultProps = {
  user: {
    roles: [],
    name: '',
  },
};

export default withRouter(NavHeader);
