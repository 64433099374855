exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".propertyData__modal___Etwf7 {\n  margin: 20px 0;\n}\n\n.propertyData__resultWrapper___3vVIX {\n  border: 1px solid rgba(72, 101, 111, .15);\n  width: 100%;\n  min-height: 60px;\n  margin-bottom: 10px;\n  padding: 0 20px 20px;\n}\n\n.propertyData__result___4nxQf {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n}\n\n.propertyData__properties___3VN8a {\n  padding: 25px 20px 20px;\n  border: 1px solid #e3e3e3;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n}\n\n.propertyData__bygninger___29DGC {\n  margin-left: 30px;\n}\n\n.propertyData__buildingWrapper___Y2OTt {\n  max-height: 100px;\n  overflow: hidden;\n  -webkit-transition: max-height 0.5s ease-in-out;\n  transition: max-height 0.5s ease-in-out;\n}\n\n.propertyData__expand___27l86 {\n  max-height: 2000px;\n}\n\n.propertyData__expandButton___2-lWM {\n  border: 0;\n  min-height: auto;\n  height: auto;\n  margin-left: 30px;\n}\n", ""]);

// exports
exports.locals = {
	"modal": "propertyData__modal___Etwf7",
	"resultWrapper": "propertyData__resultWrapper___3vVIX",
	"result": "propertyData__result___4nxQf",
	"properties": "propertyData__properties___3VN8a",
	"bygninger": "propertyData__bygninger___29DGC",
	"buildingWrapper": "propertyData__buildingWrapper___Y2OTt",
	"expand": "propertyData__expand___27l86",
	"expandButton": "propertyData__expandButton___2-lWM"
};