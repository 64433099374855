// @flow

import React from 'react';
import { Button, PropertyData } from 'components';
import polyglot from 'i18n';
import styles from './ContractWizard.css';

type Props = {
  step5: {
    showInput: boolean,
    matrikkelResult: Array<any>,
  },
  onChange(): void,
  buttonClick(): void,
  removeHit(): void,
  submitProperty(): void,
  loadingMatrikkel: boolean,
};

const ContractWizardStep5 = ({
  step5,
  onChange,
  buttonClick,
  removeHit,
  submitProperty,
  loadingMatrikkel,
}: Props) => (
  <div className={styles.wrapper}>
    <PropertyData
      grunneiendommer={step5.addedProperties}
      matrikkelResult={step5.matrikkelResult}
      removeGrunneiendom={removeHit}
      hasEditAccess
      onPropertyChange={onChange}
      buttonClick={buttonClick}
      propertyInput={step5}
      submitProperty={submitProperty}
      loadingMatrikkel={loadingMatrikkel}
      isWizard
    />
    <div className={styles.buttonWrapper}>
      <Button id="back" secondaryButton onClick={buttonClick}>
        {'Tilbake'}
      </Button>
      <Button
        id="next"
        kind="primary"
        disabled={!step5.addedProperties.length}
        onClick={buttonClick}
      >
        {'Neste'}
      </Button>
    </div>
  </div>
);

export default ContractWizardStep5;
