// @flow
import React from 'react';
import polyglot from 'i18n';
import type { Lokale, RouterHistory } from 'types';
import { toGuiDate, toApiDate } from 'utils/utils';

import {
  Heading,
  InfoBox,
  Panel,
  Button,
  LoadingOverlay,
  LedigeLokalerListPanel,
  RegisterLedigeLokaler,
} from 'components';
import styles from './LedigeLokalerTemplate.css';

type Props = {
  lokale: Lokale,
  loadingGetLokale: boolean,
  leieforhold: string,
  leieobjekt: string,
  handleSubmit(): void,
  handleEditLedigLokale(number, number): void,
  handleLeieChange({ name: string, value: string }): void,
  history: RouterHistory,
};

// TODO: Consider moving this to other place
export const createLeieforholdChoices = lokale =>
  Array.isArray(lokale.leieforholds)
    ? lokale.leieforholds.map(lforhold => ({
        id: lforhold.id,
        navn: lforhold.navn,
        leieobjekts: Array.isArray(lforhold.leieobjekter)
          ? lforhold.leieobjekter.map(l => ({
              id: l.id,
              navn: l.ledigLokale
                ? l.navn
                : `${l.navn} (Nytt ledig lokaleobjekt)`,
              ledigLokale: {
                ...(l.ledigLokale || {}),
                ledigLokaleType:
                  l.leieforholdtype === 'SELVFORVALTENDE'
                    ? 'TOMGANGSLEIE'
                    : 'FREMLEIE',
              },
              leieforholdtype: lforhold.leieforholdtype,
              leieperiodeStart: lforhold.leieperiodeStart,
              leieperiodeSlutt: lforhold.leieperiodeSlutt,
              sumParkeringsareal: lforhold.sumParkeringsareal,
              totalareal: lforhold.totalareal,
            }))
          : [],
      }))
    : [];

const LedigeLokalerTemplate = ({
  lokale,
  loadingGetLokale,
  leieobjekt,
  leieforhold,
  handleLeieChange,
  handleEditLedigLokale,
  handleSubmit,
  history,
}: Props) => {
  const leieforholdChoices = createLeieforholdChoices(lokale);

  const [leieobjektChoices] = leieforhold
    ? leieforholdChoices
        .filter(forhold => forhold.id === leieforhold)
        .map(objekts => objekts.leieobjekts)
    : [];

  let selectedLedigLokale = {};
  if (leieobjekt) {
    [selectedLedigLokale] = leieobjekt
      ? leieobjektChoices
          .filter(objekts => objekts.id === leieobjekt)
          .map(ledig => ({
            ...ledig.ledigLokale,
            fraDato: ledig.ledigLokale.fraDato
              ? ledig.ledigLokale.fraDato
              : toApiDate(new Date()),
            tilDato: ledig.ledigLokale.fraDato
              ? ledig.ledigLokale.tilDato
              : ledig.leieperiodeSlutt,
            leieobjekt,
            ledigAreal: ledig.ledigLokale.ledigAreal
              ? ledig.ledigLokale.ledigAreal
              : ledig.totalareal,
            maxAreal: ledig.totalareal,
            maxParkeringsareal: ledig.sumParkeringsareal,
          }))
      : {};
  }

  const lokalePeriode =
    Array.isArray(lokale.lokaleperioder) &&
    lokale.lokaleperioder[lokale.lokaleperioder.length - 1];

  let data;
  if (lokale.navn) {
    data = {
      label: [
        polyglot.t('newContract.name'),
        polyglot.t('newContract.lokalId'),
        polyglot.t('newContract.user'),
        polyglot.t('newContract.aarsverk'),
        polyglot.t('newContract.ansatte'),
        polyglot.t('newContract.innleide'),
        polyglot.t('newContract.kontor'),
      ],
      item: lokalePeriode && [
        lokale.navn,
        lokale.lokalId,
        lokale.bruker ? lokale.bruker.navn : '',
        lokalePeriode.antallAarsverk,
        lokalePeriode.antallAnsatte,
        lokalePeriode.antallInnleide,
        lokale.kontor ? 'Ja' : 'Nei',
      ],
    };
  }

  let extraData;
  if (lokale.navn) {
    extraData = {
      label: [
        polyglot.t('ledigeLokaler.infoBox.bruttoAreal'),
        polyglot.t('ledigeLokaler.infoBox.bruttoArealAnnet'),
        polyglot.t('ledigeLokaler.infoBox.bruttoArealKontor'),
        polyglot.t('ledigeLokaler.infoBox.parkeringsAreal'),
        polyglot.t('ledigeLokaler.infoBox.framleieAreal'),
        polyglot.t('ledigeLokaler.infoBox.totalAreal'),
      ],
      item: lokalePeriode && [
        `${lokale.sumBruttoareal} kvm`,
        `${lokale.sumBruttoarealAnnet} kvm`,
        `${lokale.sumBruttoarealKontor} kvm`,
        `${lokale.sumParkeringsareal} kvm`,
        `${lokale.sumFramleieareal} kvm`,
        `${lokale.totalareal} kvm`,
      ],
    };
  }

  const path = history && history.location.pathname;
  const route = path.substring(0, path.lastIndexOf('/'));
  const goBackButton = (
    <Button onClick={() => history.push(route)} name="cancel" transparent>
      {polyglot.t('ledigeLokaler.form.cancel')}
    </Button>
  );

  return (
    <div>
      <Heading level={0}>{polyglot.t('ledigeLokaler.header')}</Heading>
      <div className={styles.wrapper}>
        {loadingGetLokale && <LoadingOverlay />}
        <Panel className={styles.leftPanel}>
          {!loadingGetLokale && <InfoBox data={data} extraData={extraData} />}
        </Panel>
        <div className={styles.rightPanel}>
          {lokale &&
            Array.isArray(lokale.ledigeLokaler) &&
            lokale.ledigeLokaler.length > 0 && (
              <LedigeLokalerListPanel
                {...{ leieforholdChoices, handleEditLedigLokale }}
              />
            )}
          {!loadingGetLokale && (
            <Panel>
              <Heading bold level={1}>
                {polyglot.t('ledigeLokaler.form.header')}
              </Heading>
              <RegisterLedigeLokaler
                {...{
                  loadingGetLokale,
                  handleLeieChange,
                  handleSubmit,
                  selectedLedigLokale,
                  leieforhold,
                  leieobjekt,
                  leieforholdChoices,
                  leieobjektChoices,
                  goBackButton,
                  history,
                }}
              />
            </Panel>
          )}
        </div>
      </div>
    </div>
  );
};

export default LedigeLokalerTemplate;
