import React from 'react';
import PropTypes from 'prop-types';
import { setDefault, toGuiDate, validateNumber } from 'utils/utils';
import { validateDecimal } from 'utils/validationHelpers';
import polyglot from 'i18n';
import { ModalButton, Icon, Field, Button, DataItem, Table } from 'components';
import styles from './LokaleEmployeeModal.css';
import Dialog from '../../../containers/Dialog';

const LokaleEmployeeModal = ({
  modals,
  toggleModal,
  lokaleInput,
  lokaleperiode,
  lokaleperioder,
  onLokaleChange,
  buttonClick,
}) => {
  const disabled =
    validateNumber(lokaleInput.antallInnleide) === 'error' ||
    validateDecimal(lokaleInput.antallAarsverk) === 'error' ||
    validateNumber(lokaleInput.antallAnsatte) === 'error';
  const inputRow = firstRef => [
    <DataItem data={toGuiDate(new Date(lokaleperiode.fraDato))} feedback />,
    <Field
      name="antallAnsatte"
      refFunc={firstRef}
      value={setDefault(lokaleInput.antallAnsatte, lokaleperiode.antallAnsatte)}
      onChange={onLokaleChange}
      validationState={validateNumber(lokaleInput.antallAnsatte)}
      feedback={polyglot.t('feedback.invalidNumber')}
      feedbackMargin
    />,
    <Field
      name="antallAarsverk"
      type="number"
      value={setDefault(
        lokaleInput.antallAarsverk,
        lokaleperiode.antallAarsverk,
      )}
      onChange={onLokaleChange}
      validationState={validateDecimal(lokaleInput.antallAarsverk)}
      feedback={polyglot.t('feedback.invalidNumber')}
      feedbackMargin
    />,
    <Field
      name="antallInnleide"
      value={setDefault(
        lokaleInput.antallInnleide,
        lokaleperiode.antallInnleide,
      )}
      validationState={validateNumber(lokaleInput.antallInnleide)}
      feedback={polyglot.t('feedback.invalidNumber')}
      feedbackMargin
      onChange={onLokaleChange}
    />,
  ];

  const dataRows = firstRef => [
    inputRow(firstRef),
    ...lokaleperioder
      .filter(all => all.id !== lokaleperiode.id)
      .map(it => [
        <DataItem data={toGuiDate(new Date(it.fraDato))} />,
        it.antallAnsatte,
        it.antallAarsverk,
        it.antallInnleide,
      ]),
  ];

  return (
    <ModalButton
      showModal={!!modals.showModalHumanCost}
      toggle={() => toggleModal('showModalHumanCost')}
      buttonTitle="Rediger"
      icon={<Icon icon="pencil" size="1" spaced />}
    >
      <Dialog
        onExit={() => toggleModal('showModalHumanCost')}
        render={({ firstRef, lastRef }) => (
          <div className={styles.modalWrapper}>
            <Table
              headerRow={[
                polyglot.t('newContract.step6.fra'),
                polyglot.t('leideLokaler.numberEmp'),
                polyglot.t('leideLokaler.numberYears'),
                polyglot.t('leideLokaler.numberConsultants'),
              ]}
              dataRows={dataRows(firstRef)}
            />
            <div className={styles.buttonWrapper}>
              <Button
                secondaryButton
                id="cancel"
                onClick={() => toggleModal('showModalHumanCost')}
                refFunc={disabled ? lastRef : undefined}
              >
                {'Avbryt'}
              </Button>
              <Button
                kind="primary"
                id="lokalkostnader"
                onClick={buttonClick}
                disabled={disabled}
                refFunc={!disabled ? lastRef : undefined}
              >
                {'Lagre'}
              </Button>
            </div>
          </div>
        )}
      />
    </ModalButton>
  );
};

LokaleEmployeeModal.propTypes = {
  modals: PropTypes.shape({
    showNameModal: PropTypes.bool,
  }),
  toggleModal: PropTypes.func,
  lokaleInput: PropTypes.shape({
    lokalekostnaderFromObj: PropTypes.object,
    antallAarsverk: PropTypes.string,
    antallAnsatte: PropTypes.string,
    antallInnleide: PropTypes.string,
  }),
  lokaleperiode: PropTypes.shape({
    fraDato: PropTypes.string,
    antallAarsverk: PropTypes.number,
    antallAnsatte: PropTypes.number,
    antallInnleide: PropTypes.number,
  }),
  lokaleperioder: PropTypes.array,
  onLokaleChange: PropTypes.func,
  buttonClick: PropTypes.func,
};

export default LokaleEmployeeModal;
