import React from 'react';
import PropTypes from 'prop-types';

import {
  Panel,
  TableView,
  DataList,
  LoadingOverlay,
  Heading,
  BuildingFloorList,
  BuildingEdit,
  PowerBIButtonModal,
  PropertyAdressEdit,
} from 'components';
import NameEditForm from 'containers/NameEditForm/NameEditForm';
import polyglot from 'i18n';
import GroupEditForm from 'containers/EideLokaler/GroupEditForm/GroupEditForm';
import LeafletMap from 'containers/Map/LeafletMap';
import styles from './DetailPageTemplate.css';

const DetailPageTemplate = ({
  data,
  datalistHeader,
  property,
  building,
  baseDetailFields,
  loading,
  alert,
  showMap,
  type,
  id,
  name,
  hiddenFields,
  hiddenData,
  showModal,
  toggleModal,
  powerBIprops,
  ...props
}) => (
  <div>
    <div className={styles.header}>
      <div className={styles.headerNames}>
        {building ? (
          <BuildingEdit building={building} />
        ) : (
          <NameEditForm
            id={id}
            type={type}
            currentName={name || polyglot.t('notFound.metadata.notDefined')}
          />
        )}
        <Heading level={1}>{datalistHeader}</Heading>

        {property && property.editable && property.adresse ? (
          <PropertyAdressEdit property={property} />
        ) : (
          ''
        )}
      </div>
      {powerBIprops &&
        powerBIprops.rapportId && (
          <PowerBIButtonModal
            toggle={toggleModal}
            panel
            fullScreen
            showModal={showModal}
            token={powerBIprops.token}
            embedUrl={powerBIprops.embedUrl}
            id={powerBIprops.rapportId}
          />
        )}
    </div>
    <div className={styles.wrapper}>
      {loading && <LoadingOverlay />}
      <div className={styles.detailCol}>
        {showMap && (
          <Panel>
            <LeafletMap
              wide
              property={property}
              markers={
                property &&
                property.bygninger &&
                property.bygninger.filter(it => it.plassering)
              }
              plassering={building ? building.plassering : undefined}
            />
          </Panel>
        )}
        {property && (
          <Panel>
            <GroupEditForm
              propertyOwner={property.eierforhold}
              propertyId={property.id}
              matrikkelId={property.matrikkelId}
              connectedRealestate={property.eiendom}
            />
          </Panel>
        )}
        <Panel>
          <Heading level={2}>{`${datalistHeader} - data`}</Heading>
          <DataList fields={baseDetailFields} data={data} />
          {building &&
            building.editable && (
              <React.Fragment>
                <Heading level={2}>{polyglot.t('building.ownDetails')}</Heading>
                <DataList hidden fields={hiddenFields} data={hiddenData} />
              </React.Fragment>
            )}
        </Panel>
      </div>
      <div className={styles.tabPanel}>
        <Panel>
          {property && (
            <Heading level={2}>{polyglot.t('property.buildings')}</Heading>
          )}
          {building && (
            <Heading level={2}>
              {`Grunneiendom${
                building.grunneiendommer && building.grunneiendommer.length > 1
                  ? 'mer'
                  : ''
              }`}
            </Heading>
          )}
          {!loading && alert}
          <TableView drilldown {...props} />
        </Panel>
        {building && <BuildingFloorList building={building} />}
      </div>
    </div>
  </div>
);

DetailPageTemplate.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  hiddenData: PropTypes.arrayOf(PropTypes.string),
  baseDetailFields: PropTypes.arrayOf(PropTypes.string),
  hiddenFields: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
  alert: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  datalistHeader: PropTypes.string,
  property: PropTypes.objectOf(PropTypes.any),
  building: PropTypes.objectOf(PropTypes.any),
  map: PropTypes.bool,
  type: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  showMap: PropTypes.bool,
};

export default DetailPageTemplate;
