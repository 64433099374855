// @flow

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { PeriodicReportingTemplate } from 'components';
import type { Lokale, BrowserHistory, Leieobjekt } from 'types';
import { extractHash } from 'utils/utils';

import * as lokalerActions from 'redux/modules/leideLokaler/leideLokaler';
import * as leieobjektActions from 'redux/modules/leideLokaler/leieobjekt';
import * as contractActions from 'redux/modules/leideLokaler/contracts';
import userSelector from 'redux/modules/user/userSelector';
import polyglot from 'i18n';

type Props = {
  lokale: Lokale,
  location: {
    search: string,
    pathname: string,
  },
  actions: {
    getLokale(number | void): void,
    setLokaleStatus(number, boolean): void,
    saveLeieobjekt(Leieobjekt[]): void,
    updateContractPeriod(number, {}): void,
    setLokalePeriode({}, number): void,
  },
  history: BrowserHistory,
  match: {
    params: {
      lokalerId?: number,
    },
  },
  loadingLeieobjekt: boolean,
  loadingSetLokalePeriode: boolean,
  savedCosts: boolean,
  leieobjektError: string,
  dates: {},
};

type State = {
  currentStep: number,
  costInput: {},
  areaInput: {},
  lokale: Lokale,
  employeeInput: {},
  leieobjekter: Leieobjekt[],
};

const getStatusColors = ({
  lokaleperiode,
  leieobjekter,
  aktiveLeieforhold,
}) => {
  const step2Color = leieobjekter.some(
    ({ leieobjektperiode = {}, kontor }) =>
      leieobjektperiode.bruttoareal == null ||
      (!kontor && !leieobjektperiode.arbeidsrelatertareal),
  )
    ? 'orange'
    : '';
  const step3Color =
    lokaleperiode.antallAarsverk == null || lokaleperiode.antallAnsatte == null
      ? 'orange'
      : '';
  const step4Color =
    lokaleperiode.lokalkostnader == null ||
    aktiveLeieforhold.some(
      ({ leieforholdperiodeNy = {} }) =>
        leieforholdperiodeNy.husleiekostnad == null,
    )
      ? 'orange'
      : '';
  return ['', step2Color, step3Color, step4Color, ''];
};

class PeriodicReporting extends Component<Props, State> {
  state = {
    currentStep: 1,
    areaInput: {},
    employeeInput: {},
    costInput: {},
    lokale: {},
    leieobjekter: [],
  };

  throwErrorIfLokaleErUtgaat = lokale => {
    if (
      lokale &&
      lokale.rapporteringStatusNyCurrent &&
      lokale.rapporteringStatusNyCurrent === 'blue'
    ) {
      throw Error(polyglot.t('error.utgaattLokale'));
    }
  };

  componentDidMount() {
    const { lokale, actions, match } = this.props;

    if (!lokale.id) {
      actions.getLokale(match.params.lokalerId);
    } else this.processLokale();

    const savedReport = this.getEntireSavedReport();
    if (
      savedReport &&
      savedReport.id &&
      lokale.id &&
      lokale.id !== savedReport.id
    ) {
      this.removeSavedReport();
    }

    this.throwErrorIfLokaleErUtgaat(lokale);
  }

  componentDidUpdate(prevProps) {
    const {
      loadingLeieobjekt,
      lokale,
      history,
      leieobjektError,
      loadingSetLokalePeriode,
      employeeError,
      costError,
      savedCosts,
      match,
      actions,
      dates,
    } = this.props;
    const { currentStep } = this.state;
    const savedStep2 =
      !loadingLeieobjekt && prevProps.loadingLeieobjekt && !leieobjektError;

    const savedLokaleperiode =
      !loadingSetLokalePeriode &&
      prevProps.loadingSetLokalePeriode &&
      !employeeError;
    const savedStep3 = currentStep === 3 && savedLokaleperiode;
    const savedStep4 =
      currentStep === 4 &&
      savedCosts &&
      !costError &&
      !loadingSetLokalePeriode &&
      (savedLokaleperiode || !prevProps.savedCosts);

    if (lokale.id && lokale.id !== prevProps.lokale.id) {
      this.throwErrorIfLokaleErUtgaat(lokale);
      if (!lokale.editable) {
        // TODO put this in auth renderprop?
        history.replace('/d/hjem'); // TODO add error message
      } else this.processLokale();
    } else if (savedStep2 || savedStep3 || savedStep4) {
      actions.getLokale(`${match.params.lokalerId}/${dates.periodestart}`);
      this.pushUrl(currentStep + 1);
    }
  }

  static getDerivedStateFromProps({ location }, prevState) {
    // TODO ta bort denne, url er master trenger ikke step i state
    let stepFromUrl = parseInt(extractHash(location.search).page, 10);
    if (!stepFromUrl) stepFromUrl = 1;
    if (prevState.currentStep !== stepFromUrl) {
      return { currentStep: stepFromUrl };
    }
    return null;
  }

  processLokale = () => {
    const { lokale } = this.props;
    // TODO use selector for this, remove lokale from state
    this.setState({
      lokale: {
        ...lokale,
        leieforholds: lokale.leieforholds.filter(
          it => it.aktiv && it.statusNy !== 'UTGAATT',
        ),
      },
      leieobjekter: lokale.leieforholds.reduce((acc, curr) => {
        const leieobj = curr.leieobjekter.filter(obj => obj.aktiv);
        if (
          curr.aktiv &&
          curr.statusNyCurrent !== 'UTGAATT' &&
          !curr.haddeKontraktSluttIFjor
        )
          return acc.concat(
            leieobj.map(filtered => ({
              ...filtered,
              leieforholdNavn:
                curr.status !== 'UTGAATT' ? curr.navn : <i> {curr.navn} </i>,
            })),
          );
        return acc;
      }, []),
    });
  };

  buttonClick = e => {
    const { history, lokale } = this.props;

    if (e.target.id === 'next') {
      this.handleNext();
    } else if (e.target.id === 'prev') {
      if (lokale.skalKunRapporteresKostnader && this.state.currentStep == 4) {
        this.pushUrl(1);
      } else {
        this.pushUrl(this.state.currentStep - 1);
      }
    } else if (e.target.id === 'cancel') {
      history.push(`/d/lokale/${lokale.id}`);
    }
  };

  pushUrl = page => {
    const { history, location } = this.props;
    this.processLokale();
    history.push({
      pathname: location.pathname,
      search: `?page=${page}`,
    });
  };

  handleNext = () => {
    // TODO ta bort?
    const { currentStep } = this.state;
    const { lokale, history, actions } = this.props;
    if (currentStep === 1) {
      if (lokale.skalKunRapporteresKostnader) {
        this.pushUrl(4);
      } else {
        this.pushUrl(currentStep + 1);
      }
    } else if (currentStep === 5) {
      if (lokale.id) {
        history.push(`/d/lokale/${lokale.id}`);
        actions.setLokaleStatus(lokale.id, true);
      }
    }
  };

  getSavedReport = () => {
    const { currentStep } = this.state;
    if (!localStorage.getItem('periodicReport')) {
      return null;
    }

    const report = JSON.parse(localStorage.getItem('periodicReport'));

    if (!report[currentStep] || report.id !== this.state.lokale.id) {
      return null;
    }

    return report[currentStep];
  };

  getEntireSavedReport = () => {
    if (!localStorage.getItem('periodicReport')) {
      return null;
    }
    const report = JSON.parse(localStorage.getItem('periodicReport'));
    return report;
  };

  savePeriodicReport = data => {
    const { currentStep } = this.state;
    const report =
      localStorage.getItem('periodicReport') === null
        ? {}
        : JSON.parse(localStorage.getItem('periodicReport'));
    report.currentStep = currentStep;
    report[currentStep] = data;
    report.id = this.props.lokale.id;

    localStorage.setItem('periodicReport', JSON.stringify(report));
  };

  removeSavedReport = () => {
    localStorage.removeItem('periodicReport');
  };

  render() {
    const {
      dates,
      loadingLeieobjekt,
      loadingSetLokalePeriode,
      actions,
    } = this.props;
    const {
      lokale: { lokaleperiode = {}, leieforholds = [] },
      leieobjekter,
    } = this.state;
    const aktiveLeieforhold = leieforholds.filter(it => it.aktiv);
    const colors = getStatusColors({
      lokaleperiode,
      leieobjekter,
      aktiveLeieforhold,
    });

    return (
      <PeriodicReportingTemplate
        {...this.state}
        dates={dates}
        actions={actions}
        buttonClick={this.buttonClick}
        loading={loadingLeieobjekt || loadingSetLokalePeriode}
        colors={colors}
        aktiveLeieforhold={aktiveLeieforhold}
        savePeriodicReport={this.savePeriodicReport}
        getSavedReport={this.getSavedReport}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    lokale: state.leideLokaler.lokaler,
    loadingLeieobjekt: state.leieobjekt.loadingSave,
    leieobjektError: state.leieobjekt.error,
    loadingSetLokalePeriode: state.leideLokaler.loadingSetLokalePeriode,
    employeeError: state.leideLokaler.error,
    savedCosts: state.contracts.updatedContract,
    costError: state.contracts.error,
    userData: userSelector(state),
    dates: state.leideLokaler.dates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...lokalerActions, ...leieobjektActions, ...contractActions },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PeriodicReporting);
