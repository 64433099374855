import React from 'react';
import GenericList from 'containers/GenericList';
import { bindActionCreators } from 'redux';
import { TableTemplate } from 'components';
import { connect } from 'react-redux';
import * as widgetsActions from 'redux/modules/widgets/widgets';
import * as searchActions from 'redux/modules/search/search';
import * as tableActions from 'redux/modules/search/table';
import searchSelector from 'redux/modules/search/searchSelector';
import userSelector from 'redux/modules/user/userSelector';

const OrganizationList = reduxProps => (
  <GenericList
    type="organization"
    {...reduxProps}
    render={props => (
      <TableTemplate
        {...props}
        enableSearch
        aktivValg={[{ id: 'true', navn: 'Ja' }, { id: 'false', navn: 'Nei' }]}
      />
    )}
  />
);

function mapStateToProps(state) {
  return {
    data: searchSelector('organization')(state),
    loading: state.search.loading,
    toggleFlag: state.table.toggleFlag,
    loadingToggle: state.table.loadingToggle,
    error: state.search.error,
    toggleError: state.table.error,
    userData: userSelector(state),
    table: state.table.organizationTable,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...searchActions, ...tableActions, ...widgetsActions },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrganizationList);
