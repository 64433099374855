exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* VIEWPORTS */\n\n:root {\n}\n\n.DetailPageTemplate__detailCol___18Hv8 {\n  width: 100%;\n}\n\n@media (min-width: 1325px) {\n\n  .DetailPageTemplate__detailCol___18Hv8 {\n    width: 41%;\n    margin: 0 20px 20px 0;\n  }\n}\n\n.DetailPageTemplate__tabPanel___2ySmd {\n  -webkit-box-flex: 2;\n      -ms-flex: 2 2;\n          flex: 2 2;\n  width: 100%;\n}\n\n@media (min-width: 1325px) {\n\n  .DetailPageTemplate__tabPanel___2ySmd {\n    width: 59%;\n  }\n}\n\n.DetailPageTemplate__wrapper___2aPML {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n}\n\n@media (min-width: 1325px) {\n\n  .DetailPageTemplate__wrapper___2aPML {\n    -ms-flex-wrap: nowrap;\n        flex-wrap: nowrap;\n  }\n}\n\n.DetailPageTemplate__header___2Jl-H {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n  margin: 0 0 20px 20px;\n}\n", ""]);

// exports
exports.locals = {
	"detailCol": "DetailPageTemplate__detailCol___18Hv8",
	"tabPanel": "DetailPageTemplate__tabPanel___2ySmd",
	"wrapper": "DetailPageTemplate__wrapper___2aPML",
	"header": "DetailPageTemplate__header___2Jl-H"
};