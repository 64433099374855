exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".InfoLink__infolink___kxiq4 {\n  max-width: 400px;\n  width: -webkit-max-content;\n  width: -moz-max-content;\n  width: max-content;\n  height: 100%;\n  min-height: 32px;\n  background-color: #ffffff;\n  border: 1px solid #C6C6C6;\n  border-radius: 1px;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n}\n\n.InfoLink__linkWrapper___11YqL {\n  margin-left: 15px;\n  margin-right: 15px;\n}\n\n.InfoLink__iconWrapper___5wGNy {\n  margin-top: 5px;\n  margin-right: 15px;\n  cursor: pointer;\n}\n", ""]);

// exports
exports.locals = {
	"infolink": "InfoLink__infolink___kxiq4",
	"linkWrapper": "InfoLink__linkWrapper___11YqL",
	"iconWrapper": "InfoLink__iconWrapper___5wGNy"
};