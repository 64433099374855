exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* FONTS */\n\n:root {\n}\n\n.Table__wrapper___3ctQf tbody > tr > td {\n  border: none;\n}\n\n.Table__responsive___3OhSS {\n  overflow-x: auto;\n}\n\n.table th,\n.table td {\n  min-width: 100px;\n}\n\n.Table__table___83w1i {\n  width: 100%;\n  max-width: 100%;\n  margin-bottom: 20px;\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n  /*\n  overwirte bootstrap border spacing to allow room between columns\n  border-spacing: 10px 0;\n  border-collapse: separate; */\n}\n\n.Table__striped___v1NYE > tbody {\n  border-bottom: 1px solid #e3e3e3;\n}\n\n.Table__loading___7Pj_a {\n  width: 100%;\n  height: 100%;\n  top: 0;\n  left: 0;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  position: absolute;\n  background-color: rgba(255, 255, 255, .5);\n}\n\n.Table__caption___1_QDf {\n  display: none;\n}\n", ""]);

// exports
exports.locals = {
	"wrapper": "Table__wrapper___3ctQf",
	"responsive": "Table__responsive___3OhSS",
	"table": "Table__table___83w1i",
	"striped": "Table__striped___v1NYE",
	"loading": "Table__loading___7Pj_a",
	"caption": "Table__caption___1_QDf"
};