import React from 'react';
import PropTypes from 'prop-types';
import { Masthead, Breadcrumbs, NavHeader, Alert } from 'components';

const Header = ({ logout, alertEnabled, alertKind, alertText, ...props }) => (
  <div>
    <Masthead {...props} />
    <NavHeader logout={logout} {...props} />
    <Breadcrumbs {...props} />
    {alertEnabled && (
      <Alert header kind={alertKind}>
        {alertText}
      </Alert>
    )}
  </div>
);

Header.propTypes = {
  bsName: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  logout: PropTypes.func,
  alertEnabled: PropTypes.bool,
  alertKind: PropTypes.string,
  alertText: PropTypes.string,
};

export default Header;
