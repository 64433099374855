export const GET_DSF_USER = 'GET_DSF_USER';
export const RECEIVED_DSF_USER = 'RECEIVED_DSF_USER';
export const GET_DSF_USER_FAILED = 'GET_DSF_USER_FAILED';
export const CLEAR_TEMP_USER = 'CLEAR_TEMP_USER';

export const CREATE_NEW_USER = 'CREATE_NEW_USER';
export const CREATED_NEW_USER = 'CREATED_NEW_USER';
export const CREATE_NEW_USER_FAILED = 'CREATE_NEW_USER_FAILED';
export const CONVERT_TO_EDIT = 'CONVERT_TO_EDIT';

export const GET_EDITABLE_USER = 'GET_EDITABLE_USER';
export const FOUND_EDITABLE_USER = 'FOUND_EDITABLE_USER';
export const EDITABLE_USER_ERROR = 'EDITABLE_USER_ERROR';

export const getDsfUser = number => ({
  type: GET_DSF_USER,
  path: `/user/dsf/${number}`,
});
export const receivedDsfUser = user => ({ type: RECEIVED_DSF_USER, user });
export const getDsfUserFailed = error => ({ type: GET_DSF_USER_FAILED, error });
export const clearTempUser = () => ({ type: CLEAR_TEMP_USER });

export const createNewUser = user => ({
  type: CREATE_NEW_USER,
  path: `/user/${user.uuid}`,
  payload: user,
});
export const createdNewUser = user => ({ type: CREATED_NEW_USER, user });
export const createNewUserFailed = error => ({
  type: CREATE_NEW_USER_FAILED,
  error,
});
export const convertToEditable = () => ({ type: CONVERT_TO_EDIT });

export const getEditableUser = uuid => ({
  type: GET_EDITABLE_USER,
  path: `/user/${uuid}`,
});
export const foundEditableUser = user => ({ type: FOUND_EDITABLE_USER, user });
export const editableUserError = error => ({
  type: EDITABLE_USER_ERROR,
  error,
});

const initialState = {
  tempUser: {},
  createdUser: {},
  editUser: {},
  loadingNewUser: false,
};

export default function adminUserReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DSF_USER: {
      return Object.assign({}, state, {
        loadingNewUser: true,
        editUser: {},
        tempUser: {},
        createdUser: {},
        loadDsfError: '',
      });
    }
    case RECEIVED_DSF_USER: {
      return Object.assign({}, state, {
        tempUser: action.user,
        loadDsfError: action.user.saved ? 'User already exists' : '',
        loadingNewUser: false,
      });
    }
    case GET_DSF_USER_FAILED: {
      return Object.assign({}, state, {
        loadingNewUser: false,
        loadDsfError: action.error,
      });
    }
    case CLEAR_TEMP_USER: {
      return Object.assign({}, state, {
        tempUser: {},
      });
    }
    case CREATE_NEW_USER: {
      return Object.assign({}, state, {
        creatingUser: true,
        newUserId: '',
        submitUserError: '',
      });
    }
    case CREATED_NEW_USER: {
      return Object.assign({}, state, {
        creatingUser: false,
        createdUser: action.user,
      });
    }
    case CREATE_NEW_USER_FAILED: {
      return Object.assign({}, state, {
        creatingUser: false,
        submitUserError: action.error,
      });
    }
    case CONVERT_TO_EDIT: {
      return Object.assign({}, state, {
        editUser: state.createdUser,
        createdUser: {},
      });
    }
    case GET_EDITABLE_USER: {
      return Object.assign({}, state, {
        gettingUser: true,
        editUser: {},
      });
    }
    case FOUND_EDITABLE_USER: {
      return Object.assign({}, state, {
        gettingUser: false,
        editUser: action.user,
      });
    }
    case EDITABLE_USER_ERROR: {
      return Object.assign({}, state, {
        gettingUser: false,
        error: action.error,
      });
    }
    default:
      return state;
  }
}
