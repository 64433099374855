exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".pagination>li>a,\n .pagination>li>span {\n  color: #005763;\n  min-height: 40px;\n  height: -webkit-max-content;\n  height: -moz-max-content;\n  height: max-content;\n  font-weight: 300;\n}\n\n.pagination> .active>a {\n  font-weight: bold;\n}\n\n.pagination> .active>a,\n .pagination> .active>a:hover,\n .pagination> .active>span:hover,\n .pagination> .active>a:focus,\n .Pagination__pagination___20zko>.Pagination__active___15R8v>span:focus,\n .pagination> .active>span:focus {\n  background-color: #E3E3E3;\n  border-color: #E3E3E3;\n  color: #005763;\n}\n\n/* ---------------------------------------------------------------------- */\n\n/*  Pagination (packet)\n/* ---------------------------------------------------------------------- */\n\n.Pagination__packet___cx3zK>li>a,\n.Pagination__pagination___20zko>li>span {\n  margin-left: 5px;\n  padding: 5px 10px;\n  border-radius: 5px;\n  cursor: pointer;\n  color: #58748B;\n}\n\n.Pagination__packet___cx3zK> .active>a,\n.Pagination__pagination___20zko>.Pagination__active___15R8v>a:hover,\n.Pagination__pagination___20zko>.Pagination__active___15R8v>span:hover,\n.Pagination__pagination___20zko>.Pagination__active___15R8v>a:focus,\n.Pagination__pagination___20zko>.Pagination__active___15R8v>span:focus {\n  background-color: #58748B;\n  border-color: #58748B;\n}\n", ""]);

// exports
exports.locals = {
	"pagination": "Pagination__pagination___20zko",
	"active": "Pagination__active___15R8v",
	"packet": "Pagination__packet___cx3zK"
};