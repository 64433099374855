import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { RealestateTemplate } from 'components';
import * as realestateActions from 'redux/modules/realestate/realestate';

class RealestateDetails extends PureComponent {
  constructor() {
    super();
    this.state = {
      table: {
        page: 0,
        shown: 10,
      },
    };
    this.buildingClick = this.buildingClick.bind(this);
  }

  componentDidMount() {
    this.props.actions.getRealEstate(this.props.match.params.eiendomId);
  }

  buildingClick(e, i) {
    this.props.history.push(
      `/d${this.props.realestate.grunneiendommer[i].bygninger[e]._links.self}`,
    );
  }

  render() {
    const { realestate, loading } = this.props;
    return (
      <RealestateTemplate
        data={realestate}
        table={this.state.table}
        buildingClick={this.buildingClick}
        loading={loading}
        type="eiendom"
        id={realestate.id}
        name={realestate.navn}
      />
    );
  }
}

RealestateDetails.propTypes = {
  realestate: PropTypes.objectOf(PropTypes.any),
  actions: PropTypes.objectOf(PropTypes.any).isRequired,
  loading: PropTypes.bool,
  params: PropTypes.objectOf(PropTypes.any),
  match: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any),
};

RealestateDetails.contextTypes = {
  router: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    realestate: state.realestates.realestate,
    loading: state.realestates.loading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(realestateActions, dispatch),
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(RealestateDetails),
);
