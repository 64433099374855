// Using ducks pattern
// Export action constants
export const GET_VERSION = 'GET_VERSION';
export const GOT_VERSION = 'GOT_VERSION';
export const GET_VERSION_FAIL = 'GET_VERSION_FAIL';

// Export action creators
export const getVersion = () => ({ type: GET_VERSION });
export const gotVersion = response => ({ type: GOT_VERSION, response });
export const getVersionFail = error => ({ type: GET_VERSION_FAIL, error });

// Define initial state for this reducer
export const initialState = {
  loading: false,
  error: false,
  version: 'Unknown',
};

// Export the reducer
export default function versionReducer(state = initialState, action) {
  switch (action.type) {
    case GET_VERSION: {
      return Object.assign({}, state, {
        loading: true,
      });
    }
    case GOT_VERSION: {
      return Object.assign({}, state, {
        loading: false,
        version: action.response.pretty,
      });
    }
    case GET_VERSION_FAIL: {
      return Object.assign({}, state, {
        loading: false,
        error: action.error,
      });
    }
    default:
      return state;
  }
}
