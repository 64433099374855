import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'components';
import classnames from 'classnames';
import styles from './SearchResultToggle.css';

const SearchResultToggle = ({ toggleMap, map }) => (
  <div className={styles.row}>
    <a
      className={classnames(styles.tab, !map && styles.active)}
      role="button"
      tabIndex={0}
      onKeyPress={e => {
        if (e.key === 'Enter') toggleMap(false);
      }}
      onClick={() => toggleMap(false)}
    >
      <Icon icon="list" size="1" />
      {'Tabell'}
    </a>
    <a
      className={classnames(styles.tab, map && styles.active)}
      role="button"
      tabIndex={0}
      onKeyPress={e => {
        if (e.key === 'Enter') toggleMap(true);
      }}
      onClick={() => toggleMap(true)}
    >
      <Icon icon="location-pin" size="1" />
      {'Kart'}
    </a>
  </div>
);

SearchResultToggle.propTypes = {
  toggleMap: PropTypes.func,
};

export default SearchResultToggle;
