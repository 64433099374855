// @flow

import React from 'react';
import GenericList from 'containers/GenericList';
import { TableTemplate, Button } from 'components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as searchActions from 'redux/modules/search/search';
import * as tableActions from 'redux/modules/search/table';
import polyglot from 'i18n';
import searchSelector from 'redux/modules/search/searchSelector';
import userSelector from 'redux/modules/user/userSelector';

type Props = {
  userData: {
    tilganger: [],
  },
  data: {
    result: [],
  },
};

const LeideLokaler = reduxProps => (
  <GenericList
    type="leideLokaler"
    {...reduxProps}
    render={(props: Props) => (
      <TableTemplate
        {...props}
        enableSearch
        newItemButton={
          props.userData.tilganger.indexOf('ADMINISTRER_LOKALE') > -1 && (
            <Button kind="primary" newItemButton to="/d/lokale/nyttlokale">
              {polyglot.t('leideLokaler.addLokale')}
            </Button>
          )
        }
        periodicButton={
          reduxProps.userData.tilganger.indexOf('ADMINISTRER_LOKALE') > -1 &&
          (props.table.filters.visUtgaatte.value ? (
            <Button
              kind="primary"
              periodicButton
              disabled
              to={`/d/lokale/periodisk/${props.data.idArray.map(id =>
                id.id.split('/').pop(),
              )}/`}
            >
              {polyglot.t('leideLokaler.periodic.utgaatt')}
            </Button>
          ) : (
            <Button
              kind="primary"
              periodicButton
              to={`/d/lokale/periodisk/${props.data.idArray.map(id =>
                id.id.split('/').pop(),
              )}/`}
            >
              {polyglot.t('leideLokaler.periodic.title2')}
            </Button>
          ))
        }
        aktivValg={[{ id: 'true', navn: 'Ja' }, { id: 'false', navn: 'Nei' }]}
        ledigValg={[{ id: 'true', navn: 'Ja' }, { id: 'false', navn: 'Nei' }]}
        data={{
          ...props.data,
          result: props.data.result,
        }}
      />
    )}
  />
);

function mapStateToProps(state) {
  const userData = userSelector(state);
  return {
    data: searchSelector('leideLokaler', userData)(state),
    loading: state.search.loading,
    error: state.search.error,
    userData,
    table: state.table.leideLokalerTable,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...searchActions, ...tableActions },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LeideLokaler);
