exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".FormikField__required___y5P0s {\n  color: #b1b1b1;\n  margin-left: 3px;\n  font-weight: 300;\n}\n\n.FormikField__feedbackMargin___wmq_1 {\n  margin-bottom: 2rem;\n}\n", ""]);

// exports
exports.locals = {
	"required": "FormikField__required___y5P0s",
	"feedbackMargin": "FormikField__feedbackMargin___wmq_1"
};