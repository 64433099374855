exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".InfoBox__container___3Pe5i {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: horizontal;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: row;\n          flex-direction: row;\n  padding-left: 30px;\n  border: solid 1px rgb(204, 204, 204);\n  margin: 10px;\n}\n\n.InfoBox__firstDataContainer___20wMT {\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-flow: column;\n          flex-flow: column;\n  -ms-flex-item-align: center;\n      align-self: center;\n}\n\n.InfoBox__secondDataContainer___2k-5h {\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-flow: column;\n          flex-flow: column;\n  -ms-flex-item-align: center;\n      align-self: center;\n  margin-left: 20px;\n}\n", ""]);

// exports
exports.locals = {
	"container": "InfoBox__container___3Pe5i",
	"firstDataContainer": "InfoBox__firstDataContainer___20wMT",
	"secondDataContainer": "InfoBox__secondDataContainer___2k-5h"
};