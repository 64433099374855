import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DataItem, KpiTarget, Button } from 'components';
import { toGuiDate } from 'utils/utils';
import polyglot from 'i18n';
import styles from './PeriodicWizard.css';

const PeriodicWizard5 = ({
  lokale,
  dates,
  buttonClick,
  idArray,
  currentLokale,
}) => {
  const onFinish = e => {
    localStorage.removeItem('periodicReport');
    buttonClick(e);
  };

  return (
    <div>
      <DataItem
        vertical
        metaData={polyglot.t('leideLokaler.periodic.newPeriod')}
        data={toGuiDate(dates.periodestart)}
      />
      <KpiTarget list status color={lokale.rapporteringStatusNy} />
      <div className={styles.buttonWrapper}>
        <Button secondaryButton id="prev" onClick={buttonClick}>
          {polyglot.t('leideLokaler.periodic.back')}
        </Button>
        <Button kind="primary" id="next" onClick={onFinish}>
          {polyglot.t('leideLokaler.periodic.finish')}
        </Button>
        {idArray === undefined || idArray[currentLokale + 1] === undefined ? (
          ''
        ) : (
          <Button kind="primary" id="nextLokale" onClick={onFinish}>
            {polyglot.t('leideLokaler.periodic.next')}
          </Button>
        )}
      </div>
    </div>
  );
};

PeriodicWizard5.propTypes = {
  lokale: PropTypes.shape({ lokaleperiode: PropTypes.object }).isRequired,
  dates: PropTypes.objectOf(PropTypes.string),
  buttonClick: PropTypes.func.isRequired,
  idArray: PropTypes.array,
};

function mapStateToProps(state) {
  return {
    lokale: state.leideLokaler.lokaler,
  };
}

export default connect(mapStateToProps)(PeriodicWizard5);
