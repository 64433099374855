import React from 'react';
import PropTypes from 'prop-types';
import polyglot from 'i18n';
import { Panel, TableView, SearchBox, Heading } from 'components';
import mapData, { getHeaders, apiFields } from 'utils/mapApiData';

import styles from './TableTemplate.css';

const TableTemplate = ({
  error,
  table,
  type,
  newItemButton,
  periodicButton,
  secondaryButton,
  data,
  loading,
  loadingToggle,
  handleChecked,
  enableSearch,
  handleTableClick,
  changePage,
  changeShown,
  sortTable,
  userData: { organisasjon, isGlobal, isPublic },
  exportButton,
  mapListButtons,
  actions,
  ...props
}) => (
  <div>
    <div className={styles.headerWithButton}>
      <Heading level={0}>
        {polyglot.t(`${type}.header`, {
          name: isGlobal ? 'Staten' : organisasjon.navn,
        })}
      </Heading>
      <div>
        {newItemButton}
        {periodicButton}
      </div>
      {exportButton}
    </div>
    {error ? `Søket feilet: ${error.message}` : ''}
    {enableSearch && (
      <Panel>
        <SearchBox
          searchPlaceholder={polyglot.t(`${type}.searchPlaceholder`)}
          hits={data.hits}
          {...{ isGlobal, type, table, loading, ...props }}
        />
      </Panel>
    )}
    <Panel>
      {mapListButtons && (
        <div className={styles.buttonWrapper}>{mapListButtons}</div>
      )}
      <TableView
        drilldown={type !== 'organization'}
        hits={data.hits}
        headerRow={getHeaders(
          apiFields[type].searchTable
            .filter(it => (isPublic ? !it.sensitive : true))
            .map(it => it.field),
          type,
        )}
        dataRows={data.result}
        rightAlignArray={apiFields[type].searchTable.map(
          cellItem => cellItem.rightAlign,
        )}
        {...{ handleChecked, loading, changePage, changeShown, table, type }}
        dataType={type}
        bodyClick={handleTableClick}
        sortClick={sortTable}
        toggleLoading={loadingToggle}
        caption={polyglot.t(`${type}.caption`)}
      />
    </Panel>
  </div>
);

TableTemplate.propTypes = {
  table: PropTypes.objectOf(PropTypes.any).isRequired,
  data: PropTypes.shape({
    hits: PropTypes.number,
    result: PropTypes.array.isRequired,
  }).isRequired,
  loading: PropTypes.bool,
  loadingToggle: PropTypes.bool,
  error: PropTypes.string,
  userData: PropTypes.objectOf(PropTypes.any).isRequired,
  type: PropTypes.oneOf([
    'building',
    'property',
    'realestate',
    'user',
    'organization',
    'matrikkelAlert',
    'leideLokaler',
    'ledigeLokaler',
    'prosjekt',
    'rapporter',
    'adminRapporter',
    'auditlog',
    'etasje',
    'selection',
    'ledigeLokaler',
  ]).isRequired,
  handleChecked: PropTypes.func,
  enableSearch: PropTypes.bool,
  mapListButtons: PropTypes.node,
  handleTableClick: PropTypes.func,
  changePage: PropTypes.func,
  changeShown: PropTypes.func,
  sortTable: PropTypes.func,
  newItemButton: PropTypes.node,
  exportButton: PropTypes.node,
  actions: PropTypes.objectOf(PropTypes.any),
};

export default TableTemplate;
