import { extractHash } from 'utils/utils';

export const isAuthorized = (pathname, access, roles) => {
  if (!(access && Array.isArray(access))) return false;
  if (/d\/?$/.test(pathname)) return true;
  if (/d\/hjem/.test(pathname)) return true;
  if (/d\/personvern/.test(pathname)) return true;
  if (/d\/tilgjengelighet/.test(pathname)) return true;
  if (/d\/sok/.test(pathname)) return true;
  if (/d\/grunneiendom/.test(pathname))
    return access.indexOf('VIS_GRUNNEIENDOM') > -1;
  if (/d\/bygning/.test(pathname)) return access.indexOf('VIS_BYGNING') > -1;
  if (/d\/eiendom/.test(pathname)) return access.indexOf('VIS_EIENDOM') > -1;
  if (/d\/admin\/brukere/.test(pathname))
    return access.indexOf('ADMINISTRER_BRUKER') > -1;
  if (/d\/admin\/virksomheter/.test(pathname))
    return access.indexOf('ADMINISTRER_ORGANISASJON') > -1;
  if (/d\/admin\/generer/.test(pathname))
    return access.indexOf('ADMINISTRER_RAPPORT') > -1;
  if (/d\/admin\/rapport/.test(pathname))
    return access.indexOf('ADMINISTRER_RAPPORT') > -1;
  if (/d\/admin\/kpi/.test(pathname))
    return access.indexOf('ADMINISTRER_KPI') > -1;
  if (/d\/admin\/auditlog/.test(pathname))
    return access.indexOf('VIS_AUDITLOG') > -1;
  if (/d\/admin\/etasje/.test(pathname))
    return access.indexOf('VIS_ETASJE') > -1;
  if (/d\/varsling/.test(pathname)) return access.indexOf('VIS_MELDING') > -1;
  if (/d\/nyttlokale/.test(pathname))
    return access.indexOf('ADMINISTRER_LOKALE') > -1;
  if (/d\/nyttleieforhold/.test(pathname))
    return access.indexOf('ADMINISTRER_LOKALE') > -1;
  if (/d\/lokale?/.test(pathname)) return access.indexOf('VIS_LOKALE') > -1;
  if (/d\/lediglokale?/.test(pathname))
    return access.indexOf('VIS_LOKALE') > -1;
  if (/d\/rapport?/.test(pathname)) return access.indexOf('VIS_RAPPORT') > -1;
  if (/d\/hjelp?/.test(pathname)) return true;
  if (/d\/prosjekt?/.test(pathname) || /d\/admin\/utvalg?/.test(pathname))
    return access.indexOf('ADMINISTRER_UTVALG') > -1;
  if (/d\/profil/.test(pathname)) return true;
  console.log('no match in access');
  return true;
};

export default ({ location, actions, setState }) => {
  if (location.hash) {
    const hashObj = extractHash(location.hash);
    setState({ processingHash: true, state: hashObj.state });

    if (hashObj.access_token) {
      localStorage.token = hashObj.access_token;

      console.log('dispatching get user from checkforHash');
      actions.getUser(hashObj.state);
    } else {
      console.log('invalid hash, removing from url');
      window.location = location.pathname;
    }
  }
};
