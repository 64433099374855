exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PowerBIButtonModal__modal___1_7OV {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  padding: 20px;\n}\n\n.PowerBIButtonModal__fullScreen___2VdNz {\n  width: 100%;\n  height: 100%;\n}\n\n.PowerBIButtonModal__buttonWrapper___2yNgz {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: end;\n      -ms-flex-pack: end;\n          justify-content: flex-end;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center\n}\n\n.PowerBIButtonModal__buttonWrapper___2yNgz > button:first-child {\n  margin-right: 10px;\n}\n", ""]);

// exports
exports.locals = {
	"modal": "PowerBIButtonModal__modal___1_7OV",
	"fullScreen": "PowerBIButtonModal__fullScreen___2VdNz",
	"buttonWrapper": "PowerBIButtonModal__buttonWrapper___2yNgz"
};