import React from 'react';
import PropTypes from 'prop-types';
import mapData, { apiFields } from 'utils/mapApiData';
import polyglot from 'i18n';
import styles from './RealestateNamebar.css';

const RealestateNamebar = ({ data, className }) => {
  const block = (navn, d) => (
    <div key={navn} className={styles.block}>
      <div className={styles.header}>{navn}</div>
      <div className={styles.text}>{d}</div>
    </div>
  );
  return (
    <div className={`${styles.wrapper} ${className}`}>
      {apiFields.realestate.details.map(it =>
        block(polyglot.t(`realestate.${it}`), mapData(data, [it])),
      )}
      {block(polyglot.t('realestate.antallBygg'), data.antallBygg)}
    </div>
  );
};

RealestateNamebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  className: PropTypes.string,
};

export default RealestateNamebar;
