import React from 'react';
import PropTypes from 'prop-types';
import { Link, Icon } from 'components';
import styles from './InfoLink.css';

const InfoLink = ({ to, removable, children, handleRemove, ...props }) => {
  if (removable) {
    return (
      <div className={styles.infolink} {...props}>
        <div className={styles.linkWrapper}>
          <Link to={to} plain>
            {children}
          </Link>
        </div>
        <div className={styles.iconWrapper} onClick={handleRemove}>
          <Icon icon="close" spaced size="2" />
        </div>
      </div>
    );
  }
  return (
    <div className={styles.infolink} {...props}>
      <div className={styles.linkWrapper}>
        {
          <Link to={to} plain>
            {children}
          </Link>
        }
      </div>
    </div>
  );
};

InfoLink.propTypes = {
  removable: PropTypes.bool,
  to: PropTypes.string,
  handleRemove: PropTypes.func,
  children: PropTypes.any,
};

InfoLink.defaultProps = {
  removable: true,
};

export default InfoLink;
