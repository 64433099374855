import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button } from 'components';
import styles from './DashboardBadge.css';

const DashboardBadge = ({ title, number, to, className }) => {
  const button = to && (
    <Button secondaryButton className={styles.button} to={to}>
      {`Se ${title}`}
    </Button>
  );
  return (
    <section className={classnames(styles.wrapper, className)}>
      <h3 className={styles.heading}>{title}</h3>
      <p className={styles.number}>{number}</p>
      {button}
    </section>
  );
};

DashboardBadge.propTypes = {
  title: PropTypes.string.isRequired,
  number: PropTypes.number,
  to: PropTypes.string,
  className: PropTypes.string,
};

export default DashboardBadge;
