// Using ducks pattern
// Export action constants
export const SAVE_LEDIG_LOKALE = 'SAVE_LEDIG_LOKALE';
export const SAVE_LEDIG_LOKALE_SUCCESS = 'SAVE_LEDIG_LOKALE_SUCCESS';
export const SAVE_LEDIG_LOKALE_FAILED = 'SAVE_LEDIG_LOKALE_FAILED';

export const GET_LEDIG_LOKALE = 'GET_LEDIG_LOKALE';
export const GET_LEDIG_LOKALE_SUCCESS = 'GET_LEDIG_LOKALE_SUCCESS';
export const GET_LEDIG_LOKALE_FAILED = 'GET_LEDIG_LOKALE_FAILED';

// Export action creators
export const saveLedigLokale = payload => ({
  type: SAVE_LEDIG_LOKALE,
  path: '/ledigLokale',
  payload,
});
export const saveLedigLokaleSuccess = ledigLokale => ({
  type: SAVE_LEDIG_LOKALE_SUCCESS,
  ledigLokale,
});
export const saveLedigLokaleFailed = error => ({
  type: SAVE_LEDIG_LOKALE_FAILED,
  error,
});

export const getLedigLokale = id => ({
  type: GET_LEDIG_LOKALE,
  path: `/ledigLokale/${id}`,
});
export const getLedigLokaleSuccess = ledigLokale => ({
  type: GET_LEDIG_LOKALE_SUCCESS,
  ledigLokale,
});
export const getLedigLokaleFailed = error => ({
  type: GET_LEDIG_LOKALE_FAILED,
  error,
});

// Define initial state for this reducer
export const initialState = {
  loadingSaveLedigLokale: false,
  loadingGetLedigLokale: false,
  error: false,
};

// Export the reducer
export default function ledigeLokalerReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_LEDIG_LOKALE: {
      return Object.assign({}, state, {
        loadingSaveLedigLokale: true,
        saveLedigLokaleFailed: false,
      });
    }
    case SAVE_LEDIG_LOKALE_SUCCESS: {
      return Object.assign({}, state, {
        ledigLokale: action.ledigLokale,
        loadingSaveLedigLokale: false,
      });
    }
    case SAVE_LEDIG_LOKALE_FAILED: {
      return Object.assign({}, state, {
        error: true,
        payload: action.error,
        saveLedigLokaleFailed: true,
        loadingSaveLedigLokale: false,
      });
    }
    case GET_LEDIG_LOKALE: {
      return Object.assign({}, state, {
        ledigLokale: {},
        loadingGetLedigLokale: true,
      });
    }
    case GET_LEDIG_LOKALE_SUCCESS: {
      return Object.assign({}, state, {
        ledigLokale: action.ledigLokale,
        loadingGetLedigLokale: false,
      });
    }
    case GET_LEDIG_LOKALE_FAILED: {
      return Object.assign({}, state, {
        error: action.error,
        loadingGetLedigLokale: false,
      });
    }
    default:
      return state;
  }
}
