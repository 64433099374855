import React from 'react';
import { Icon } from 'components';
import {
  Arrow,
  Content,
  Provider,
  Root,
  Trigger,
} from '@radix-ui/react-tooltip';
import styles from '../../atoms/Tooltip/Tooltip.css';

const HelpToolTipButton = ({ ...props }) => (
  <Provider delayDuration={200} {...props}>
    <Root>
      <Trigger asChild>
        <span aria-label={props.title}>
          <Icon aria-hidden icon="help-alt" size="2" />
        </span>
      </Trigger>
      <Content className={styles.TooltipContent} side="right" align="center">
        {props.title}
        <Arrow width={11} height={5} />
      </Content>
    </Root>
  </Provider>
);

export default HelpToolTipButton;
