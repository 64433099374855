import React from 'react';
import PropTypes from 'prop-types';
import { Provider, Root, Trigger, Content } from '@radix-ui/react-tooltip';
import styles from './Tooltip.css';

const Tooltip = ({ children, ...props }) => (
  <Provider delayDuration={200}>
    <Root>
      <Trigger asChild>{children}</Trigger>
      <Content
        className={styles.TooltipContent}
        side="top"
        align="center"
        {...props}
      >
        {props.title}
      </Content>
    </Root>
  </Provider>
);
Tooltip.propTypes = {
  children: PropTypes.node,
};

export default Tooltip;
