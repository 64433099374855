export const GET_REPORT_TEXT = 'GET_REPORT_TEXT';
export const RECEIVED_REPORT_TEXT = 'RECEIVED_REPORT_TEXT';
export const GET_REPORT_TEXT_ERROR = 'GET_REPORT_TEXT_ERROR';

export const SET_REPORT_TEXT = 'SET_REPORT_TEXT';
export const SET_TEXT_OK = 'SET_TEXT_OK';
export const SET_TEXT_FAIL = 'SET_TEXT_FAIL';

export const PREVIEW_REPORT = 'PREVIEW_REPORT';
export const RECEIVED_REPORT = 'RECEIVED_REPORT';
export const REPORT_ERROR = 'REPORT_ERROR';

export const PUBLISH_REPORT = 'PUBLISH_REPORT';
export const PUBLISH_OK = 'PUBLISH_OK';
export const PUBLISH_FAILED = 'PUBLISH_FAILED';

export const GET_LATEST = 'GET_LATEST';
export const RECEIVED_LATEST = 'RECEIVED_LATEST';
export const LATEST_FAILED = 'LATEST_FAILED';

export const getReportText = () => ({
  type: GET_REPORT_TEXT,
  path: '/vedleggsrapport/tekst',
});
export const receivedReportText = response => ({
  type: RECEIVED_REPORT_TEXT,
  response,
});
export const getReportTextError = error => ({
  type: GET_REPORT_TEXT_ERROR,
  error,
});

export const setReportText = payload => ({
  type: SET_REPORT_TEXT,
  path: '/vedleggsrapport/tekst',
  payload,
});
export const setTextOk = response => ({ type: SET_TEXT_OK, response });
export const setTextFail = error => ({ type: SET_TEXT_FAIL, error });

export const previewReport = () => ({
  type: PREVIEW_REPORT,
  path: '/vedleggsrapport/preview',
});
export const receivedReport = report => ({ type: RECEIVED_REPORT, report });
export const reportError = error => ({ type: REPORT_ERROR, error });

export const publishReport = () => ({
  type: PUBLISH_REPORT,
  path: '/vedleggsrapport/publish',
});
export const publishOk = response => ({ type: PUBLISH_OK, response });
export const publishFailed = error => ({ type: PUBLISH_FAILED, error });

export const getLatest = () => ({
  type: GET_LATEST,
  path: '/vedleggsrapport/latest',
});
export const receivedLatest = response => ({ type: RECEIVED_LATEST, response });
export const latestFailed = error => ({ type: LATEST_FAILED, error });

const initialState = {
  reportText: {
    innledning: '',
    kapittel: '',
  },
  previewLink: '',
  latestReport: { filepath: '', timestamp: '' },
  waitingForText: false,
  waitingForSetText: false,
  waitingForReport: false,
  waitingForLatest: false,
  error: false,
  waitingForPublish: false,
  publishOk: false,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case GET_REPORT_TEXT: {
      return Object.assign({}, state, {
        waitingForText: true,
        publishOk: false,
        setTextOk: false,
        error: '',
      });
    }
    case RECEIVED_REPORT_TEXT: {
      return Object.assign({}, state, {
        waitingForText: false,
        reportText: action.response,
      });
    }
    case GET_REPORT_TEXT_ERROR: {
      return Object.assign({}, state, {
        error: action.error,
      });
    }
    case SET_REPORT_TEXT: {
      return Object.assign({}, state, {
        waitingForSetText: true,
        setTextOk: false,
      });
    }
    case SET_TEXT_OK: {
      return Object.assign({}, state, {
        waitingForSetText: false,
        setTextOk: true,
      });
    }
    case SET_TEXT_FAIL: {
      return Object.assign({}, state, {
        error: action.error,
        setTextOk: false,
      });
    }
    case PREVIEW_REPORT: {
      return Object.assign({}, state, {
        waitingForReport: true,
        previewLink: '',
        publishOk: false,
      });
    }
    case RECEIVED_REPORT: {
      return Object.assign({}, state, {
        waitingForReport: false,
        previewLink: action.report,
      });
    }
    case REPORT_ERROR: {
      return Object.assign({}, state, {
        waitingForReport: false,
        error: action.error,
      });
    }
    case PUBLISH_REPORT: {
      return Object.assign({}, state, {
        waitingForPublish: true,
        publishOk: '',
        error: '',
      });
    }
    case PUBLISH_OK: {
      return Object.assign({}, state, {
        waitingForPublish: false,
        publishOk: action.response,
      });
    }
    case PUBLISH_FAILED: {
      return Object.assign({}, state, {
        waitingForPublish: false,
        error: action.error,
      });
    }
    case GET_LATEST: {
      return Object.assign({}, state, {
        waitingForLatest: true,
        latestReport: initialState.latestReport,
      });
    }
    case RECEIVED_LATEST: {
      return Object.assign({}, state, {
        waitingForLatest: false,
        latestReport: action.response,
      });
    }
    case LATEST_FAILED: {
      return Object.assign({}, state, {
        waitingForLatest: false,
        error: action.error,
      });
    }
    default:
      return state;
  }
}
