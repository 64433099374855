// @flow

import React, { Fragment } from 'react';
import GenericList from 'containers/GenericList';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import polyglot from 'i18n';

import { TableTemplate, Button, Icon } from 'components';

import * as searchActions from 'redux/modules/search/search';
import * as tableActions from 'redux/modules/search/table';
import searchSelector from 'redux/modules/search/searchSelector';
import userSelector from 'redux/modules/user/userSelector';

type Props = {
  userData: {
    tilganger: [],
  },
  data: {
    result: [],
  },
};

const LedigeLokalerList = reduxProps => (
  <GenericList
    type="ledigeLokaler"
    {...reduxProps}
    render={(props: Props) => (
      <TableTemplate
        {...props}
        enableSearch
        data={{
          ...props.data,
          result: props.data.result,
        }}
        mapListButtons={
          <Fragment>
            <Button>
              <Icon icon="list" size="1" spaced />
              {polyglot.t('ledigeLokaler.table')}
            </Button>
            {/* <Button> TODO: Enable when maps are ready
              <Icon icon="map" size="1" spaced />
              {polyglot.t('ledigeLokaler.map')}
            </Button> */}
          </Fragment>
        }
      />
    )}
  />
);

function mapStateToProps(state) {
  return {
    data: searchSelector('ledigeLokaler')(state),
    loading: state.search.loading,
    error: state.search.error,
    userData: userSelector(state),
    table: state.table.ledigeLokalerTable,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...searchActions, ...tableActions },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LedigeLokalerList);
