exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".EditFloor__wrapper___31_QT {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  padding: 40px;\n  -webkit-box-align: end;\n      -ms-flex-align: end;\n          align-items: flex-end\n}\n.EditFloor__wrapper___31_QT > * {\n  margin: 0 10px;\n}\n", ""]);

// exports
exports.locals = {
	"wrapper": "EditFloor__wrapper___31_QT"
};