import React from 'react';
import PropTypes from 'prop-types';
import { Hero } from 'components';
import styles from './FrontPageTemplate.css';

const FrontPageTemplate = ({ children, footer, forside }) => {
  const backgroundStyle = {
    backgroundImage: `url(${window.location.origin}${encodeURI(
      forside.bilde,
    )})`,
  };
  return (
    <div
      className={styles.backgroundWrapper}
      style={forside.bilde ? backgroundStyle : {}}
    >
      <section className={styles.contentWrapper}>
        <Hero {...forside} />
        <section className={styles.content}>{children}</section>
        <section className={styles.footer}>{footer}</section>
      </section>
    </div>
  );
};

FrontPageTemplate.propTypes = {
  forside: PropTypes.shape({
    bilde: PropTypes.string,
  }).isRequired,
  children: PropTypes.node,
  footer: PropTypes.node,
};

export default FrontPageTemplate;
