import React from 'react';
import PropTypes from 'prop-types';
import { Button, DateTimePicker, SelectField } from 'components';
import polyglot from 'i18n';
import styles from './FilterBox.css';

const FilterBox = ({
  handleFilterChange,
  fylkeFilter,
  kommuneFilter,
  organisasjonFilter,
  departementFilter,
  objektTypeFilter,
  rapporteringStatusFilter,
  ledigeLokalerFraDatoFilter,
  ledigeLokalerTilDatoFilter,
  onSubmit,
  isGlobal,
  type,
  filters: { visMine },
  visFylkeFilter,
  auditLog,
}) => {
  const changeHandler = field => e =>
    handleFilterChange({
      field,
      value: e != null ? e.value : '',
    });
  const depFilter = (
    <span className={styles.selectWrapper}>
      <SelectField
        label={polyglot.t('searchForm.departement')}
        name="departementFilter"
        placeholder={polyglot.t('searchForm.selectDepartement')}
        selectedValue={departementFilter}
        matchProp="label"
        optionUrl="/organisasjon/dep"
        onChange={changeHandler('departement')}
        isClearable
      />
    </span>
  );
  const virkFilter = (
    <span className={`${styles.selectWrapper} ${styles.divide}`}>
      <SelectField
        label={polyglot.t('searchForm.organisasjon')}
        name="organisasjonFilter"
        placeholder={polyglot.t('searchForm.selectOrganisasjon')}
        selectedValue={organisasjonFilter}
        matchProp="label"
        optionUrl={
          departementFilter
            ? `/organisasjon/dep/${departementFilter}`
            : '/organisasjon/filter'
        }
        onChange={changeHandler('organisasjon')}
        isClearable
      />
    </span>
  );

  const fylkesFilter = (
    <span className={styles.selectWrapper}>
      <SelectField
        label={polyglot.t('searchForm.fylke')}
        name="fylkeFilter"
        placeholder={polyglot.t('searchForm.selectFylke')}
        selectedValue={fylkeFilter}
        optionUrl="/fylker"
        onChange={changeHandler('fylke')}
        isClearable
      />
    </span>
  );

  const kommuneFilterSelect = (
    <span className={styles.selectWrapper}>
      <SelectField
        label={polyglot.t('searchForm.kommune')}
        name="kommuneFilter"
        placeholder={polyglot.t('searchForm.selectKommune')}
        selectedValue={kommuneFilter}
        optionUrl={fylkeFilter ? `/kommuner/fylke/${fylkeFilter}` : '/kommuner'}
        onChange={changeHandler('kommune')}
        isClearable
      />
    </span>
  );

  const statusValg = [
    { id: 'red', navn: polyglot.t('kpi.color.red') },
    { id: 'green', navn: polyglot.t('kpi.color.green') },
    { id: 'orange', navn: polyglot.t('kpi.color.orange') },
    { id: 'blue', navn: polyglot.t('kpi.color.blue') },
    { id: 'white', navn: polyglot.t('kpi.color.white') },
  ];

  const oppdateringStatusFilterSelect =
    Array.isArray(statusValg) && statusValg.length > 0 ? (
      <span className={styles.selectWrapper}>
        <SelectField
          label={polyglot.t('searchForm.rapporteringStatus')}
          name="rapporteringStatusFilter"
          choices={statusValg}
          placeholder={polyglot.t('searchForm.rapporteringStatusPlaceholder')}
          selectedValue={rapporteringStatusFilter}
          onChange={changeHandler('rapporteringStatus')}
          isClearable
        />
      </span>
    ) : (
      ''
    );

  const ledigeLokalerFraDatoFilterSelect = (
    <span className={styles.selectWrapper}>
      <DateTimePicker
        label={polyglot.t('searchForm.ledigeLokalerFraDato')}
        name="ledigeLokalerFraDatoFilter"
        placeholder={polyglot.t('searchForm.ledigeLokalerFraDato')}
        value={ledigeLokalerFraDatoFilter}
        onChange={changeHandler('ledigeLokalerFraDato')}
      />
    </span>
  );
  const ledigeLokalerTilDatoFilterSelect = (
    <span className={styles.selectWrapper}>
      <DateTimePicker
        label={polyglot.t('searchForm.ledigeLokalerTilDato')}
        name="ledigeLokalerTilDatoFilter"
        value={ledigeLokalerTilDatoFilter}
        placeholder={polyglot.t('searchForm.ledigeLokalerTilDato')}
        onChange={changeHandler('ledigeLokalerTilDato')}
      />
    </span>
  );

  const objectTypes = [
    'Bygning',
    'Eiendom',
    'Etasje',
    'Grunneiendom',
    'Leieforhold',
    'Leieobjekt',
    'Lokale',
    'Organisasjon',
    'Rapport',
  ];

  const objektTypeFilterSelect = (
    <span className={styles.selectWrapper}>
      <SelectField
        label={polyglot.t('searchForm.objektType')}
        name="objektTypeFilter"
        placeholder={polyglot.t('searchForm.choose')}
        selectedValue={objektTypeFilter || undefined}
        choices={objectTypes.map(it => ({
          navn: it,
          id: it,
        }))}
        onChange={e =>
          handleFilterChange({
            field: 'objektType',
            value: e ? e.label : undefined,
          })
        }
      />
    </span>
  );

  return (
    <div className={styles.wrapper}>
      {auditLog ? (
        <div className={styles.subWrapper}>{objektTypeFilterSelect}</div>
      ) : (
        <React.Fragment>
          <div className={styles.subWrapper}>
            {(type === 'globalSearch' || isGlobal || !visMine.value) &&
              depFilter}
            {virkFilter}
          </div>
          <div className={styles.subWrapper}>
            {visFylkeFilter && fylkesFilter}
            {visFylkeFilter && kommuneFilterSelect}
            {type === 'leideLokaler' && oppdateringStatusFilterSelect}
            {type === 'ledigeLokaler' && ledigeLokalerFraDatoFilterSelect}
            {type === 'ledigeLokaler' && ledigeLokalerTilDatoFilterSelect}
          </div>
        </React.Fragment>
      )}
      <Button className={styles.btn} onClick={onSubmit} kind="primary">
        Anvend
      </Button>
    </div>
  );
};

FilterBox.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  kommuneFilter: PropTypes.string,
  fylkeFilter: PropTypes.string,
  objektTypeFilter: PropTypes.string,
  organisasjonFilter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  departementFilter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ledigeLokalerFraDatoFilter: PropTypes.string,
  ledigeLokalerTilDatoFilter: PropTypes.string,
  isAktiv: PropTypes.bool,
  aktivFilter: PropTypes.string,
  harLedigeLokaler: PropTypes.bool,
  rapporteringStatusFilter: PropTypes.string,
  aktivValg: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, navn: PropTypes.string }),
  ),
  isGlobal: PropTypes.bool,
  isSysAdm: PropTypes.bool,
  type: PropTypes.string,
  filters: PropTypes.shape({ visMine: PropTypes.object }),
  handleFilterChange: PropTypes.func.isRequired,
  auditLog: PropTypes.bool.isRequired,
  visFylkeFilter: PropTypes.bool,
};

export default FilterBox;
