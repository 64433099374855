import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Label } from 'components';
import { Thumb, Root } from '@radix-ui/react-switch';

import styles from './Toggle.css';

const Toggle = ({
  helpToolTipButton,
  label,
  feedback,
  validationState,
  id,
  value,
  vertical,
  ...props
}) => (
  <div className={classnames(styles.wrapper, vertical && styles.vertical)}>
    {label && (
      <Label htmlFor={id} id={`${id}_label`}>
        {label} {helpToolTipButton}
      </Label>
    )}
    <Root
      id={id}
      className={`${styles.SwitchRoot} ${
        props.disabled ? styles.disabled : ''
      }`}
      onClick={() => {
        props.onChange({ target: { name: props.name } });
      }}
      aria-labelledby={`${id}_label`}
      {...props}
    >
      <Thumb className={styles.SwitchThumb} checked={value || false} />
    </Root>
  </div>
);

Toggle.propTypes = {
  helpToolTipButton: PropTypes.node,
  value: PropTypes.bool,
  label: PropTypes.string,
  feedback: PropTypes.bool,
  vertical: PropTypes.bool,
  validationState: PropTypes.string,
  id: PropTypes.string,
};

export default Toggle;
