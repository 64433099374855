exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".DataList__wrapper___2q_uW > div {\n  border-bottom: 1px solid #E3E3E3;\n  height: 50px;\n}\n\n.DataList__wrapper___2q_uW > div > * {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n}\n\n.DataList__wrapper___2q_uW {\n  padding: 10px;\n}\n", ""]);

// exports
exports.locals = {
	"wrapper": "DataList__wrapper___2q_uW"
};