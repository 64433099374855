// @flow

import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as selectionActions from 'redux/modules/selections/selection';
import * as tableActions from 'redux/modules/search/table';
import { TableTemplate } from 'components';
import selectionSelector from 'redux/modules/selections/selectionSelector';
import { RouterHistory, UserData } from 'types';
import userSelector from 'redux/modules/user/userSelector';

type Props = {
  actions: {
    getSelections(): void,
  },
  history: RouterHistory,
  selections: [],
  userData: UserData,
};

class SelectionList extends PureComponent<Props> {
  state = {
    table: {
      page: 0,
      shown: 10,
      sortCol: 0,
      sortAsc: false,
    },
  };

  componentDidMount() {
    const { actions } = this.props;
    actions.getSelections();
  }

  handleTableClick = e => {
    const { history, selections } = this.props;
    const {
      table: { page, shown },
    } = this.state;
    history.push(`/d/sok/${selections[e + page * shown].id}`);
  };

  changePage = e => {
    this.setState({
      table: {
        ...this.state.table,
        page: e,
      },
    });
  };
  changeShown = e => {
    this.setState({
      table: {
        ...this.state.table,
        shown: e,
      },
    });
  };

  sortClick = () => {};

  render() {
    const { selections, userData } = this.props;
    return (
      <TableTemplate
        {...this.state}
        type="selection"
        data={{
          result: selections.map(selection => selection.tableRow || []),
          hits: selections.length,
        }}
        changePage={this.changePage}
        changeShown={this.changeShown}
        userData={userData}
        sortClick={this.sortClick}
        handleTableClick={this.handleTableClick}
      />
    );
  }
}

SelectionList.propTypes = {};

function mapStateToProps(state) {
  return {
    selections: selectionSelector(state),
    userData: userSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...tableActions, ...selectionActions },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectionList);
