exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".LeieObjektInput__newLeieObject___3ya4I {\n  border: 1px solid #e3e3e3;\n  padding: 10px;\n  background-color: #fff;\n  max-width: 100%;\n  overflow: scroll;\n}\n\n.LeieObjektInput__propertyLine___fmdtw {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n}\n\n.LeieObjektInput__propertyLine___fmdtw > div {\n  padding: 5px 10px;\n}\n\n.LeieObjektInput__propertyLine___fmdtw > button {\n  margin: 5px 0;\n}\n\n.LeieObjektInput__buttonWrapper___1XQA5 {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n}\n\n.LeieObjektInput__buttonWrapper___1XQA5 > button {\n  margin: 5px;\n}\n", ""]);

// exports
exports.locals = {
	"newLeieObject": "LeieObjektInput__newLeieObject___3ya4I",
	"propertyLine": "LeieObjektInput__propertyLine___fmdtw",
	"buttonWrapper": "LeieObjektInput__buttonWrapper___1XQA5"
};