import React from 'react';
import PropTypes from 'prop-types';
import { Table as BsTable } from 'react-bootstrap';
import classnames from 'classnames';
import { TableRow, TableHeader } from 'components';
import styles from './Table.css';

const Table = ({
  helpToolButton,
  headerRow,
  dataType,
  dataRows,
  bodyClick,
  sortClick,
  sortCol,
  sortDir,
  handleChecked,
  drilldown,
  caption,
  className,
  bold,
  striped,
  feedbackMargin,
  responsive,
  rightAlignArray,
  tooltipFunc,
  buttonProps,
  ...props
}) => {
  const dr = dataRows.map((it, i) => (
    <TableRow
      key={i}
      bold={bold && i === dataRows.length - 1}
      data={it}
      tooltip={tooltipFunc && tooltipFunc(i)}
      tabIndex={drilldown ? 0 : undefined}
      handleChecked={e => handleChecked(e, i)}
      dataType={dataType}
      drilldown={drilldown}
      bodyClick={() => drilldown && bodyClick(i)}
      onKeyDown={e => {
        if (e.key === 'Enter' && drilldown) bodyClick(i);
      }}
      feedbackMargin={feedbackMargin}
      rightAlignArray={rightAlignArray}
    />
  ));

  return (
    <div
      className={classnames(
        styles.wrapper,
        responsive && styles.responsive,
        className,
      )}
    >
      <BsTable
        className={classnames(styles.table, striped && styles.striped)}
        hover={drilldown}
        {...props}
      >
        <caption className={styles.caption}>
          {caption}
          {sortClick && (
            <span className="sr-only">, Tabell kan sorteres per kolonne.</span>
          )}
        </caption>
        <thead className={styles.header}>
          <TableHeader
            sortClick={sortClick}
            header
            sortDir={sortDir}
            helpToolButton={helpToolButton}
            data={headerRow}
            sortCol={sortCol}
            rightAlignArray={rightAlignArray}
          />
        </thead>
        {
          <tbody className={!drilldown ? styles.noCursor : undefined}>
            {dr}
          </tbody>
        }
      </BsTable>
    </div>
  );
};

Table.propTypes = {
  headerRow: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ).isRequired,
  helpToolButton: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ),
  dataRows: PropTypes.arrayOf(PropTypes.array),
  dataType: PropTypes.string,
  sortCol: PropTypes.number,
  sortDir: PropTypes.string,
  handleChecked: PropTypes.func,
  caption: PropTypes.string,
  className: PropTypes.string,
  bodyClick: PropTypes.func,
  sortClick: PropTypes.func,
  tooltipFunc: PropTypes.func,
  drilldown: PropTypes.bool,
  bold: PropTypes.bool,
  striped: PropTypes.bool,
  feedbackMargin: PropTypes.bool,
  responsive: PropTypes.bool,
  rightAlignArray: PropTypes.arrayOf(PropTypes.oneOf([true, undefined])),
};

Table.defaultProps = {
  dataRows: [],
  bodyClick: () => {},
};

export default Table;
