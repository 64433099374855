exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../../node_modules/css-loader/index.js??ref--5-1!../../../../node_modules/postcss-loader/lib/index.js!../GlobalSearchFilter/GlobalSearchFilter.css"), undefined);

// module
exports.push([module.id, ".GlobalSearchLokaleFilters__doubleField___149A_ {\n}\n\n.GlobalSearchLokaleFilters__filterWrapper___3LSGx > * {\n  margin: 15px 0;\n}", ""]);

// exports
exports.locals = {
	"doubleField": "GlobalSearchLokaleFilters__doubleField___149A_ " + require("-!../../../../node_modules/css-loader/index.js??ref--5-1!../../../../node_modules/postcss-loader/lib/index.js!../GlobalSearchFilter/GlobalSearchFilter.css").locals["doubleField"] + "",
	"filterWrapper": "GlobalSearchLokaleFilters__filterWrapper___3LSGx"
};