exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* VIEWPORTS */\n\n:root {\n}\n\n.HelpContent__buttonWrapper___2kIoo {\n  margin-left: 10px;\n  margin-top: 10px;\n}\n\n.HelpContent__button___3YWy0 {\n  border: none;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  -webkit-box-pack: end;\n      -ms-flex-pack: end;\n          justify-content: flex-end;\n}\n\n.HelpContent__vimeo___3xyTq {\n  margin-top: 10px;\n}\n\n.HelpContent__wrapper___Qz0kh {\n  min-height: 400px;\n}\n\n.HelpContent__modalWrapper___3k6KL {\n  padding: 40px;\n  min-width: 600px;\n}\n\n@media (max-width: 800px) {\n\n  .HelpContent__modalWrapper___3k6KL {\n    min-width: 0;\n    min-width: initial;\n  }\n}\n\n.HelpContent__line___3NIFn {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n}\n", ""]);

// exports
exports.locals = {
	"buttonWrapper": "HelpContent__buttonWrapper___2kIoo",
	"button": "HelpContent__button___3YWy0",
	"vimeo": "HelpContent__vimeo___3xyTq",
	"wrapper": "HelpContent__wrapper___Qz0kh",
	"modalWrapper": "HelpContent__modalWrapper___3k6KL",
	"line": "HelpContent__line___3NIFn"
};