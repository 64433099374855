exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* FONTS */\n\n:root {\n}\n\n/* STATENS LOKALER COLOR PALETTE */\n\n:root {\n\n  /* colors for input fields */\n\n  /* Button colors */\n\n  /* Anchor colors */\n\n  /* STATSBYGG PRIMARY COLORS */\n\n  /* Packet colors */\n  /* Statsbygg Glassblå 01-03 */\n  /* SECONDARY COLORS - NEUTRAL */\n  /* Statsbygg Nøytral grå 01-02 */\n  /* Statsbygg Stein */\n  /* Statsbygg Jord */\n  /* TERTIARY COLORS - MARKER COLORS */\n  /* Statsbygg Sennep */\n  /* Statsbygg Multe */\n  /* Statsbygg Laks */\n  /* Statsbygg Lyng */\n}\n\n.Paragraph__paragraph___1QjfF {\n  color: #232f3a;\n  font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;\n  font-weight: 300;\n  font-size: 1rem;\n  line-height: 1.375rem;\n  margin: 0.2rem 0 0;\n  white-space: pre-wrap;\n}\n\n.Paragraph__ingress___MWEF7 {\n  font-size: 1.2rem;\n  line-height: 1.6875rem;\n  font-weight: normal;\n}\n\n.Paragraph__bold___1F9LU {\n  font-weight: 400;\n}\n\n.Paragraph__inline___1hNag {\n  display: inline;\n}\n\n.Paragraph__spaced___2XoWB {\n  margin-right: 20px;\n}\n\n.Paragraph__zeromargin___1ZY9T {\n  margin: 0px;\n}\n", ""]);

// exports
exports.locals = {
	"paragraph": "Paragraph__paragraph___1QjfF",
	"ingress": "Paragraph__ingress___MWEF7",
	"bold": "Paragraph__bold___1F9LU",
	"inline": "Paragraph__inline___1hNag",
	"spaced": "Paragraph__spaced___2XoWB",
	"zeromargin": "Paragraph__zeromargin___1ZY9T"
};