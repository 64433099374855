exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".MapLegend__wrapper___32C7T {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  padding: 10px;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center\n}\n.MapLegend__wrapper___32C7T > img {\n  margin-right: 10px;\n}\n", ""]);

// exports
exports.locals = {
	"wrapper": "MapLegend__wrapper___32C7T"
};