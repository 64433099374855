import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Heading, Label } from 'components';
import styles from './ResultItem.css';

const ResultItem = ({ header, itemArray, button, label }) => (
  <div className={styles.wrapper}>
    {label && <Label>{label}</Label>}
    <div className={classnames(styles.listHeader)}>
      <Heading level={2}>{header}</Heading>
      {itemArray.map((it, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <span key={i}>{it}</span>
      ))}
      {button}
    </div>
  </div>
);

ResultItem.propTypes = {
  header: PropTypes.string,
  itemArray: PropTypes.arrayOf(PropTypes.any).isRequired,
  button: PropTypes.element,
  label: PropTypes.string,
};

export default ResultItem;
