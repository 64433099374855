import React from 'react';
import PropTypes from 'prop-types';
import polyglot from 'i18n';
import styles from './HitCount.css';

const HitCount = ({ current, total }) => (
  <span className={styles.hitCount}>
    {total
      ? polyglot.t('hitcounter.showing') +
        current +
        polyglot.t('hitcounter.ofTotal') +
        total +
        polyglot.t('hitcounter.hits')
      : polyglot.t('hitcounter.noHits')}
  </span>
);

HitCount.propTypes = {
  current: PropTypes.string,
  total: PropTypes.number,
};

HitCount.defaultProps = {
  current: 0,
  total: 0,
};

export default HitCount;
