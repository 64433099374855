import React from 'react';
import PropTypes from 'prop-types';
import polyglot from 'i18n';
import { Logo, Link } from 'components';
import styles from './Footer.css';

const FrontPageFooter = () => (
  <div className={styles.frontPage}>
    <div className={styles.content}>
      {`${polyglot.t('footer.companyName')} -`}
      <Link href={`mailto:${polyglot.t('footer.email')}`}>
        {polyglot.t('footer.email')}
      </Link>
      <Link to="/personvern">{polyglot.t('footer.cookies')}</Link>
      <Link href="https://uustatus.no/nb/erklaringer/publisert/8adcc0d4-bb2a-4851-9a63-f88306d75026">
        {polyglot.t('footer.availability')}
      </Link>
    </div>
  </div>
);

const Footer = ({ frontpage, version, ...props }) => {
  if (frontpage) return <FrontPageFooter />;
  return (
    <div className={styles.footer} {...props}>
      <div className={styles.content}>
        {`${polyglot.t('footer.companyName')}`}
      </div>
      <div className={styles.content}>
        <div className={styles.statsbygg}>
          {polyglot.t('footer.deliveredBy')}
          <Logo
            footer
            target="_blank"
            href="http://www.statsbygg.no/Oppgaver/Statens-lokaler/"
          />
        </div>
      </div>

      <div className={styles.content}>
        <Link footer href={`mailto:${polyglot.t('footer.email')}`}>
          {polyglot.t('footer.email')}
        </Link>
        <Link footer to="/d/personvern">
          {polyglot.t('footer.cookies')}
        </Link>
        <Link
          footer
          href="https://uustatus.no/nb/erklaringer/publisert/8adcc0d4-bb2a-4851-9a63-f88306d75026"
        >
          {polyglot.t('footer.availability')}
        </Link>
      </div>
    </div>
  );
};

Footer.propTypes = {
  frontpage: PropTypes.bool,
  version: PropTypes.string,
};

export default Footer;
