import createAsyncRedux from '../../../utils/ReduxHelpers';

const projectRedux = createAsyncRedux({
  constantName: 'PROJECT',
  path: '/prosjekt',
  responseName: 'project',
  initialState: {
    project: {
      utvalg: [],
    },
  },
});

export const getProject = projectRedux.getWithId;
export const createProject = projectRedux.post;
export const lagreUtvalg = projectRedux.postWithId;
export const deleteUtvalg = projectRedux.deleteItem;
export const oppdaterProsjekt = projectRedux.putWithId;
export const deleteSelection = projectRedux.deleteItem;

export const projectReducer = projectRedux.reducer;

export const projectSaga = projectRedux.sagas;
