// @flow

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PeriodicReportingTemplate } from 'components';
import type { Lokale, BrowserHistory, Leieobjekt } from 'types';
import { extractHash } from 'utils/utils';

import * as lokalerActions from 'redux/modules/leideLokaler/leideLokaler';
import * as leieobjektActions from 'redux/modules/leideLokaler/leieobjekt';
import * as contractActions from 'redux/modules/leideLokaler/contracts';
import userSelector from 'redux/modules/user/userSelector';
import polyglot from 'i18n';

type Props = {
  lokale: Lokale,
  location: {
    search: string,
    pathname: string,
  },

  actions: {
    getLokale(number | void): void,
    setLokaleStatus(number, boolean): void,
    saveLeieobjekt(Leieobjekt[]): void,
    updateContractPeriod(number, {}): void,
    setLokalePeriode({}, number): void,
  },
  history: BrowserHistory,
  match: {
    params: {
      lokalerId?: number,
    },
  },

  loadingLeieobjekt: boolean,
  loadingSetLokalePeriode: boolean,
  savedCosts: boolean,
  leieobjektError: string,
  dates: {},
};

type State = {
  currentStep: number,
  currentLokale: number,
  costInput: {},
  areaInput: {},
  lokale: Lokale,
  employeeInput: {},
  leieobjekter: Leieobjekt[],
};

const getStatusColors = ({
  lokaleperiode,
  leieobjekter,
  aktiveLeieforhold,
}) => {
  const step2Color = leieobjekter.some(
    ({ leieobjektperiode = {}, kontor }) =>
      !leieobjektperiode.bruttoareal ||
      (!kontor && !leieobjektperiode.arbeidsrelatertareal),
  )
    ? 'orange'
    : '';
  const step3Color =
    !lokaleperiode.antallAarsverk || !lokaleperiode.antallAnsatte
      ? 'orange'
      : '';
  const step4Color =
    !lokaleperiode.lokalkostnader ||
    aktiveLeieforhold.some(
      ({ leieforholdperiodeNy = {} }) => !leieforholdperiodeNy.husleiekostnad,
    )
      ? 'orange'
      : '';
  return ['', step2Color, step3Color, step4Color, ''];
};

class PeriodicReportingFromSearch extends Component<Props, State> {
  state = {
    currentStep: 1,
    currentLokale: 0,
    areaInput: {},
    employeeInput: {},
    costInput: {},
    lokale: {},
    leieobjekter: [],
  };

  throwErrorIfLokaleErUtgaat = lokale => {
    if (
      lokale &&
      lokale.rapporteringStatusNy &&
      lokale.rapporteringStatusNy === 'blue'
    ) {
      throw Error(polyglot.t('error.utgaattLokale'));
    }
  };

  componentDidMount() {
    const {
      actions,
      match: {
        params: { lokalerId },
      },
    } = this.props;
    const lokaler = lokalerId.split(',');
    actions.getLokale(lokaler[0]);
  }

  componentDidUpdate(prevProps) {
    const {
      loadingLeieobjekt,
      lokale,
      history,
      leieobjektError,
      loadingSetLokalePeriode,
      employeeError,
      costError,
      savedCosts,
      match: {
        params: { lokalerId },
      },
      actions,
      dates,
    } = this.props;

    const { currentStep, currentLokale } = this.state;
    const next = this.getNext();
    const savedStep2 =
      !loadingLeieobjekt && prevProps.loadingLeieobjekt && !leieobjektError;

    const savedLokaleperiode =
      !loadingSetLokalePeriode &&
      prevProps.loadingSetLokalePeriode &&
      !employeeError;
    const savedStep3 = currentStep === 3 && savedLokaleperiode;
    const savedStep4 =
      currentStep === 4 &&
      savedCosts &&
      !costError &&
      !loadingSetLokalePeriode &&
      (savedLokaleperiode || !prevProps.savedCosts);

    if (lokalerId !== prevProps.match.params.lokalerId || next) {
      const lokaler = lokalerId.split(',');
      actions.getLokale(`${lokaler[currentLokale]}/${dates.periodestart}`);
      this.pushUrl(currentStep, currentLokale);
      this.handleCurrentLokale();
    }
    if (lokale.id && lokale.id !== prevProps.lokale.id) {
      if (!lokale.editable) {
        // TODO put this in auth renderprop?
        // console.log('no access, redirecting to dashboard');
        history.replace('/d/hjem'); // TODO add error message
      } else this.processLokale();
    } else if (savedStep2 || savedStep3 || savedStep4) {
      actions.getLokale(`${lokale.id}/${dates.periodestart}`);
      this.pushUrl(currentStep + 1, currentLokale);
    }
  }

  static getDerivedStateFromProps({ location }, prevState) {
    // TODO ta bort denne, url er master trenger ikke step i state
    let stepFromUrl = parseInt(extractHash(location.search).page, 10);
    if (!stepFromUrl) stepFromUrl = 1;
    if (prevState.currentStep !== stepFromUrl) {
      return { currentStep: stepFromUrl };
    }
    return null;
  }

  processLokale = () => {
    const { lokale } = this.props;
    // TODO use selector for this, remove lokale from state
    this.setState({
      lokale: {
        ...lokale,
        leieforholds: lokale.leieforholds.filter(
          it => it.aktiv && it.statusNy !== 'UTGAATT',
        ),
      },
      leieobjekter: lokale.leieforholds.reduce((acc, curr) => {
        const leieobj = curr.leieobjekter.filter(obj => obj.aktiv);
        if (curr.aktiv && curr.statusNy !== 'UTGAATT')
          return acc.concat(
            leieobj.map(filtered => ({
              ...filtered,
              leieforholdNavn:
                curr.status !== 'UTGAATT' ? curr.navn : <i> {curr.navn} </i>,
            })),
          );
        return acc;
      }, []),
    });
  };

  buttonClick = e => {
    const { history } = this.props;
    if (e.target.id === 'next') {
      this.handleNext();
    } else if (e.target.id === 'nextLokale') {
      this.handleNextLokale();
    } else if (e.target.id === 'prev') {
      this.pushUrl(this.state.currentStep - 1, this.state.currentLokale);
    } else if (e.target.id === 'cancel') {
      history.push(`/d/lokale/`);
    }
  };

  pushUrl = (page, currentLokale, next = false) => {
    const { history, location } = this.props;
    const path = `?page=${page}&lokale=${currentLokale}`;
    this.processLokale();
    history.push({
      pathname: location.pathname,
      search: next ? `${path}&next=true` : path,
    });
  };

  handleCount = () => {
    this.setState(prevState => ({
      currentLokale: prevState.currentLokale + 1,
    }));
  };

  handleCurrentLokale = () => {
    this.setState(prevState => {
      let lokaleFromUrl = parseInt(extractHash(location.search).lokale, 10);
      if (!lokaleFromUrl) lokaleFromUrl = 0;
      if (prevState.currentLokale !== lokaleFromUrl)
        return { currentLokale: lokaleFromUrl };
    });
  };

  getNext = () => extractHash(location.search).next;

  handleNext = () => {
    // TODO ta bort?
    const { currentStep, currentLokale } = this.state;
    const { lokale, history } = this.props;
    if (currentStep === 1) {
      this.pushUrl(currentStep + 1, currentLokale);
    } else if (currentStep === 5) {
      if (lokale.id) history.push(`/d/lokale/`);
    }
  };

  handleNextLokale = () => {
    const { currentStep, currentLokale } = this.state;
    const { lokale, actions } = this.props;
    if (currentStep === 5) {
      this.handleCount();
      actions.setLokaleStatus(lokale.id, true);
      this.pushUrl(currentStep - 4, currentLokale + 1, true);
    }
  };

  getSavedReport = () => {
    const { currentStep } = this.state;
    if (!localStorage.getItem('periodicReport')) {
      return null;
    }

    const report = JSON.parse(localStorage.getItem('periodicReport'));
    if (!report[currentStep] || report.id !== this.state.lokale.id) {
      return null;
    }
    console.log('Report: ', report[currentStep]);
    return report[currentStep];
  };

  savePeriodicReport = data => {
    const { currentStep } = this.state;
    const report =
      localStorage.getItem('periodicReport') === null
        ? {}
        : JSON.parse(localStorage.getItem('periodicReport'));
    report.currentStep = currentStep;
    report[currentStep] = data;
    report.id = this.props.lokale.id;
    console.log('Saving... ', report);

    localStorage.setItem('periodicReport', JSON.stringify(report));
  };

  render() {
    const {
      dates,
      loadingLeieobjekt,
      loadingSetLokalePeriode,
      actions,
    } = this.props;
    const {
      lokale: { lokaleperiode = {}, leieforholds = [] },
      leieobjekter,
    } = this.state;

    const aktiveLeieforhold = leieforholds.filter(it => it.aktiv);
    const colors = getStatusColors({
      lokaleperiode,
      leieobjekter,
      aktiveLeieforhold,
    });
    const ids = this.props.match.params.lokalerId;
    const idArray = ids.split(',');

    return (
      <div>
        <PeriodicReportingTemplate
          {...this.state}
          dates={dates}
          actions={actions}
          buttonClick={this.buttonClick}
          loading={loadingLeieobjekt || loadingSetLokalePeriode}
          colors={colors}
          aktiveLeieforhold={leieforholds}
          idArray={idArray}
          savePeriodicReport={this.savePeriodicReport}
          getSavedReport={this.getSavedReport}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    lokale: state.leideLokaler.lokaler,
    loadingLeieobjekt: state.leieobjekt.loadingSave,
    leieobjektError: state.leieobjekt.error,
    loadingSetLokalePeriode: state.leideLokaler.loadingSetLokalePeriode,
    employeeError: state.leideLokaler.error,
    savedCosts: state.contracts.updatedContract,
    costError: state.contracts.error,
    userData: userSelector(state),
    dates: state.leideLokaler.dates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...lokalerActions, ...leieobjektActions, ...contractActions },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PeriodicReportingFromSearch);
