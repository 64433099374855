// @flow

import React from 'react';
import GenericList from 'containers/GenericList';
import { TableTemplate, Button, Link, Icon } from 'components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as searchActions from 'redux/modules/search/search';
import * as tableActions from 'redux/modules/search/table';
import searchSelector from 'redux/modules/search/searchSelector';
import userSelector from 'redux/modules/user/userSelector';

type Props = {
  userData: {
    tilganger: [],
  },
  data: {
    result: [],
    idArray: [],
  },
};

const AdminReportList = reduxProps => (
  <GenericList
    type="adminRapporter"
    {...reduxProps}
    render={({ data, userData, ...props }: Props) => (
      <TableTemplate
        {...props}
        userData={userData}
        enableSearch
        data={{
          ...data,
          result: data.result.map((rowItem, i) =>
            rowItem.concat([
              <Link to={`/d/admin${data.idArray[i].id}`}>
                <Icon icon="pencil" size="1" spaced />
                {'Rediger'}
              </Link>,
            ]),
          ),
        }}
        newItemButton={
          userData.tilganger.indexOf('ADMINISTRER_LOKALE') > -1 && (
            <Button
              kind="primary"
              newItemButton
              to="/d/admin/rapport/nyrapport"
            >
              {'+ Lag ny rapport'}
            </Button>
          )
        }
      />
    )}
  />
);

function mapStateToProps(state) {
  return {
    data: searchSelector('adminRapporter')(state),
    loading: state.search.loading,
    error: state.search.error,
    userData: userSelector(state),
    table: state.table.adminRapportTable,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...searchActions, ...tableActions },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminReportList);
