// @flow

import * as React from 'react';
import PropTypes from 'prop-types';
import { Heading } from 'components';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import styles from './WizardStepsNew.css';

type Props = {
  boxLabel: string,
  labels: Array<number>,
  steps: number,
  currentStep: number,
  currentLokale: number,
};

const CircleWithLabel = ({
  dot,
  label,
  step,
  links,
  history,
  location,
  currentLokale,
  colors = [],
}) =>
  links ? (
    <button
      key={label}
      type="button"
      className={styles.dotWrap}
      onClick={() =>
        step && currentLokale === undefined
          ? history.push({
              pathname: location.pathname,
              search: `?page=${step}`,
            })
          : history.push({
              pathname: location.pathname,
              search: `?page=${step}&lokale=${currentLokale}`,
            })
      }
    >
      <div className={classnames(styles[dot], styles[colors[step - 1]])} />
      <span className={styles.label}>{label}</span>
    </button>
  ) : (
    <div key={label} className={styles.dotWrap}>
      <div className={classnames(styles[dot], styles[colors[step - 1]])} />
      <span className={styles.label}>{label}</span>
    </div>
  );

CircleWithLabel.propTypes = {
  dot: PropTypes.string,
  label: PropTypes.string,
  step: PropTypes.number,
  links: PropTypes.bool,
  history: PropTypes.shape({ push: PropTypes.func }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  colors: PropTypes.array,
};

const WizardStepsNew = ({
  boxLabel,
  labels,
  steps,
  currentStep,
  ...props
}: Props) => {
  const activeLine = <div className={styles.activeLine} />;
  const inactiveLine = <div className={styles.inactiveLine} />;

  const dotArray = Array.from(Array(steps).keys())
    .filter(i => labels[i] !== null)
    .map(i => {
      const step = i + 1;
      const addLine = i > 0;
      if (step === currentStep) {
        return (
          <React.Fragment key={step}>
            {addLine && activeLine}
            <CircleWithLabel
              dot="activeDot"
              label={labels[i]}
              step={step}
              {...props}
            />
          </React.Fragment>
        );
      }
      if (step < currentStep) {
        return (
          <React.Fragment key={step}>
            {addLine && activeLine}
            <CircleWithLabel
              dot="finishedDot"
              label={labels[i]}
              step={step}
              {...props}
            />
          </React.Fragment>
        );
      }
      return (
        <React.Fragment key={step}>
          {addLine && inactiveLine}{' '}
          <CircleWithLabel
            dot="notStartedDot"
            label={labels[i]}
            step={step}
            {...props}
          />
        </React.Fragment>
      );
    });

  return (
    <div className={styles.boxWrapper}>
      <Heading level={3} green bottomMargin className={styles.boxLabel}>
        {boxLabel}
      </Heading>
      <div className={styles.line}>{dotArray}</div>
    </div>
  );
};

WizardStepsNew.defaultProps = {
  labels: [],
};

export default withRouter(WizardStepsNew);
