exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* VIEWPORTS */\n\n:root {\n}\n\n.ResultItem__listHeader___2_xLZ {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  width: 100%;\n  min-height: 45px;\n  border: 1px solid rgba(72, 101, 111, .15);\n  margin-bottom: 10px;\n  padding: 0 10px\n}\n\n.ResultItem__listHeader___2_xLZ > span,\n  .ResultItem__listHeader___2_xLZ > button,\n  .ResultItem__listHeader___2_xLZ > a {\n  margin-left: auto;\n}\n\n.ResultItem__listHeader___2_xLZ > span {\n  min-width: 160px;\n}\n\n@media (max-width: 1367px) {\n\n  .ResultItem__listHeader___2_xLZ > span {\n    min-width: 120px\n  }\n}\n\n.ResultItem__listHeader___2_xLZ > h4 {\n  min-width: 200px;\n  max-width: 200px;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n@media (max-width: 1367px) {\n\n  .ResultItem__listHeader___2_xLZ > h4 {\n    min-width: 160px\n  }\n}\n\n.ResultItem__listHeader___2_xLZ > h4:hover, .ResultItem__listHeader___2_xLZ > h4:focus {\n  white-space: normal;\n  overflow: visible;\n}\n\n.ResultItem__header___2ZlW4 {\n  font-weight: bold;\n  white-space: wrap;\n}\n", ""]);

// exports
exports.locals = {
	"listHeader": "ResultItem__listHeader___2_xLZ",
	"header": "ResultItem__header___2ZlW4"
};