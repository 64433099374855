import React from 'react';
import PropTypes from 'prop-types';
import polyglot from 'i18n';
import classnames from 'classnames';
import { Icon, Button, Heading } from 'components';
import styles from './RealestateDataRow.css';

const RealestateDataRow = ({ data, buildings, link, open, onClick }) => {
  const expand = (
    <Button
      borderless
      className={styles.collapseButton}
      disabled={!buildings}
      onClick={onClick}
      aria-hidden={!buildings}
      aria-expanded={open}
      aria-label={
        data ? polyglot.t('realestate.visBygningFor') + data[0] : undefined
      }
    >
      {buildings && <Icon size="3" icon={open ? 'angle-up' : 'angle-down'} />}
    </Button>
  );

  const linkButton = link && (
    <Button secondaryButton className={styles.link} to={link}>
      {polyglot.t('realestate.linkButton')}
    </Button>
  );

  const name = Array.isArray(data) && (
    <Heading level={1} className={styles.navn}>
      {data[0]}
    </Heading>
  );

  const matrikkelIdent = Array.isArray(data) && (
    <div className={classnames(styles.hideWhenSmall)}>
      <div className={styles.listHeader}>
        {polyglot.t('property.matrikkelident')}
      </div>
      <div className={styles.data}>{data[1]}</div>
    </div>
  );

  const address = Array.isArray(data) && (
    <div className={classnames(styles.hideWhenSmall)}>
      <div className={styles.listHeader}>
        {polyglot.t('property.adresse.adressenavn')}
      </div>
      <div className={styles.data}>{data[2]}</div>
    </div>
  );

  const code = Array.isArray(data) && (
    <div className={classnames(styles.hideWhenSmall, styles.ownerCode)}>
      <div className={styles.listHeader}>
        {polyglot.t('property.eierforholdKode')}
      </div>
      <div className={styles.data}>{data[3]}</div>
    </div>
  );

  const propertyLink = <div className={styles.link}>{linkButton}</div>;

  return (
    <div className={styles.wrapper}>
      {expand}
      {name}
      {matrikkelIdent}
      {address}
      {code}
      {propertyLink}
    </div>
  );
};

RealestateDataRow.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string),
  buildings: PropTypes.bool,
  link: PropTypes.string,
  open: PropTypes.bool,
  onClick: PropTypes.func,
};

export default RealestateDataRow;
