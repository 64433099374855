import { createSelector } from 'reselect';
import { localize, sortData } from 'utils/utils';

const getStatsFromState = state => state.search.stats;
const getTableFromState = state => state.table.kpiTable;
export const getRapportMetaFromState = state => state.search.rapportMeta;

export default createSelector(
  getStatsFromState,
  getTableFromState,
  (stats, { sortCol, sortAsc }) => {
    const tables = {
      officeTable: [],
      nonOfficeTable: [],
      costTable: [],
      employeeTable: [],
    };
    if (stats.lokale && stats.lokale.stats)
      stats.lokale.stats.forEach(
        ({
          name,
          global,
          value,
          kpi: {
            ansatte,
            kontoransatte,
            ikkekontoransatte,
            totalKontorareal,
            totalIkkeKontorareal,
            arealPerAnsatt,
            arbeidsrelatertarealPerAnsatt,
            totalkostnad,
            kostnaderPerKvm,
            aarsverk,
            kostnaderPerAarsverk,
          },
        }) => {
          if (
            totalKontorareal &&
            parseInt(totalKontorareal.value, 10) > 0 &&
            global !== 0
          ) {
            tables.officeTable.push({
              name,
              global,
              value,
              table: [
                name,
                localize(kontoransatte.value),
                localize(totalKontorareal.value),
                localize(arealPerAnsatt.value),
              ],
            });
          }
          if (
            totalIkkeKontorareal &&
            parseInt(totalIkkeKontorareal.value, 10) > 0 &&
            global !== 1
          ) {
            tables.nonOfficeTable.push({
              name,
              global,
              value,
              table: [
                name,
                localize(ikkekontoransatte.value),
                localize(totalIkkeKontorareal.value),
                localize(arbeidsrelatertarealPerAnsatt.value),
              ],
            });
          }
          if (
            (totalkostnad && parseInt(totalkostnad.value, 10) > 0) ||
            (kostnaderPerKvm && parseInt(kostnaderPerKvm.value, 10) > 0)
          ) {
            tables.costTable.push({
              name,
              global,
              value,
              table: [
                name,
                localize(totalkostnad.value),
                localize(kostnaderPerKvm.value),
              ],
            });
          }
          if (
            (aarsverk && parseInt(aarsverk.value, 10) > 0) ||
            (kostnaderPerAarsverk &&
              parseInt(kostnaderPerAarsverk.value, 10) > 0)
          ) {
            tables.employeeTable.push({
              name,
              global,
              value,
              table: [
                name,
                localize(ansatte.value),
                localize(aarsverk.value),
                localize(kostnaderPerAarsverk.value),
              ],
            });
          }
        },
      );
    Object.keys(tables).forEach(table => {
      if (tables[table].length > 0) sortData(tables[table], sortCol, sortAsc);
    });

    return {
      ...stats,
      lokale: {
        kpi: stats.lokale && stats.lokale.kpi,
        tables,
      },
    };
  },
);
