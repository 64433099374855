import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Select, Label } from 'components';
import { getDataItem } from 'services/api';
import styles from './SelectField.css';

class SelectField extends React.PureComponent {
  state = {
    choices: [],
    loading: false,
  };

  componentDidMount() {
    this.getChoices();
  }

  componentDidUpdate(prevProps, prevState) {
    const { optionUrl: prevUrl } = prevProps;
    const { optionUrl, choices } = this.props;
    if (
      prevState.choices.length > 0 &&
      choices.length > 0 &&
      JSON.stringify(this.mapChoices(choices)) !==
        JSON.stringify(this.mapChoices(prevState.choices))
    ) {
      this.getChoices();
    }
    if (optionUrl !== prevUrl) this.getChoices();
  }

  getChoices = async () => {
    const { optionUrl, choices } = this.props;
    let choiceArray = choices;
    if (optionUrl) {
      this.setState({ loading: true });
      choiceArray = await getDataItem(optionUrl);
    }
    this.setState({ loading: false, choices: this.mapChoices(choiceArray) });
  };

  mapChoices = arr => {
    const { labelFunc } = this.props;
    return arr
      .map(it => {
        if (!it) return undefined;
        if (typeof it === 'string') {
          return {
            value: it,
            label: labelFunc ? labelFunc(it) : it,
          };
        }
        return {
          value: it.id || it.uuid || it.value,
          label: it.navn || it.label,
        };
      })
      .filter(it => !!it);
  };

  render() {
    const { loading, choices } = this.state;
    const {
      required,
      label,
      labelFunc,
      className,
      horizontal,
      large,
      helpToolTipButton,
      onChange,
      ...props
    } = this.props;
    const id = label !== undefined ? label.replace(' ', '-') : '';

    return (
      <div
        className={classnames(
          styles.formGroup,
          horizontal && styles.horizontal,
          large && styles.large,
          className,
        )}
      >
        {label && (
          <Label htmlFor={props.name} id={id}>
            {label}
            {required && <span className={styles.required}>*</span>}
            {helpToolTipButton}
          </Label>
        )}
        <Select
          {...props}
          isLoading={loading}
          choices={choices}
          aria-labelledby={id}
          onChange={onChange}
        />
      </div>
    );
  }
}

SelectField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  choices: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ),
  onChange: PropTypes.func.isRequired,
  optionUrl: PropTypes.string,
  required: PropTypes.bool,
  horizontal: PropTypes.bool,
  large: PropTypes.bool,
  labelFunc: PropTypes.func,
  className: PropTypes.string,
};

SelectField.defaultProps = {
  choices: [],
};

export default SelectField;
