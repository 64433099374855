exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".LedigeLokalerListPanel__ledigeLokalerList___2MIGd {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  display: inline-grid;\n  grid-template-columns: 125px 125px 100px 1fr 1fr 100px 100px 1fr;\n  grid-template-rows: auto;\n  padding: 10px;\n  margin: 5px 0;\n  border: 1px solid #e3e3e3;\n}\n", ""]);

// exports
exports.locals = {
	"ledigeLokalerList": "LedigeLokalerListPanel__ledigeLokalerList___2MIGd"
};