// Export action constants

export const GET_PROPERTY = 'GET_PROPERTY';
export const GOT_PROPERTY = 'GOT_PROPERTY';
export const GET_PROPERTY_FAIL = 'GET_PROPERTY_FAIL';
export const SET_PROPERTY_ALIAS = 'SET_PROPERTY_ALIAS';
export const SET_PROPERTY_ALIAS_SUCCESS = 'SET_PROPERTY_ALIAS_SUCCESS';
export const SET_PROPERTY_ALIAS_FAIL = 'SET_PROPERTY_ALIAS_FAIL';
export const SET_PROPERTY_ADRESSE = 'SET_PROPERTY_ADRESSE';
export const SET_PROPERTY_ADRESSE_SUCCESS = 'SET_PROPERTY_ADRESSE_SUCCESS';
export const SET_PROPERTY_ADRESSE_FAIL = 'SET_PROPERTY_ADRESSE_FAIL';
// Export action creators

export const getProperty = id => ({
  type: GET_PROPERTY,
  path: `/grunneiendom/${id}`,
});
export const gotProperty = response => ({ type: GOT_PROPERTY, response });
export const getPropertyFail = (id, error) => ({
  type: GET_PROPERTY_FAIL,
  error,
});

export const setPropertyAlias = query => ({
  type: SET_PROPERTY_ALIAS,
  path: `/grunneiendom/${query.id}/navn`,
  payload: query,
});
export const setPropertyAliasSuccess = query => ({
  type: SET_PROPERTY_ALIAS_SUCCESS,
  query,
});
export const setPropertyAliasFail = (query, error) => ({
  type: SET_PROPERTY_ALIAS_FAIL,
  error,
});
export const setPropertyAdresse = query => ({
  type: SET_PROPERTY_ADRESSE,
  path: `/grunneiendom/${query.id}/valgtAdresse`,
  payload: query,
});
export const setPropertyAdresseSuccess = query => ({
  type: SET_PROPERTY_ADRESSE_SUCCESS,
  query,
});
export const setPropertyAdresseFail = (query, error) => ({
  type: SET_PROPERTY_ADRESSE_FAIL,
  error,
});

export const initialState = {
  property: {},
  loading: false,
  loadingAlias: false,
  error: false,
};

// Export the reducer
export default function propertyReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PROPERTY: {
      return Object.assign({}, state, {
        loading: true,
        property: {},
      });
    }
    case GOT_PROPERTY: {
      return Object.assign({}, state, {
        property: action.response,
        loading: false,
      });
    }
    case GET_PROPERTY_FAIL: {
      return Object.assign({}, state, {
        loading: false,
        error: action.error,
      });
    }
    case SET_PROPERTY_ALIAS: {
      return Object.assign({}, state, {
        loadingAlias: true,
        property: Object.assign({}, state.property, {
          navn: action.payload.value, // server just responds with true. Should get name in return
          visningsnavn: action.payload.value, // server just responds with true. Should get name in return
        }),
      });
    }
    case SET_PROPERTY_ALIAS_SUCCESS: {
      return Object.assign({}, state, {
        /*        property: Object.assign({}, state.property, {
                  navn: "loading", // server just responds with true. Should get name in return
                }), */
        loadingAlias: false,
      });
    }
    case SET_PROPERTY_ALIAS_FAIL: {
      return Object.assign({}, state, {
        loadingAlias: false,
        error: action.error,
      });
    }
    case SET_PROPERTY_ADRESSE: {
      return Object.assign({}, state, {
        loadingAlias: true,
        property: Object.assign({}, state.property, {
          id: action.payload.id,
          value: action.payload.value,
        }),
      });
    }
    case SET_PROPERTY_ADRESSE_SUCCESS: {
      return Object.assign({}, state, {
        loadingAlias: false,
      });
    }
    case SET_PROPERTY_ADRESSE_FAIL: {
      return Object.assign({}, state, {
        loadingAlias: false,
        error: action.error,
      });
    }
    default:
      return state;
  }
}
