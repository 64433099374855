exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* VIEWPORTS */\n\n:root {\n}\n\n.KpiTarget__wrapper___1B4ma {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  background-color: #f7f8fa;\n  height: 32px;\n  font-size: 14px;\n  margin-top: 14px;\n}\n\n.KpiTarget__list___rhusb {\n  background-color: inherit;\n  margin-top: 0;\n  -webkit-box-pack: start;\n      -ms-flex-pack: start;\n          justify-content: flex-start;\n}\n\n.KpiTarget__dot___38tqy {\n  height: 10px;\n  width: 10px;\n  border-radius: 50%;\n  margin-right: 10px;\n}\n\n.KpiTarget__green___1Ze6b {\n  background-color: #7ed321;\n}\n\n.KpiTarget__orange___2Vlm_ {\n  background-color: #f5a623;\n}\n\n.KpiTarget__red___2bpuK {\n  background-color: #d0021b;\n}\n\n.KpiTarget__blue___Wl73l {\n  background-color: #4471c4;\n}\n\n.KpiTarget__fullWidth___2bbg1 {\n  width: 100%;\n}\n\n.KpiTarget__alignLeft___2wAJi {\n  -webkit-box-pack: start;\n      -ms-flex-pack: start;\n          justify-content: flex-start;\n}\n\n.KpiTarget__sideMargins___3rSKr {\n  margin-left: 24px;\n  margin-right: 24px;\n}\n", ""]);

// exports
exports.locals = {
	"wrapper": "KpiTarget__wrapper___1B4ma",
	"list": "KpiTarget__list___rhusb",
	"dot": "KpiTarget__dot___38tqy",
	"green": "KpiTarget__green___1Ze6b KpiTarget__dot___38tqy",
	"orange": "KpiTarget__orange___2Vlm_ KpiTarget__dot___38tqy",
	"red": "KpiTarget__red___2bpuK KpiTarget__dot___38tqy",
	"blue": "KpiTarget__blue___Wl73l KpiTarget__dot___38tqy",
	"fullWidth": "KpiTarget__fullWidth___2bbg1",
	"alignLeft": "KpiTarget__alignLeft___2wAJi",
	"sideMargins": "KpiTarget__sideMargins___3rSKr"
};