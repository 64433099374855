import { combineReducers } from 'redux';
import createAsyncRedux from '../../../utils/ReduxHelpers';

const selectionsRedux = createAsyncRedux({
  constantName: 'UTVALG_ALL',
  path: '/utvalg',
  responseName: 'selections',
  initialState: {
    selections: [[]],
  },
});

const selectionRedux = createAsyncRedux({
  constantName: 'UTVALG',
  path: '/utvalg',
  responseName: 'selection',
  initialState: {
    selection: {},
  },
});

export const getSelections = selectionsRedux.get;
export const getSelection = selectionRedux.getWithId;
export const postSelection = selectionRedux.post;
export const deleteSelection = selectionRedux.deleteItem;

export const selectionsReducers = combineReducers({
  selection: selectionRedux.reducer,
  allSelections: selectionsRedux.reducer,
});

export const selectionsSaga = selectionsRedux.sagas;
export const selectionSaga = selectionRedux.sagas;
