import React from 'react';
import PropTypes from 'prop-types';
import { GenericModal, Field, DataItem, Table } from 'components';
import polyglot from 'i18n';
import { setDefault, validateNumber } from 'utils/utils';

const ContractCostInput = ({
  husleiekostnad,
  framleiereduksjon,
  leieforholdperiodeNy,
  leieforholdperioder,
  buttonClick,
  onCostChange,
  generateDateRangeMultiPeriod,
  showInput,
  ...props
}) => {
  const prevPeriods = Array.isArray(leieforholdperioder)
    ? leieforholdperioder.filter(
        all => all.id !== leieforholdperiodeNy.id || showInput,
      )
    : [];

  const alleFraDatoer = leieforholdperioder.map(
    leieforholdperiode => leieforholdperiode.fraDato,
  );

  const inputRow = firstRef => [
    <DataItem
      inputCost
      data={generateDateRangeMultiPeriod(
        leieforholdperiodeNy.fraDato,
        alleFraDatoer,
      )}
    />,
    <Field
      name="husleiekostnad"
      value={setDefault(husleiekostnad, leieforholdperiodeNy.husleiekostnad)}
      onChange={onCostChange}
      type="number"
      refFunc={firstRef}
      onKeyPress={e =>
        e.key === 'Enter' &&
        buttonClick({
          target: { id: 'saveCosts' },
        })
      }
    />,
    <Field
      name="framleiereduksjon"
      value={setDefault(
        framleiereduksjon,
        leieforholdperiodeNy.framleiereduksjon,
      )}
      onChange={onCostChange}
      type="number"
      onKeyPress={e =>
        e.key === 'Enter' &&
        buttonClick({
          target: { id: 'saveCosts' },
        })
      }
    />,
  ];

  const dataRows = firstRef => [
    leieforholdperiodeNy.fraDato && !showInput ? inputRow(firstRef) : [],
    ...prevPeriods
      .sort((a, b) => (a > b ? 1 : -1)) // Sort rows by date
      .map(it => [
        <DataItem
          inputCost
          data={generateDateRangeMultiPeriod(it.fraDato, alleFraDatoer)}
        />,
        it.husleiekostnad,
        it.framleiereduksjon,
      ]),
  ];

  return (
    <GenericModal
      renderInputs={({ firstRef }) => (
        <Table
          responsive
          headerRow={[
            polyglot.t('newContract.step6.kostnaderFor'),
            polyglot.t('newContract.step6.husleie'),
            polyglot.t('newContract.step6.framleie'),
          ]}
          dataRows={dataRows(firstRef)}
        />
      )}
      saveProps={{
        disabled:
          validateNumber(husleiekostnad) === 'error' ||
          validateNumber(framleiereduksjon) === 'error',
        id: 'saveCosts',
        onClick: buttonClick,
      }}
      showInput={showInput}
      {...props}
    />
  );
};

ContractCostInput.propTypes = {
  husleiekostnad: PropTypes.string,
  framleiereduksjon: PropTypes.string,
  generateDateRangeMultiPeriod: PropTypes.func,
  showInput: PropTypes.bool,
  leieforholdperiodeNy: PropTypes.shape({
    fraDato: PropTypes.string,
    id: PropTypes.number,
    framleiereduksjon: PropTypes.number,
  }),
  leieforholdperioder: PropTypes.arrayOf(PropTypes.object),
  buttonClick: PropTypes.func,
  onCostChange: PropTypes.func,
  firstRef: PropTypes.func,
  lastRef: PropTypes.func,
};

export default ContractCostInput;
