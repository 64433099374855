import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './Checkbox.css';

const Checkbox = ({ label = '', className, inverted, ...props }) => {
  const id = `${label.replace(' ', '_')}-checkbox`;
  return (
    <label
      htmlFor={id}
      className={classnames(
        props.disabled ? styles.disabledLabel : styles.label,
        inverted && styles.inverted,
        className,
      )}
    >
      {label}
      <input
        id={id}
        className={styles.input}
        type="checkbox"
        tabIndex={-1}
        {...props}
      />
      <div
        className={props.disabled ? styles.disabledFakeBox : styles.fakeBox}
      />
    </label>
  );
};

Checkbox.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  inverted: PropTypes.bool,
};

export default Checkbox;
