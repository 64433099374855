// @flow

import React from 'react';
import { Button, DateTimePicker, Field, HelpToolTipButton } from 'components';
import polyglot from 'i18n';
import styles from './ContractWizard.css';

type Props = {
  buttonClick(): {},
  onChange(): {},
  onDateChange(Date, string, string): {},
  step3: {
    startDateObj: Date,
    endDateObj: Date,
    internalNumber?: number,
    contractName: string,
  },
  step4: {
    selvforvaltende: boolean,
  },
};

const ContractWizardStep4 = ({
  buttonClick,
  onChange,
  onDateChange,
  step3,
  step4: { selvforvaltende },
}: Props) => {
  const helpToolTipButton = text => <HelpToolTipButton title={text} />;

  const validDates = () => !!(step3.startDateObj < step3.endDateObj);
  const isFormValid = () => {
    if (step3.contractName && validDates()) {
      return true;
    }
    if (step3.contractName && validDates()) {
      return true;
    }
    return false;
  };
  return (
    <div className={styles.wrapper}>
      <Field
        name="contractName"
        disabled={selvforvaltende}
        value={step3.contractName || ''}
        label={polyglot.t('newContract.step1.name')}
        onChange={onChange}
        placeholder={polyglot.t('newContract.step1.name')}
        required
        helpToolTipButton={helpToolTipButton(
          polyglot.t('helpToolTipButton.newLokale.step4.contractName'),
        )}
      />
      <Field
        name="internalNumber"
        value={step3.internalNumber || ''}
        label={polyglot.t('newContract.step1.internalNumber')}
        onChange={onChange}
        placeholder={polyglot.t('newContract.step1.internalNumber')}
        helpToolTipButton={helpToolTipButton(
          polyglot.t('helpToolTipButton.newLokale.step4.internalNumber'),
        )}
      />
      <DateTimePicker
        required
        disabled={selvforvaltende}
        name="contractStart" // value should be set to new Date(step3.contractStart) but is not valid
        label={polyglot.t('newContract.step1.contractStart')}
        onChange={(date, string) => onDateChange(date, string, 'contractStart')}
        validationState={!validDates() && !selvforvaltende && 'error'}
        value={step3.startDateObj}
        max={step3.endDateObj || undefined}
      />
      <DateTimePicker
        required
        disabled={selvforvaltende}
        name="contractEnd"
        min={step3.startDateObj || undefined}
        label={polyglot.t('newContract.step1.contractEnd')}
        onChange={(date, string) => onDateChange(date, string, 'contractEnd')}
        validationState={!validDates() && !selvforvaltende && 'error'}
        value={step3.endDateObj}
      />
      <div className={styles.buttonWrapper}>
        <Button id="back" secondaryButton onClick={buttonClick}>
          {'Tilbake'}
        </Button>
        <Button
          id="next"
          kind="primary"
          onClick={buttonClick}
          disabled={!isFormValid()}
        >
          {'Neste'}
        </Button>
      </div>
    </div>
  );
};

export default ContractWizardStep4;
