import { takeLatest } from 'redux-saga/effects';

import {
  GET_USER,
  LOGOUT,
  logoutSuccess,
  logoutFailed,
  receivedUser,
  getUserFailed,
} from 'redux/modules/user/user';
import * as adminUsers from '../modules/adminUsers/adminUsers';

import {
  handleCallToApi,
  handleLogoutCall,
  handlePostToApi,
  fetchUser,
} from './sagaHandlers';

export default function* userSaga() {
  yield takeLatest(GET_USER, fetchUser, receivedUser, getUserFailed);
  // yield takeLatest(RECEIVED_USER, pushState);
  yield takeLatest(LOGOUT, handleLogoutCall, logoutSuccess, logoutFailed);
  yield takeLatest(
    adminUsers.GET_DSF_USER,
    handleCallToApi,
    adminUsers.receivedDsfUser,
    adminUsers.getDsfUserFailed,
  );
  yield takeLatest(
    adminUsers.CREATE_NEW_USER,
    handlePostToApi,
    adminUsers.createdNewUser,
    adminUsers.createNewUserFailed,
  );
  yield takeLatest(
    adminUsers.GET_EDITABLE_USER,
    handleCallToApi,
    adminUsers.foundEditableUser,
    adminUsers.editableUserError,
  );
}
