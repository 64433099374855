exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".RealestateTemplate__header___2AZvt {\n  margin: 0 0 10px 10px;\n}\n\n.RealestateTemplate__properties___KI9Px {\n  padding: 0;\n}\n\n.RealestateTemplate__padding___3EWIN {\n  padding: 10px 35px 20px;\n}\n\n.RealestateTemplate__wrapper___a0HV1 {\n  position: relative;\n}\n", ""]);

// exports
exports.locals = {
	"header": "RealestateTemplate__header___2AZvt",
	"properties": "RealestateTemplate__properties___KI9Px",
	"padding": "RealestateTemplate__padding___3EWIN",
	"wrapper": "RealestateTemplate__wrapper___a0HV1"
};