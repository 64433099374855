import { combineReducers } from 'redux';
import createAsyncRedux from '../../../utils/ReduxHelpers';

export const getBuildingRedux = createAsyncRedux({
  constantName: 'BUILDING',
  path: '/bygning',
  initialState: {
    building: {},
  },
  responseName: 'building',
});

const setBuildingNameRedux = createAsyncRedux({
  constantName: 'SET_BUILDING_ALIAS',
  path: id => `/bygning/${id}/navn`,
});

const setFloorRedux = createAsyncRedux({
  constantName: 'FLOOR',
  path: `/etasje`,
  responseName: 'updatedFloor',
});

const setFootprintRedux = createAsyncRedux({
  constantName: 'FOOTPRINT',
  path: (id, value) => `/bygning/${id}/fotavtrykk/${value}`,
});

export const getBuilding = getBuildingRedux.getWithId;
export const gotBuilding = getBuildingRedux.getSuccess;
export const setBuildingAlias = setBuildingNameRedux.postWithId;
export const setFloor = setFloorRedux.post;
export const setFootprint = setFootprintRedux.postValue;

export const buildingRedux = combineReducers({
  building: getBuildingRedux.reducer,
  postBuildingName: setBuildingNameRedux.reducer,
  postFloor: setFloorRedux.reducer,
  setFootprint: setFootprintRedux.reducer,
});

export const getBuildingSaga = getBuildingRedux.sagas;
export const postBuildingNameSaga = setBuildingNameRedux.sagas;
export const setFloorSaga = setFloorRedux.sagas;
export const setFootprintSaga = setFootprintRedux.sagas;

export default buildingRedux;
