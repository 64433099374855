// @flow

import React, { Fragment, PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { deleteItem } from 'services/api';
import * as userActions from 'redux/modules/adminUsers/adminUsers';
import * as widgetsActions from 'redux/modules/widgets/widgets';
import { UserForm, Panel, Heading } from 'components';

type EditUserType = {
  name: string,
  uuid: string,
  organisasjonId: string,
  roles: Array<string>,
  aktiv: boolean,
  email: string,
  phone: string,
};
type Props = {
  actions: {
    getEditableUser(uuid: string): void,
    convertToEditable(): void,
    displayAlert({ kind: string, text: string }): void,
    createNewUser({
      organisasjonId: string,
      roles: Array<string>,
      aktiv: boolean,
    }): void,
  },
  editUser: EditUserType,
  submitUserError: string,
  createdUser: {
    name: string,
    uuid: string,
    organisasjonId: string,
    roles: Array<string>,
    aktiv: boolean,
    email: string,
    phone: string,
  },
  history(): void,
  match: {
    params: {
      uuid: string,
    },
  },
  loadingUser: boolean,
  loadingCreateUser: boolean,
};

type State = {
  noUser: boolean,
};

class EditUser extends PureComponent<Props, State> {
  state = {
    noUser: false,
  };

  async componentDidMount() {
    const { actions, match } = this.props;
    if (match.params.uuid || match.params.uuid !== 'undefined') {
      await actions.getEditableUser(match.params.uuid);
    } else {
      this.setState({ noUser: true });
    }
  }

  showAlert = (kind, text) => {
    this.props.actions.displayAlert({ kind, text });
  };

  deleteUser = async () => {
    const { editUser, history, actions } = this.props;
    try {
      const ok = await deleteItem(`/user/${editUser.uuid}`);
      if (ok) {
        actions.displayAlert({
          text: 'Bruker er slettet!',
          kind: 'success',
        });
        history.push('/d/admin/brukere'); // TODO: Do a refresh of list or add delay
      }
    } catch (error) {
      console.error(error);
    }
  };

  handleSubmit = async values => {
    const { createdUser, actions, editUser } = this.props;

    const updatedUser = {
      ...editUser,
      roles: [values.role],
      organisasjonId: values.organization,
      aktiv: values.aktiv,
      email: values.email,
      phone: values.phone,
    };

    try {
      await actions.createNewUser({ ...updatedUser });
      if (createdUser.uuid) {
        // User has just been created, convert to editable and show alert
        await actions.convertToEditable();
        this.showAlert('success', 'Brukeren er opprettet!');
      } else {
        // user got updated, show alert
        this.showAlert('success', 'Brukeren er oppdatert!');
      }
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    const { noUser } = this.state;
    const { editUser, loadingUser, loadingCreateUser } = this.props;

    return (
      <Panel key={editUser.uuid}>
        {!noUser ? (
          <Fragment>
            <UserForm
              editMode
              uuid={editUser.uuid}
              deleteUser={this.deleteUser}
              handleSubmit={this.handleSubmit}
              loading={loadingUser}
              loadingSubmit={loadingCreateUser}
              {...{
                editUser,
                organization: editUser.organisasjonId,
                role: Array.isArray(editUser.roles) ? editUser.roles[0] : '',
                aktiv: editUser.aktiv,
                email: editUser.email,
                phone: editUser.phone,
              }}
            />
          </Fragment>
        ) : (
          <Heading level={0}>User does not exist!</Heading>
        )}
      </Panel>
    );
  }
}

function mapStateToProps(state) {
  return {
    createdUser: state.adminUsers.createdUser,
    editUser: state.adminUsers.editUser,
    submitUserError: state.adminUsers.submitUserError,
    loadingUser: state.adminUsers.gettingUser,
    loadingCreateUser: state.adminUsers.creatingUser,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...userActions, ...widgetsActions },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditUser);
