import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Heading, Icon, Button } from 'components';
import styles from './InlineEdit.css';

// ** Todo: Rewrite span to invisible button element

const InlineEdit = ({
  icon,
  level,
  children,
  handleEdit,
  editAccess,
  closeButton,
}) => {
  const classes = classNames(styles.wrapper);
  const editButton = (
    <span
      tabIndex="0"
      role="button"
      aria-label="Edit"
      className={styles.icon}
      onClick={handleEdit}
      onKeyPress={e => (e.key === 'Enter' ? handleEdit() : '')}
    >
      <Icon icon={icon} size="2" spaced />
    </span>
  );
  return (
    <span className={classes}>
      <Heading level={level} noMargin noPadding>
        {children}
      </Heading>
      {editAccess && editButton}
      {closeButton}
    </span>
  );
};

InlineEdit.propTypes = {
  level: PropTypes.number,
  size: PropTypes.string,
  icon: PropTypes.string,
  children: PropTypes.any,
  handleEdit: PropTypes.func,
  editAccess: PropTypes.bool,
};

export default InlineEdit;
