import React from 'react';
import polyglot from 'i18n';
import {
  Panel,
  WizardSteps,
  Heading,
  Field,
  Button,
  Icon,
  Bubbles,
} from 'components';
import styles from './WizardStep2.css';

const WizardStep2 = ({
  onClick,
  onTextInput,
  fieldValues,
  waitingForSetText,
}) => (
  <Panel>
    <WizardSteps steps={3} currentStep={2} onClick={onClick} />
    <div>
      <Heading level={1} green>
        {' '}
        {polyglot.t('report.step2Header')}
      </Heading>
      <Field
        type="textarea"
        maxLength="1000"
        value={fieldValues.innledning}
        onChange={onTextInput}
        className={styles.intro}
        label={polyglot.t('report.intro')}
        id="introText"
      />
      <Field
        type="textarea"
        maxLength="3000"
        value={fieldValues.kapittel}
        onChange={onTextInput}
        className={styles.main}
        label={polyglot.t('report.subHeader')}
        id="mainText"
      />
      <div className={styles.buttonWrapper}>
        <Button
          onClick={onClick}
          className={styles.backButton}
          name="prev"
          secondaryButton
        >
          <Icon icon="arrow-left" spaced size="1" />
          {polyglot.t('report.back')}
        </Button>
        <Button
          onClick={onClick}
          className={styles.nextButton}
          name="next"
          kind="primary"
        >
          {waitingForSetText ? <Bubbles /> : polyglot.t('report.next')}
        </Button>
      </div>
    </div>
  </Panel>
);

export default WizardStep2;
