import React from 'react';
import PropTypes from 'prop-types';
import polyglot from 'i18n';
import { Field, Button, Bubbles, HelpToolTipButton } from 'components';
import styles from './ContractWizard.css';

const ContractWizardStep2 = ({
  buttonClick,
  onChange,
  numberManYears,
  numberEmployees,
  numberConsultants,
  localCost,
  loadingCreateLokale,
}) => {
  const validate = input => {
    if (!input) return undefined;
    if (isNaN(input)) return 'error';
    if (input.length === 0) return undefined;
    return 'success';
  };

  const helpToolTipButton = text => {
    return <HelpToolTipButton title={text} />;
  };

  const validateAll = () =>
    validate(numberManYears) !== 'error' &&
    validate(numberEmployees) !== 'error' &&
    validate(numberConsultants) !== 'error' &&
    validate(localCost) !== 'error';

  return (
    <div className={styles.wrapper}>
      <Field
        name="numberManYears"
        label={polyglot.t('newContract.numberManyears')}
        value={numberManYears}
        feedback="Må være et gyldig tall"
        feedbackMargin
        onChange={onChange}
        placeholder="Antall"
        validationState={validate(numberManYears)}
        helpToolTipButton={helpToolTipButton(
          polyglot.t('helpToolTipButton.newLokale.step2.aarsverk'),
        )}
      />
      <Field
        name="numberEmployees"
        label={polyglot.t('newContract.numberEmployees')}
        value={numberEmployees}
        feedback="Må være et gyldig tall"
        feedbackMargin
        onChange={onChange}
        placeholder="Antall"
        validationState={validate(numberEmployees)}
        helpToolTipButton={helpToolTipButton(
          polyglot.t('helpToolTipButton.newLokale.step2.ansatte'),
        )}
      />
      <Field
        name="numberConsultants"
        label={polyglot.t('newContract.numberConsultants')}
        value={numberConsultants}
        feedback="Må være et gyldig tall"
        feedbackMargin
        onChange={onChange}
        placeholder="Antall"
        validationState={validate(numberConsultants)}
        helpToolTipButton={helpToolTipButton(
          polyglot.t('helpToolTipButton.newLokale.step2.innleide'),
        )}
      />
      <Field
        name="localCost"
        label={polyglot.t('newContract.localCost')}
        value={localCost}
        feedback="Må være et gyldig tall"
        feedbackMargin
        onChange={onChange}
        placeholder="Kostnad"
        validationState={validate(localCost)}
        helpToolTipButton={helpToolTipButton(
          polyglot.t('helpToolTipButton.newLokale.step2.kostnad'),
        )}
      />
      <div className={styles.buttonWrapper}>
        <Button id="back" secondaryButton onClick={buttonClick}>
          {'Tilbake'}
        </Button>
        <Button
          id="opprettLokale"
          kind="primary"
          disabled={!validateAll()}
          onClick={buttonClick}
        >
          {loadingCreateLokale ? <Bubbles /> : 'Opprett nytt lokale'}
        </Button>
      </div>
    </div>
  );
};

ContractWizardStep2.propTypes = {
  buttonClick: PropTypes.func,
  onChange: PropTypes.func,
  numberManYears: PropTypes.string,
  numberEmployees: PropTypes.string,
  numberConsultants: PropTypes.string,
  localCost: PropTypes.string,
  loadingCreateLokale: PropTypes.bool,
};

export default ContractWizardStep2;
