// @flow

import React from 'react';
import ReactPlayer from 'react-player';
import { ModalButton, Button } from 'components';
import styles from './WelcomeVideo.css';

const WelcomeVideo = ({ onClick }: { onClick(): void }) => (
  <ModalButton showModal>
    <div className={styles.wrapper}>
      <ReactPlayer
        className={styles.player}
        url="https://vimeo.com/250108431"
      />
      <Button kind="primary" onClick={onClick}>
        {'Gå videre'}
      </Button>
    </div>
  </ModalButton>
);

export default WelcomeVideo;
