exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* VIEWPORTS */\n\n:root {\n}\n\n.WelcomeVideo__wrapper___1PIET {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  background-color: rgba(0, 0, 0, 0)\n}\n\n.WelcomeVideo__wrapper___1PIET > div {\n  padding: 10px;\n}\n\n.WelcomeVideo__player___cOS7d {\n  max-width: 100vw;\n  max-height: 80vh;\n}\n", ""]);

// exports
exports.locals = {
	"wrapper": "WelcomeVideo__wrapper___1PIET",
	"player": "WelcomeVideo__player___cOS7d"
};