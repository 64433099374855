exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* STATENS LOKALER COLOR PALETTE */\n\n:root {\n\n  /* colors for input fields */\n\n  /* Button colors */\n\n  /* Anchor colors */\n\n  /* STATSBYGG PRIMARY COLORS */\n\n  /* Packet colors */\n  /* Statsbygg Glassblå 01-03 */\n  /* SECONDARY COLORS - NEUTRAL */\n  /* Statsbygg Nøytral grå 01-02 */\n  /* Statsbygg Stein */\n  /* Statsbygg Jord */\n  /* TERTIARY COLORS - MARKER COLORS */\n  /* Statsbygg Sennep */\n  /* Statsbygg Multe */\n  /* Statsbygg Laks */\n  /* Statsbygg Lyng */\n}\n\n.SearchResultToggle__row___14FFt {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n}\n\n.SearchResultToggle__tab___1YHTm {\n  margin: 10px 15px;\n  color: #131615;\n  padding: 5px 0;\n  cursor: pointer;\n  border-bottom: 3px solid #fff;\n  text-decoration: none;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center\n}\n\n.SearchResultToggle__tab___1YHTm > span {\n  padding: 5px 5px 0;\n}\n\n.SearchResultToggle__tab___1YHTm:hover, .SearchResultToggle__tab___1YHTm:focus {\n  border-bottom: 3px solid #88c9d0;\n  text-decoration: none;\n  -webkit-transition: 0.3s;\n  transition: 0.3s;\n  color: #131615;\n}\n\n.SearchResultToggle__active___3Wo00 {\n  border-bottom: 3px solid #88c9d0;\n}\n", ""]);

// exports
exports.locals = {
	"row": "SearchResultToggle__row___14FFt",
	"tab": "SearchResultToggle__tab___1YHTm",
	"active": "SearchResultToggle__active___3Wo00"
};