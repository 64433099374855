// Export action constants
export const GET_KPI = 'GET_KPI';
export const RECEIVED_KPI = 'RECEIVED_KPI';
export const GET_KPI_ERROR = 'GET_KPI_ERROR';

export const SAVE_KPI = 'SAVE_KPI';
export const SAVED_KPI = 'SAVED_KPI';
export const SAVE_KPI_ERROR = 'SAVE_KPI_ERROR';

// Export action creators
export const getKpi = () => ({ type: GET_KPI, path: '/kpi/all' });
export const receivedKpi = kpiObj => ({ type: RECEIVED_KPI, kpiObj });
export const getKpiError = error => ({ type: GET_KPI_ERROR, error });

export const saveKpi = payload => ({
  type: SAVE_KPI,
  path: '/kpi/all',
  payload,
});
export const savedKpi = kpiObj => ({ type: SAVED_KPI, kpiObj });
export const saveKpiError = error => ({ type: SAVE_KPI_ERROR, error });

const initialState = {
  kpi: [],
  savedKpi: false,
};

// Export the reducer
export default function kpiReducer(state = initialState, action) {
  switch (action.type) {
    case GET_KPI: {
      return Object.assign({}, state, { loading: true, error: '' });
    }
    case RECEIVED_KPI: {
      return Object.assign({}, state, { loading: false, kpi: action.kpiObj });
    }
    case GET_KPI_ERROR: {
      return Object.assign({}, state, {
        loading: false,
        error: action.error,
      });
    }
    case SAVE_KPI: {
      return Object.assign({}, state, {
        loadingSave: true,
        saveError: '',
        savedKpi: false,
      });
    }
    case SAVED_KPI: {
      return Object.assign({}, state, {
        loadingSave: false,
        savedKpi: action.kpiObj,
      });
    }
    case SAVE_KPI_ERROR: {
      return Object.assign({}, state, {
        loadingSave: false,
        saveError: action.error,
      });
    }
    default:
      return state;
  }
}
