// @flow

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import polyglot from 'i18n';
import { LokaleWizardLayout } from 'components';
import type { Lokale, MatrikkelResult, RouterHistory } from 'types';
import { toApiDate } from 'utils/utils';

import * as leideLokalerActions from 'redux/modules/leideLokaler/leideLokaler';
import * as contractActions from 'redux/modules/leideLokaler/contracts';
import * as widgetsActions from 'redux/modules/widgets/widgets';
import userSelector from 'redux/modules/user/userSelector';
import Bubbles from '../../components/atoms/Bubbles/Bubbles';

type Props = {
  loading: boolean,
  lokale: Lokale,
  verifiedOrg: {
    id: number,
  },
  savedContract: { id: number },
  error: string,
  errorMessage: string,
  actions: {
    getLokale(string): void,
    displayAlert({}): void,
    saveContract({}): void,
    matrikkelGet({}): void,
    getOrg(?number): void,
  },
  matrikkelResult: MatrikkelResult,
  loadingMatrikkel: boolean,
  loadingOrg: boolean,
  saveContractFailed: boolean,
  history: RouterHistory,
  match: {
    params: {
      lokalerId: string,
    },
  },
};

type CurrentObject = {
  bruttoareal?: string,
  arbeidsrelatertareal?: string,
  spesialformaalareal?: string,
  fraDato?: string,
  framleieareal?: string,
  parkeringsareal?: string,
  navn?: string,
};

type State = {
  step: number,
  step3: {
    endDate?: string,
    startDate?: string,
    contractName?: string,
    internalNumber?: number,
  },
  step4: {
    selvforvaltende?: boolean,
    inputOrg?: number,
    verifiedOrg?: {
      id: number,
    },
    leietaker?: {
      value: number,
    },
  },
  step5: { matrikkelResult: Array<?MatrikkelResult> },
  step6: {
    currentObject: CurrentObject,
    showInput: boolean,
    objects: Array<CurrentObject>,
    husleie?: number,
    framleiereduksjon?: number,
  },
};

class ContractWizard extends Component<Props, State> {
  initialState = {
    step: 3,
    step3: {},
    step4: {},
    step5: {
      matrikkelResult: {},
      addedProperties: [],
    },
    step6: {
      showInput: true,
      objects: [],
      currentObject: {},
      husleie: 0,
      framleiereduksjon: 0,
    },
  };

  state = this.initialState;

  componentDidMount() {
    const { lokale, actions, match } = this.props;
    if (!lokale.id) {
      actions.getLokale(match.params.lokalerId);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.lokale.id !== this.props.lokale.id) {
      if (!nextProps.lokale.editable) {
        console.log('no access, redirecting to dashboard');
        nextProps.history.replace('/');
      }
    }
    if (
      nextProps.savedContract.id !== this.props.savedContract.id &&
      !nextProps.saveContractFailed
    ) {
      this.setState({ step: 7 });
    }

    this.handleOrgProps(nextProps);

    this.handleMatrikkelProps(nextProps);
  }

  handleOrgProps = nextProps => {
    if (!nextProps.loadingOrg && this.props.loadingOrg) {
      if (!nextProps.error) {
        this.setState(prevState => ({
          step4: {
            ...prevState.step4,
            verifiedOrg: nextProps.verifiedOrg,
          },
        }));
      } else {
        this.props.actions.displayAlert({
          kind: 'danger',
          text: `Fant ikke organisasjon med det nummeret: ${nextProps.error}`,
        });
      }
    }
  };

  handleMatrikkelProps = ({
    loadingMatrikkel,
    matrikkelResult,
    error,
    errorMessage,
  }) => {
    if (!loadingMatrikkel && this.props.loadingMatrikkel) {
      // new result from matrikkel, check if error, empty or result
      if (error) {
        window.scrollTo(0, 0);
        this.props.actions.displayAlert({
          kind: 'danger',
          text: `Problem med henting fra matrikkelen: ${error}: ${errorMessage}`,
        });
      } else if (matrikkelResult.length === 0)
        this.props.actions.displayAlert({
          kind: 'danger',
          text: 'Ingen grunneiendom funnet!',
        });
      else {
        this.setState(prevState => ({
          step5: {
            ...this.state.step5,
            matrikkelResult: matrikkelResult[0],
            addedProperties: [
              ...this.state.step5.addedProperties,
              {
                ...matrikkelResult[0],
                allBuildings: matrikkelResult[0].bygninger,
              },
            ],
          },
        }));
      }
    }
  };

  onChange = e => {
    const { name, value } = e.target;
    if (name === 'contractName' || name === 'internalNumber') {
      this.setState(prevState => ({
        step3: {
          ...prevState.step3,
          [name]: value,
        },
      }));
    }
    if (name === 'utleier') {
      this.setState(prevState => ({
        step4: {
          ...prevState.step4,
          inputOrg: value,
        },
      }));
    }
    if (name === 'selvforvaltende') {
      this.setState(prevState => ({
        step4: {
          ...prevState.step4,
          selvforvaltende: !prevState.step4.selvforvaltende,
          verifiedOrg: !prevState.step4.selvforvaltende
            ? this.props.lokale.bruker
            : undefined,
          leietaker: !prevState.step4.selvforvaltende
            ? {
                value: this.props.lokale.bruker.id,
              }
            : undefined,
        },
        step3: !prevState.step4.selvforvaltende
          ? {
              ...prevState.step3,
              startDate: '01.01.2018',
              startDateObj: new Date('2018-01-01'),
              endDate: '31.12.2099',
              endDateObj: new Date('2099-12-31'),
              contractName: 'Selvforvaltende',
            }
          : prevState.step3,
      }));
    }
    if (
      name === 'kommune' ||
      name === 'gardsnr' ||
      name === 'bruksnr' ||
      name === 'festenr' ||
      name === 'seksjonsnr'
    ) {
      this.setState(prevState => ({
        step5: {
          ...prevState.step5,
          [name]: value,
        },
      }));
    }
    if (name === 'husleie' || name === 'framleiereduksjon') {
      this.setState(prevState => ({
        step6: {
          ...prevState.step6,
          [name]: value,
        },
      }));
    }
    if (
      name === 'navn' ||
      name === 'parkeringsareal' ||
      name === 'bruttoareal' ||
      name === 'arbeidsrelatertareal' ||
      name === 'spesialformaalareal' ||
      name === 'framleieareal'
    ) {
      const val = name === 'navn' || !value ? value : parseInt(value, 10);
      this.setState(prevState => ({
        step6: {
          ...prevState.step6,
          currentObject: {
            ...prevState.step6.currentObject,
            [name]: val,
          },
        },
      }));
    }
  };

  onSelectChange = (e, name) => {
    if (name === 'leietaker') {
      this.setState(prevState => ({
        step4: {
          ...prevState.step4,
          leietaker: e,
        },
      }));
    }
    if (name === 'typeAreal') {
      this.setState(prevState => ({
        step6: {
          ...prevState.step6,
          typeAreal: e,
        },
      }));
    }
  };

  onDateChange = (date, string, name) => {
    if (name === 'contractStart')
      this.setState(prevState => ({
        step3: {
          ...prevState.step3,
          startDate: string,
          startDateObj: date,
        },
      }));
    if (name === 'contractEnd')
      this.setState(prevState => ({
        step3: {
          ...prevState.step3,
          endDate: string,
          endDateObj: date,
        },
      }));
  };

  buttonClick = e => {
    if (e.target.id === 'next') {
      this.setState(prevState => ({ step: prevState.step + 1 }));
    }
    if (e.target.id === 'back') {
      this.setState(prevState => ({ step: prevState.step - 1 }));
    }
    if (e.target.id === 'getOrg') {
      this.props.actions.getOrg(this.state.step4.inputOrg);
    }
    if (e.target.id === 'deleteOrg') {
      this.setState(prevState => ({
        step4: {
          ...prevState.step4,
          verifiedOrg: undefined,
        },
      }));
    }
    if (e.target.id === 'hentMatrikkelen') {
      this.props.actions.matrikkelGet(this.state.step5);
    }
    if (e.target.id === 'fullfør') {
      this.saveContract();
    }
    if (e.target.id === 'nyttleieforhold') {
      this.setState(this.initialState);
    }
    if (e.target.id === 'nyttLokale')
      this.props.history.push('/d/lokale/nyttlokale');

    if (e.target.id === 'visLokale') {
      this.props.history.push(`/d/lokale/${this.props.lokale.id}`);
    }
  };

  saveContract = () => {
    const areal = this.props.lokale.kontor
      ? 'spesialformaalareal'
      : 'arbeidsrelatertareal';
    const convertObject = obj => ({
      kontor: this.props.lokale.kontor,
      aktiv: true,
      leieobjektperioder: [
        {
          bruttoareal: obj.bruttoareal,
          [areal]: obj[areal],
          fraDato: obj.fraDato,
          framleieareal: obj.framleieareal,
          parkeringsareal: obj.parkeringsareal,
        },
      ],
      navn: obj.navn,
    });
    const { step3, step4, step5, step6 } = this.state;
    this.props.actions.saveContract({
      leieforholdtype: step4.selvforvaltende
        ? polyglot.t('newContract.step1.self')
        : polyglot.t('newContract.step1.tenant'),
      leieperiodeSlutt: toApiDate(step3.endDate),
      leieperiodeStart: toApiDate(step3.startDate),
      leietakerId: step4.leietaker && step4.leietaker.value,
      lokale: this.props.match.params.lokalerId,
      navn: step3.contractName,
      aktiv: true,
      saksnummer: step3.internalNumber,
      utleierId: step4.verifiedOrg ? step4.verifiedOrg.id : undefined,
      leieforholdperioder: [
        {
          husleiekostnad: step6.husleie,
          framleiereduksjon: step6.framleiereduksjon,
        },
      ],
      grunneiendommer: step5.addedProperties,
      leieobjekter: [
        ...step6.objects.map(convertObject),
        step6.showInput &&
          Object.keys(step6.currentObject).length > 0 &&
          convertObject(step6.currentObject),
      ].filter(it => it !== false),
    });
  };

  toggleInput = i =>
    this.setState(prevState => ({
      [`step${i}`]: {
        ...prevState[`step${i}`],
        showInput: !prevState[`step${i}`].showInput,
      },
    }));

  removeHit = matrikkelId =>
    this.setState(prevState => ({
      step5: {
        ...prevState.step5,
        addedProperties: prevState.step5.addedProperties.filter(
          p => p.matrikkelId !== matrikkelId,
        ),
      },
    }));

  submitProperty = ({ values, matrikkelId }) => {
    const { step5 } = this.state;

    const valgteBygninger = Object.keys(values).filter(bygg => values[bygg]);

    const addedProperties = step5.addedProperties.map(
      property =>
        property.matrikkelId === matrikkelId
          ? {
              ...property,
              bygninger: valgteBygninger.map(vb =>
                property.allBuildings.find(b => b.bygningsnr.toString() === vb),
              ),
            }
          : property,
    );

    this.setState({
      step5: {
        addedProperties,
        matrikkelResult: [],
      },
    });
  };

  removeObject = i =>
    this.setState(prevState => ({
      step6: {
        ...prevState.step6,
        objects: [
          ...prevState.step6.objects.splice(0, i),
          ...prevState.step6.objects.splice(i + 1),
        ],
      },
    }));

  addObject = () =>
    this.setState(prevState => ({
      step6: {
        ...prevState.step6,
        showInput: false,
        objects: [...prevState.step6.objects, prevState.step6.currentObject],
        currentObject: {},
      },
    }));

  render() {
    if (this.props.loadingGetLokale) {
      return <Bubbles />;
    }
    return (
      <LokaleWizardLayout
        buttonClick={this.buttonClick}
        onChange={this.onChange}
        onDateChange={this.onDateChange}
        onSelectChange={this.onSelectChange}
        currentLokale={this.props.lokale}
        contract={this.props.savedContract}
        {...this.state}
        {...this.props}
        toggleInput={this.toggleInput}
        removeHit={this.removeHit}
        removeObject={this.removeObject}
        addObject={this.addObject}
        submitProperty={this.submitProperty}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.leideLokaler.loading,
    loadingGetLokale: state.leideLokaler.loadingGetLokale,
    lokale: state.leideLokaler.lokaler,
    verifiedOrg: state.contracts.org,
    matrikkelResult: state.contracts.matrikkelResult,
    loadingMatrikkel: state.contracts.loadingMatrikkel,
    loadingOrg: state.contracts.loadingOrg,
    savedContract: state.contracts.contract,
    loadingSaveContract: state.contracts.loadingSaveContract,
    error: state.contracts.error,
    errorMessage: state.contracts.errorMessage,
    user: userSelector(state),
    periodestart: state.leideLokaler.dates.periodestart,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...leideLokalerActions, ...contractActions, ...widgetsActions },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContractWizard);
