exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../../node_modules/css-loader/index.js??ref--5-1!../../../../node_modules/postcss-loader/lib/index.js!../GlobalSearchFilter/GlobalSearchFilter.css"), undefined);

// module
exports.push([module.id, ".GlobalSearchPropertyFilters__doubleField___3HsWD {\n}\n\n.GlobalSearchPropertyFilters__filterWrapper___3nhZB > * {\n  margin: 15px 0;\n}", ""]);

// exports
exports.locals = {
	"doubleField": "GlobalSearchPropertyFilters__doubleField___3HsWD " + require("-!../../../../node_modules/css-loader/index.js??ref--5-1!../../../../node_modules/postcss-loader/lib/index.js!../GlobalSearchFilter/GlobalSearchFilter.css").locals["doubleField"] + "",
	"filterWrapper": "GlobalSearchPropertyFilters__filterWrapper___3nhZB"
};