import React from 'react';
import PropTypes from 'prop-types';
import { Button, SelectField, Paragraph, Icon } from 'components';
import polyglot from 'i18n';
import styles from './GroupEdit.css';

const GroupEdit = ({ onSubmit, validationState, editAccess, ...props }) => (
  <div className={styles.wrapper}>
    <div className={styles.infoWrapper}>
      <Icon
        icon="info-alt"
        aria-label={polyglot.t('connectionInfo.ikonLabel')}
        size="3"
        spaced
      />
      <Paragraph>{polyglot.t('connectionInfo.disconnected')}</Paragraph>
    </div>
    {editAccess && (
      <form className={styles.form} onSubmit={onSubmit}>
        <SelectField
          {...props}
          selectGroup
          keepInput
          noResultsText={polyglot.t('connectionInfo.noresults')}
          matchProp="label"
        />
        <div className={styles.buttonWrapper}>
          <Button secondaryButton disabled={validationState === 'error'} submit>
            {polyglot.t('connectionInfo.fieldName')}
          </Button>
        </div>
      </form>
    )}
  </div>
);

GroupEdit.propTypes = {
  name: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  validationState: PropTypes.string,
  editAccess: PropTypes.bool,
};

export default GroupEdit;
