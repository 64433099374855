import { fork, all } from 'redux-saga/effects';
import lokalerSaga from './lokalerSaga';
import ledigeLokalerSaga from './ledigeLokalerSaga';
import adminSaga from './adminSaga';
import propertySaga from './propertySaga';
import userSaga from './userSaga';
import widgetsSaga from './widgetsSaga';
import reportSaga from './reportSaga';
import searchSaga from './searchSaga';
import meldingSaga from './meldingSaga';
import { excelSaga } from '../modules/excel/excel';
import { helpSaga, helpAllSaga } from '../modules/help/help';
import {
  powerBIReportsSaga,
  powerBIReportSaga,
  powerBIReportTokenSaga,
  powerBIReportAdminSaga,
} from '../modules/report/powerbi';
import { selectionsSaga, selectionSaga } from '../modules/selections/selection';
import {
  getBuildingSaga,
  postBuildingNameSaga,
  setFloorSaga,
  setFootprintSaga,
} from '../modules/building/building';
import { projectSaga } from '../modules/projects/projects';
import { forsideSaga, forsidePublicSaga } from '../modules/frontpage/frontpage';

export default function* rootSaga() {
  yield all([
    fork(lokalerSaga),
    fork(ledigeLokalerSaga),
    fork(adminSaga),
    fork(propertySaga),
    fork(userSaga),
    fork(widgetsSaga),
    fork(reportSaga),
    fork(searchSaga),
    fork(meldingSaga),
    fork(excelSaga),
    fork(helpSaga),
    fork(helpAllSaga),
    fork(getBuildingSaga),
    fork(postBuildingNameSaga),
    fork(setFloorSaga),
    fork(setFootprintSaga),
    fork(selectionsSaga),
    fork(selectionSaga),
    fork(projectSaga),
    fork(forsideSaga),
    fork(forsidePublicSaga),
    fork(powerBIReportsSaga),
    fork(powerBIReportSaga),
    fork(powerBIReportTokenSaga),
    fork(powerBIReportAdminSaga),
  ]);
}
