// @flow

import React from 'react';
import { Link } from 'components';
import polyglot from 'i18n';

const ActionLink = ({
  hasContract,
  addLeieobjekt,
  lokaleId,
}: {
  hasContract: boolean,
  addLeieobjekt?: () => {},
  lokaleId: number,
}) => {
  if (!hasContract)
    return (
      <Link to={`/d/lokale/${lokaleId}/nyttleieforhold`}>
        {polyglot.t('leideLokaler.addLeieforhold')}
      </Link>
    );
  if (addLeieobjekt)
    return (
      <Link onClick={addLeieobjekt}>
        {polyglot.t('leideLokaler.addLeieobject')}
      </Link>
    );
  return (
    <Link to={`/d/lokale/${lokaleId}/periodisk`}>
      {polyglot.t('leideLokaler.choosePeriodic')}
    </Link>
  );
};

export default ActionLink;
