// @flow

import React from 'react';
import GenericList from 'containers/GenericList';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { TableTemplate, Button } from 'components';
import * as widgetsActions from 'redux/modules/widgets/widgets';
import * as searchActions from 'redux/modules/search/search';
import * as tableActions from 'redux/modules/search/table';
import { getExcel } from 'redux/modules/excel/excel';
import searchSelector from 'redux/modules/search/searchSelector';
import searchRequestBuilder from 'utils/searchApiHelpers';
import polyglot from 'i18n';
import { TableState, UserData } from 'types';
import userSelector from 'redux/modules/user/userSelector';

type Props = {
  type: string,
  table: TableState,
  actions: {
    getExcel(): void,
  },
  userData: UserData,
};

const EtasjerList = reduxProps => (
  <GenericList
    type="etasje"
    {...reduxProps}
    render={(props: Props) => (
      <TableTemplate
        {...props}
        exportButton={
          <Button
            kind="primary"
            newItemButton
            onClick={() =>
              props.actions.getExcel(
                searchRequestBuilder({
                  type: props.type,
                  table: props.table,
                  userData: props.userData,
                }),
              )
            }
          >
            {polyglot.t('buttons.export')}
          </Button>
        }
      />
    )}
  />
);

function mapStateToProps(state) {
  return {
    data: searchSelector('etasje')(state),
    loading: state.search.loading,
    toggleFlag: state.table.toggleFlag,
    loadingToggle: state.table.loadingToggle,
    error: state.search.error,
    userData: userSelector(state),
    table: state.table.etasjeTable,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...searchActions, ...tableActions, ...widgetsActions, getExcel },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EtasjerList);
