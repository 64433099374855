// @flow

import React, { Component } from 'react';
import type { Dispatch } from 'redux';
import { RouterHistory } from 'types';
import { connect } from 'react-redux';
import { ProjectTemplate } from 'components';
import {
  getProject,
  lagreUtvalg,
  deleteUtvalg,
  oppdaterProsjekt,
} from '../../redux/modules/projects/projects';
import { displayAlert } from 'redux/modules/widgets/widgets';

type Props = {
  match: RouterHistory,
  dispatch: Dispatch<getProject>,
  project: {
    navn: string,
    uuid: string,
  },
  deleted: boolean,
  postSuccess: boolean,
  putSuccess: boolean,
};

type State = {
  selected?: {
    navn: string,
    value: string,
  },
  editProjectModal: boolean,
  deleteModal: boolean,
  utvalgUuid: string,
  showReport: boolean,
};

class ProjectDetails extends Component<Props, State> {
  state = {
    selected: undefined,
    editProjectModal: false,
    deleteModal: false,
    utvalgUuid: '',
    showReport: false,
  };

  componentDidMount() {
    const {
      match: { params },
      dispatch,
    } = this.props;
    dispatch(getProject(params.prosjektId));
  }

  componentDidUpdate({
    postSuccess: prevPost,
    deleted: wasDeleted,
    putSuccess: prevPut,
  }) {
    const {
      postSuccess,
      putSuccess,
      deleted,
      match: { params },
      dispatch,
      history,
    } = this.props;
    if ((!prevPost && postSuccess) || (!prevPut && putSuccess)) {
      dispatch(getProject(params.prosjektId));
    }
    if (!wasDeleted && deleted) {
      if (this.state.editProjectModal) {
        dispatch(
          displayAlert({ kind: 'success', text: 'Prosjektet er slettet' }),
        );
        history.push('/d/prosjekt');
      } else {
        dispatch(
          displayAlert({ kind: 'success', text: 'Utvalget er fjernet' }),
        );
        dispatch(getProject(params.prosjektId));
      }
    }
  }

  saveSelection = () => {
    const {
      match: { params },
      dispatch,
    } = this.props;
    dispatch(
      lagreUtvalg({ id: `${params.prosjektId}/${this.state.selected.value}` }),
    );
  };

  removeSelection = () => {
    const {
      match: { params },
      dispatch,
    } = this.props;
    const { utvalgUuid } = this.state;
    if (!utvalgUuid) {
      dispatch(deleteUtvalg(params.prosjektId));
    } else {
      dispatch(deleteUtvalg(`${params.prosjektId}/${utvalgUuid}`));
      this.setState({ deleteModal: false });
    }
  };

  saveProject = e => {
    e.preventDefault();
    const {
      match: {
        params: { prosjektId },
      },
      dispatch,
    } = this.props;
    dispatch(
      oppdaterProsjekt({
        id: prosjektId,
        navn: e.target.childNodes[0].childNodes[1].value,
        prosjektnummer: e.target.childNodes[1].childNodes[1].value,
        uuid: prosjektId,
      }),
    );
    this.setState({ editProjectModal: false });
  };

  toggleModal = (modal, uuid = '') => {
    this.setState(prevState => ({
      [modal]: !prevState[modal],
      utvalgUuid: uuid,
    }));
  };

  toggleReport = () => {
    this.setState(prevState => ({ showReport: !prevState.showReport }));
  };

  render() {
    const { showReport } = this.state;
    const { project = {} } = this.props;
    const powerBIprops = project.rapportMeta
      ? {
          aggregeringsnivaa: project.rapportMeta.aggregeringsnivaa,
          rapportId: project.rapportMeta.rapportId,
          datasetId: project.rapportMeta.datasetId,
          embedUrl: project.rapportMeta.embedUrl,
          token: project.rapportMeta.token,
        }
      : {};

    return !project.uuid ? null : (
      <ProjectTemplate
        {...project}
        {...this.state}
        onSelectChange={selected => this.setState({ selected })}
        saveSelection={this.saveSelection}
        toggleModal={this.toggleModal}
        saveProject={this.saveProject}
        removeSelection={this.removeSelection}
        showReport={showReport}
        toggleReport={this.toggleReport}
        powerBIprops={powerBIprops}
      />
    );
  }
}

ProjectDetails.propTypes = {};

const mapStateToProps = state => ({
  project: state.projects.project,
  postSuccess: state.projects.postSuccess,
  putSuccess: state.projects.putSuccess,
  deleted: state.projects.deleted,
});

export default connect(mapStateToProps)(ProjectDetails);
