// @flow

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { LokaleWizardLayout } from 'components';
import type { BrowserHistory, Lokale } from 'types';

import * as leideLokalerActions from 'redux/modules/leideLokaler/leideLokaler';
import * as widgetActions from 'redux/modules/widgets/widgets';
import userSelector from 'redux/modules/user/userSelector';

type Props = {
  nyttLokaler: {
    id: number,
  },
  history: BrowserHistory,
  createLokaleFailed: boolean,
  actions: {
    displayAlert({ text: string, kind: string }): void,
    createLokaler(Lokale): void,
  },
  error: {
    message: string,
  },
};

type State = {
  selectedOrg?: {
    value: number,
  },
  step: number,
  lokalerName: string,
  kontor: boolean,
  lokalId: string,
  numberEmployees?: string,
  numberManYears?: string,
  numberConsultants?: string,
  localCost?: string,
};

class NewLokaleWizard extends Component<Props, State> {
  state = {
    step: 1,
    lokalerName: '',
    lokalId: '',
    kontor: 'true',
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.nyttLokaler.id &&
      nextProps.nyttLokaler.id !== this.props.nyttLokaler.id
    ) {
      // saved new lokaler, go to step 3
      this.props.history.push(
        `/d/lokale/${nextProps.nyttLokaler.id}/nyttleieforhold`,
      );
    }
    if (nextProps.createLokaleFailed && !this.props.createLokaleFailed)
      this.props.actions.displayAlert({
        kind: 'danger',
        text: `Problem med å lagre lokale: ${nextProps.error.message}`,
      });
  }

  onChange = ({ target: { value, name } }) => {
    this.setState({ [name]: value });
  };

  onSelectChange = (e, name) => {
    if (name === 'tenant') {
      this.setState({
        selectedOrg: e,
      });
    } else if (name === 'kontor') {
      this.setState({ kontor: e ? e.value : undefined });
    }
  };

  buttonClick = e => {
    if (e.target.id === 'next') {
      this.setState({ step: this.state.step + 1 });
    }
    if (e.target.id === 'back') {
      this.setState({ step: this.state.step - 1 });
    }
    if (e.target.id === 'opprettLokale') {
      this.onSumbitLeidLokale();
    }
  };

  onSumbitLeidLokale = () => {
    const {
      numberConsultants,
      numberEmployees,
      localCost,
      numberManYears,
      selectedOrg,
      lokalerName,
      kontor,
      lokalId,
    }: State = this.state;
    this.props.actions.createLokaler({
      brukerId: selectedOrg && selectedOrg.value,
      navn: lokalerName,
      kontor: kontor === 'true',
      aktiv: true,
      lokalId,
      lokaleperioder: [
        {
          antallAarsverk: numberManYears,
          antallAnsatte: numberEmployees,
          antallInnleide: numberConsultants,
          lokalkostnader: localCost,
        },
      ],
    });
  };

  render() {
    return (
      <LokaleWizardLayout
        {...this.state}
        {...this.props}
        buttonClick={this.buttonClick}
        onChange={this.onChange}
        onSelectChange={this.onSelectChange}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.leideLokaler.loadingNewLokale,
    nyttLokaler: state.leideLokaler.lokaler,
    loadingCreateLokale: state.leideLokaler.loadingCreateLokale,
    user: userSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...leideLokalerActions, ...widgetActions },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewLokaleWizard);
