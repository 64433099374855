import React from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  Button,
  Link,
  Table,
  LeieobjektInputLine,
  HelpToolTipButton,
} from 'components';
import { validateAreaSum } from 'utils/validationHelpers';
import { toGuiDate } from 'utils/utils';
import polyglot from 'i18n';
import styles from './LeieObjektInput.css';

const LeieObjektInput = ({
  navn,
  onChange,
  buttonClick,
  abortClick,
  defaultValues = {},
  deleteItem,
  periodestart,
  firstRef,
  lastRef,
  ...props
}) => {
  const helpToolTipButton = text => {
    return <HelpToolTipButton title={text} />;
  };
  const {
    leieobjektperiode = {},
    leieobjektperioder = [],
    navn: defaultNavn = '',
    kontor,
    aktiv,
    id,
  } = defaultValues;
  const validationState = validateAreaSum(
    { kontor, ...props },
    leieobjektperiode,
  );
  const enableButton = (navn || defaultNavn) && validationState !== 'error';

  const headerRow = [
    polyglot.t('newContract.step6.leieObjectFra'),
    polyglot.t('newContract.step6.bta'),
    kontor
      ? polyglot.t('newContract.step6.spesialformaalareal')
      : polyglot.t('newContract.step6.arbeidsrelatertareal'),
    polyglot.t('newContract.step6.parkering'),
    polyglot.t('newContract.step6.framleieareal'),
    polyglot.t('newContract.step6.totalareal'),
  ];

  const perioder = Array.isArray(leieobjektperioder)
    ? leieobjektperioder
        .filter(all => all.id !== leieobjektperiode.id)
        .map(it => [
          toGuiDate(it.fraDato),
          it.bruttoareal || 0,
          it.kontor ? it.spesialformaalareal : it.arbeidsrelatertareal,
          it.parkeringsareal || 0,
          it.framleieareal || 0,
          `${it.totalareal} kvm`,
        ])
    : [];

  const dataRows = [
    LeieobjektInputLine({
      ...{
        onChange,
        validationState,
        leieobjektperiode,
        periodestart,
        kontor: defaultValues.kontor,
        ...props,
      },
    }).filter(it => !!it),
    ...perioder,
  ];

  return (
    <div className={styles.newLeieObject}>
      <div className={styles.propertyLine}>
        <Field
          name="navn"
          required
          refFunc={firstRef}
          feedbackMargin
          value={navn}
          defaultValue={defaultNavn}
          onChange={onChange}
          label={polyglot.t('newContract.step6.navn')}
          placeholder=""
        />
        {kontor !== undefined && <i>{kontor ? 'Kontor' : 'Ikke kontor'}</i>}
      </div>
      <Table
        dataRows={dataRows}
        responsive
        headerRow={headerRow.filter(it => !!it)}
        helpToolButton={[
          null,
          polyglot.t('helpToolTipButton.periodicReport.step2.bta'),
          polyglot.t('helpToolTipButton.periodicReport.step2.typeareal'),
          polyglot.t('helpToolTipButton.periodicReport.step2.parkeringsareal'),
          polyglot.t('helpToolTipButton.periodicReport.step2.framleie'),
          polyglot.t('helpToolTipButton.periodicReport.step2.totaltareal'),
        ]}
      />
      <div className={styles.propertyLine}>
        <div className={styles.buttonWrapper}>
          {aktiv && (
            <Link
              deleteStyle
              onClick={() =>
                confirm(polyglot.t('newContract.step6.slettConfirm')) &&
                deleteItem('leieobjekt', id, !aktiv)
              }
            >
              {polyglot.t('newContract.step6.slett')}
            </Link>
          )}
          <Button
            refFunc={!enableButton ? lastRef : undefined}
            id="cancel"
            secondaryButton
            onClick={abortClick}
          >
            {polyglot.t('buttons.cancel')}
          </Button>
          <Button
            id="saveLeieobjekt"
            kind="primary"
            refFunc={enableButton ? lastRef : undefined}
            disabled={!enableButton}
            onClick={e => buttonClick(e, defaultValues)}
          >
            {polyglot.t('newContract.step6.lagreObjekt')}
          </Button>
        </div>
      </div>
    </div>
  );
};

LeieObjektInput.propTypes = {
  navn: PropTypes.string,
  bruttoareal: PropTypes.number,
  arbeidsrelatertareal: PropTypes.number,
  spesialformaalareal: PropTypes.number,
  parkeringsareal: PropTypes.number,
  framleieareal: PropTypes.number,
  onChange: PropTypes.func,
  buttonClick: PropTypes.func.isRequired,
  abortClick: PropTypes.func.isRequired,
  defaultValues: PropTypes.shape({
    kontor: PropTypes.bool,
    bruttoareal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    leieobjektperiode: PropTypes.shape({
      fraDato: PropTypes.string,
    }),
  }),
  deleteItem: PropTypes.func,
  firstRef: PropTypes.func,
  lastRef: PropTypes.func,
  periodestart: PropTypes.string,
};

export default LeieObjektInput;
