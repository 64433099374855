import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import polyglot from 'i18n';
import styles from './KpiTarget.css';

const KpiTarget = ({
  target,
  color = '',
  status,
  ledigStatus,
  list,
  alignLeft,
  sideMargins,
  className,
}) => (
  <div
    className={classnames(
      styles.wrapper,
      list && styles.list,
      !status && styles.fullWidth,
      alignLeft && styles.alignLeft,
      sideMargins && styles.sideMargins,
      className,
    )}
  >
    <div className={styles[color]} />
    <div>
      {!status && !ledigStatus && `KPI mål er ${target || ''}`}
      {status && polyglot.t(`kpi.color.${color}`)}
    </div>
  </div>
);

KpiTarget.propTypes = {
  target: PropTypes.number,
  color: PropTypes.oneOf(['red', 'orange', 'green', 'blue', 'white', '']),
  status: PropTypes.bool,
  ledigStatus: PropTypes.bool,
  list: PropTypes.bool,
  alignLeft: PropTypes.bool,
  sideMargins: PropTypes.bool,
  className: PropTypes.string,
};

export default KpiTarget;
