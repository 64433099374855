exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* VIEWPORTS */\n\n:root {\n}\n\n.CoreDataInput__modalInput___2_mhA {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n  min-width: 300px;\n  padding: 17px;\n  max-height: 100vh;\n}\n\n@media (max-width: 800px) {\n\n  .CoreDataInput__modalInput___2_mhA {\n    overflow: scroll;\n  }\n}\n\n.CoreDataInput__modalInput___2_mhA .CoreDataInput__box___3k4tC {\n  padding: 10px;\n}\n\n.CoreDataInput__modalInput___2_mhA .CoreDataInput__box___3k4tC > div {\n  margin-top: 10px;\n}\n\n.CoreDataInput__modalInput___2_mhA > div {\n  margin: 10px;\n  margin-bottom: 0px;\n}\n\n.CoreDataInput__buttonWrapper___LXtUo {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: end;\n      -ms-flex-pack: end;\n          justify-content: flex-end;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  margin: 20px;\n}\n\n.CoreDataInput__buttonWrapper___LXtUo > button {\n  margin: 10px;\n}\n\n.CoreDataInput__fieldButtonWrapper___kjMwy {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n}\n", ""]);

// exports
exports.locals = {
	"modalInput": "CoreDataInput__modalInput___2_mhA",
	"box": "CoreDataInput__box___3k4tC",
	"buttonWrapper": "CoreDataInput__buttonWrapper___LXtUo",
	"fieldButtonWrapper": "CoreDataInput__fieldButtonWrapper___kjMwy"
};