import { logoutSuccess } from 'redux/modules/user/user';

const basicHeader = {
  headers: {
    Authorization: `Basic ${btoa('statenslokaler:lokalerstatens')}`,
  },
};

const callUrl = url =>
  fetch(url, basicHeader)
    .then(res => {
      if (!res.ok) throw Error('could not fetch url', res);
      return res;
    })
    .then(response => response.text());

const API_ROOT = callUrl('/api/url', 'text');

const buildUrl = endpoint => API_ROOT.then(path => `${path}${endpoint}`);

export async function redirectToLogin() {
  const origin =
    window.location.origin ||
    `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? `:${window.location.port}` : ''
    }`;
  const state = window.location.pathname;
  const path = await buildUrl(
    `/oauth/authorize?client_id=statenslokaler&response_type=token&redirect_uri=${origin}&state=${state}`,
  );
  window.location = path;
}

async function redirectToLogout() {
  const path = await buildUrl('');
  window.location = `${path}/logout?redirect_uri=${window.location.origin}`;
}

const checkStatus = response => {
  if (!response.ok) {
    if (response.status === 401) {
      redirectToLogin();
      logoutSuccess();
      throw Error('Redirecting to login...');
    }
    const error = new Error(
      `${response.status} ${
        response.statusText ? response.statusText : 'Response not OK'
      }`,
    );
    error.response = response;
    throw error;
  }
  return response;
};

const openExcel = async response => {
  const blob = await response.blob();
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob);
  } else {
    const link = URL.createObjectURL(blob);
    window.open(link);
  }
};

const stringify = b => JSON.stringify(b);

const extractResponse = response => {
  if (response.path) return response;
  const contentType = response.headers.get('content-type');
  if (contentType && contentType.indexOf('application/json') !== -1) {
    return response.json();
  }
  if (contentType && contentType.indexOf('text/plain') !== -1) {
    return response.text();
  }
  if (contentType && contentType.indexOf('application/pdf') !== -1) {
    return response.blob();
  }
  if (
    contentType &&
    contentType.indexOf(
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ) !== -1
  ) {
    openExcel(response);
    return true;
  }
  console.log(`Unknown content type: ${contentType}`);
  return true;
};

const call = async (url, opt) => {
  try {
    const response = await fetch(url, opt || basicHeader);
    return extractResponse(checkStatus(response));
  } catch (e) {
    console.error('fetch returned error promise:');
    console.error(e);
    if (e.toString() === 'TypeMismatchError') {
      redirectToLogin();
    }
    throw e;
  }
};

const buildGet = endpoint =>
  buildUrl(endpoint).then(url => [
    url,
    {
      mode: endpoint === '/logout' ? 'no-cors' : 'cors',
      headers:
        endpoint === '/vedleggsrapport/latest'
          ? {}
          : {
              Authorization: `Bearer ${localStorage.token}`,
              'Access-Control-Max-Age': 600,
            },
    },
  ]);

const buildPost = (endpoint, query) =>
  buildUrl(endpoint).then(url => {
    const contentHeaders = query
      ? { 'Content-Type': 'application/json' }
      : { 'Content-Length': 0 };
    return [
      url,
      {
        method: 'post',
        mode: endpoint === '/logout' ? 'no-cors' : 'cors',
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
          'Access-Control-Max-Age': 600,
          ...contentHeaders,
        },
        body: endpoint === '/kart/wfs' ? query : stringify(query),
      },
    ];
  });

const buildPut = async (endpoint, query) => {
  const url = await buildUrl(endpoint);
  const contentHeaders = query
    ? { 'Content-Type': 'application/json' }
    : { 'Content-Length': 0 };
  return [
    url,
    {
      method: 'put',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${localStorage.token}`,
        'Access-Control-Max-Age': 600,
        ...contentHeaders,
      },
      body: stringify(query),
    },
  ];
};

const buildDelete = endpoint =>
  buildUrl(endpoint).then(url => [
    url,
    {
      method: 'delete',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${localStorage.token}`,
        'Content-Type': 'multipart/form-data',
      },
    },
  ]);

async function callApi(endpoint, method, query) {
  if (method === 'get') {
    const [url, opt] = await buildGet(endpoint);
    return call(url, opt);
  }
  if (method === 'put') {
    const [url, opt] = await buildPut(endpoint, query);
    return call(url, opt);
  }
  if (method === 'post') {
    const [url, opt] = await buildPost(endpoint, query);
    return call(url, opt);
  }
  if (method === 'delete') {
    const [url, opt] = await buildDelete(endpoint);
    return call(url, opt);
  }
  return console.log('no valid method specified');
}

export const postFrontpage = async (_, formData) => {
  const url = await buildUrl('/forside');
  return call(url, {
    method: 'POST',
    body: formData,
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${localStorage.token}`,
      'Access-Control-Max-Age': 600,
    },
  });
};

export const getDataItem = url => callApi(url, 'get');
export const setDataItem = (path, query) => callApi(path, 'post', query);
export const deleteItem = url => callApi(url, 'delete');
export const logout = () => redirectToLogout();
export const getVersion = () => call('/api/info');
export const putItem = (path, payload) => callApi(path, 'put', payload);
