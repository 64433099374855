import React from 'react';
import PropTypes from 'prop-types';
import { Pagination as BsPagination } from 'react-bootstrap';
import { Icon } from 'components';
import polyglot from 'i18n';
import styles from './Pagination.css';

const Pagination = ({ pages, active, onSelect }) => (
  <BsPagination
    prev={<Icon icon="arrow-left" green small />}
    next={<Icon icon="arrow-right" green small />}
    ellipsis
    first={<Icon icon="angle-double-left" green small />}
    items={pages}
    maxButtons={5}
    activePage={active + 1}
    onSelect={e => onSelect(e - 1)}
    className={styles.pagination}
    aria-label={polyglot.t('pagination.velgSidetall')}
  />
);

Pagination.propTypes = {
  pages: PropTypes.number.isRequired,
  active: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default Pagination;
