import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FrontPageForm } from 'components';
import { getForside, postForside } from 'redux/modules/frontpage/frontpage';
import { displayAlert } from 'redux/modules/widgets/widgets';

class FrontPageAdmin extends Component {
  componentDidMount() {
    this.props.dispatch(getForside());
  }

  componentDidUpdate(prevProps) {
    const { dispatch, frontpageRedux } = this.props;
    if (frontpageRedux.postSuccess && !prevProps.frontpageRedux.postSuccess) {
      dispatch(
        displayAlert({
          text: 'Lagret ny forside',
          kind: 'success',
        }),
      );
    }
  }

  render() {
    return (
      <FrontPageForm
        {...this.props.frontpageRedux}
        postForside={values => this.props.dispatch(postForside(values))}
      />
    );
  }
}

FrontPageAdmin.propTypes = {
  frontpageRedux: PropTypes.shape({
    response: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.object,
  }),
};

const mapStateToProps = state => ({
  frontpageRedux: state.frontpage.frontpage,
});

export default connect(mapStateToProps)(FrontPageAdmin);
