// @flow

import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { DashboardTemplate } from 'components';
import type {
  TableState,
  UserData,
  Lokale,
  RouterHistory,
  PowerBI,
} from 'types';

import {
  extractHash,
  statsDate,
  toGuiDate,
  toApiDate,
  recursiveFind,
  getLevelFromUrl,
} from 'utils/utils';
import searchRequestBuilder, {
  eierFilterFields,
  lokaleBrukerFields,
} from 'utils/searchApiHelpers';
import userSelector from 'redux/modules/user/userSelector';
import getStatsSorted, {
  getRapportMetaFromState,
} from 'redux/modules/search/statsSelector';
import * as searchActions from 'redux/modules/search/search';
import * as tableActions from 'redux/modules/search/table';
import * as reportActions from 'redux/modules/report/vedleggsrapport';
import ErrorBoundary from '../ErrorBoundary';

type Props = {
  userData: UserData,
  orgnr: string,
  location: {
    search: string,
    pathname: string,
  },
  latestReport: {
    filepath: string,
    timestamp: string,
  },
  stats: {
    lokale: {
      tables: {},
      list: Array<{
        name: string,
        value: number,
        global?: number,
      }>,
    },
    bygg: {
      value: number,
    },
    eiendom: { value: number },
    grunneiendom: {
      value: number,
    },
  },
  rapportMetaStats: {
    rapportMeta: {
      aggregeringsnivaa: string,
      rapportId: string,
      datasetId: string,
      embedUrl: string,
      token: string,
    },
  },
  lokaleData: {
    result: ?Array<Lokale>,
  },
  history: RouterHistory,
  actions: {
    getLatest(): void,
    updateTable(string, {}): void,
    getStats({
      type: string,
      level: string,
      filter?: [
        {
          field: string,
          value: number,
        },
      ],
    }): void,
    searchRequest({}): void,
  },
  table: TableState,
  kpiTable: TableState,
  loadingStats: boolean,
  powerBIReportLevels: PowerBI.reportLevels,
};

type State = {
  statUser: {
    navn?: string,
    organisasjonsnummer: number,
  },
  depUser: {
    navn?: string,
    organisasjonsnummer: number,
  },
};

class Dashboard extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    const {
      userData: { organisasjon, isDep },
    } = props;
    this.state = {
      kpiDate: toGuiDate(statsDate()),
      statUser: organisasjon,
      depUser: isDep ? organisasjon : organisasjon.overordnet || {},
    };
  }

  componentDidMount() {
    const {
      actions,
      userData: { isGlobal },
    } = this.props;
    const newUrl = this.pushStartUrl();
    this.getStats(newUrl);
    if (isGlobal) {
      this.getReports();
    } else this.getLokaler();
    actions.getLatest();
  }

  componentDidUpdate(prevProps) {
    const {
      location,
      actions,
      table,
      userData: { isGlobal },
      loadingStats,
      kpiTable,
    } = this.props;
    const query = extractHash(location.search);
    const prevQuery = extractHash(prevProps.location.search);
    if (query.page !== prevQuery.page) {
      // page got updated in url, update in redux
      actions.updateTable('dashboardTable', {
        page: parseInt(query.page, 10) || 0,
      });
    }

    if (JSON.stringify(table) !== JSON.stringify(prevProps.table)) {
      // table got updated in redux, update search
      if (isGlobal) {
        this.getReports(table);
      } else this.getLokaler(table);
    }

    if (prevProps.location.pathname !== location.pathname) {
      if (!loadingStats) this.getStats();
      if (kpiTable.page !== 0) actions.updateTable('kpiTable', { page: 0 });
    }
  }

  pushStartUrl = () => {
    const {
      history: { push },
      userData: { isDep, isOrg, isPublic },
    } = this.props;

    if (isPublic) {
      push('/d/hjem');
      return '/d/hjem';
    }
    if (isDep) {
      push('/d/hjem/dep');
      return '/d/hjem/dep';
    }
    if (isOrg) {
      push('/d/hjem/dep/org');
      return '/d/hjem/dep/org';
    }

    push('/d/hjem');
    return '/d/hjem';
  };

  getStats = newUrl => {
    const { depUser, statUser, kpiDate } = this.state;
    const { location, actions } = this.props;
    const currentLevel = getLevelFromUrl(newUrl || location.pathname);
    const orgnr =
      currentLevel === 'DEPARTEMENT'
        ? depUser.organisasjonsnummer
        : statUser.organisasjonsnummer;

    const search = {
      type: 'lokale',
      level: currentLevel,
      filter: this.orgFilter(currentLevel, orgnr),
    };

    actions.getStats(search, toApiDate(kpiDate));
  };

  changeKpiDate = e => {
    this.setState({ kpiDate: e.target.innerText }, () => this.getStats());
  };

  orgFilter = (currentLevel, orgnr) => {
    switch (currentLevel) {
      case 'VIRKSOMHET':
        return [
          {
            field: lokaleBrukerFields.concat(eierFilterFields),
            value: orgnr,
          },
        ];
      case 'DEPARTEMENT':
        return [
          {
            field: lokaleBrukerFields.concat(eierFilterFields),
            value: orgnr,
          },
        ];
      default:
        return undefined;
    }
  };

  getLokaler = (table = this.props.table) => {
    const { userData, actions } = this.props;
    actions.searchRequest(
      searchRequestBuilder({
        type: 'leideLokaler',
        table,
        userData,
      }),
    );
  };

  getReports = (table = this.props.table) => {
    const { userData, actions } = this.props;
    actions.searchRequest(
      searchRequestBuilder({
        type: 'rapporter',
        table,
        userData,
      }),
    );
  };

  handleDownload = () => {
    window.open(this.props.latestReport.filepath);
  };

  bodyClick = e => {
    const { lokaleData, history } = this.props;
    if (Array.isArray(lokaleData.result)) {
      history.push(`/d${lokaleData.result[e]._links.self}`);
    }
  };

  drilldownFunc = (currentTable, i) => {
    const {
      stats: { lokale },
      history: { push },
      kpiTable: { shown, page },
    } = this.props;
    const { depUser, statUser } = this.state;
    const clickedObj = lokale.tables[currentTable][i + page * shown];
    const currentLevel = getLevelFromUrl(location.pathname);
    if (currentLevel === 'VIRKSOMHET') {
      push(`/d/lokale/${clickedObj.value}`);
      return;
    }
    const toDep = currentLevel === 'STATEN';
    const obj = {
      navn: clickedObj.name,
      organisasjonsnummer: clickedObj.value,
    };

    this.setState({
      depUser: toDep ? obj : depUser,
      statUser: !toDep ? obj : statUser,
    });

    push(toDep ? '/d/hjem/dep' : '/d/hjem/dep/org');
  };

  sortClick = e => {
    const { actions, table } = this.props;
    const newSort = recursiveFind(e.target, 'cellIndex');
    const { sortCol, sortAsc } = table;
    actions.updateTable('dashboardTable', {
      sortAsc: sortCol === newSort ? !sortAsc : true,
      sortCol: newSort,
    });
  };

  kpiTableSort = e => {
    const {
      actions,
      kpiTable: { sortAsc, sortCol },
    } = this.props;
    const newSort = recursiveFind(e.target, 'cellIndex');
    actions.updateTable('kpiTable', {
      sortAsc: sortCol === newSort ? !sortAsc : true,
      sortCol: newSort,
    });
  };

  changePage = e => {
    this.props.history.push({
      pathname: this.props.location.pathname,
      search: `?page=${e}`,
    });
  };

  changeKpiTablePage = e => {
    this.props.actions.updateTable('kpiTable', {
      page: e,
    });
  };

  render() {
    const { depUser, statUser } = this.state;
    const {
      userData: { isGlobal, isDep, isPublic },
      stats,
      rapportMeta,
      latestReport,
      lokaleData,
      ...rest
    } = this.props;
    const currentLevel = getLevelFromUrl(location.pathname);
    const userName =
      currentLevel === 'DEPARTEMENT' ? depUser.navn || '' : statUser.navn || '';

    const powerBIprops = rapportMeta
      ? {
          aggregeringsnivaa: rapportMeta.aggregeringsnivaa,
          rapportId: rapportMeta.rapportId,
          datasetId: rapportMeta.datasetId,
          embedUrl: rapportMeta.embedUrl,
          token: rapportMeta.token,
        }
      : {};

    return (
      <ErrorBoundary>
        <DashboardTemplate
          currentVirksomhet={currentLevel === 'STATEN' ? 'staten' : userName}
          eiendommer={stats.eiendom ? stats.eiendom.value : 0}
          bygninger={stats.bygg ? stats.bygg.value : 0}
          grunneiendommer={stats.grunneiendom ? stats.grunneiendom.value : 0}
          handleDownload={this.handleDownload}
          reportTimestamp={toGuiDate(latestReport.timestamp, true)}
          lokaleStats={stats.lokale}
          data={lokaleData}
          changePage={this.changePage}
          bodyClick={this.bodyClick}
          sortClick={this.sortClick}
          drilldownFunc={this.drilldownFunc}
          changeKpiTablePage={this.changeKpiTablePage}
          isGlobal={isGlobal}
          isDep={isDep}
          isPublic={isPublic}
          kpiTableSort={this.kpiTableSort}
          changeKpiDate={this.changeKpiDate}
          currentLevel={currentLevel}
          powerBIprops={powerBIprops}
          {...rest}
          {...this.state}
        />
      </ErrorBoundary>
    );
  }
}

function mapStateToProps(state) {
  return {
    userData: userSelector(state),
    rapportMeta: getRapportMetaFromState(state),
    stats: getStatsSorted(state),
    loadingStats: state.search.loadingStats,
    orgnr: state.user.data.organisasjon.organisasjonsnummer,
    latestReport: state.vedleggsrapport.latestReport,
    lokaleData: state.search.data,
    loadingTable: state.search.loading,
    table: state.table.dashboardTable,
    kpiTable: state.table.kpiTable,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...searchActions,
        ...tableActions,
        ...reportActions,
      },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Dashboard);
