exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PowerBIAdminTemplate__buttonWrapper___3LPgy {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: end;\n      -ms-flex-pack: end;\n          justify-content: flex-end;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  margin: 20px;\n}\n", ""]);

// exports
exports.locals = {
	"buttonWrapper": "PowerBIAdminTemplate__buttonWrapper___3LPgy"
};