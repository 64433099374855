// @flow

import React from 'react';
import { Button, Icon } from 'components';
import styles from './SearchFilterCheckbox.css';
import polyglot from 'i18n';

type Props = {
  onChange(boolean): void,
  checked: boolean,
  label: string,
};

const SearchFilterCheckbox = ({ onChange, checked, label }: Props) => (
  <div className={styles.wrapper}>
    <Button
      secondaryButton
      className={styles.filterButton}
      onClick={e => {
        e.preventDefault();
        onChange(!checked);
      }}
      aria-controls={label}
      aria-expanded={checked ? 'true' : 'false'}
      aria-label={polyglot.t('searchFilter.apneValgFor') + label}
    >
      {label}
      <Icon icon={checked ? 'angle-up' : 'angle-down'} spaced size="1" />
    </Button>
  </div>
);

export default SearchFilterCheckbox;
