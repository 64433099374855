import React from 'react';
import PropTypes from 'prop-types';
import polyglot from 'i18n';
import styles from './PropertyAdressEdit.css';
import * as propertyAction from 'redux/modules/property/property';
import * as widgetsActions from 'redux/modules/widgets/widgets';
import { toGuiDate, toApiDate } from 'utils/utils';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Dialog from '../../../containers/Dialog';
import { Button, ModalButton, SelectField, Icon, Bubbles } from 'components';

class PropertyAdressEdit extends React.PureComponent {
  state = {
    editMode: false,
    loading: false,
    inputName: {
      value: this.props.property.adresse.id,
      label: this.props.property.adresse.adressenavn,
    },
    kpiDate: toGuiDate(),
  };

  toggleModal = () => {
    this.setState(prevState => ({
      editMode: !prevState.editMode,
    }));
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onAdressChange = e => {
    const { inputName } = this.state;
    this.setState({
      inputName: {
        ...e,
      },
    });
  };

  refresh = () => {
    const { kpiDate } = this.state;
    const { actions } = this.props;
    this.setState(this.inputState);
    const isCurrentDate = kpiDate === toGuiDate();
    actions.getProperty(
      `${this.props.property.matrikkelId}/${
        isCurrentDate ? '' : toApiDate(kpiDate)
      }`,
    );
  };

  handleSubmit = () => {
    const { inputName } = this.state;
    const { property, actions } = this.props;
    if (inputName.label !== property.adresse.adressenavn) {
      this.setState({
        loading: true,
      });
      actions.setPropertyAdresse({
        id: property.id,
        value: `${inputName.value}`,
      });
      setTimeout(() => {
        this.refresh();
        this.toggleModal();
      }, 7000);
    } else {
      this.toggleModal();
    }
  };

  render() {
    const {
      property: { editable, adresser },
    } = this.props;
    const { editMode, inputName, loading } = this.state;
    if (adresser && adresser.length > 1) {
      return (
        <div className={styles.wrapper}>
          {editable && (
            <ModalButton
              toggle={this.toggleModal}
              link
              showModal={editMode}
              icon={<Icon icon="pencil" size="2" spaced />}
              buttonTitle={polyglot.t('property.buttonTitle')}
              content={
                <Dialog
                  onExit={this.toggleModal}
                  render={props => (
                    <div className={styles.modalWrapper}>
                      <SelectField
                        clearable={false}
                        className={styles.addressDialog}
                        label={polyglot.t('property.adresse.adressenavn')}
                        selectedValue={inputName.value}
                        wide
                        onChange={this.onAdressChange}
                        choices={adresser
                          .sort((a, b) => a.id - b.id)
                          .map(adress => ({
                            value: adress.id,
                            label: adress.adressenavn,
                          }))}
                      />
                      <Button kind="primary" onClick={this.handleSubmit}>
                        {!loading ? polyglot.t('buttons.save') : <Bubbles />}
                      </Button>
                      <Button secondaryButton onClick={this.toggleModal}>
                        {polyglot.t('buttons.cancel')}
                      </Button>
                    </div>
                  )}
                />
              }
            />
          )}
        </div>
      );
    } else {
      return '';
    }
  }
}

PropertyAdressEdit.propTypes = {
  actions: PropTypes.shape({
    setPropertyAdresse: PropTypes.func,
  }),
  property: PropTypes.shape({
    navn: PropTypes.string,
    adresser: PropTypes.array,
    id: PropTypes.number,
  }),
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...propertyAction,
        ...widgetsActions,
      },
      dispatch,
    ),
  };
}

export default connect(
  null,
  mapDispatchToProps,
)(PropertyAdressEdit);
