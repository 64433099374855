exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* VIEWPORTS */\n\n:root {\n}\n\n.DashboardBadge__wrapper___ViC6G {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n  padding: 25px;\n}\n\n@media (min-width: 1325px) {\n\n  .DashboardBadge__wrapper___ViC6G {\n    min-width: 300px;\n    width: -webkit-max-content;\n    width: -moz-max-content;\n    width: max-content;\n  }\n}\n\n.DashboardBadge__wrapper___ViC6G > * {\n  margin: 0;\n}\n\n.DashboardBadge__heading___gBaPi {\n  font-weight: normal;\n}\n\n.DashboardBadge__button___3bd2f {\n  text-align: center;\n  line-height: 19px;\n  margin-top: 20px;\n  min-width: 154px;\n  width: -webkit-max-content;\n  width: -moz-max-content;\n  width: max-content;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n}\n\n.DashboardBadge__number___1N8eq {\n  margin-top: 20px;\n  font-size: 1.5em;\n  font-weight: bold;\n}\n", ""]);

// exports
exports.locals = {
	"wrapper": "DashboardBadge__wrapper___ViC6G",
	"heading": "DashboardBadge__heading___gBaPi",
	"button": "DashboardBadge__button___3bd2f",
	"number": "DashboardBadge__number___1N8eq"
};