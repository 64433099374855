exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".WizardStep2__buttonWrapper___-zE6Z {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n  margin: 20px 0px;\n}\n\n.WizardStep2__backButton___UyajF,\n.WizardStep2__backButton___UyajF:hover,\n.WizardStep2__backButton___UyajF:active,\n.WizardStep2__backButton___UyajF:focus,\n.WizardStep2__backButton___UyajF:active:focus {\n  min-width: 154px;\n  width: -webkit-max-content;\n  width: -moz-max-content;\n  width: max-content;\n  min-height: 40px;\n  height: -webkit-max-content;\n  height: -moz-max-content;\n  height: max-content;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n}\n\n.WizardStep2__nextButton___3Sn4o,\n.WizardStep2__nextButton___3Sn4o:hover,\n.WizardStep2__nextButton___3Sn4o:active,\n.WizardStep2__nextButton___3Sn4o:focus,\n.WizardStep2__nextButton___3Sn4o:active:focus {\n  min-width: 200px;\n  width: -webkit-max-content;\n  width: -moz-max-content;\n  width: max-content;\n  min-height: 40px;\n  height: -webkit-max-content;\n  height: -moz-max-content;\n  height: max-content;\n}\n\n.WizardStep2__intro___1-21e textarea {\n  min-height: 130px;\n}\n\n.WizardStep2__main___12Spo textarea {\n  min-height: 325px;\n}\n", ""]);

// exports
exports.locals = {
	"buttonWrapper": "WizardStep2__buttonWrapper___-zE6Z",
	"backButton": "WizardStep2__backButton___UyajF",
	"nextButton": "WizardStep2__nextButton___3Sn4o",
	"intro": "WizardStep2__intro___1-21e",
	"main": "WizardStep2__main___12Spo"
};