export const GET_USER = 'GET_USER';
export const RECEIVED_USER = 'RECEIVED_USER';
export const GET_USER_FAILED = 'GET_USER_FAILED';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';

export const getUser = statePath => ({
  type: GET_USER,
  path: '/user',
  statePath,
});
export const receivedUser = (user, path) => ({
  type: RECEIVED_USER,
  user,
  path,
});
export const getUserFailed = error => ({ type: GET_USER_FAILED, error });

export const logout = () => ({ type: LOGOUT });
export const logoutSuccess = () => ({ type: LOGOUT_SUCCESS });
export const logoutFailed = error => ({ type: LOGOUT_FAILED, error });

export const initialState = {
  data: {
    tilganger: [],
    roles: [],
    organisasjon: {},
  },
  waitingForUser: false,
  isAuthenticated: false,
  waitingForLogout: false,
  logoutFailed: false,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case GET_USER: {
      return Object.assign({}, state, {
        ...initialState,
        waitingForUser: true,
      });
    }
    case RECEIVED_USER: {
      return Object.assign({}, state, {
        data: action.user,
        waitingForUser: false,
        isAuthenticated: true,
      });
    }
    case GET_USER_FAILED: {
      return Object.assign({}, state, {
        isAuthenticated: false,
        error: action.error,
      });
    }
    case LOGOUT: {
      return Object.assign({}, state, {
        waitingForLogout: true,
        isAuthenticated: false, // remove when we have the correct server response
        data: {}, // ^^^
      });
    }
    case LOGOUT_SUCCESS:
      return initialState;
    case LOGOUT_FAILED:
      return Object.assign({}, state, {
        waitingForLogout: false,
        logoutFailed: true,
      });
    default:
      return state;
  }
}
