import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getForsidePublic } from 'redux/modules/frontpage/frontpage';
import { FrontPageTemplate, Footer } from 'components';

class FrontPageContainer extends PureComponent {
  componentDidMount() {
    this.props.actions.getForsidePublic();
  }

  render() {
    const { loading } = this.props;
    if (loading) return null;
    return <FrontPageTemplate footer={<Footer frontpage />} {...this.props} />;
  }
}

FrontPageContainer.propTypes = {};

FrontPageContainer.contextTypes = {
  router: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    forside: state.frontpage.frontpagePublic.response,
    loading: state.frontpage.frontpagePublic.loading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ getForsidePublic }, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FrontPageContainer);
