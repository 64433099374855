// @flow

import React, { Fragment } from 'react';
import type { Node } from 'react';
import type { PowerBI } from 'types';
import polyglot from 'i18n';
import { Heading, Button } from 'components';

import styles from './PowerBIReport.css';

type Props = {
  id: string,
  report: PowerBI.report,
  close(): void,
  children: Node,
  fullscreenReport(): void,
};

const PowerBIReport = ({
  id,
  close,
  report,
  children,
  fullscreenReport,
}: Props) => (
  <Fragment>
    {close && (
      <div className={styles.wrapper}>
        <Button kind="primary" id="fullscreen" onClick={fullscreenReport}>
          {polyglot.t('buttons.fullscreen')}
        </Button>
        <Button kind="primary" id="close" onClick={close}>
          {polyglot.t('buttons.close')}
        </Button>
      </div>
    )}
    {!id && 'PowerBI ID is missing'}
    {report && <Heading level={1}>STATENS LOKALER {report.name}</Heading>}
    {children}
  </Fragment>
);

export default PowerBIReport;
