import React from 'react';
import PropTypes from 'prop-types';
import styles from './Label.css';

/**
 * Label component
 */
const Label = ({ ...props }) => <label className={styles.label} {...props} />;

Label.propTypes = {
  custom: PropTypes.bool,
  id: PropTypes.string,
};

export default Label;
