// @flow
import * as React from 'react';
import polyglot from 'i18n';

import {
  SearchBox,
  Heading,
  Panel,
  GlobalSearchFilter,
  LoadingOverlay,
  TableView,
  MapLegend,
  SearchResultToggle,
} from 'components';
import type { Table, UserData } from 'types';

import LeafletMap from 'containers/Map/LeafletMap';
import mapData, { apiFields, getHeaders } from 'utils/mapApiData';
import styles from './GlobalSearchTemplate.css';

type Props = {
  table: Table,
  data: {
    hits: number,
    result: Array<any>,
  },
  userData: UserData,
  tableClick(): void,
  loading: boolean,
  changePage(): void,
  toggleMap: boolean,
  sortClick(): void,
  currentSelection: { navn?: string },
};

const GlobalSearchTemplate = ({
  table,
  data,
  userData: { isPublic, isOrg, organisasjon },
  tableClick,
  loading,
  changePage,
  sortClick,
  ...props
}: Props) => {
  const { hits, result = [] } = data;
  const { currentSelection, toggleMap } = props;
  return (
    <div>
      <div className={styles.headerWrapper}>
        <Heading level={0}>{polyglot.t('globalSearch.heading')}</Heading>
        {currentSelection.navn && (
          <Heading level={3}>{`Utvalg: ${currentSelection.navn}`}</Heading>
        )}
      </div>
      <Panel>
        <SearchBox
          searchPlaceholder="Søk"
          isGlobal={!isOrg}
          type="globalSearch"
          searchButton
          table={table}
          loading={loading}
          hits={hits}
          {...props}
        />
      </Panel>
      <div className={styles.wrapper}>
        {loading && <LoadingOverlay />}
        <Panel className={styles.filterBox}>
          <GlobalSearchFilter {...{ table, isPublic, isOrg, ...props }} />
        </Panel>

        {table.map ? (
          <Panel className={styles.resultBox}>
            <SearchResultToggle toggleMap={toggleMap} map={table.map} />
            <LeafletMap
              markers={result
                .filter(
                  it =>
                    it.plassering &&
                    (it.type === 'lokale' || it.type === 'grunneiendom'),
                )
                .map(it => ({ ...it, sensitive: !isPublic }))}
              search
              loadingSearch={loading}
              myOrg={isOrg ? organisasjon.organisasjonsnummer : undefined}
            />
            <div className={styles.legend}>
              {!isPublic &&
                isOrg && <MapLegend icon="map-pin-blue" text="Mine lokaler" />}
              <MapLegend icon="map-pin-blue-filled" text="Statlige lokaler" />
              {!isPublic &&
                isOrg && (
                  <MapLegend icon="map-pin-orange" text="Mine eiendommer" />
                )}
              <MapLegend
                icon="map-pin-orange-filled"
                text="Statlige eiendommer"
              />
            </div>
          </Panel>
        ) : (
          <Panel className={styles.resultBox}>
            <SearchResultToggle toggleMap={toggleMap} map={table.map} />
            <TableView
              dataRows={result.map(row =>
                mapData(
                  row,
                  apiFields.globalSearch.searchTable
                    .filter(ci => (isPublic ? !ci.sensitive : true))
                    .map(
                      cellItem =>
                        row.type === 'lokale'
                          ? cellItem.lokaleField
                          : cellItem.propertyField,
                    ),
                ),
              )}
              hits={hits}
              table={table}
              changePage={changePage}
              drilldown
              bodyClick={tableClick}
              sortClick={sortClick}
              hideOptions={true}
              headerRow={getHeaders(
                apiFields.globalSearch.searchTable
                  .filter(ci => (isPublic ? !ci.sensitive : true))
                  .map(it => it.lokaleField),
                'leideLokaler',
              )}
              rightAlignArray={apiFields.globalSearch.searchTable.map(
                it => it.rightAlign,
              )}
            />
          </Panel>
        )}
      </div>
    </div>
  );
};

export default GlobalSearchTemplate;
