import React from 'react';
import { ModalButton, GenericModal, Field } from 'components';

const AddProjectModal = ({
  toggleModal,
  buttonTitle,
  showModal,
  onInputChange,
  navn,
  prosjektnummer,
  ...props
}) => (
  <ModalButton
    showModal={showModal}
    primaryButton
    toggle={toggleModal}
    buttonTitle={buttonTitle}
  >
    <GenericModal
      renderInputs={({ firstRef }) => (
        <React.Fragment>
          <Field
            refFunc={firstRef}
            name="navn"
            label="Navn på prosjektet"
            onChange={onInputChange}
            value={navn}
          />
          <Field
            name="prosjektnummer"
            label="Prosjektnummer"
            onChange={onInputChange}
            value={prosjektnummer}
          />
        </React.Fragment>
      )}
      onCancel={toggleModal}
      {...props}
    />
  </ModalButton>
);

export default AddProjectModal;
