// @flow

import React from 'react';
import L from 'leaflet';
import { Marker } from 'react-leaflet';
import { withRouter } from 'react-router-dom';

import styles from './MapMarker.css';

const BuildingPickerMarker = ({
  position,
  filled,
  item,
  history,
  onBuildingPick,
}) => {
  const color =
    item.type === 'lokale' || item.type === 'bygning' ? 'blue' : 'orange';
  const icon = L.icon({
    // $FlowFixMe
    iconUrl: require(`components/atoms/Icon/icons/map-pin-${color}${
      filled ? '-filled' : ''
    }.png`),
    iconSize: [30, 45],
    iconAnchor: [22, 54],
    popupAnchor: [-3, -76],
  });

  return (
    <Marker
      position={position}
      icon={icon}
      className={styles.marker}
      onClick={() => onBuildingPick(item.bygningsnr)}
    />
  );
};

export default withRouter(BuildingPickerMarker);
