// @flow

import * as React from 'react';
import { Button } from 'components';
import polyglot from 'i18n';
import Dialog from '../../../containers/Dialog';
import styles from './Modal.css';

type Props = {
  renderInputs: ({ lastRef: string }) => React.Node,
  onCancel: () => {},
  saveProps: { onClick?: () => {}, disabled?: boolean },
  deleteComponent?: React.Node,
  handleSubmit: () => void,
  showInput?: boolean,
};

const GenericModal = ({
  saveProps = {},
  onCancel,
  renderInputs,
  deleteComponent,
  handleSubmit,
  showInput,
}: Props) => {
  const content = ({ lastRef, ...props }) => (
    <React.Fragment>
      {renderInputs(props)}
      <div className={styles.bottomLine}>
        {deleteComponent}
        {!showInput && (
          <div className={styles.buttonWrapper}>
            <Button
              id="cancel"
              refFunc={saveProps.disabled ? lastRef : undefined}
              secondaryButton
              onClick={onCancel}
            >
              {polyglot.t('buttons.cancel')}
            </Button>
            <Button
              kind="primary"
              {...saveProps}
              submit={!!handleSubmit}
              refFunc={!saveProps.disabled ? lastRef : undefined}
            >
              {polyglot.t('newContract.lagre')}
            </Button>
          </div>
        )}
      </div>
    </React.Fragment>
  );
  return (
    <Dialog
      onExit={onCancel}
      render={props =>
        handleSubmit ? (
          <form onSubmit={handleSubmit} className={styles.dialogWrapper}>
            {content(props)}
          </form>
        ) : (
          <div className={styles.dialogWrapper}>{content(props)}</div>
        )
      }
    />
  );
};

export default GenericModal;
