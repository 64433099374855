import { takeLatest, all } from 'redux-saga/effects';

import * as LEIDELOKALER from 'redux/modules/leideLokaler/leideLokaler';
import * as CONTRACTS from 'redux/modules/leideLokaler/contracts';
import * as LEIEOBJEKT from 'redux/modules/leideLokaler/leieobjekt';

import {
  handleCallToApi,
  handlePostToApi,
  handleDeleteCall,
} from './sagaHandlers';

export default function* lokalerSaga() {
  yield all([
    takeLatest(
      LEIDELOKALER.CREATE_LOKALER,
      handlePostToApi,
      LEIDELOKALER.createLokalerSuccess,
      LEIDELOKALER.createLokalerFailed,
    ),
    takeLatest(
      LEIDELOKALER.GET_LOKALE,
      handleCallToApi,
      LEIDELOKALER.getLokaleSuccess,
      LEIDELOKALER.getLokaleFailed,
    ),
    takeLatest(
      LEIDELOKALER.SET_LOKALE_PERIODE,
      handlePostToApi,
      LEIDELOKALER.setLokalePeriodeSuccess,
      LEIDELOKALER.setLokalePeriodeFailed,
    ),
    takeLatest(
      CONTRACTS.GET_ORG,
      handleCallToApi,
      CONTRACTS.getOrgSuccess,
      CONTRACTS.getOrgFailed,
    ),
    takeLatest(
      CONTRACTS.SAVE_CONTRACT,
      handlePostToApi,
      CONTRACTS.saveContractSuccess,
      CONTRACTS.saveContractFailed,
    ),
    takeLatest(
      CONTRACTS.MATRIKKEL_GET,
      handleCallToApi,
      CONTRACTS.matrikkelGetSuccess,
      CONTRACTS.matrikkelGetFailed,
    ),
    takeLatest(
      CONTRACTS.UPDATE_CONTRACT_PERIOD,
      handlePostToApi,
      CONTRACTS.updateContractPeriodSuccess,
      CONTRACTS.updateContractPeriodFailed,
    ),
    takeLatest(
      CONTRACTS.ADD_PROPERTY,
      handlePostToApi,
      CONTRACTS.addPropertySuccess,
      CONTRACTS.addPropertySuccess,
    ),
    takeLatest(
      CONTRACTS.DELETE_PROPERTY,
      handlePostToApi,
      CONTRACTS.deletePropertySuccess,
      CONTRACTS.deletePropertyFailed,
    ),
    takeLatest(
      LEIDELOKALER.SET_LOKALE_NAME,
      handlePostToApi,
      LEIDELOKALER.setLokaleNameSuccess,
      LEIDELOKALER.setLokaleNameFailed,
    ),
    takeLatest(
      LEIDELOKALER.SET_LOKALE_STATUS,
      handlePostToApi,
      LEIDELOKALER.setLokaleStatusSuccess,
      LEIDELOKALER.setLokaleStatusFailed,
    ),
    takeLatest(
      LEIDELOKALER.DELETE,
      handlePostToApi,
      LEIDELOKALER.deleteSuccess,
      LEIDELOKALER.deleteFailed,
    ),
    takeLatest(
      LEIDELOKALER.DELETE_PERIOD,
      handleDeleteCall,
      LEIDELOKALER.deletePeriodSuccess,
      LEIDELOKALER.deletePeriodFailed,
    ),
    takeLatest(
      LEIEOBJEKT.SAVE_LEIEOBJEKT,
      handlePostToApi,
      LEIEOBJEKT.saveLeieobjektSuccess,
      LEIEOBJEKT.saveLeieobjektFailed,
    ),
    takeLatest(
      LEIDELOKALER.GET_DATES,
      handleCallToApi,
      LEIDELOKALER.getDatesSuccess,
      LEIDELOKALER.getDatesFailed,
    ),
  ]);
}
