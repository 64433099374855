exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* STATENS LOKALER COLOR PALETTE */\n\n:root {\n\n  /* colors for input fields */\n\n  /* Button colors */\n\n  /* Anchor colors */\n\n  /* STATSBYGG PRIMARY COLORS */\n\n  /* Packet colors */\n  /* Statsbygg Glassblå 01-03 */\n  /* SECONDARY COLORS - NEUTRAL */\n  /* Statsbygg Nøytral grå 01-02 */\n  /* Statsbygg Stein */\n  /* Statsbygg Jord */\n  /* TERTIARY COLORS - MARKER COLORS */\n  /* Statsbygg Sennep */\n  /* Statsbygg Multe */\n  /* Statsbygg Laks */\n  /* Statsbygg Lyng */\n}\n\n.Bubbles__spinner___fZ4ep {\n  width: 100%;\n  text-align: center;\n}\n\n.Bubbles__spinner___fZ4ep>div {\n  width: 18px;\n  height: 18px;\n  background-color: #232f3a;\n  border-radius: 100%;\n  display: inline-block;\n  -webkit-animation: Bubbles__sk-bouncedelay___JAquw 1.4s infinite ease-in-out both;\n  animation: Bubbles__sk-bouncedelay___JAquw 1.4s infinite ease-in-out both;\n}\n\n.Bubbles__spinner___fZ4ep .Bubbles__bounce1___3bGS- {\n  -webkit-animation-delay: -0.32s;\n  animation-delay: -0.32s;\n}\n\n.Bubbles__spinner___fZ4ep .Bubbles__bounce2___1bSX8 {\n  -webkit-animation-delay: -0.16s;\n  animation-delay: -0.16s;\n}\n\n@-webkit-keyframes Bubbles__sk-bouncedelay___JAquw {\n  0%,\n  80%,\n  100% {\n    -webkit-transform: scale(0)\n  }\n  40% {\n    -webkit-transform: scale(1.0)\n  }\n}\n\n@keyframes Bubbles__sk-bouncedelay___JAquw {\n  0%,\n  80%,\n  100% {\n    -webkit-transform: scale(0);\n    transform: scale(0);\n  }\n  40% {\n    -webkit-transform: scale(1.0);\n    transform: scale(1.0);\n  }\n}\n", ""]);

// exports
exports.locals = {
	"spinner": "Bubbles__spinner___fZ4ep",
	"sk-bouncedelay": "Bubbles__sk-bouncedelay___JAquw",
	"bounce1": "Bubbles__bounce1___3bGS-",
	"bounce2": "Bubbles__bounce2___1bSX8"
};