exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Logo__logo___2b2fk {\n  background-size: contain;\n}\n\n.Logo__footer___1ZPpE {\n  width: 140px;\n  height: 28px;\n}\n\n.Logo__frontpage___1J9O_ {\n  width: 201px;\n  height: 40px;\n}\n\n.Logo__paddingBottom___3xljx {\n  padding-bottom: 15px;\n}\n", ""]);

// exports
exports.locals = {
	"logo": "Logo__logo___2b2fk",
	"footer": "Logo__footer___1ZPpE",
	"frontpage": "Logo__frontpage___1J9O_",
	"paddingBottom": "Logo__paddingBottom___3xljx"
};