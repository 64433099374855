import { createSelector } from 'reselect';
import mapData, { apiFields } from 'utils/mapApiData';

const getSelectionsFromState = state =>
  state.selections.allSelections.selections;

export const getSelectionFromState = state => state.selections.selection;

export default createSelector(
  [getSelectionsFromState],
  selections =>
    Array.isArray(selections)
      ? selections.map(selection => ({
          tableRow: mapData(
            selection,
            apiFields.selection.searchTable.map(cellItem => cellItem.field),
          ),
          id: selection.uuid,
        }))
      : [{}],
);
