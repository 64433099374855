exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".WizardButton__button___1Xn0L {\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n  width: 53px;\n  min-height: 53px;\n  background-color: #FFFFFF;\n  border: 6px solid #D8D8D8;\n  border-radius: 50%;\n  font-size: 1.5em;\n  line-height: 33px;\n  color: #232F3A;\n}\n\n.WizardButton__buttonWrapper___2NIj4 {\n  width: 80px;\n  min-height: 63px;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n}\n\n.WizardButton__label___3QwNi {\n  min-height: 20px;\n  font-size: 0.87rem;\n  text-align: center;\n}\n\n.WizardButton__active___1dTgA {\n  border-color: #88C9D0;\n}\n", ""]);

// exports
exports.locals = {
	"button": "WizardButton__button___1Xn0L",
	"buttonWrapper": "WizardButton__buttonWrapper___2NIj4",
	"label": "WizardButton__label___3QwNi",
	"active": "WizardButton__active___1dTgA"
};