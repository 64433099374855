export const UPDATE_TABLE = 'UPDATE_TABLE';
export const RESET_TABLE = 'RESET_TABLE';

export const TOGGLE_LIST_ITEM = 'TOGGLE_LIST_ITEM';
export const TOGGLE_LIST_ITEM_SUCCESS = 'TOGGLE_LIST_ITEM_SUCESS';
export const TOGGLE_LIST_ITEM_FAIL = 'TOGGLE_LISTITEM_FAIL';

export const TOGGLE_ORG = 'TOGGLE_ORG';
export const TOGGLE_ORG_SUCCESS = 'TOGGLE_ORG_SUCCESS';
export const TOGGLE_ORG_FAILED = 'TOGGLE_ORG_FAILED';

export const updateTable = (tableType, table) => ({
  type: UPDATE_TABLE,
  tableType,
  table,
});
export const resetTable = tableType => ({
  type: RESET_TABLE,
  tableType,
});

// List items (clickable checkboxes, icons etc)
export const toggleListItem = payload => ({
  type: TOGGLE_LIST_ITEM,
  path: `${payload.id}/lest/${payload.status}`,
});
export const toggleListItemSuccess = response => ({
  type: TOGGLE_LIST_ITEM_SUCCESS,
  response,
});
export const toggleListItemFail = error => ({
  type: TOGGLE_LIST_ITEM_FAIL,
  error,
});

export const toggleOrg = payload => ({
  type: TOGGLE_LIST_ITEM,
  path: `/organisasjon/${payload.id}/aktiv/${payload.status}`,
});
export const toggleOrgSuccess = response => ({
  type: TOGGLE_LIST_ITEM_SUCCESS,
  response,
});
export const toggleOrgFailed = error => ({
  type: TOGGLE_LIST_ITEM_FAIL,
  error,
});

const table = {
  page: 0,
  shown: 10,
  query: '',
  sortCol: 0,
  sortAsc: true,
  filters: {
    visMine: {
      value: true,
    },
    visLedige: {
      value: false,
    },
    visAktive: {
      value: true,
    },
    visUtgaatte: {
      value: false,
    },
  },
};

// Define initial state for this reducer
const initialState = {
  toggleFlag: false,
  loadingToggle: false,
  error: '',
  buildingTable: table,
  propertyTable: table,
  realestateTable: table,
  organizationTable: table,
  userTable: table,
  matrikkelAlertTable: table,
  leideLokalerTable: table,
  ledigeLokalerTable: table,
  rapporterTable: table,
  adminRapportTable: table,
  meldingTable: table,
  prosjektTable: { ...table, toggle: false },
  kpiTable: {
    page: 0,
    shown: 10,
    sortCol: 0,
    sortAsc: true,
  },
  auditlogTable: {
    ...table,
    sortCol: 4,
    sortAsc: false,
  },
  etasjeTable: {
    ...table,
  },
  globalTable: {
    ...table,
    filters: {
      visMine: {
        lokaler: 'lokale',
      },
    },
    map: false,
    type: 'lokale',
  },
  dashboardTable: {
    ...table,
    shown: 5,
  },
};

export default function tableReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_TABLE: {
      return Object.assign({}, state, {
        [action.tableType]: Object.assign(
          {},
          state[action.tableType],
          action.table,
        ),
        toggleFlag: false,
      });
    }
    case RESET_TABLE: {
      return {
        ...state,
        [action.tableType]: initialState[action.tableType],
      };
    }
    case TOGGLE_LIST_ITEM: {
      return Object.assign({}, state, {
        toggleFlag: false,
        loadingToggle: true,
      });
    }
    case TOGGLE_LIST_ITEM_SUCCESS: {
      return Object.assign({}, state, {
        toggleFlag: true,
        loadingToggle: false,
      });
    }
    case TOGGLE_LIST_ITEM_FAIL: {
      return Object.assign({}, state, {
        loadingToggle: false,
        error: action.error,
        toggleFlag: false,
      });
    }
    case TOGGLE_ORG: {
      return Object.assign({}, state, {
        toggleFlag: false,
        loadingToggle: true,
      });
    }
    case TOGGLE_ORG_SUCCESS: {
      return Object.assign({}, state, {
        toggleFlag: true,
        loadingToggle: false,
      });
    }
    case TOGGLE_ORG_FAILED: {
      return Object.assign({}, state, {
        loadingToggle: false,
        error: action.error,
        toggleFlag: false,
      });
    }
    default:
      return state;
  }
}
