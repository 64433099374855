// @flow

import React, { Fragment } from 'react';
import { LedigeLokalerListPanel, Button, Lightbox } from 'components';
import classnames from 'classnames';
import polyglot from 'i18n';
import type { Lokale } from 'types';
import { createLeieforholdChoices } from '../../templates/LedigeLokalerTemplate/LedigeLokalerTemplate';

import styles from './LedigeLokalerListPanelModal.css';

type Props = {
  lokale: Lokale,
  isReadOnly?: boolean,
  handleEditLedigLokale({ leieforhold: string, leieobjekt: string }): void,
  toggle(id: string): void,
  showModal: boolean,
  fullScreen?: boolean,
};

const LedigeLokalerListPanelModal = ({
  lokale,
  handleEditLedigLokale,
  isReadOnly,
  showModal,
  toggle,
  fullScreen,
}: Props) => (
  <Fragment>
    <Button id="showLedigeLokalerModal" onClick={e => toggle(e.target.id)}>
      {polyglot.t('ledigeLokaler.showLedigeLokaler')}
    </Button>
    {showModal && (
      <Lightbox fullScreen={fullScreen} close={toggle}>
        <div
          className={classnames(styles.modal, fullScreen && styles.fullScreen)}
        >
          <LedigeLokalerListPanel
            {...{
              leieforholdChoices: createLeieforholdChoices(lokale),
              handleEditLedigLokale,
              isReadOnly,
              toggle,
            }}
            close={toggle}
          />
        </div>
      </Lightbox>
    )}
  </Fragment>
);

export default LedigeLokalerListPanelModal;
