exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* VIEWPORTS */\n\n:root {\n}\n\n.UserForm__form___DAKJd > div {\n  margin-top: 2rem;\n}\n\n.UserForm__center___2qZBR {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  width: 33%;\n}\n\n@media (max-width: 500px) {\n\n  .UserForm__center___2qZBR {\n    width: 100%;\n  }\n}\n\n.UserForm__button___3nZLC {\n  margin-top: 10px;\n  height: 40px;\n  width: 200px;\n}\n\n.UserForm__buttonWrapper___1ISNL {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n}\n", ""]);

// exports
exports.locals = {
	"form": "UserForm__form___DAKJd",
	"center": "UserForm__center___2qZBR",
	"button": "UserForm__button___3nZLC",
	"buttonWrapper": "UserForm__buttonWrapper___1ISNL"
};