import React from 'react';
import PropTypes from 'prop-types';
import { Button, Field, Icon } from 'components';
import styles from './FieldEdit.css';

const FieldEdit = ({
  label,
  placeholder,
  handleSubmit,
  handleCancel,
  ...props
}) => {
  const kind = props.validationState === 'error' ? 'danger' : 'primary'; // Include 'warning' later
  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <Field
        {...props}
        name={props.name}
        id={props.name}
        label={label}
        placeholder={placeholder}
        feedback
      />
      <Button kind={kind} submit icon className={styles.buttonWrapper}>
        <Icon white button icon="save" size="1" />
      </Button>
      <Button
        kind={kind}
        submit
        icon
        className={styles.buttonWrapper}
        onClick={handleCancel}
      >
        <Icon white button icon="close" size="1" />
      </Button>
    </form>
  );
};

FieldEdit.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  validationState: PropTypes.string,
};

export default FieldEdit;
