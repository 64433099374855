exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PageNotFound__pageNotFound___3D9OQ {\n  margin-top: 200px;\n  background-color: white;\n  width: 100%;\n  height: 100%;\n  padding: 100px;\n  margin: auto;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n}\n", ""]);

// exports
exports.locals = {
	"pageNotFound": "PageNotFound__pageNotFound___3D9OQ"
};