import React from 'react';
import PropTypes from 'prop-types';
import {
  EditFloor,
  Heading,
  Icon,
  ModalButton,
  Panel,
  TableView,
} from 'components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import polyglot from 'i18n';
import * as buildingActions from 'redux/modules/building/building';
import * as widgetActions from 'redux/modules/widgets/widgets';
import mapData, { apiFields, getHeaders } from 'utils/mapApiData';

class BuildingFloorList extends React.PureComponent {
  state = { modals: {}, input: {} };

  componentDidUpdate(prevProps) {
    const { actions, building, error, loadingSetFloor } = this.props;
    if (!loadingSetFloor && prevProps.loadingSetFloor && !error) {
      actions.getBuilding(building.bygningsnr);
      actions.displayAlert({ text: 'Etasje er lagret', kind: 'success' });
    }
    if (error) {
      actions.displayAlert({
        text: `Lagring feilet: ${error}`,
        kind: 'danger',
      });
    }
  }

  toggleModal = i => {
    this.setState(prevState => ({
      input: {},
      modals: {
        [`showModal${i}`]: !prevState.modals[`showModal${i}`],
      },
    }));
  };

  handleChange = e => {
    const {
      target: { name, value },
    } = e;
    this.setState({
      input: {
        ...this.state.input,
        [name]: Number.isNaN(value) ? value : parseInt(value, 10),
      },
    });
  };

  onSelectInputChange = e => {
    this.setState({
      input: {
        ...this.state.input,
        etasjeplan: e,
      },
    });
  };

  onSelectChange = e => {
    this.setState({
      input: {
        ...this.state.input,
        etasjeplanSelected: e,
        etasjeplan: e,
      },
    });
  };

  handleSubmit = i => {
    const {
      actions: { setFloor },
      building: { etasjer, bygningsnr },
    } = this.props;
    if (i === '') {
      setFloor({ bygningsnr, ...this.state.input });
    } else {
      setFloor({ bygningsnr, ...etasjer[i], ...this.state.input });
    }
    this.toggleModal(i);
    this.setState({ input: {}, modals: {} });
  };

  render() {
    const {
      building: { editable, etasjer = [] },
    } = this.props;
    const addFloorButton = editable
      ? [
          [
            <ModalButton
              toggle={() => this.toggleModal('')}
              showModal={!!this.state.modals.showModal}
              icon={<Icon icon="pencil" size="1" />}
              link
              buttonTitle="+ Legg til etasje"
              content={
                <EditFloor
                  {...this.state.input}
                  handleChange={this.handleChange}
                  onExit={() => this.setState({ input: {}, modals: {} })}
                  handleSubmit={() => this.handleSubmit('')}
                  onSelectChange={this.onSelectChange}
                  onSelectInputChange={this.onSelectInputChange}
                />
              }
            />,
          ],
        ]
      : [[]];
    return (
      <Panel>
        <Heading level={2}>{polyglot.t('building.etasjer')}</Heading>
        <TableView
          dataRows={
            etasjer.length > 0
              ? etasjer
                  .map((row, i) => [
                    ...mapData(
                      row,
                      apiFields.building.floorTable.map(it => it.field),
                    ),
                    editable ? (
                      <ModalButton
                        toggle={() => this.toggleModal(i)}
                        showModal={!!this.state.modals[`showModal${i}`]}
                        icon={<Icon icon="pencil" size="1" />}
                        link
                        buttonTitle="Rediger"
                        content={
                          <EditFloor
                            {...this.state.input}
                            handleChange={this.handleChange}
                            handleSubmit={() => this.handleSubmit(i)}
                            onExit={() =>
                              this.setState({ input: {}, modals: {} })
                            }
                            floor={row}
                          />
                        }
                      />
                    ) : (
                      ''
                    ),
                  ])
                  .concat(addFloorButton)
              : addFloorButton
          }
          headerRow={getHeaders(
            apiFields.building.floorTable.map(it => it.field),
            'floors',
          )}
          table={{ shown: 100, page: 0 }}
          hits={etasjer.length}
          responsive
          changePage={() => {}}
          hover={false}
        />
      </Panel>
    );
  }
}

BuildingFloorList.propTypes = {
  building: PropTypes.shape({
    etasjer: PropTypes.array,
  }),
  actions: PropTypes.shape({
    setFloor: PropTypes.func.isRequired,
    getBuilding: PropTypes.func.isRequired,
  }),
  loadingSetFloor: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    loadingSetFloor: state.buildings.postFloor.loading,
    error: state.buildings.postFloor.error,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...buildingActions, ...widgetActions },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BuildingFloorList);
