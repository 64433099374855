exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".LedigeLokalerListPanelModal__modal___17VGL {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  width: 75%\n}\n\n.LedigeLokalerListPanelModal__modal___17VGL > * {\n  margin-bottom: 0;\n}\n\n.LedigeLokalerListPanelModal__fullScreen___35Hor {\n  width: 100%;\n  height: 100%;\n}\n\n.LedigeLokalerListPanelModal__buttonWrapper___3FAUy {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: end;\n      -ms-flex-pack: end;\n          justify-content: flex-end;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center\n}\n\n.LedigeLokalerListPanelModal__buttonWrapper___3FAUy > button:first-child {\n  margin-right: 10px;\n}\n", ""]);

// exports
exports.locals = {
	"modal": "LedigeLokalerListPanelModal__modal___17VGL",
	"fullScreen": "LedigeLokalerListPanelModal__fullScreen___35Hor",
	"buttonWrapper": "LedigeLokalerListPanelModal__buttonWrapper___3FAUy"
};