exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* STATENS LOKALER COLOR PALETTE */\n\n:root {\n\n  /* colors for input fields */\n\n  /* Button colors */\n\n  /* Anchor colors */\n\n  /* STATSBYGG PRIMARY COLORS */\n\n  /* Packet colors */\n  /* Statsbygg Glassblå 01-03 */\n  /* SECONDARY COLORS - NEUTRAL */\n  /* Statsbygg Nøytral grå 01-02 */\n  /* Statsbygg Stein */\n  /* Statsbygg Jord */\n  /* TERTIARY COLORS - MARKER COLORS */\n  /* Statsbygg Sennep */\n  /* Statsbygg Multe */\n  /* Statsbygg Laks */\n  /* Statsbygg Lyng */\n}\n\n/* FONTS */\n\n:root {\n}\n\n.Select__select___26ixM {\n  margin: 0;\n  width: 200px;\n  height: 45px;\n  border-radius: 0;\n  font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;\n  font-size: 0.875rem\n}\n\n.Select__select___26ixM .Select-menu-outer {\n  z-index: 1200;\n}\n\n.Select__wide___-Z55A {\n  min-width: 300px;\n}\n\n.is-focused.Select__select___26ixM {\n  border: solid 2px rgb(20, 126, 135);\n}\n\n.Select__select___26ixM > .rw-input,\n.Select__select___26ixM > .rw-select {\n  height: 100%;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n}\n\n.Select__select___26ixM .rw-list-option {\n  height: 45px;\n}\n\n.form-group > .is-focused {\n  -webkit-transition: all 0.05s ease 0s;\n  transition: all 0.05s ease 0s;\n  border: 3px solid #945e88;\n  background-color: #ffffff;\n  -webkit-box-shadow: none;\n          box-shadow: none;\n  outline: none;\n}\n\n.Select__select___26ixM .Select-control {\n  background-color: inherit;\n  border: 0px;\n  color: #232f3a;\n  cursor: default;\n  display: table;\n  border-spacing: 0;\n  border-collapse: separate;\n  height: 100%;\n}\n\n.Select__select___26ixM .Select-placeholder,\n.Select-value,\n.Select--single > .Select-control .Select-value {\n  line-height: 45px;\n  padding-right: 45px;\n}\n", ""]);

// exports
exports.locals = {
	"select": "Select__select___26ixM",
	"wide": "Select__wide___-Z55A"
};