import React from 'react';
import { Heading, Paragraph } from 'components';
import polyglot from 'i18n';
import styles from './NotFound.css';

const NotFound = ({ ...props }) => (
  <div className={styles.wrapper} {...props}>
    <Heading className={styles.heading}>
      {polyglot.t('notFound.primaryMessage')}
    </Heading>
    <Paragraph className={styles.paragraph}>
      {polyglot.t('notFound.secondaryMessage')}
    </Paragraph>
  </div>
);

export default NotFound;
