exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".LokaleEmployeeModal__modalWrapper___3IV0P {\n  padding: 40px;\n}\n\n.LokaleEmployeeModal__buttonWrapper___94CHU {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: end;\n      -ms-flex-pack: end;\n          justify-content: flex-end;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  padding: 0 20px\n}\n\n.LokaleEmployeeModal__buttonWrapper___94CHU > button {\n  margin: 10px;\n}\n", ""]);

// exports
exports.locals = {
	"modalWrapper": "LokaleEmployeeModal__modalWrapper___3IV0P",
	"buttonWrapper": "LokaleEmployeeModal__buttonWrapper___94CHU"
};