import React from 'react';
import PropTypes from 'prop-types';
import { setDefault } from 'utils/utils';
import polyglot from 'i18n';
import {
  ModalButton,
  GenericModal,
  Field,
  Toggle,
  Link,
  SelectField,
} from 'components';
import styles from './LokaleNameModal.css';

const LokaleNameModal = ({
  modals,
  toggleModal,
  lokaleInput,
  lokale,
  onLokaleChange,
  buttonClick,
  deleteItem,
  alert,
  innloggetOrg,
}) => (
  <ModalButton
    showModal={!!modals.showNameModal}
    toggle={() => toggleModal('showNameModal')}
    link
    {...alert}
  >
    <GenericModal
      onCancel={() => toggleModal('showNameModal')}
      saveProps={{
        id: 'lokalnavn',
        onClick: buttonClick,
      }}
      renderInputs={({ firstRef }) => (
        <React.Fragment>
          <Field
            name="lokalnavn"
            value={setDefault(lokaleInput.lokalnavn, lokale.navn)}
            onChange={onLokaleChange}
            label="Navn på lokalet"
            onKeyPress={e =>
              e.key === 'Enter'
                ? buttonClick({ target: { id: 'lokalnavn' } })
                : ''
            }
            refFunc={firstRef}
          />
          <Field
            name="lokalId"
            value={setDefault(lokaleInput.lokalId, lokale.lokalId)}
            onChange={onLokaleChange}
            label="Lokal ID"
          />
          <Toggle
            name="kontor"
            label={polyglot.t('newContract.step6.kontor')}
            onChange={onLokaleChange}
            id="kontor"
            value={setDefault(lokaleInput.kontor, lokale.kontor)}
          />
          <SelectField
            label={polyglot.t('newContract.user')}
            placeholder={polyglot.t('newContract.orgnr')}
            className={styles.lokaleNameSelect}
            selectedValue={
              lokaleInput.selectedOrg
                ? lokaleInput.selectedOrg.value
                : lokale.bruker.id
            }
            clearable={false}
            wide
            matchProp="label"
            optionUrl={`/organisasjon/brukere/${innloggetOrg.id}`}
            onChange={onLokaleChange}
          />
        </React.Fragment>
      )}
      deleteComponent={
        <Link
          onClick={() => {
            if (confirm('Vil du virkelig slette lokalet?')) {
              deleteItem('lokale', lokale.id, !lokale.aktiv);
            }
          }}
          deleteStyle
        >
          {lokale.aktiv ? 'X Slett lokale' : 'Aktiver lokale?'}
        </Link>
      }
    />
  </ModalButton>
);

LokaleNameModal.propTypes = {
  modals: PropTypes.shape({
    showNameModal: PropTypes.bool,
  }),
  toggleModal: PropTypes.func,
  lokaleInput: PropTypes.shape({
    lokalnavn: PropTypes.string,
  }),
  lokale: PropTypes.shape({
    navn: PropTypes.string,
    kontor: PropTypes.bool,
    aktiv: PropTypes.bool,
    id: PropTypes.number,
  }),
  onLokaleChange: PropTypes.func,
  buttonClick: PropTypes.func,
  deleteItem: PropTypes.func,
  alert: PropTypes.shape({
    text: PropTypes.string,
    kind: PropTypes.string,
  }),
  innloggetOrg: PropTypes.shape({ id: PropTypes.number }),
};

export default LokaleNameModal;
