import React from 'react';
import PropTypes from 'prop-types';

import styles from './PageTemplate.css';

const PageTemplate = ({ header, children, footer, version, ...props }) => (
  <div className={styles.wrapper} {...props}>
    <a href="#mainContent" className={styles.jumpLink}>
      Hopp til hovedinnhold
    </a>
    <header className={styles.header}>{header}</header>
    <main id="mainContent" className={styles.main}>
      {children}
    </main>
    <footer className={styles.footer}>{footer}</footer>
  </div>
);

PageTemplate.propTypes = {
  header: PropTypes.element.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
  footer: PropTypes.element,
  version: PropTypes.string,
};

export default PageTemplate;
