import React, { Component } from 'react';
import PropTypes from 'prop-types';

/* 
Component to give autofocus and keyboard trap to input modals
 */
class Dialog extends Component {
  componentDidMount() {
    if (this.firstEl) this.firstEl.focus();
  }

  onKeyDown = e => {
    if (e.keyCode === 9) {
      if (this.props.onlyOneFocus) {
        e.preventDefault();
      }
      if (e.shiftKey) {
        if (document.activeElement === this.firstEl) {
          e.preventDefault();
          this.lastEl.focus();
        }
      } else if (document.activeElement === this.lastEl) {
        e.preventDefault();
        this.firstEl.focus();
      }
    }
    if (e.key === 'Escape') {
      e.preventDefault();
      this.props.onExit(e);
    }
  };

  firstRef = node => {
    this.firstEl = node;
  };

  lastRef = node => {
    this.lastEl = node;
  };

  render() {
    return (
      <div onKeyDown={this.onKeyDown} role="dialog" aria-label="Rediger dialog">
        {this.props.render({
          firstRef: this.firstRef,
          lastRef: this.lastRef,
        })}
      </div>
    );
  }
}

Dialog.propTypes = {
  render: PropTypes.func,
  onExit: PropTypes.func,
  onlyOneFocus: PropTypes.bool,
};

export default Dialog;
