import { combineReducers } from 'redux';

import widgetsReducer from './widgets/widgets';
import searchReducer from './search/search';
import realestateReducer from './realestate/realestate';
import propertyReducer from './property/property';
import buildingReducer from './building/building';
import versionReducer from './version/version';
import userReducer from './user/user';
import vedleggsrapportReducer from './report/vedleggsrapport';
import reportReducer from './report/report';
import adminUserReducer from './adminUsers/adminUsers';
import helpReducer from './help/help';
import leideLokalerReducer from './leideLokaler/leideLokaler';
import ledigeLokalerReducer from './ledigeLokaler/ledigeLokaler';
import contractReducer from './leideLokaler/contracts';
import leieobjektReducer from './leideLokaler/leieobjekt';
import kpiReducer from './kpi/kpiReducer';
import tableReducer from './search/table';
import { excelReducer } from './excel/excel';
import { selectionsReducers } from './selections/selection';
import { projectReducer } from './projects/projects';
import powerBIreducer from './report/powerbi';
import forsideReducer from './frontpage/frontpage';
import meldingReducer from './melding/melding';

const rootReducer = combineReducers({
  widgets: widgetsReducer,
  search: searchReducer,
  table: tableReducer,
  realestates: realestateReducer,
  properties: propertyReducer,
  buildings: buildingReducer,
  versions: versionReducer,
  user: userReducer,
  vedleggsrapport: vedleggsrapportReducer,
  report: reportReducer,
  adminUsers: adminUserReducer,
  help: helpReducer,
  leideLokaler: leideLokalerReducer,
  ledigeLokaler: ledigeLokalerReducer,
  contracts: contractReducer,
  leieobjekt: leieobjektReducer,
  kpi: kpiReducer,
  excel: excelReducer,
  selections: selectionsReducers,
  projects: projectReducer,
  powerbi: powerBIreducer,
  frontpage: forsideReducer,
  melding: meldingReducer,
});

export default rootReducer;
