import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isAuthorized } from 'utils/authHelpers';
import { withRouter } from 'react-router-dom';

import * as versionActions from 'redux/modules/version/version';
import * as userActions from 'redux/modules/user/user';
import * as widgetsActions from 'redux/modules/widgets/widgets';
import * as leideLokalerActions from 'redux/modules/leideLokaler/leideLokaler';
import polyglot from 'i18n';
import userSelector from 'redux/modules/user/userSelector';

import { Header, Footer, PageTemplate, WelcomeVideo } from 'components';
import ErrorBoundary from './ErrorBoundary';

class Wrapper extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showWelcomeVideo: true,
      collapsed: true,
      helpOpen: false,
    };
  }

  componentDidMount() {
    const { isAuthenticated, waitingForUser, actions } = this.props;
    if (!isAuthenticated && !waitingForUser) {
      actions.getUser(window.location.pathname);
    }
    if (isAuthenticated) {
      actions.getVersion();
      actions.getDates();
    }
    // TODO set timeout and redirect to frontpage if not logged in
    // TODO check for permission here or just on each restricted page like admin? Can be HOC applied in routes
  }

  componentDidUpdate(prevProps) {
    const { isAuthenticated, history, actions, location, user } = this.props;
    if (isAuthenticated && !prevProps.isAuthenticated) {
      actions.getVersion();
      actions.getDates();
    }
    if (isAuthenticated) {
      if (!isAuthorized(location.pathname, user.tilganger, user.roles)) {
        history.replace('/d');
        actions.displayAlert({
          kind: 'warning',
          text: polyglot.t('warning.noAccess'),
        });
      }
    }
  }

  static getDerivedStateFromProps(nextProps) {
    const {
      location: { pathname },
      ...props
    } = nextProps;
    const matches = pathname.match(/\/d\/(\w+)\/?(\d+)?\/?(\w+)?\/?(\w+)?/);
    if (!matches || matches.length < 3) return {};
    switch (matches[1]) {
      case 'hjem':
        return {
          helpPath: 'hjem',
          titleName: 'Hjem',
        };
      case 'admin': {
        switch (matches[3]) {
          case 'virksomheter':
            return { helpPath: 'virksomheter', titleName: 'Virksomheter' };
          case 'generer':
            return { helpPath: 'rapport', titleName: 'Generer rapport' };
          case 'redigerBruker':
            return { helpPath: 'redigerBruker', titleName: 'Rediger bruker' };
          case 'brukere':
            if (matches[4])
              return {
                helpPath: 'redigerBruker',
                titleName: `${props.editUser.name}`,
              };
            return { helpPath: 'brukerListe', titleName: 'Brukerliste' };
          case 'kpi':
            return { helpPath: 'adminKpi', titleName: 'Administrer KPI' };
          case 'rapport': {
            if (matches[4] === 'nyrapport')
              return { helpPath: 'nyRapport', titleName: 'Publiser rapport' };
            return { helpPath: 'adminRapport', titleName: 'Publiser rapport' };
          }
          case 'auditlog':
            return {
              helpPath: 'auditlog',
              titleName: polyglot.t('auditlog.header'),
            };
          case 'etasje':
            return {
              helpPath: 'etasje',
              titleName: polyglot.t('etasje.header'),
            };
          case 'framside':
            return {
              titleName: polyglot.t('frontPage.admin.option'),
              helpPath: 'framside',
            };
          case 'melding':
            return {
              titleName: polyglot.t('melding.header'),
              helpPath: 'melding',
            };
          default:
            return {};
        }
      }
      case 'eiendom': {
        if (isNaN(matches[2]))
          return {
            helpPath: 'eiendomListe',
            titleName: 'Eiendomsgrupper',
          };
        return {
          helpPath: 'eiendomDetaljer',
          titleName:
            props.realestate.visningsnavn ||
            polyglot.t('notFound.metadata.notDefined'),
        };
      }
      case 'grunneiendom': {
        if (isNaN(matches[2]))
          return {
            helpPath: 'grunneiendomListe',
            titleName: 'Grunneiendommer',
          };

        // on the detail view
        return {
          helpPath: 'grunneiendomDetaljer',
          titleName: props.property.visningsnavn,
        };
      }
      case 'bygning': {
        if (isNaN(matches[2]))
          return {
            helpPath: 'bygningListe',
            titleName: 'Bygninger',
          };

        return {
          helpPath: 'bygningDetaljer',
          titleName: props.building.visningsnavn,
        };
      }

      case 'varsling': {
        return { helpPath: 'varslingListe', titleName: 'Varslinger' };
      }
      case 'lediglokale': {
        return {
          helpPath: 'lediglokale',
          titleName: polyglot.t('ledigeLokaler.breadcrumb'),
        };
      }
      case 'lokale': {
        if (matches.some(it => it === 'nyttlokale'))
          return {
            titleName: polyglot.t('newContract.nyttlokale'),
            helpPath: 'NyttLokale',
          };
        if (matches.some(it => it === 'nyttleieforhold'))
          return {
            helpPath: 'nyttleieforhold',
            titleName: polyglot.t('newContract.nyttleieforhold'),
          };
        if (matches.some(it => it === 'ledigeLokaler'))
          return {
            helpPath: 'ledigeLokaler',
            titleName: polyglot.t('ledigeLokaler.formHeader'),
          };
        if (matches.some(it => it === 'periodisk'))
          return {
            helpPath: 'periodisk',
            titleName: polyglot.t('leideLokaler.periodic.title'),
          };
        if (!isNaN(matches[2])) {
          return {
            helpPath: 'lokaleDetailjer',
            titleName: props.loading ? '' : props.lokale.navn,
          };
        }
        return {
          helpPath: 'LokalerListe',
          titleName: polyglot.t('leideLokaler.title'),
        };
      }
      case 'rapport': {
        if (!isNaN(matches[2]))
          return { helpPath: 'rapportVisning', titleName: props.report.navn };
        return { helpPath: 'rapportListe', titleName: 'Rapporter' };
      }
      case 'hjelp': {
        return {
          helpPath: 'Hjelp',
          titleName: 'Hjelp',
        };
      }
      case 'sok': {
        if (matches[3])
          return {
            helpPath: 'globaltSok',
            titleName: props.selection.navn,
          };
        return {
          helpPath: 'globaltSok',
          titleName: polyglot.t('globalSearch.heading'),
        };
      }
      case 'personvern': {
        return {
          helpPath: 'personvern',
          titleName: polyglot.t('privacy.heading'),
        };
      }
      case 'tilgjengelighet': {
        return {
          helpPath: 'tilgjengelighet',
          titleName: polyglot.t('availability.heading'),
        };
      }
      case 'profil': {
        return {
          helpPath: 'profil',
          titleName: polyglot.t('user.myProfile'),
        };
      }
      case 'prosjekt': {
        if (matches[3])
          return {
            titleName: 'Prosjekt detaljer',
            helpPath: 'prosjektDetaljer',
          };
        return { titleName: 'Liste over prosjekt', helpPath: 'prosjektListe' };
      }
      default:
        return null;
    }
  }

  logout = () => {
    this.props.actions.logout();
    localStorage.removeItem('token');
  };

  switchHelp = () => {
    this.setState(prevState => ({ helpOpen: !prevState.helpOpen }));
  };

  closeHelpOrNav = e => {
    const { helpOpen, collapsed } = this.state;
    if (helpOpen) {
      if (this.helpRef && !this.helpRef.contains(e.target))
        this.setState({ helpOpen: false });
    }
    if (!collapsed) {
      if (this.navRef && !this.navRef.contains(e.target))
        this.setState({ collapsed: true });
    }
  };

  dismissWelcome = () => this.setState({ showWelcomeVideo: false });

  collapseToggle = () =>
    this.setState(prevState => ({ collapsed: !prevState.collapsed }));

  render() {
    const { version, isAuthenticated, user, ...rest } = this.props;
    const { helpOpen, showWelcomeVideo, titleName } = this.state;
    const header = (
      <Header
        helpOpen={helpOpen}
        switchHelp={this.switchHelp}
        logout={this.logout}
        helpRef={node => {
          this.helpRef = node;
        }}
        navRef={node => {
          this.navRef = node;
        }}
        user={user}
        collapseToggle={this.collapseToggle}
        {...this.state}
        {...rest}
      />
    );
    const footer = <Footer version={version} />;
    if (isAuthenticated) {
      return (
        <ErrorBoundary>
          <PageTemplate
            onClick={this.closeHelpOrNav}
            version={version}
            header={header}
            footer={footer}
          >
            <Helmet
              title={
                titleName === undefined
                  ? 'statenslokaler.no'
                  : `${titleName} | statenslokaler.no `
              }
            />
            {user.roles.includes('ROLE_VIRKSOMHETSANSVARLIG') &&
              user.antallInnlogginger === 1 &&
              showWelcomeVideo && (
                <WelcomeVideo onClick={this.dismissWelcome} />
              )}
            {rest.children}
          </PageTemplate>
        </ErrorBoundary>
      );
    }
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
        }}
      >
        <h1>{polyglot.t('alert.redirect')}</h1>
      </div>
    );
  }
}

Wrapper.propTypes = {
  version: PropTypes.string,
  user: PropTypes.shape({
    roles: PropTypes.arrayOf(PropTypes.string),
    antallInnlogginger: PropTypes.number,
    tilganger: PropTypes.any,
  }),
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  isAuthenticated: PropTypes.bool,
  waitingForUser: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  history: PropTypes.shape({
    replace: PropTypes.func,
  }),
};

function mapStateToProps(state) {
  return {
    version: state.versions.version,
    user: userSelector(state),
    isAuthenticated: state.user.isAuthenticated,
    waitingForUser: state.user.waitingForUser,
    property: state.properties.property,
    propertyLoad: state.properties.loading,
    building: state.buildings.building.building,
    buildingLoad: state.buildings.building.loading,
    realestate: state.realestates.realestate,
    realestateLoad: state.realestates.loading,
    alertEnabled: state.widgets.alert.enabled,
    alertKind: state.widgets.alert.kind,
    alertText: state.widgets.alert.text,
    editUser: state.adminUsers.editUser,
    lokale: state.leideLokaler.lokaler,
    loading: state.leideLokaler.loadingGetLokale,
    report: state.report.report,
    selection: state.selections.selection.selection,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...userActions,
        ...versionActions,
        ...widgetsActions,
        ...leideLokalerActions,
      },
      dispatch,
    ),
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Wrapper),
);
