// Using ducks pattern
// Export action constants
export const GET_ORG = 'GET_ORG';
export const GET_ORG_SUCCESS = 'GET_ORG_SUCCESS';
export const GET_ORG_FAILED = 'GET_ORG_FAILED';

export const MATRIKKEL_GET = 'MATRIKKEL_GET';
export const MATRIKKEL_GET_SUCCESS = 'MATRIKKEL_GET_SUCCESS';
export const MATRIKKEL_GET_FAILED = 'MATRIKKEL_GET_FAILED';

export const SAVE_CONTRACT = 'SAVE_CONTRACT';
export const SAVE_CONTRACT_SUCCESS = 'SAVE_CONTRACT_SUCCESS';
export const SAVE_CONTRACT_FAILED = 'SAVE_CONTRACT_FAILED';

export const UPDATE_CONTRACT_PERIOD = 'UPDATE_CONTRACT_PERIOD';
export const UPDATE_CONTRACT_PERIOD_SUCCESS = 'UPDATE_CONTRACT_PERIOD_SUCCESS';
export const UPDATE_CONTRACT_PERIOD_FAILED = 'UPDATE_CONTRACT_PERIOD_FAILED';

export const ADD_PROPERTY = 'ADD_PROPERTY';
export const ADD_PROPERTY_SUCCESS = 'ADD_PROPERTY_SUCCESS';
export const ADD_PROPERTY_FAILED = 'ADD_PROPERTY_FAILED';

export const DELETE_PROPERTY = 'DELETE_PROPERTY';
export const DELETE_PROPERTY_SUCCESS = 'DELETE_PROPERTY_SUCCESS';
export const DELETE_PROPERTY_FAILED = 'DELETE_PROPERTY_FAILED';

// Export action creators
export const getOrg = orgnr => ({
  type: GET_ORG,
  path: `/organisasjon/org/${orgnr}`,
});
export const getOrgSuccess = org => ({ type: GET_ORG_SUCCESS, org });
export const getOrgFailed = error => ({ type: GET_ORG_FAILED, error });

export const matrikkelGet = obj => ({
  type: MATRIKKEL_GET,
  path: `/leieforhold/sok/${obj.kommune}/${obj.gardsnr}/${
    obj.bruksnr
  }/${obj.festenr || 0}/${obj.seksjonsnr || 0}`,
});
export const matrikkelGetSuccess = result => ({
  type: MATRIKKEL_GET_SUCCESS,
  result,
});
export const matrikkelGetFailed = (error, message) => ({
  type: MATRIKKEL_GET_FAILED,
  error,
  message,
});

export const saveContract = payload => ({
  type: SAVE_CONTRACT,
  path: '/leieforhold/lagre',
  payload,
});
export const saveContractSuccess = contract => ({
  type: SAVE_CONTRACT_SUCCESS,
  contract,
});
export const saveContractFailed = (error, message) => ({
  type: SAVE_CONTRACT_FAILED,
  error,
  message,
});

export const updateContractPeriod = (id, payload) => ({
  type: UPDATE_CONTRACT_PERIOD,
  path: Array.isArray(payload)
    ? '/leieforhold/leieforholdperioder'
    : `/leieforhold/${id}/leieforholdperiode`,
  payload,
});
export const updateContractPeriodSuccess = contract => ({
  type: UPDATE_CONTRACT_PERIOD_SUCCESS,
  contract,
});
export const updateContractPeriodFailed = (error, message) => ({
  type: UPDATE_CONTRACT_PERIOD_FAILED,
  error,
  message,
});

export const addProperty = (id, payload) => ({
  type: ADD_PROPERTY,
  path: `/leieforhold/${id}/grunneiendom`,
  payload,
});
export const addPropertySuccess = ok => ({ type: ADD_PROPERTY_SUCCESS, ok });
export const addPropertyFailed = (error, message) => ({
  type: ADD_PROPERTY_FAILED,
  error,
  message,
});

export const deleteProperty = (id, matrikkelId) => ({
  type: DELETE_PROPERTY,
  path: `/leieforhold/${id}/grunneiendom/${matrikkelId}`,
});
export const deletePropertySuccess = ok => ({
  type: DELETE_PROPERTY_SUCCESS,
  ok,
});
export const deletePropertyFailed = (error, message) => ({
  type: DELETE_PROPERTY_FAILED,
  error,
  message,
});

// Define initial state for this reducer
export const initialState = {
  loadingOrg: false,
  loadingSaveContract: false,
  error: '',
  contract: {},
  matrikkelResult: [],
  org: {},
};

// Export the reducer
export default function contractReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ORG: {
      return Object.assign({}, state, {
        loadingOrg: true,
        org: {},
        error: '',
      });
    }
    case GET_ORG_SUCCESS: {
      return Object.assign({}, state, {
        loadingOrg: false,
        org: action.org,
      });
    }
    case GET_ORG_FAILED: {
      return Object.assign({}, state, {
        loadingOrg: false,
        error: action.error,
        errorMessage: action.message,
      });
    }
    case MATRIKKEL_GET: {
      return Object.assign({}, state, {
        loadingMatrikkel: true,
        error: '',
        errorMessage: '',
        matrikkelResult: [],
      });
    }
    case MATRIKKEL_GET_SUCCESS: {
      return Object.assign({}, state, {
        loadingMatrikkel: false,
        matrikkelResult: action.result,
      });
    }
    case MATRIKKEL_GET_FAILED: {
      return Object.assign({}, state, {
        loadingMatrikkel: false,
        error: action.error,
        errorMessage: action.message,
      });
    }
    case SAVE_CONTRACT: {
      return Object.assign({}, state, {
        loadingSaveContract: true,
        error: '',
        errorMessage: '',
      });
    }
    case SAVE_CONTRACT_SUCCESS: {
      return Object.assign({}, state, {
        loadingSaveContract: false,
        contract: action.contract,
      });
    }
    case SAVE_CONTRACT_FAILED: {
      return Object.assign({}, state, {
        loadingSaveContract: false,
        error: action.error,
        errorMessage: action.message,
      });
    }
    case UPDATE_CONTRACT_PERIOD: {
      return Object.assign({}, state, {
        loadingSaveContract: true,
        updatedContract: false,
        error: '',
        errorMessage: '',
      });
    }
    case UPDATE_CONTRACT_PERIOD_SUCCESS: {
      return Object.assign({}, state, {
        loadingSaveContract: false,
        updatedContract: true,
      });
    }
    case UPDATE_CONTRACT_PERIOD_FAILED: {
      return Object.assign({}, state, {
        loadingSaveContract: false,
        error: action.error,
        errorMessage: action.message,
      });
    }
    case ADD_PROPERTY: {
      return Object.assign({}, state, {
        loadingSaveContract: true,
        updatedContract: false,
        matrikkelResult: [],
        error: '',
        errorMessage: '',
      });
    }
    case ADD_PROPERTY_SUCCESS: {
      return Object.assign({}, state, {
        loadingSaveContract: false,
        updatedContract: true,
      });
    }
    case ADD_PROPERTY_FAILED: {
      return Object.assign({}, state, {
        loadingSaveContract: false,
        error: action.error,
        errorMessage: action.message,
      });
    }
    case DELETE_PROPERTY: {
      return Object.assign({}, state, {
        loadingSaveContract: true,
        updatedContract: false,
        error: '',
        errorMessage: '',
      });
    }
    case DELETE_PROPERTY_SUCCESS: {
      return Object.assign({}, state, {
        loadingSaveContract: false,
        updatedContract: true,
      });
    }
    case DELETE_PROPERTY_FAILED: {
      return Object.assign({}, state, {
        loadingSaveContract: false,
        error: action.error,
        errorMessage: action.message,
      });
    }
    default:
      return state;
  }
}
