import { takeLatest, all } from 'redux-saga/effects';

import * as REPORT from 'redux/modules/report/report';

import {
  handleCallToApi,
  handlePostToApi,
  handleDeleteCall,
} from './sagaHandlers';

export default function* reportSaga() {
  yield all([
    takeLatest(
      REPORT.GET_REPORT,
      handleCallToApi,
      REPORT.getReportSuccess,
      REPORT.getReportFail,
    ),
    takeLatest(
      REPORT.SAVE_REPORT,
      handlePostToApi,
      REPORT.saveReportSuccess,
      REPORT.saveReportFail,
    ),
    takeLatest(
      REPORT.PUBLISH_REPORT,
      handlePostToApi,
      REPORT.publishReportSuccess,
      REPORT.publishReportFail,
    ),
    takeLatest(
      REPORT.DELETE_REPORT,
      handleDeleteCall,
      REPORT.deleteReportSuccess,
      REPORT.deleteReportFail,
    ),
  ]);
}
