// @flow

import React, { Component } from 'react';
import { NewMeldingTemplate } from 'components';
import type { RouterHistory } from 'types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as meldingActions from 'redux/modules/melding/melding';
import * as widgetActions from 'redux/modules/widgets/widgets';

type Props = {
  history: RouterHistory,
  match: {
    params: {
      meldingId: number,
    },
  },
  actions: {
    getMelding(meldingId: number): void,
    displayAlert({}): void,
    sendMelding({}): void,
  },
  melding: {
    id: number,
    emne: string,
    tekst: string,
    organisasjon: number,
    synligForUnderenheter: boolean,
    mottakere: Array<string>,
    roller: Array<string>,
    adresser: string,
  },
  loadingSendMelding: boolean,
  error: string,
};

type State = {
  emne?: string,
  tekst?: string,
  org?: {
    value: number,
  },
  users: Array<string>,
  visibleDown?: boolean,
  role?: Array<string>,
  adresser?: string,
};

class NewMelding extends Component<Props, State> {
  state = {};

  componentDidMount() {
    if (this.props.match.params) {
      this.props.actions.getMelding(this.props.match.params.meldingId);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      id,
      emne,
      innhold,
      organisasjon,
      synligForUnderenheter,
      mottakere,
      roller,
      adresser,
    } = nextProps.melding;
    if (id && !this.props.melding.id) {
      this.setState({
        emne,
        tekst: innhold,
        org: { value: organisasjon },
        visibleDown: synligForUnderenheter,
        users: mottakere,
        role: roller,
        adresser,
      });
    } else if (
      !nextProps.loadingSendMelding &&
      this.props.loadingSendMelding &&
      !nextProps.error
    ) {
      this.props.actions.displayAlert({
        kind: 'success',
        text: 'Meldingen er sendt!',
      });
      this.props.history.push('/d/admin/melding');
    }
  }

  onInputChange = e => {
    if (e.target.name === 'visibleDown')
      this.setState({ visibleDown: !this.state.visibleDown });
    else this.setState({ [e.target.name]: e.target.value });
  };

  onSelectChange = (e, type) => {
    this.setState({ [type]: Array.isArray(e) ? e.map(it => it.value) : e });
  };

  onSendMelding = () => {
    this.props.actions.sendMelding({
      ...this.props.melding,
      emne: this.state.emne,
      innhold: this.state.tekst,
      organisasjon: this.state.org && this.state.org.value,
      synligForUnderenheter: this.state.visibleDown,
      mottakere: this.state.users,
      roller: this.state.role,
    });
  };

  render() {
    return (
      <NewMeldingTemplate
        {...this.props}
        {...this.state}
        onInputChange={this.onInputChange}
        onSelectChange={this.onSelectChange}
        onSendMelding={this.onSendMelding}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    loadingSendMelding: state.melding.loadingSendMelding,
    error: state.melding.error,
    melding: state.melding.melding,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...meldingActions, ...widgetActions },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewMelding);
