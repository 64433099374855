exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ErrorMessage__feedback___1n2rb {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  height: 2rem;\n}\n\n.ErrorMessage__absolute___3xlG2 {\n  position: absolute;\n}\n", ""]);

// exports
exports.locals = {
	"feedback": "ErrorMessage__feedback___1n2rb",
	"absolute": "ErrorMessage__absolute___3xlG2"
};