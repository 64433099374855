exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".LokaleNameModal__modalWrapper___3kwRY {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n}\n\n.LokaleNameModal__modalWrapper___3kwRY > * {\n  margin: 10px;\n}\n\n.LokaleNameModal__pencilColor___3JziH {\n  color: #131615;\n  margin: 0 0 0 10px;\n}\n\n.LokaleNameModal__lokaleNameSelect___goDZV {\n  width: 40vw;\n  min-height: 8vw;\n}\n", ""]);

// exports
exports.locals = {
	"modalWrapper": "LokaleNameModal__modalWrapper___3kwRY",
	"pencilColor": "LokaleNameModal__pencilColor___3JziH",
	"lokaleNameSelect": "LokaleNameModal__lokaleNameSelect___goDZV"
};