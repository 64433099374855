exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".LokaleKpiPanels__humanPanel___14sKz {\n  padding: 20px\n}\n\n.LokaleKpiPanels__humanPanel___14sKz > div {\n  margin-bottom: 10px;\n}\n\n.LokaleKpiPanels__tableButton___1G54M {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n}\n", ""]);

// exports
exports.locals = {
	"humanPanel": "LokaleKpiPanels__humanPanel___14sKz",
	"tableButton": "LokaleKpiPanels__tableButton___1G54M"
};