// @flow

import React, { createRef, PureComponent } from 'react';

import * as pbi from 'powerbi-client';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import type { PowerBI } from 'types';

import * as powerBIActions from 'redux/modules/report/powerbi';
import { PowerBIReport, Bubbles } from 'components';

const powerbi = new pbi.service.Service(
  pbi.factories.hpmFactory,
  pbi.factories.wpmpFactory,
  pbi.factories.routerFactory,
);

type PowerBISettings = {
  filterPaneEnabled?: boolean,
  navContentPaneEnabled?: boolean,
};

type PowerBIConfig = {
  accessToken: string,
  embedUrl: string,
  pageName?: string,
  filters?: pbi.models.IFilter[],
  filterPaneEnabled?: boolean,
  navContentPaneEnabled?: boolean,
  settings: PowerBISettings,
};

type Props = {
  actions: {
    getPowerBIReport(id: string): void,
    getPowerBIReportToken(id: string): void,
  },
  id: string,
  report: PowerBI.report,
  token: string,
  tokenFromRedux: string,
  embedUrl: string,
  width: string,
  height: string,
  loadingReport: boolean,
  loadingToken: boolean,
  close(): void,
};

type State = {
  config: PowerBIConfig,
};

class PowerBIReportContainer extends PureComponent<Props, State> {
  state = {
    config: {
      id: this.props.id,
      accessToken: this.props.token,
      embedUrl: this.props.embedUrl,
      type: 'report',
      tokenType: pbi.models.TokenType.Embed,
      permissions: pbi.models.Permissions.Read,
      settings: {
        filterPaneEnabled: true,
        navContentPaneEnabled: true,
        layoutType: pbi.models.LayoutType.Custom,
        customLayout: {
          displayOption: pbi.models.DisplayOption.FitToPage,
        },
      },
    },
  };

  static defaultProps = {
    width: '100%',
    height: '92%',
  };

  component: pbi.Embed = null;

  powerBIElement = createRef();

  componentDidMount() {
    const { config } = this.state;
    const { id, token, embedUrl, actions } = this.props;

    if (id && (!embedUrl || !token)) {
      Promise.all([
        actions.getPowerBIReport(id),
        actions.getPowerBIReportToken(id),
      ]);
    } else if (this.validateConfig(config)) {
      this.embed();
    }
  }

  componentDidUpdate(prevProps) {
    const { config } = this.state;
    const { token, tokenFromRedux, loadingToken, loadingReport } = this.props;

    if (
      (token && token !== prevProps.token) ||
      (!token && tokenFromRedux && tokenFromRedux !== prevProps.tokenFromRedux)
    ) {
      this.updateEmbedConfig();
    } else if (
      (token !== '' || tokenFromRedux !== '') &&
      !loadingReport &&
      !loadingToken &&
      this.validateConfig(config)
    ) {
      this.embed();
    }
  }

  validateConfig = (settings: PowerBIConfig) => {
    const errors = pbi.models.validateReportLoad(settings);
    return errors === undefined;
  };

  embed: pbi.Embed = () => {
    const { config } = this.state;
    this.component = powerbi.embed(this.powerBIElement.current, config);
  };

  componentWillUnmount() {
    this.reset();
  }

  fullscreenReport = () => {
    this.component.fullscreen();
  };

  reset() {
    powerbi.reset(this.powerBIElement);
    this.component = null;
  }

  updateEmbedConfig = () => {
    const { config } = this.state;
    const { report, token, tokenFromRedux } = this.props;
    const newConfig: PowerBIConfig = {
      ...config,
      id: report.id,
      accessToken: token || tokenFromRedux,
      embedUrl: report.embedUrl,
    };
    this.setState({ config: { ...newConfig } });
  };

  render() {
    const {
      id,
      loadingReport,
      loadingToken,
      width,
      height,
      close,
      report,
    } = this.props;

    if (loadingReport || loadingToken) return <Bubbles />;

    const dimensions = { width, height };

    return (
      <PowerBIReport
        fullscreenReport={this.fullscreenReport}
        {...{
          id,
          close,
          report,
        }}
      >
        <div
          style={dimensions}
          className="powerbi-frame"
          ref={this.powerBIElement}
        />
      </PowerBIReport>
    );
  }
}

function mapStateToProps(state) {
  return {
    report: state.powerbi.powerBIReport.report,
    tokenFromRedux: state.powerbi.powerBIReportToken.token,
    loadingReport: state.powerbi.powerBIReport.loading,
    loadingToken: state.powerbi.powerBIReportToken.loading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...powerBIActions }, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PowerBIReportContainer);
