import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Icon.css';

const Icon = ({
  className,
  size,
  icon,
  spaced,
  button,
  tabButton,
  white,
  green,
  yellow,
  danger,
  warning,
  info,
  read,
  unread,
  small,
  map,
  red,
  black,
  noMargin,
  ...props
}) => {
  const iconClasses = classNames(styles.defaultSize, className, {
    [styles.small]: small,
    [styles.sizex1]: size === '1',
    [styles.sizex2]: size === '2',
    [styles.sizex3]: size === '3',
    [styles.sizex4]: size === '4',
    [styles.sizex5]: size === '5',
    [styles.spaced]: spaced,
    [styles.button]: button,
    [styles.tabButton]: tabButton,
    [styles.white]: white,
    [styles.green]: green,
    [styles.yellow]: yellow,
    [styles.red]: red,
    [styles.black]: black,
    [styles.danger]: danger,
    [styles.warning]: warning,
    [styles.info]: info,
    [styles.read]: read,
    [styles.unread]: unread,
    [styles.map]: map,
    [styles.noMargin]: noMargin,
  });
  const svg = require(`raw-loader!./icons/${icon}.svg`);
  return (
    <span
      className={iconClasses}
      {...props}
      dangerouslySetInnerHTML={{ __html: svg }}
    />
  );
};

Icon.propTypes = {
  icon: PropTypes.string,
  size: PropTypes.string,
  spaced: PropTypes.bool,
  button: PropTypes.bool,
  tabButton: PropTypes.bool,
  white: PropTypes.bool,
};

Icon.defaultTypes = {
  size: '1',
};

export default Icon;
