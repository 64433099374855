import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Moment from 'moment';
import { ErrorMessage, Label } from 'components';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import momentLocalizer from 'react-widgets-moment';
import styles from './DatePicker.css';

Moment.locale('nb');
momentLocalizer();

class DatePicker extends React.PureComponent {
  state = {
    open: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { min, max } = nextProps;
    if (min !== this.props.min && !nextProps.value) {
      this.setCurrentDate(min);
    } else if (max !== this.props.max && !nextProps.value) {
      this.setCurrentDate(max);
    }
  }

  setCurrentDate = date => this.setState({ currentDate: date });

  handleKeyPress = e => {
    if (e.key === 'Enter') this.props.onEnter();
    if (e.key === 'ArrowDown' && !this.state.open)
      this.setState({ open: 'date' });
  };

  render() {
    const {
      required,
      label,
      validationState,
      padding,
      fullWidth,
      feedbackMargin,
      onEnter,
      error,
      ...props
    } = this.props;
    return (
      <div
        className={classnames(
          styles.wrapper,
          fullWidth && styles.fullWidth,
          padding && styles.padding,
          feedbackMargin && styles.feedbackMargin,
          (validationState === 'error' || error) && styles.error,
        )}
      >
        {label && (
          <Label>
            {label}
            {required && <span className={styles.required}>*</span>}
          </Label>
        )}
        <DateTimePicker
          currentDate={this.state.currentDate}
          onCurrentDateChange={this.setCurrentDate}
          onKeyDown={this.handleKeyPress}
          format="DD.MM.YYYY"
          time={false}
          open={this.state.open}
          onToggle={() =>
            this.setState(prevState => ({
              open: prevState.open ? false : 'date',
            }))
          }
          {...props}
        />
        {error && <ErrorMessage feedbackMargin feedback={error} />}
      </div>
    );
  }
}

DatePicker.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string,
  validationState: PropTypes.oneOf(['error', false]),
  padding: PropTypes.bool,
  feedbackMargin: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onEnter: PropTypes.func,
  min: PropTypes.objectOf(PropTypes.any),
  max: PropTypes.objectOf(PropTypes.any),
  value: PropTypes.objectOf(PropTypes.any),
};

DatePicker.defaultProps = {
  onEnter: () => {},
};

export default DatePicker;
