import { createSelector } from 'reselect';

const getUserFromState = state => state.user.data;

export default createSelector([getUserFromState], data => ({
  ...data,
  isGlobal: data.tilganger && data.tilganger.indexOf('GLOBAL_TILGANG') > -1,
  isOrg: data.limitedAccess,
  isPublic: data.roles && data.roles.some(it => it === 'ROLE_PUBLIKUM'),
  isDep:
    data.organisasjon && data.organisasjon.organisasjonstype === 'DEPARTEMENT',
}));
