exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../../node_modules/css-loader/index.js??ref--5-1!../../../../node_modules/postcss-loader/lib/index.js!../../molecules/Modal/Modal.css"), undefined);

// module
exports.push([module.id, ".ProjectTemplate__addBox___3AhPT {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n  -webkit-box-align: end;\n      -ms-flex-align: end;\n          align-items: flex-end;\n  max-width: 350px;\n}\n\n.ProjectTemplate__headerWrapper___1wpjL {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n}\n\n.ProjectTemplate__spacing___20Fhi {\n  margin: 20px 0 45px;\n}\n\n.ProjectTemplate__closeButton____loRa {\n  margin-left: 10px !important;\n  border: none\n}\n\n.ProjectTemplate__closeButton____loRa > button {\n  border: none;\n}\n\n.ProjectTemplate__deleteModal___1QwCl {\n}\n\n.ProjectTemplate__buttonWrapper___1qN9B {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  margin-left: auto\n}\n\n.ProjectTemplate__buttonWrapper___1qN9B > button {\n  margin: 10px;\n}\n\n.ProjectTemplate__deleteLink___3Hd4m {\n  margin: 10px;\n}\n", ""]);

// exports
exports.locals = {
	"addBox": "ProjectTemplate__addBox___3AhPT",
	"headerWrapper": "ProjectTemplate__headerWrapper___1wpjL",
	"spacing": "ProjectTemplate__spacing___20Fhi",
	"closeButton": "ProjectTemplate__closeButton____loRa",
	"deleteModal": "ProjectTemplate__deleteModal___1QwCl " + require("-!../../../../node_modules/css-loader/index.js??ref--5-1!../../../../node_modules/postcss-loader/lib/index.js!../../molecules/Modal/Modal.css").locals["dialogWrapper"] + "",
	"buttonWrapper": "ProjectTemplate__buttonWrapper___1qN9B",
	"deleteLink": "ProjectTemplate__deleteLink___3Hd4m"
};