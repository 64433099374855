import React from 'react';
import PropTypes from 'prop-types';
import styles from './MapLegend.css';

const MapLegend = ({ icon, text }) => (
  <div className={styles.wrapper}>
    <img src={require(`../Icon/icons/${icon}.png`)} height="16.5" width="11" />
    <span>{text}</span>
  </div>
);

MapLegend.propTypes = {};

export default MapLegend;
