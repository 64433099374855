// @flow

import React from 'react';
import { Heading, Panel } from 'components';
import styles from './ReportViewTemplate.css';

type Props = {
  name: string,
  url: string,
  publisert: string,
};

const ReportViewTemplate = ({ name, url, publisert }: Props) => (
  <div>
    <Heading level={0}>{name}</Heading>
    <Panel>
      {url && (
        <iframe
          title="report frame"
          width="100%"
          height="600px"
          src={url}
          className={styles.iframe}
          allowFullScreen="true"
        />
      )}
    </Panel>
    <div className={styles.published}>{`Publisert ${publisert}`}</div>
  </div>
);

export default ReportViewTemplate;
