import React from 'react';
import classnames from 'classnames';
import styles from './WizardButton.css';

const WizardButton = ({ active, disabled, number, onClick, label }) => (
  <div className={styles.buttonWrapper}>
    <button
      onClick={onClick}
      disabled={disabled}
      name={number}
      className={classnames(styles.button, { [styles.active]: active })}
    >
      {number}
    </button>
    <div className={styles.label}>{label}</div>
  </div>
);

export default WizardButton;
