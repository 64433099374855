// @flow

import React from 'react';
import { Field } from 'components';
import { setDefault, sumTotalAreal, toGuiDate } from 'utils/utils';
import polyglot from 'i18n';
import type { Leieobjektperiode } from 'types';
import styles from './LeieobjektInputLine.css';

type Name =
  | 'bruttoareal'
  | 'arbeidsrelatertareal'
  | 'spesialformaalareal'
  | 'parkeringsareal'
  | 'framleieareal';

type WrapperProps = {
  name: Name,
  onChange(): void,
  leieobjektperiode: Leieobjektperiode,
  lessThan?: number,
};

type Props = {
  leieobjektperiode: Leieobjektperiode,
  periodestart: string,
  onChange(): void,
  kontor: boolean,
  bruttoareal: number,
  parkeringsareal: number,
  framleieareal: number,
  spesialformaalareal: number,
  arbeidsrelatertareal: number,
};

const FieldWrapper = ({ name, leieobjektperiode, ...props }: WrapperProps) => (
  <Field
    name={name}
    key={name}
    type="number"
    feedbackMargin
    value={setDefault(props[name], leieobjektperiode[name])}
    maxLength="7"
    placeholder=""
    {...props}
    id={name}
  />
);

const LeieobjektInputLine = ({
  leieobjektperiode,
  periodestart,
  kontor,
  validationState,
  ...props
}: Props) => [
  periodestart && (
    <div className={styles.feedback}>{toGuiDate(new Date(periodestart))}</div>
  ),
  <FieldWrapper
    name="bruttoareal"
    {...{
      leieobjektperiode,
      validationState,
      feedback: polyglot.t('feedback.lessThan'),
      ...props,
    }}
  />,
  !kontor && (
    <FieldWrapper
      name="arbeidsrelatertareal"
      {...{
        leieobjektperiode,
        validationState,
        feedback: '',
        ...props,
      }}
    />
  ),
  kontor && (
    <FieldWrapper
      name="spesialformaalareal"
      {...{
        leieobjektperiode,
        validationState,
        feedback: '',
        ...props,
      }}
    />
  ),
  <FieldWrapper name="parkeringsareal" {...{ leieobjektperiode, ...props }} />,
  <FieldWrapper
    name="framleieareal"
    {...{
      leieobjektperiode,
      validationState,
      feedback: '',
      ...props,
    }}
  />,
  <div className={styles.feedback}>
    {`${sumTotalAreal(props, leieobjektperiode)} kvm`}
  </div>,
];

export default LeieobjektInputLine;
