// @flow

import React from 'react';
import ReactPlayer from 'react-player';
import polyglot from 'i18n';
import {
  Heading,
  Paragraph,
  Icon,
  Button,
  Field,
  ModalButton,
} from 'components';
import styles from './HelpContent.css';
import Dialog from '../../../containers/Dialog';

type Props = {
  canEdit: boolean,
  toggleEdit(): void,
  helpObj: {
    overskrift: string,
    hjelpetekst: string,
    videoUrl: string,
  },
  helpInput: {
    overskrift?: string,
    hjelpetekst?: string,
    videoUrl?: string,
  },
  editMode: boolean,
  onChange(): void,
  onSubmit(): void,
  handleCancel(): void,
  open: boolean,
};

const HelpContent = ({
  canEdit,
  toggleEdit,
  helpObj,
  helpInput: { overskrift, hjelpetekst, videoUrl } = {},
  editMode,
  onChange,
  onSubmit,
  handleCancel,
  open,
}: Props) => (
  <div className={styles.wrapper}>
    <div className={styles.line}>
      <Heading level={2}>{helpObj.overskrift || 'ikke definert'}</Heading>
      {canEdit && (
        <ModalButton
          tabIndex={open ? 0 : -1}
          className={styles.button}
          showModal={editMode || false}
          toggle={editMode ? handleCancel : toggleEdit}
          link
        >
          <Dialog
            onExit={handleCancel}
            render={({ firstRef, lastRef }) => (
              <form onSubmit={onSubmit} className={styles.modalWrapper}>
                <Field
                  name="overskrift"
                  tabNumber={open ? 0 : -1}
                  label={polyglot.t('help.newHeadline')}
                  onChange={onChange}
                  refFunc={firstRef}
                  value={overskrift}
                />
                <Field
                  name="hjelpetekst"
                  tabNumber={open ? 0 : -1}
                  type="textarea"
                  label={polyglot.t('help.newText')}
                  largeTextArea
                  onChange={onChange}
                  value={hjelpetekst}
                />
                <Field
                  name="videoUrl"
                  tabNumber={open ? 0 : -1}
                  label={polyglot.t('help.newVimeo')}
                  onChange={onChange}
                  value={videoUrl}
                />
                <Button
                  kind="primary"
                  tabIndex={open ? 0 : -1}
                  submit
                  icon
                  className={styles.buttonWrapper}
                >
                  <Icon size="1" white button icon="save" />
                </Button>
                <Button
                  kind="primary"
                  tabIndex={open ? 0 : -1}
                  icon
                  className={styles.buttonWrapper}
                  onClick={handleCancel}
                  refFunc={lastRef}
                >
                  <Icon size="1" white button icon="close" />
                </Button>
              </form>
            )}
          />
        </ModalButton>
      )}
    </div>
    <Paragraph>{helpObj.hjelpetekst || 'ikke definert'}</Paragraph>
    <ReactPlayer
      className={styles.vimeo}
      hidden={!open}
      width={300}
      height={225}
      url={helpObj.videoUrl}
    />
  </div>
);

export default HelpContent;
