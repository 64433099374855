import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { KpiAdminTemplate } from 'components';
import * as kpiActions from 'redux/modules/kpi/kpiReducer';
import { setDefault } from 'utils/utils';

class KpiAdmin extends Component {
  state = {
    inputs: {},
    editMode: false,
  };

  componentDidMount() {
    this.refresh();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.savedKpi && !this.props.savedKpi) this.refresh();
  }

  onChange = (e, kpi) => {
    this.setState({
      inputs: {
        ...this.state.inputs,
        [kpi]: {
          ...this.state.inputs[kpi],
          [e.target.name]: e.target.value,
        },
      },
    });
  };

  onSubmit = () => {
    const inputObjects = Object.keys(this.state.inputs);
    const payload = inputObjects.map(it => {
      const currentObj = this.props.kpi.filter(obj => obj.kpi === it).pop();
      return {
        ...currentObj,
        grense: setDefault(this.state.inputs[it].limit, currentObj.grense),
        maal: setDefault(this.state.inputs[it].target, currentObj.maal),
      };
    });
    this.props.actions.saveKpi(payload);
  };

  refresh = () => {
    this.props.actions.getKpi();
    this.setState({ editMode: false });
  };

  render() {
    return (
      <KpiAdminTemplate
        toggleEdit={() => this.setState({ editMode: !this.state.editMode })}
        onSubmit={this.onSubmit}
        onChange={this.onChange}
        {...this.props}
        {...this.state}
      />
    );
  }
}

KpiAdmin.propTypes = {
  kpi: PropTypes.arrayOf(PropTypes.object).isRequired,
};

function mapStateToProps(state) {
  return {
    kpi: state.kpi.kpi,
    savedKpi: state.kpi.savedKpi,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(kpiActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(KpiAdmin);
