import React from 'react';
import PropTypes from 'prop-types';
import {
  Heading,
  Panel,
  Field,
  SelectField,
  Button,
  Bubbles,
  Checkbox,
} from 'components';
import polyglot from 'i18n';
import styles from './NewMeldingTemplate.css';

const NewMeldingTemplate = ({
  onInputChange,
  onSelectChange,
  org,
  emne,
  users,
  tekst,
  visibleDown,
  onSendMelding,
  melding: { id },
  loadingSendMelding,
  history,
  role,
  adresser,
}) => (
  <div>
    <Heading level={0}>{id ? 'Rediger melding' : 'Ny melding'}</Heading>
    <Panel>
      <div className={styles.fieldWrapper}>
        <Field
          horizontal
          required
          label="Emne"
          name="emne"
          value={emne || ''}
          onChange={onInputChange}
        />
        <SelectField
          label={polyglot.t('melding.visibleForOrg')}
          onChange={e => onSelectChange(e, 'org')}
          selectedValue={org ? org.value : undefined}
          optionUrl="/organisasjon/filter"
          placeholder="Alle"
          horizontal
          className={styles.select}
        />
        <Checkbox
          label={polyglot.t('melding.visibleForSubOrgs')}
          inverted
          name="visibleDown"
          checked={visibleDown}
          onChange={onInputChange}
        />
        <SelectField
          label={polyglot.t('melding.visibleForRole')}
          onChange={e => onSelectChange(e, 'role')}
          selectedValue={role}
          multi
          optionUrl="/user/roles"
          labelFunc={responseValue => polyglot.t(`Rolle.${responseValue}`)}
          placeholder="Alle"
          horizontal
          className={styles.select}
        />
        <SelectField
          label={polyglot.t('melding.mottakere')}
          onChange={e => onSelectChange(e, 'users')}
          selectedValue={users}
          multi
          optionUrl="/users"
          labelFunc={responseValue => responseValue}
          placeholder="Alle"
          horizontal
          className={styles.select}
        />
        <Field
          label={polyglot.t('melding.tekst')}
          horizontal
          required
          name="tekst"
          type="textarea"
          value={tekst || ''}
          onChange={onInputChange}
        />
        <Field
          label={polyglot.t('melding.adresser')}
          horizontal
          readOnly
          name="adresser"
          type="textarea"
          value={adresser || ''}
          onChange={onInputChange}
        />
      </div>

      <div className={styles.buttonWrapper}>
        <Button
          secondaryButton
          onClick={() => history.push('/d/admin/melding')}
        >
          {'Avbryt'}
        </Button>
        <Button kind="primary" onClick={onSendMelding}>
          {loadingSendMelding ? <Bubbles /> : 'Send melding'}
        </Button>
      </div>
    </Panel>
  </div>
);

NewMeldingTemplate.propTypes = {
  onInputChange: PropTypes.func,
  onSelectChange: PropTypes.func,
  org: PropTypes.shape({ value: PropTypes.number }),
  emne: PropTypes.string,
  visibleDown: PropTypes.bool,
  tekst: PropTypes.string,
  role: PropTypes.arrayOf(PropTypes.string),
  adresser: PropTypes.string,
  onSendMelding: PropTypes.func,
  melding: PropTypes.shape({ id: PropTypes.number }),
  loadingSendMelding: PropTypes.bool,
  history: PropTypes.shape({ push: PropTypes.func }),
};

export default NewMeldingTemplate;
