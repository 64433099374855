exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".BuildingEdit__wrapper___2SUMP {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n}\n\n.BuildingEdit__modalWrapper___1iIWJ {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: end;\n      -ms-flex-align: end;\n          align-items: flex-end;\n  margin: 40px\n}\n\n.BuildingEdit__modalWrapper___1iIWJ > * {\n  margin: 0 10px;\n}\n", ""]);

// exports
exports.locals = {
	"wrapper": "BuildingEdit__wrapper___2SUMP",
	"modalWrapper": "BuildingEdit__modalWrapper___1iIWJ"
};