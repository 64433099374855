import { takeLatest, all } from 'redux-saga/effects';

import * as KPI from 'redux/modules/kpi/kpiReducer';
import * as REPORT from 'redux/modules/report/vedleggsrapport';
import * as VERSION from 'redux/modules/version/version';

import {
  handleCallToApi,
  handlePostToApi,
  handleGetVersion,
} from './sagaHandlers';

export default function* adminSaga() {
  yield all([
    takeLatest(
      VERSION.GET_VERSION,
      handleGetVersion,
      VERSION.gotVersion,
      VERSION.getVersionFail,
    ),
    takeLatest(KPI.GET_KPI, handleCallToApi, KPI.receivedKpi, KPI.getKpiError),
    takeLatest(KPI.SAVE_KPI, handlePostToApi, KPI.savedKpi, KPI.saveKpiError),
    takeLatest(
      REPORT.GET_REPORT_TEXT,
      handleCallToApi,
      REPORT.receivedReportText,
      REPORT.getReportTextError,
    ),
    takeLatest(
      REPORT.SET_REPORT_TEXT,
      handlePostToApi,
      REPORT.setTextOk,
      REPORT.setTextFail,
    ),
    takeLatest(
      REPORT.PREVIEW_REPORT,
      handleCallToApi,
      REPORT.receivedReport,
      REPORT.reportError,
    ),
    takeLatest(
      REPORT.PUBLISH_REPORT,
      handleCallToApi,
      REPORT.publishOk,
      REPORT.publishFailed,
    ),
    takeLatest(
      REPORT.GET_LATEST,
      handleCallToApi,
      REPORT.receivedLatest,
      REPORT.latestFailed,
    ),
  ]);
}
