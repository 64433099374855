import React from 'react';
import { Panel, Heading, Paragraph, Link } from 'components';
import polyglot from 'i18n';
import styles from './Privacy.css';

const Privacy = () => (
  <Panel className={styles.wrapper}>
    <Heading level={0}>{polyglot.t('privacy.heading')}</Heading>
    <Paragraph className={styles.inline}>
      {polyglot.t('privacy.text1')}
    </Paragraph>
    <Link href={`mailto:${polyglot.t('privacy.email')}`}>
      {polyglot.t('privacy.email')}
    </Link>

    <Heading level={2}>{polyglot.t('privacy.personvernHeader')}</Heading>
    <Paragraph className={styles.inline}>
      {polyglot.t('privacy.personvern')}
    </Paragraph>

    <Heading level={2}>{polyglot.t('privacy.utleveringHeader')}</Heading>
    <Paragraph className={styles.inline}>
      {polyglot.t('privacy.utlevering')}
    </Paragraph>

    <Heading level={2}>{polyglot.t('privacy.rettigheterHeader')}</Heading>
    <Paragraph className={styles.inline}>
      {polyglot.t('privacy.rettigheter')}
    </Paragraph>
    <Link href="http://www.datatilsynet.no">http://www.datatilsynet.no</Link>

    <Heading level={2}>{polyglot.t('privacy.formaalHeader')}</Heading>
    <Paragraph className={styles.inline}>
      {polyglot.t('privacy.formaal')}
    </Paragraph>

    <Heading level={2}>{polyglot.t('privacy.artOgHensiktHeader')}</Heading>
    <Paragraph className={styles.inline}>
      {polyglot.t('privacy.artOgHensikt')}
    </Paragraph>

    <Heading level={2}>{polyglot.t('privacy.varighetHeader')}</Heading>
    <Paragraph className={styles.inline}>
      {polyglot.t('privacy.varighet')}
    </Paragraph>

    <Heading level={2}>{polyglot.t('privacy.kategorierHeader')}</Heading>
    <Paragraph className={styles.inline}>
      {polyglot.t('privacy.kategorier')}
    </Paragraph>

    <Heading level={2}>{polyglot.t('privacy.typeOpplysingerHeader')}</Heading>
    <Paragraph className={styles.inline}>
      {polyglot.t('privacy.typeOpplysinger')}
    </Paragraph>

    <Heading level={0}>{polyglot.t('privacy.sikkerhetstiltak')}</Heading>

    <Heading level={2}>{polyglot.t('privacy.pseudonymiseringHeader')}</Heading>
    <Paragraph className={styles.inline}>
      {polyglot.t('privacy.pseudonymisering')}
    </Paragraph>

    <Heading level={2}>{polyglot.t('privacy.krypteringHeader')}</Heading>
    <Paragraph className={styles.inline}>
      {polyglot.t('privacy.kryptering')}
    </Paragraph>

    <Heading level={2}>{polyglot.t('privacy.fortrolighetHeader')}</Heading>
    <Paragraph className={styles.inline}>
      {polyglot.t('privacy.fortrolighet')}
    </Paragraph>

    <Heading level={2}>{polyglot.t('privacy.integritetHeader')}</Heading>
    <Paragraph className={styles.inline}>
      {polyglot.t('privacy.integritet')}
    </Paragraph>

    <Heading level={2}>{polyglot.t('privacy.andreHeader')}</Heading>
    <Paragraph className={styles.inline}>
      {polyglot.t('privacy.andre')}
    </Paragraph>
  </Panel>
);

export default Privacy;
