import React from 'react';
import {
  FormikField,
  Field,
  Button,
  Bubbles,
  Icon,
  DataItem,
  Panel,
} from 'components';
import { Formik, Form } from 'formik';
import polyglot from 'i18n';
import PropTypes from 'prop-types';
import styles from './FrontPageForm.css';

const replacePath = path => path && path.replace(/([^\\]*\\)*/, '');

const FrontPageForm = ({
  response,
  response_POST,
  postSuccess,
  error,
  loading,
  postForside,
}) => {
  const { rapportEn, rapportTo, tekst, bilde } = postSuccess
    ? response_POST
    : response;

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          rapportEn,
          rapportTo,
          tekst,
          bilde: replacePath(bilde),
        }}
        onSubmit={values => {
          const formData = new FormData();
          Object.keys(values).forEach(val => {
            formData.append(val, values[val]);
          });
          postForside(formData);
        }}
      >
        {({ values, errors, handleChange }) => (
          <Form>
            <Panel
              heading={polyglot.t('frontPage.admin.option')}
              className={styles.wrapper}
            >
              <div className={styles.pictureWrapper}>
                <Field
                  label={polyglot.t('frontPage.admin.uploadPicture')}
                  error={errors.file}
                  type="file"
                  onChange={({ target: { files, value } }) => {
                    handleChange({
                      target: {
                        name: 'file',
                        value: files[0],
                      },
                    });
                    handleChange({
                      target: {
                        name: 'bilde',
                        value: replacePath(value),
                      },
                    });
                  }}
                  name="file"
                />
                {values.bilde && (
                  <div className={styles.pictureWrapper}>
                    <DataItem
                      vertical
                      fieldStyle
                      metaData="Nåværende bilde"
                      data={values.bilde}
                    />
                    <Button
                      onClick={() => {
                        handleChange({ target: { name: 'bilde', value: '' } });
                      }}
                      borderless
                    >
                      <Icon red icon="close" />
                    </Button>
                  </div>
                )}
              </div>
              <FormikField
                label={polyglot.t('frontPage.admin.text')}
                errors={errors}
                type="textarea"
                value={values.tekst}
                onChange={e =>
                  handleChange({
                    target: { value: e.target.value, name: 'tekst' },
                  })
                }
                name="tekst"
              />
              <FormikField
                label={polyglot.t('frontPage.admin.rapportEn')}
                errors={errors}
                name="rapportEn"
              />
              <FormikField
                label={polyglot.t('frontPage.admin.rapportTo')}
                errors={errors}
                name="rapportTo"
              />
              <div className={styles.buttonWrapper}>
                <Button
                  type="submit"
                  kind="primary"
                  disabled={Object.keys(errors).length > 0}
                >
                  {loading ? <Bubbles /> : polyglot.t('newContract.lagre')}
                </Button>
              </div>
            </Panel>
          </Form>
        )}
      </Formik>
      {error}
    </div>
  );
};

FrontPageForm.propTypes = {
  response: PropTypes.shape({
    bilde: PropTypes.string,
    tekst: PropTypes.string,
    rapportEn: PropTypes.string,
    rapportTo: PropTypes.string,
  }),
  response_POST: PropTypes.shape({
    bilde: PropTypes.string,
    tekst: PropTypes.string,
    rapportEn: PropTypes.string,
    rapportTo: PropTypes.string,
  }),
  postSuccess: PropTypes.bool,
  error: PropTypes.string,
  loading: PropTypes.bool,
  postForside: PropTypes.func,
};

export default FrontPageForm;
