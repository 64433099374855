exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* VIEWPORTS */\n\n:root {\n}\n\n.LokaleWizardLayout__wrapper___3DDxo {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n}\n\n.LokaleWizardLayout__headWrapper___yYxcw {\n}\n\n.LokaleWizardLayout__lokaleBox___rb8tm {\n  width: 100%;\n  min-height: 400px;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  -ms-flex-pack: distribute;\n      justify-content: space-around;\n}\n\n.LokaleWizardLayout__leftPanel___KesZI {\n  margin-right: 20px;\n  -webkit-box-flex: 2;\n      -ms-flex: 2 1 35%;\n          flex: 2 1 35%;\n}\n\n.LokaleWizardLayout__rightPanel___3aomP {\n  -webkit-box-flex: 5;\n      -ms-flex: 5 1 55%;\n          flex: 5 1 55%;\n  margin-right: 20px;\n}\n\n.LokaleWizardLayout__plus___2xvFU {\n  width: 10%;\n  height: 150px;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  padding-top: 56px;\n  margin: 4px 20px;\n}\n\n.LokaleWizardLayout__drawCross___3i0B7 {\n  background: #d8d8d8;\n  height: 50px;\n  position: relative;\n  width: 6px;\n}\n\n.LokaleWizardLayout__drawCross___3i0B7::after {\n  background: #d8d8d8;\n  content: '';\n  height: 6px;\n  left: -22px;\n  position: absolute;\n  top: 22px;\n  width: 50px;\n}\n\n.LokaleWizardLayout__leieforholdList___1W7YB {\n  padding: 10px;\n  margin: 5px 0;\n  border: 1px solid #e3e3e3;\n}\n\n.LokaleWizardLayout__newContract___2eQJe {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n}\n\n.LokaleWizardLayout__contractWizard___Stbty {\n  width: 100%;\n}\n\n.LokaleWizardLayout__subHeader___1RX3m {\n  padding: 25px 80px;\n}\n", ""]);

// exports
exports.locals = {
	"wrapper": "LokaleWizardLayout__wrapper___3DDxo",
	"headWrapper": "LokaleWizardLayout__headWrapper___yYxcw",
	"lokaleBox": "LokaleWizardLayout__lokaleBox___rb8tm",
	"leftPanel": "LokaleWizardLayout__leftPanel___KesZI",
	"rightPanel": "LokaleWizardLayout__rightPanel___3aomP",
	"plus": "LokaleWizardLayout__plus___2xvFU",
	"drawCross": "LokaleWizardLayout__drawCross___3i0B7",
	"leieforholdList": "LokaleWizardLayout__leieforholdList___1W7YB",
	"newContract": "LokaleWizardLayout__newContract___2eQJe",
	"contractWizard": "LokaleWizardLayout__contractWizard___Stbty",
	"subHeader": "LokaleWizardLayout__subHeader___1RX3m"
};