import { TableTemplate } from 'components';
import React from 'react';
import GenericList from 'containers/GenericList';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as searchActions from 'redux/modules/search/search';
import * as tableActions from 'redux/modules/search/table';
import searchSelector from 'redux/modules/search/searchSelector';
import userSelector from 'redux/modules/user/userSelector';

const MatrikkelAlertList = reduxProps => (
  <GenericList
    type="matrikkelAlert"
    {...reduxProps}
    render={props => <TableTemplate {...props} />}
  />
);

function mapStateToProps(state) {
  return {
    data: searchSelector('matrikkelAlert')(state),
    loading: state.search.loading,
    toggleFlag: state.table.toggleFlag,
    loadingToggle: state.table.loadingToggle,
    error: state.search.error,
    toggleError: state.table.error,
    userData: userSelector(state),
    table: state.table.matrikkelAlertTable,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...searchActions, ...tableActions },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MatrikkelAlertList);
