exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ReportViewTemplate__published___1cLJT {\n  text-align: right;\n}\n", ""]);

// exports
exports.locals = {
	"published": "ReportViewTemplate__published___1cLJT"
};