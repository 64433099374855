import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import classNames from 'classnames';
import styles from './Select.css';

const Select = ({
  choices,
  selectGroup,
  keepInput,
  selectedValue,
  onKeyPress,
  loadOptions,
  wide,
  onChange,
  ...props
}) => {
  const classes = classNames(styles.select, {
    [styles.selectGroup]: selectGroup,
    [styles.wide]: wide,
  });
  // Options to not reset typed input
  const keepInputProps = keepInput
    ? { onBlurResetsInput: false, onCloseResetsInput: false }
    : '';

  return (
    <ReactSelect
      {...keepInputProps}
      className={classes}
      ignoreAccents
      isLoading={props.isLoading}
      options={choices}
      inputId={props.name}
      value={choices.filter(choice => choice.value === selectedValue)}
      onChange={onChange}
      onKeyDown={onKeyPress}
      inputProps={{ 'data-test': props.testid }}
      theme={theme => ({
        ...theme,
        colors: {
          ...theme.colors,
          neutral50: '#696969',
        },
      })}
      {...props}
    />
  );
};

Select.propTypes = {
  choices: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedValue: PropTypes.any,
  noResultsText: PropTypes.string,
  name: PropTypes.string,
  selectGroup: PropTypes.bool,
  keepInput: PropTypes.bool,
  onSelectChange: PropTypes.func,
  onInputChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  matchProp: PropTypes.oneOf(['any', 'label', 'value']),
  loadOptions: PropTypes.func,
};

export default Select;
