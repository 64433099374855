import { call, put } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import * as API from 'services/api';

function* callToApi(apiFunc, cb, errorCb, action) {
  try {
    const response = yield call(apiFunc, action.path, action.payload);
    if (!response) yield put(errorCb('No response from server!'));
    else if (response.error) {
      yield put(errorCb(response.error, response.message));
    } else yield put(cb(response));
  } catch (error) {
    yield put(errorCb(error, error ? error.message : ''));
  }
}

export const handleCallToApi = (cb, errorCb, action) =>
  callToApi(API.getDataItem, cb, errorCb, action);

export const handlePutToApi = (cb, errorCb, action) =>
  callToApi(API.putItem, cb, errorCb, action);

export const handlePostToApi = (cb, errorCb, action) =>
  callToApi(API.setDataItem, cb, errorCb, action);

export const handleDeleteCall = (cb, errorCb, action) =>
  callToApi(API.deleteItem, cb, errorCb, action);

export const handleLogoutCall = (cb, errorCb, action) =>
  callToApi(API.logout, cb, errorCb, action);

export const handleGetVersion = (cb, errorCb, action) =>
  callToApi(API.getVersion, cb, errorCb, action);

export const handlePostToForside = (cb, errorCb, action) =>
  callToApi(API.postFrontpage, cb, errorCb, action);

export function* delayCallToApi(cb, errorCb, action) {
  try {
    const response = yield call(API.setDataItem, action.path);
    if (response) {
      yield delay(1250);
      yield put(cb(response));
    } else yield put(errorCb('response was null'));
  } catch (error) {
    yield put(errorCb(error, error.message));
  }
}

export function* fetchUser(cb, errorCb, action) {
  try {
    const response = yield call(API.getDataItem, action.path);
    if (!response) yield put(errorCb('No response from server!'));
    else if (response.error)
      yield put(errorCb(response.error, response.message));
    else yield put(cb(response, action.statePath));
  } catch (error) {
    yield put(errorCb(error, error ? error.message : ''));
  }
}
