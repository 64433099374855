import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  Pagination,
  HitCount,
  LoadingOverlay,
  SelectField,
} from 'components';
import polyglot from 'i18n';
import styles from './TableView.css';

const TableView = ({
  headerRow,
  dataRows,
  dataType,
  hits,
  changePage,
  changeShown,
  table,
  type,
  loading,
  toggleLoading,
  hideOptions,
  ...props
}) => {
  const pages = Math.ceil(hits / table.shown);
  const startIndex = Math.min(1 + table.page * table.shown, hits);
  const endIndex = Math.min(startIndex + (table.shown - 1), hits);
  const options = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
  ];
  const handleChange = selectedValue => {
    changeShown(selectedValue.value, type);
  };
  return (
    <div className={styles.wrapper}>
      {(loading || toggleLoading) && <LoadingOverlay relative />}
      {!hideOptions && hits > 10 ? (
        <SelectField
          label={polyglot.t('table.antallRader')}
          name="antallRader"
          matchProp="label"
          placeholder={table.shown.toString()}
          selectedValue={table.shown.toString()}
          onChange={handleChange}
          options={options}
        />
      ) : (
        ''
      )}
      <Table
        dataRows={
          dataRows.length > table.shown
            ? dataRows.slice(startIndex - 1, endIndex)
            : dataRows
        }
        headerRow={headerRow}
        sortCol={table.sortCol}
        sortDir={table.sortAsc ? 'asc' : 'desc'}
        dataType={dataType}
        responsive
        {...props}
      />
      {pages > 1 && (
        <span className={styles.pagination}>
          <Pagination
            bsStyle={false}
            pages={pages}
            active={table.page}
            onSelect={changePage}
          />
          <HitCount current={`${startIndex}-${endIndex}`} total={hits} />
        </span>
      )}
    </div>
  );
};

TableView.propTypes = {
  dataRows: PropTypes.arrayOf(PropTypes.any).isRequired,
  headerRow: PropTypes.arrayOf(PropTypes.any).isRequired,
  hits: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  toggleLoading: PropTypes.bool,
  dataType: PropTypes.oneOf([
    'property',
    'building',
    'realestate',
    'organization',
    'user',
    'matrikkelAlert',
    'leideLokaler',
    'ledigeLokaler',
    'rapporter',
    'adminRapporter',
    'auditlog',
    'etasje',
    'prosjekt',
    'selection',
    'ledigeLokaler',
  ]),
  changePage: PropTypes.func.isRequired,
  changeShown: PropTypes.func,
  table: PropTypes.objectOf(PropTypes.any),
  type: PropTypes.string,
};

TableView.defaultProps = {
  table: {
    shown: 10,
    page: 0,
    sortCol: 1,
    sortAsc: true,
  },
};

export default TableView;
