import React from 'react';
import { Heading, Paragraph } from 'components';
import polyglot from 'i18n';
import styles from './PageNotFound.css';

const PageNotFound = () => (
  <div className={styles.pageNotFound}>
    <Heading>{polyglot.t('notFound.primaryMessage')}</Heading>
    <Paragraph>{polyglot.t('notFound.secondaryMessage')}</Paragraph>
  </div>
);

export default PageNotFound;
