import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { HelpModal } from 'components';
import * as helpActions from 'redux/modules/help/help';
import * as widgetsActions from 'redux/modules/widgets/widgets';
import polyglot from 'i18n';

class HelpContainer extends Component {
  state = {
    helpInput: {},
  };

  componentDidMount() {
    const { actions, helpPath } = this.props;
    actions.getHelp(helpPath);
  }

  componentDidUpdate(prevProps, { editMode }) {
    const {
      loadedHelp,
      savedHelp,
      loading,
      error,
      postError,
      helpPath,
      open,
      actions,
    } = this.props;
    const {
      loading: didLoad,
      error: prevError,
      postError: prevPostError,
      helpPath: prevPath,
      savedHelp: justSaved,
      loadedHelp: justLoaded,
      open: wasOpen,
    } = prevProps;

    if (didLoad && !loading && loadedHelp && !justLoaded) {
      this.resetState();
    } else if (error && !prevError) {
      actions.displayAlert({
        kind: 'danger',
        text: `There was an error getting help: ${error}`,
      });
    } else if (postError && !prevPostError) {
      actions.displayAlert({
        kind: 'danger',
        text: `There was an error posting help: ${postError}`,
      });
    } else if (helpPath !== prevPath) {
      this.checkForUnsaved();
      actions.getHelp(helpPath);
    } else if (editMode && !open && wasOpen) {
      // check if we have unsaved changes, then close editmode
      this.checkForUnsaved();
      this.resetState();
    } else if (savedHelp && !justSaved && didLoad) {
      console.log(didLoad);
      actions.getHelp(helpPath);
    }
  }

  resetState = () =>
    this.setState({ helpInput: this.props.helpObj, editMode: false });

  checkForUnsaved = () => {
    const { helpInput, editMode } = this.state;
    const { helpObj } = this.props;
    if (
      editMode &&
      (helpInput.overskrift !== helpObj.overskrift ||
        helpInput.hjelpetekst !== helpObj.hjelpetekst)
    ) {
      if (confirm(polyglot.t('help.reallyExit'))) this.postNewHelp();
    }
  };

  onChange = e => {
    const {
      target: { id, value },
    } = e;
    this.setState(prevState => ({
      helpInput: {
        ...prevState.helpInput,
        [id]: value,
      },
    }));
  };

  postNewHelp = e => {
    const { actions, helpPath } = this.props;
    const { helpInput } = this.state;
    if (e) e.preventDefault();
    actions.postHelp({
      ...helpInput,
      id: helpPath,
    });
  };

  toggleEdit = () => {
    this.setState(prevState => ({ editMode: !prevState.editMode }));
  };

  handleCancel = () => {
    const { helpObj } = this.props;
    const { helpInput } = this.state;
    if (
      helpInput.overskrift !== helpObj.overskrift ||
      helpInput.hjelpetekst !== helpObj.hjelpetekst
    ) {
      if (confirm(polyglot.t('help.reallyCancel'))) {
        this.resetState();
      }
    } else {
      this.resetState();
    }
  };

  render() {
    const { helpRef, ...props } = this.props;
    const { editMode, helpInput } = this.state;
    return (
      <HelpModal
        helpRef={helpRef}
        toggleEdit={this.toggleEdit}
        {...props}
        helpInput={helpInput}
        onChange={this.onChange}
        onSubmit={this.postNewHelp}
        editMode={editMode}
        handleCancel={this.handleCancel}
      />
    );
  }
}

HelpContainer.propTypes = {
  helpPath: PropTypes.string,
  error: PropTypes.string,
  postError: PropTypes.string,
  loading: PropTypes.bool,
  helpObj: PropTypes.objectOf(PropTypes.any),
  helpRef: PropTypes.func,
  open: PropTypes.bool,
  actions: PropTypes.objectOf(PropTypes.any),
  loadedHelp: PropTypes.bool,
  savedHelp: PropTypes.bool,
};

HelpContainer.defaultProps = {
  helpPath: 'defaultHelp',
};

function mapStateToProps(state) {
  return {
    helpObj: state.help.pageHelp.help,
    loading: state.help.pageHelp.loading,
    savedHelp: state.help.pageHelp.postSuccess,
    loadedHelp: state.help.pageHelp.loaded,
    error: state.help.pageHelp.error,
    postError: state.help.pageHelp.postError,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...helpActions, ...widgetsActions },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HelpContainer);
