import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Heading } from 'components';
import styles from './Panel.css';

const Panel = ({ heading, ...rest }) => {
  // const classes = packet ? classnames(styles.tablePanel, 'panel-body') : '';
  const classes = classnames(styles.default, rest.className);
  const panelHeading = heading ? <Heading level={2}>{heading}</Heading> : '';
  return (
    <div className={classes}>
      {panelHeading}
      {rest.children}
    </div>
  );
};

Panel.propTypes = {
  packet: PropTypes.bool,
  heading: PropTypes.string,
};

export default Panel;
