exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../../../node_modules/css-loader/index.js??ref--5-1!../../../../node_modules/postcss-loader/lib/index.js!../../templates/PageTemplate/PageTemplate.css"), undefined);

// module
exports.push([module.id, "/* STATENS LOKALER COLOR PALETTE */\n\n:root {\n\n  /* colors for input fields */\n\n  /* Button colors */\n\n  /* Anchor colors */\n\n  /* STATSBYGG PRIMARY COLORS */\n\n  /* Packet colors */\n  /* Statsbygg Glassblå 01-03 */\n  /* SECONDARY COLORS - NEUTRAL */\n  /* Statsbygg Nøytral grå 01-02 */\n  /* Statsbygg Stein */\n  /* Statsbygg Jord */\n  /* TERTIARY COLORS - MARKER COLORS */\n  /* Statsbygg Sennep */\n  /* Statsbygg Multe */\n  /* Statsbygg Laks */\n  /* Statsbygg Lyng */\n}\n\n.ErrorPage__wrapper___1Z4ws {\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  padding-top: 40px;\n}\n\n.ErrorPage__box___2gxKN {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  margin: 40px;\n}\n\n.ErrorPage__header___10Jt4 {\n  margin: 40px 0;\n}\n\n.ErrorPage__error___1jepe {\n  color: #d0021b;\n  max-width: 400px;\n  font-size: 1.3rem;\n}\n", ""]);

// exports
exports.locals = {
	"wrapper": "ErrorPage__wrapper___1Z4ws " + require("-!../../../../node_modules/css-loader/index.js??ref--5-1!../../../../node_modules/postcss-loader/lib/index.js!../../templates/PageTemplate/PageTemplate.css").locals["wrapper"] + "",
	"box": "ErrorPage__box___2gxKN",
	"header": "ErrorPage__header___10Jt4",
	"error": "ErrorPage__error___1jepe"
};