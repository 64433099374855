exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".NewMeldingTemplate__buttonWrapper___Exeqm {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: end;\n      -ms-flex-pack: end;\n          justify-content: flex-end;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  width: 100%;\n  margin: 0 10px\n}\n\n.NewMeldingTemplate__buttonWrapper___Exeqm > * {\n  margin: 10px;\n}\n\n.NewMeldingTemplate__fieldWrapper___iRDzC {\n  width: 820px;\n  max-width: 100%;\n}\n\n.NewMeldingTemplate__selectWrapper___3_RuB {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap\n}\n\n.NewMeldingTemplate__selectWrapper___3_RuB .Select {\n  min-width: 295px;\n}\n\n.NewMeldingTemplate__select___25-IG > .Select {\n  width: 100%;\n}\n\n.NewMeldingTemplate__select___25-IG .Select-value {\n  font-size: 1em;\n  color: #333;\n  border: none;\n  background-color: white;\n  line-height: 2\n}\n\n.NewMeldingTemplate__select___25-IG .Select-value > .Select-value-icon {\n  border-right: none;\n}\n\n.NewMeldingTemplate__select___25-IG .Select-input > input {\n  line-height: 2;\n}\n\n.NewMeldingTemplate__previewWrapper___2o9XQ {\n  width: 100%;\n  background-color: transparent;\n\n  % > a {\n    text-align: right;\n    color: white;\n    margin: 10px;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"buttonWrapper": "NewMeldingTemplate__buttonWrapper___Exeqm",
	"fieldWrapper": "NewMeldingTemplate__fieldWrapper___iRDzC",
	"selectWrapper": "NewMeldingTemplate__selectWrapper___3_RuB",
	"select": "NewMeldingTemplate__select___25-IG",
	"previewWrapper": "NewMeldingTemplate__previewWrapper___2o9XQ"
};