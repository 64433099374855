exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n.Tooltip__TooltipContent___XBR8f {\n  max-width: 30em;\n  white-space: normal;\n  z-index: 1;\n  border-radius: 4px;\n  padding: 10px 15px;\n  font-size: 12px;\n  color: white;\n  background-color: black;\n  -webkit-box-shadow: hsla(206, 22%, 7%, .35) 0px 10px 38px -10px, hsla(206, 22%, 7%, .2) 0px 10px 20px -15px;\n          box-shadow: hsla(206, 22%, 7%, .35) 0px 10px 38px -10px, hsla(206, 22%, 7%, .2) 0px 10px 20px -15px;\n}\n\n.Tooltip__TooltipArrow___I14jV {\n  fill: white;\n}\n", ""]);

// exports
exports.locals = {
	"TooltipContent": "Tooltip__TooltipContent___XBR8f",
	"TooltipArrow": "Tooltip__TooltipArrow___I14jV"
};