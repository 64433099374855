// @flow

import React from 'react';
import { CoreDataInput, DataItem, Heading, ModalButton } from 'components';
import { toGuiDate } from 'utils/utils';
// eslint-disable-next-line import/no-extraneous-dependencies
import polyglot from 'i18n';
import Dialog from '../../../containers/Dialog';
import styles from '../../organisms/ContractPanel/ContractPanel.css';

type Props = {
  navn: string,
  saksnummer: number,
  utleier: { navn: string },
  leieforholdtype: string,
  leieperiodeStart: string,
  leieperiodeSlutt: string,
  leietaker: { navn: string },
  hasEditAccess: boolean,
  modals: {},
  index: number,
  toggleModal(string): void,
  buttonClick(): void,
  deleteItem(number): void,
  alert: {
    enabled: boolean,
    kind: string,
    text: string,
  },
};

const CoreData = ({
  navn,
  saksnummer,
  utleier,
  leieforholdtype,
  leieperiodeStart,
  leieperiodeSlutt,
  leietaker,
  hasEditAccess,
  modals,
  index,
  toggleModal,
  buttonClick,
  deleteItem,
  alert,
  ...props
}: Props) => (
  <div className={styles.coreData}>
    <Heading green bottomMargin level={4}>
      {'Kjernedata'}
    </Heading>
    <DataItem data={navn} metaData={polyglot.t('newContract.step1.name')} />
    <DataItem
      data={saksnummer}
      metaData={polyglot.t('newContract.step1.internalNumber')}
    />
    <DataItem metaData="Utleier" data={utleier.navn} />
    <DataItem metaData="Leietaker" data={leietaker.navn} />
    <DataItem
      data={leieforholdtype}
      metaData={polyglot.t('newContract.step1.typeContract')}
    />
    <DataItem
      data={toGuiDate(leieperiodeStart)}
      metaData={polyglot.t('newContract.step1.contractStart')}
    />
    <DataItem
      data={toGuiDate(leieperiodeSlutt)}
      metaData={polyglot.t('newContract.step1.contractEnd')}
    />
    {hasEditAccess && (
      <div className={styles.buttonWrapper}>
        <ModalButton
          showModal={!!modals[`showModalCoreData${index}`]}
          toggle={() => toggleModal('showModalCoreData')}
          buttonTitle={polyglot.t('newContract.step1.secondaryButton')}
          {...alert}
        >
          <Dialog
            onExit={() => toggleModal('showModalCoreData')}
            render={dialogProps => (
              <CoreDataInput
                {...{
                  navn,
                  saksnummer,
                  utleier,
                  leietaker,
                  leieforholdtype,
                  leieperiodeStart,
                  leieperiodeSlutt,
                  buttonClick,
                  deleteItem,
                  ...props,
                  ...dialogProps,
                }}
              />
            )}
          />
        </ModalButton>
      </div>
    )}
  </div>
);

CoreData.defaultProps = {
  utleier: {},
  leietaker: {},
};

export default CoreData;
