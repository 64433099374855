import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button, Heading, RealestateDataRow, KpiTarget } from 'components';
import mapData, { apiFields } from 'utils/mapApiData';
import { toGuiDate } from 'utils/utils';
import styles from './ExpandableList.css';

class ExpandableList extends PureComponent {
  state = {
    openPanel: this.props.openPanel,
  };

  render() {
    const { handleClick, openPanel, type, children } = this.props;

    const currentOpen =
      typeof openPanel === 'undefined' ? this.state.openPanel : openPanel;

    const contractButton = it => (
      <Button
        className={styles.listHeader}
        onClick={() => handleClick(it.props.id)}
      >
        <Heading level={2}>
          {it.props.utleier && it.props.utleier.navn}
          {` - ${it.props.navn}`}
        </Heading>
        <span>
          {`${toGuiDate(it.props.leieperiodeStart)} - ${toGuiDate(
            it.props.leieperiodeSlutt,
          )}`}
        </span>
        {it.props.statusNy === 'UTGAATT' && (
          <KpiTarget className={styles.expired} status color="blue" />
        )}
        {it.props.statusNy === 'FREMTIDIG' && (
          <KpiTarget className={styles.expired} status color="white" />
        )}
      </Button>
    );

    const helpButton = it => (
      <Button
        className={styles.listHeader}
        onClick={() => handleClick(it.props.id)}
      >
        <Heading level={1}>
          {it.props.helpObj && it.props.helpObj.overskrift}
        </Heading>
      </Button>
    );

    const realestateButton = ({ props: { buttonProps, id, dataRows } }) => (
      <RealestateDataRow
        link={`/d${buttonProps._links.self}`}
        data={mapData(buttonProps, apiFields.property.realestateList)}
        buildings={dataRows.length > 0}
        onClick={() =>
          this.setState({
            openPanel: currentOpen === id ? -1 : id,
          })
        }
        open={currentOpen === id}
      />
    );

    const list = children.map(it => (
      <div key={it.props.id} className={styles.listItem}>
        {type === 'contract' && contractButton(it)}
        {type === 'help' && helpButton(it)}
        {type === 'realestate' && realestateButton(it)}
        <div
          className={classnames(
            styles.collapsePanel,
            currentOpen === it.props.id && styles.openItem,
            it.props.overflow && styles.overflow,
          )}
        >
          {it}
        </div>
      </div>
    ));

    return <div className={styles.wrapper}>{list}</div>;
  }
}

ExpandableList.propTypes = {
  type: PropTypes.string,
  openPanel: PropTypes.number,
  handleClick: PropTypes.func,
  children: PropTypes.arrayOf(PropTypes.object),
};

ExpandableList.defaultProps = {
  children: [],
};

export default ExpandableList;
