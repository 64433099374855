export const SEND_MELDING = 'SEND_MELDING';
export const SEND_MELDING_SUCCESS = 'SEND_MELDING_SUCCESS';
export const SEND_MELDING_FAIL = 'SEND_MELDING_FAIL';

export const GET_MELDING = 'GET_MELDING';
export const GET_MELDING_SUCCESS = 'GET_MELDING_SUCCESS';
export const GET_MELDING_FAIL = 'GET_MELDING_FAIL';

export const sendMelding = obj => ({
  type: SEND_MELDING,
  path: '/melding',
  payload: obj,
});
export const sendMeldingSuccess = () => ({ type: SEND_MELDING_SUCCESS });
export const sendMeldingFail = error => ({ type: SEND_MELDING_FAIL, error });

export const getMelding = id => ({
  type: GET_MELDING,
  path: `/melding/${id}`,
});
export const getMeldingSuccess = melding => ({
  type: GET_MELDING_SUCCESS,
  melding,
});
export const getMeldingFail = error => ({ type: GET_MELDING_FAIL, error });

const initialState = {
  melding: {},
};

export default function meldingReducer(state = initialState, action) {
  switch (action.type) {
    case SEND_MELDING:
      return { ...state, loadingSendMelding: true, error: '' };
    case SEND_MELDING_SUCCESS:
      return { ...state, loadingSendMelding: false };
    case SEND_MELDING_FAIL:
      return { ...state, loadingSendMelding: false, error: action.error };
    case GET_MELDING:
      return { ...state, loadingGetMelding: true, error: '', melding: {} };
    case GET_MELDING_SUCCESS:
      return { ...state, loadingGetMelding: false, melding: action.melding };
    case GET_MELDING_FAIL:
      return { ...state, loadingGetMelding: false, error: action.error };
    default:
      return state;
  }
}
