import React from 'react';
import PropTypes from 'prop-types';
import { Button, Link, Lightbox, Icon } from 'components';

const ModalButton = ({
  showModal,
  toggle,
  id,
  link,
  icon,
  buttonTitle,
  buttonClass,
  divClass,
  enabled,
  kind,
  text,
  content,
  children,
  disabled,
  tabIndex,
  primaryButton,
  ...props
}) => {
  const button = link ? (
    <Link tabIndex={tabIndex} onClick={toggle}>
      {icon || <Icon icon="pencil" black size="1" spaced />}
      {buttonTitle}
    </Link>
  ) : (
    <Button
      tabIndex={tabIndex}
      secondaryButton={!primaryButton}
      kind={primaryButton && 'primary'}
      disabled={disabled}
      onClick={toggle}
      className={buttonClass}
    >
      {icon}
      {buttonTitle}
    </Button>
  );

  return (
    <div {...props}>
      {button}
      {showModal && (
        <Lightbox kind={kind} text={text} enabled={enabled} close={toggle}>
          {content || children}
        </Lightbox>
      )}
    </div>
  );
};

ModalButton.propTypes = {
  buttonTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  buttonClass: PropTypes.string,
  divClass: PropTypes.string,
  link: PropTypes.bool,
  enabled: PropTypes.bool,
  kind: PropTypes.string,
  text: PropTypes.string,
  content: PropTypes.element,
  children: PropTypes.node,
  tabIndex: PropTypes.number,
  disabled: PropTypes.bool,
  showModal: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  id: PropTypes.number,
  icon: PropTypes.element,
};

ModalButton.defaultProps = {
  buttonTitle: '',
  buttonClass: '',
  divClass: '',
};

export default ModalButton;
