exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* VIEWPORTS */\n\n:root {\n}\n\n.PeriodicReportingTemplate__wizardPart___12SaL {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  width: 100%;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n}\n\n.PeriodicReportingTemplate__leftPart___3XHfK {\n  -webkit-box-flex: 1;\n      -ms-flex: 1;\n          flex: 1;\n}\n\n@media (min-width: 1024px) {\n\n  .PeriodicReportingTemplate__leftPart___3XHfK {\n    margin-right: 30px;\n  }\n}\n\n.PeriodicReportingTemplate__rightPart___dhGD- {\n  -webkit-box-flex: 3;\n      -ms-flex: 3 1 800px;\n          flex: 3 1 800px;\n}\n\n@media (max-width: 800px) {\n\n  .PeriodicReportingTemplate__rightPart___dhGD- {\n    overflow: scroll;\n  }\n}\n\n.PeriodicReportingTemplate__buttonWrapper___3ew-U {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: end;\n      -ms-flex-pack: end;\n          justify-content: flex-end\n}\n\n.PeriodicReportingTemplate__buttonWrapper___3ew-U > button {\n  margin: 10px;\n}\n", ""]);

// exports
exports.locals = {
	"wizardPart": "PeriodicReportingTemplate__wizardPart___12SaL",
	"leftPart": "PeriodicReportingTemplate__leftPart___3XHfK",
	"rightPart": "PeriodicReportingTemplate__rightPart___dhGD-",
	"buttonWrapper": "PeriodicReportingTemplate__buttonWrapper___3ew-U"
};