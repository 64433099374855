// Using ducks pattern
// Export action constants
export const SAVE_LEIEOBJEKT = 'SAVE_LEIEOBJEKT';
export const SAVE_LEIEOBJEKT_SUCCESS = 'SAVE_LEIEOBJEKT_SUCCESS';
export const SAVE_LEIEOBJEKT_FAILED = 'SAVE_LEIEOBJEKT_FAILED';

// Export action creators
export const saveLeieobjekt = payload => ({
  type: SAVE_LEIEOBJEKT,
  path: Array.isArray(payload)
    ? '/leieobjekt/leieobjektperioder'
    : '/leieobjekt/lagre',
  payload,
});
export const saveLeieobjektSuccess = objekt => ({
  type: SAVE_LEIEOBJEKT_SUCCESS,
  objekt,
});
export const saveLeieobjektFailed = error => ({
  type: SAVE_LEIEOBJEKT_FAILED,
  error,
});

// Define initial state for this reducer
export const initialState = {
  objekt: {},
  loadingSave: false,
  error: '',
};

// Export the reducer
export default function leieobjektReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_LEIEOBJEKT: {
      return Object.assign({}, state, {
        loadingSave: true,
        error: '',
      });
    }
    case SAVE_LEIEOBJEKT_SUCCESS: {
      return Object.assign({}, state, {
        objekt: action.objekt,
        loadingSave: false,
      });
    }
    case SAVE_LEIEOBJEKT_FAILED: {
      return Object.assign({}, state, {
        error: action.error,
        loadingSave: false,
      });
    }
    default:
      return state;
  }
}
