import React from 'react';
import { Icon } from 'components';
import polyglot from 'i18n';
import styles from './FeedbackDiv.css';

const FeedbackDiv = ({ invalidNumber, biggerThan, id = '' }) => (
  <div className={styles.feedback} role="alert" id={id}>
    <Icon icon="alert" red size="2" />
    {invalidNumber && polyglot.t('feedback.number')}
    {biggerThan && <span>{polyglot.t('feedback.biggerThan')}</span>}
  </div>
);

export default FeedbackDiv;
