import createAsyncRedux from '../../../utils/ReduxHelpers';

const excelRedux = createAsyncRedux({
  constantName: 'EXCEL',
  path: '/etasje/redigerte',
});

export const getExcel = excelRedux.post;

export const excelReducer = excelRedux.reducer;

export const excelSaga = excelRedux.sagas;
