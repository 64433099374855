exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".NewReportTemplate__buttonWrapper___3PWTC {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: end;\n      -ms-flex-pack: end;\n          justify-content: flex-end;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  width: 100%;\n  margin: 0 10px\n}\n\n.NewReportTemplate__buttonWrapper___3PWTC > * {\n  margin: 10px;\n}\n\n.NewReportTemplate__fieldWrapper___2A-9S {\n  width: 820px;\n  max-width: 100%;\n}\n\n.NewReportTemplate__selectWrapper___3_eFZ {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap\n}\n\n.NewReportTemplate__selectWrapper___3_eFZ .Select {\n  min-width: 295px;\n}\n\n.NewReportTemplate__select___2CGy3 > .Select {\n  width: 100%;\n}\n\n.NewReportTemplate__select___2CGy3 .Select-value {\n  font-size: 1em;\n  color: #333;\n  border: none;\n  background-color: white;\n  line-height: 2\n}\n\n.NewReportTemplate__select___2CGy3 .Select-value > .Select-value-icon {\n  border-right: none;\n}\n\n.NewReportTemplate__select___2CGy3 .Select-input > input {\n  line-height: 2;\n}\n\n.NewReportTemplate__previewWrapper___2stIG {\n  width: 100%;\n  background-color: transparent;\n\n  % > a {\n    text-align: right;\n    color: white;\n    margin: 10px;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"buttonWrapper": "NewReportTemplate__buttonWrapper___3PWTC",
	"fieldWrapper": "NewReportTemplate__fieldWrapper___2A-9S",
	"selectWrapper": "NewReportTemplate__selectWrapper___3_eFZ",
	"select": "NewReportTemplate__select___2CGy3",
	"previewWrapper": "NewReportTemplate__previewWrapper___2stIG"
};