import React from 'react';
import PropTypes from 'prop-types';
import polyglot from 'i18n';
import { toGuiDate } from 'utils/utils';

import {
  Heading,
  Panel,
  WizardStepsNew,
  DataItem,
  Bubbles,
  PeriodicWizard1,
  PeriodicWizard2,
  PeriodicWizard3,
  PeriodicWizard4,
  PeriodicWizard5,
} from 'components';
import styles from './PeriodicReportingTemplate.css';

const PeriodicReportingTemplate = ({
  currentStep,
  lokale,
  loading,
  dates,
  colors,
  idArray,
  currentLokale,
  ...props
}) => {
  const { navn, id, tilDato } = lokale;
  const periodeStart = toGuiDate(dates.periodestart);
  const periodeYear = periodeStart.split('.')[2];

  let dateRange = '';
  if (!tilDato) {
    dateRange = `01.01.${periodeYear} - 31.12.${periodeYear}`;
  } else {
    const tilDatoGuiFormat = toGuiDate(tilDato);
    const tilDatoYear = tilDatoGuiFormat.split('.')[2];
    if (tilDatoYear === periodeYear) {
      dateRange = `01.01.${periodeYear} - ${tilDatoGuiFormat}`;
    } else {
      dateRange = `01.01.${periodeYear} - 31.12.${periodeYear}`;
    }
  }

  const deadline = `15.03.${periodeYear}`;

  const datePeriod = currentStep !== 4 ? periodeStart : dateRange;
  const wizardContent = [
    <PeriodicWizard1
      dates={{ ...dates, periodestart: dateRange, frist: deadline }}
      {...props}
    />,
    <PeriodicWizard2
      kontor={lokale.kontor}
      showHelpText={colors[1] === 'orange'}
      {...props}
    />,
    <PeriodicWizard3
      lokale={lokale}
      showHelpText={colors[2] === 'orange'}
      {...props}
    />,
    <PeriodicWizard4
      lokale={lokale}
      showHelpText={colors[3] === 'orange'}
      {...props}
    />,
    <PeriodicWizard5
      dates={dates}
      lokale={lokale}
      idArray={idArray}
      currentLokale={currentLokale}
      {...props}
    />,
  ];

  return (
    <div>
      <Heading level={0}>{polyglot.t('leideLokaler.periodic.title')}</Heading>
      {idArray !== undefined ? (
        <span style={{ fontSize: '20px' }}>
          {currentLokale + 1}/{idArray.length}
        </span>
      ) : (
        ''
      )}
      <Panel>
        <WizardStepsNew
          labels={
            lokale.skalKunRapporteresKostnader
              ? [
                  polyglot.t('leideLokaler.periodic.period'),
                  null,
                  null,
                  polyglot.t('leideLokaler.periodic.costs'),
                  polyglot.t('leideLokaler.periodic.finish'),
                ]
              : [
                  polyglot.t('leideLokaler.periodic.period'),
                  polyglot.t('leideLokaler.periodic.area'),
                  polyglot.t('leideLokaler.periodic.employees'),
                  polyglot.t('leideLokaler.periodic.costs'),
                  polyglot.t('leideLokaler.periodic.finish'),
                ]
          }
          steps={5}
          links
          currentStep={currentStep}
          currentLokale={currentLokale}
          colors={colors}
        />
      </Panel>
      <div className={styles.wizardPart}>
        <Panel className={styles.leftPart}>
          {idArray !== undefined ? (
            !navn || id !== parseInt(idArray[currentLokale], 10) ? (
              <Bubbles />
            ) : (
              <DataItem
                vertical
                metaData={polyglot.t('leideLokaler.periodic.lokaleName')}
                data={navn}
              />
            )
          ) : (
            <DataItem
              vertical
              metaData={polyglot.t('leideLokaler.periodic.lokaleName')}
              data={navn}
            />
          )}
          {currentStep !== 1 &&
            currentStep !== 5 && (
              <DataItem
                vertical
                metaData={
                  currentStep !== 4
                    ? polyglot.t('leideLokaler.periodic.newPeriod')
                    : polyglot.t('leideLokaler.periodic.periodRange')
                }
                data={datePeriod}
              />
            )}
        </Panel>
        <Panel className={styles.rightPart}>
          {wizardContent[currentStep - 1]}
        </Panel>
      </div>
    </div>
  );
};

PeriodicReportingTemplate.propTypes = {
  currentStep: PropTypes.number,
  currentLokale: PropTypes.number,
  buttonClick: PropTypes.func,
  lokale: PropTypes.shape({
    rapporteringStatus: PropTypes.string,
  }),
  loading: PropTypes.bool,
  dates: PropTypes.shape({
    frist: PropTypes.string,
    periodestart: PropTypes.string,
  }),
  costInput: PropTypes.objectOf(PropTypes.any),
  employeeInput: PropTypes.objectOf(PropTypes.string),
  areaInput: PropTypes.objectOf(PropTypes.object),
};

PeriodicReportingTemplate.defaultProps = {
  dates: {},
};

export default PeriodicReportingTemplate;
