// @flow

import React from 'react';
import { ContractCostInput, Heading, DataItem, ModalButton } from 'components';
import { toGuiDate, localize } from 'utils/utils';
import polyglot from 'i18n';

import styles from '../../organisms/ContractPanel/ContractPanel.css';

type Leieforholdperiode = {
  fraDato?: string,
  husleiekostnad?: number,
  framleiereduksjon?: number,
};

type Props = {
  leieforholdperiodeNy?: Leieforholdperiode,
  hasEditAccess: boolean,
  buttonClick(): void,
  leieforholdperioder: Array<Leieforholdperiode>,
  onCostChange(): void,
  onDeleteContractCost(): void,
  modals: {},
  index: number,
  toggleModal(string): void,
  costInput: {},
};

const ContractCost = ({
  leieforholdperiodeNy = {},
  hasEditAccess,
  buttonClick,
  leieforholdperioder,
  onCostChange,
  onDeleteContractCost,
  modals,
  index,
  toggleModal,
  costInput,
  user,
}: Props) => {
  const generateDateRange = (fraDato: string): string => {
    const year = toGuiDate(fraDato).split('.')[2];
    const range = `01.01.${year} - 31.12.${year}`;
    return year ? range : polyglot.t('newContract.step6.ukjentDato');
  };

  const generateDateRangeMultiPeriod = (
    fraDato: string,
    andreFraDatoer: string[],
  ) => {
    const year = toGuiDate(fraDato).split('.')[2];

    if (!year) {
      return generateDateRange(fraDato);
    }

    const intYear = parseInt(year);

    const otherYears = andreFraDatoer
      .map(fraDato => parseInt(toGuiDate(fraDato).split('.')[2]))
      .filter(localYear => localYear > intYear);

    if (otherYears.length === 0) {
      return generateDateRange(fraDato);
    }

    const minOtherYear = Math.min(...otherYears);
    const range = `01.01.${intYear} - 31.12.${minOtherYear - 1}`;

    return range;
  };

  const showInputField = user.roles.includes('ROLE_VIRKSOMHETSANSVARLIG');

  return (
    <div className={styles.costs}>
      <Heading green bottomMargin level={4}>
        {polyglot.t('newContract.step6.kostnader')}
      </Heading>
      <DataItem
        metaData={polyglot.t('newContract.step6.kostnaderFor')}
        data={generateDateRange(leieforholdperiodeNy.fraDato)}
      />
      <DataItem
        metaData={polyglot.t('newContract.step6.husleie')}
        data={localize(leieforholdperiodeNy.husleiekostnad)}
      />
      <DataItem
        metaData={polyglot.t('newContract.step6.framleie')}
        data={localize(leieforholdperiodeNy.framleiereduksjon)}
      />
      {hasEditAccess && (
        <div className={styles.buttonWrapper}>
          <ModalButton
            showModal={!!modals[`showModalCosts${index}`]}
            toggle={() => toggleModal('showModalCosts')}
            buttonTitle={
              !user.roles.includes('ROLE_VIRKSOMHETSANSVARLIG')
                ? polyglot.t('newContract.step6.endreKostnader')
                : polyglot.t('newContract.step6.seKostnader')
            }
          >
            <ContractCostInput
              {...costInput}
              generateDateRangeMultiPeriod={generateDateRangeMultiPeriod}
              showInput={showInputField}
              leieforholdperiodeNy={leieforholdperiodeNy}
              leieforholdperioder={leieforholdperioder}
              buttonClick={buttonClick}
              onCostChange={onCostChange}
              onCancel={() => toggleModal('showModalCosts')}
              onDelete={onDeleteContractCost}
            />
          </ModalButton>
        </div>
      )}
    </div>
  );
};

export default ContractCost;
