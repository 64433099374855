import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Input.css';

/**
 * Form input types for text, textarea, checkbox and radio.
 * @prop {("type"|"invalid"|"...props")}
 * @default 'text'
 */
const Input = ({
  type,
  medium,
  lg,
  tall,
  maxWide,
  largeTextArea,
  validationState,
  disabled,
  refFunc,
  field = {},
  error,
  orange,
  ...props
}) => {
  const enableAutoFocus = props.autoFocus ? { autoFocus: true } : '';
  const classes = classNames({
    [styles.text]: type === 'text' || type === 'number',
    [styles.medium]: medium,
    [styles.lg]: lg,
    [styles.tall]: tall,
    [styles.textarea]: type === 'textarea',
    [styles.checkbox]: type === 'checkbox',
    [styles.largeTextArea]: largeTextArea,
    [styles.error]: validationState === 'error' || error,
    [styles.disabled]: disabled,
    [styles.orange]: orange,
  });
  if (type === 'textarea')
    return (
      <textarea
        ref={refFunc}
        {...props}
        disabled={disabled}
        className={classes}
      />
    );
  if (type === 'file') {
    return (
      <label className={styles.file} htmlFor={props.name}>
        <span className={styles.fileText}>Velg fil</span>
        <input
          ref={refFunc}
          {...field}
          {...props}
          {...enableAutoFocus}
          disabled={disabled}
          type={type}
          className={classes}
        />
      </label>
    );
  }
  return (
    <input
      ref={refFunc}
      {...field}
      {...props}
      {...enableAutoFocus}
      disabled={disabled}
      type={type}
      className={classes}
    />
  );
};

Input.propTypes = {
  id: PropTypes.string,
  medium: PropTypes.bool,
  lg: PropTypes.bool,
  /**
   * Defines Form input type Attribute
   * @type {("text"|"textarea"|"checkbox"|"radio")}
   * @defaultValue 'text'
   */
  type: PropTypes.oneOf(['text', 'textarea', 'number', 'file']),
  tall: PropTypes.bool,
  largeTextArea: PropTypes.bool,
  validationState: PropTypes.string,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  refFunc: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Input.defaultProps = {
  type: 'text',
};

export default Input;
