import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'components';
import styles from './Breadcrumbs.css';

const Breadcrumbs = ({ location: { pathname }, titleName, lokale }) => {
  const routes = route => {
    switch (route) {
      case '/d':
        return 'Hjem';
      case '/d/hjem':
        return 'Nøkkeltall for staten';
      case '/d/hjem/dep':
        return 'Nøkkeltall for departement';
      case '/d/hjem/dep/org':
        return 'Nøkkeltall for virksomhet';
      case '/d/eiendom':
        return 'Eiendomsgrupper';
      case '/d/grunneiendom':
        return 'Grunneiendommer';
      case '/d/bygning':
        return 'Bygninger';
      case '/d/lokale':
        return 'Lokaler';
      case '/d/lediglokale':
        return 'Ledige lokaler';
      case '/d/admin/brukere':
        return 'Brukere';
      case '/d/admin/rapport':
        return 'Administrer rapporter';
      case '/d/rapport':
        return 'Rapporter';
      case '/d/sok':
        return 'Globalt søk';
      case '/d/prosjekt':
        return 'Liste over prosjekt';
      case '/d/admin/melding':
        return 'Meldinger';
      default:
        if (route.indexOf('/d/lokale/') > -1)
          return lokale.navn || 'Lokale detaljer';
        return '';
    }
  };

  const getPaths = path => {
    const paths = [];

    path.split('/').reduce((prev, curr) => {
      const currPath = `${prev}/${curr}`;
      paths.push(currPath);
      return currPath;
    });

    return paths;
  };

  const getItem = (it, i) => {
    if (i === getPaths(pathname).length - 1) {
      if (it.indexOf('/d/hjem') > -1) return routes(it);
      return titleName;
    }
    return (
      <span>
        <Link tabIndex={0} breadcrumb to={it}>
          <span>{routes(it)}</span>
        </Link>
        <span className={styles.angle}>{'>'}</span>
      </span>
    );
  };

  const items = getPaths(pathname).map((it, i) => {
    if (it === '/d/admin') return undefined;
    return <li key={i}>{getItem(it, i)}</li>;
  });

  return <ol className={styles.breadcrumb}>{items}</ol>;
};

Breadcrumbs.propTypes = {
  titleName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  location: PropTypes.shape({ pathname: PropTypes.string }),
};

export default Breadcrumbs;
