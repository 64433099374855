exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* VIEWPORTS */\n\n:root {\n}\n\n.RealestateDataRow__wrapper___3mhIh {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  min-height: 90px;\n  padding: 0 15px;\n}\n\n.RealestateDataRow__link___3NFZ4 {\n  float: right;\n  margin-left: auto;\n  min-height: 40px;\n}\n\n@media (max-width: 500px) {\n\n  .RealestateDataRow__link___3NFZ4 {\n    max-width: 130px;\n    text-overflow: ellipsis;\n    white-space: pre-line;\n    overflow: hidden;\n  }\n}\n\n.RealestateDataRow__collapseButton___37ctA {\n  width: 50px;\n  padding: 0;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n}\n\n.RealestateDataRow__navn___3Yic2 {\n  line-height: 33px;\n  width: 22%;\n  margin-left: 10px;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n}\n\n@media (max-width: 800px) {\n\n  .RealestateDataRow__navn___3Yic2 {\n    width: 50%;\n  }\n}\n\n.RealestateDataRow__listHeader___UCN9R {\n  font-weight: bold;\n}\n\n.RealestateDataRow__hideWhenSmall___15bIl {\n  margin-left: 10px;\n  width: 10%;\n}\n\n@media (max-width: 800px) {\n\n  .RealestateDataRow__hideWhenSmall___15bIl {\n    display: none;\n  }\n}\n\n.RealestateDataRow__ownerCode___2f-hS {\n  margin-left: 70px;\n}\n\n.RealestateDataRow__data___2mtul {\n  white-space: nowrap;\n  height: 1em;\n}\n", ""]);

// exports
exports.locals = {
	"wrapper": "RealestateDataRow__wrapper___3mhIh",
	"link": "RealestateDataRow__link___3NFZ4",
	"collapseButton": "RealestateDataRow__collapseButton___37ctA",
	"navn": "RealestateDataRow__navn___3Yic2",
	"listHeader": "RealestateDataRow__listHeader___UCN9R",
	"hideWhenSmall": "RealestateDataRow__hideWhenSmall___15bIl",
	"ownerCode": "RealestateDataRow__ownerCode___2f-hS",
	"data": "RealestateDataRow__data___2mtul"
};