import { takeLatest, all } from 'redux-saga/effects';

import * as REALESTATE from 'redux/modules/realestate/realestate';
import * as PROPERTY from 'redux/modules/property/property';
import * as BUILDING from 'redux/modules/building/building';

import { handleCallToApi, handlePostToApi } from './sagaHandlers';

export default function* propertySaga() {
  yield all([
    takeLatest(
      REALESTATE.GET_REALESTATE,
      handleCallToApi,
      REALESTATE.getRealEstateSuccess,
      REALESTATE.getRealEstateFail,
    ),
    takeLatest(
      REALESTATE.GET_REALESTATE_ALL,
      handleCallToApi,
      REALESTATE.getAllRealEstateSuccess,
      REALESTATE.getAllRealEstateFail,
    ),
    takeLatest(
      REALESTATE.SET_REALESTATE_NAME,
      handlePostToApi,
      REALESTATE.setRealEstateNameSuccess,
      REALESTATE.setRealEstateNameFail,
    ),
    takeLatest(
      REALESTATE.CONNECT_REALESTATE,
      handlePostToApi,
      REALESTATE.connectRealEstateSuccess,
      REALESTATE.connectRealEstateFail,
    ),
    takeLatest(
      REALESTATE.DISCONNECT_REALESTATE,
      handlePostToApi,
      REALESTATE.disconnectRealEstateSuccess,
      REALESTATE.disconnectRealEstateFail,
    ),
    takeLatest(
      PROPERTY.GET_PROPERTY,
      handleCallToApi,
      PROPERTY.gotProperty,
      PROPERTY.getPropertyFail,
    ),
    takeLatest(
      PROPERTY.SET_PROPERTY_ALIAS,
      handlePostToApi,
      PROPERTY.setPropertyAliasSuccess,
      PROPERTY.setPropertyAliasFail,
    ),
    takeLatest(
      PROPERTY.SET_PROPERTY_ADRESSE,
      handlePostToApi,
      PROPERTY.setPropertyAdresseSuccess,
      PROPERTY.setPropertyAdresseFail,
    ),
  ]);
}
