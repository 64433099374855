import React from 'react';
import { DataItem, Button } from 'components';
import { toGuiDate } from 'utils/utils';
import polyglot from 'i18n';
import styles from './PeriodicWizard.css';

const PeriodicWizard1 = ({ dates, buttonClick }) => (
  <React.Fragment>
    <div className={styles.flex}>
      <DataItem
        vertical
        metaData={polyglot.t('leideLokaler.periodic.periodRange')}
        data={dates.periodestart}
      />
      <DataItem
        vertical
        metaData={polyglot.t('leideLokaler.periodic.deadline')}
        data={dates.frist}
      />
    </div>
    <div className={styles.buttonWrapper}>
      <Button kind="primary" id="next" onClick={buttonClick}>
        {polyglot.t('leideLokaler.periodic.start')}
      </Button>
    </div>
  </React.Fragment>
);

export default PeriodicWizard1;
