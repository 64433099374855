import React from 'react';
import PropTypes from 'prop-types';
import polyglot from 'i18n';

import {
  ContractWizardStep1,
  ContractWizardStep2,
  ContractWizardStep3,
  ContractWizardStep4,
  ContractWizardStep5,
  ContractWizardStep6,
  ContractWizardStep7,
  Panel,
  WizardStepsNew,
  InfoBox,
  DataItem,
  Heading,
} from 'components';
import styles from './LokaleWizardLayout.css';

const LokaleWizardLayout = ({ step, currentLokale, ...props }) => {
  const getCurrentStep = () => {
    switch (step) {
      case 1:
        return <ContractWizardStep1 {...props} />;
      case 2:
        return <ContractWizardStep2 {...props} />;
      case 3:
        return <ContractWizardStep3 {...props} />;
      case 4:
        return <ContractWizardStep4 {...props} />;
      case 5:
        return <ContractWizardStep5 {...props} />;
      case 6:
        return <ContractWizardStep6 {...props} />;
      case 7:
        return <ContractWizardStep7 {...props} />;
      default:
        return 'Incorrect step state';
    }
  };

  const lokalePeriode =
    Array.isArray(currentLokale.lokaleperioder) &&
    currentLokale.lokaleperioder[currentLokale.lokaleperioder.length - 1];

  let leftPanelLokaleBox = '';
  if (currentLokale.navn) {
    const data = {
      label: [
        polyglot.t('newContract.name'),
        polyglot.t('newContract.lokalId'),
        polyglot.t('newContract.user'),
        polyglot.t('newContract.aarsverk'),
        polyglot.t('newContract.ansatte'),
        polyglot.t('newContract.innleide'),
        polyglot.t('newContract.kontor'),
      ],
      item: lokalePeriode && [
        currentLokale.navn,
        currentLokale.lokalId,
        currentLokale.bruker ? currentLokale.bruker.navn : '',
        lokalePeriode.antallAarsverk,
        lokalePeriode.antallAnsatte,
        lokalePeriode.antallInnleide,
        currentLokale.kontor ? 'Ja' : 'Nei',
      ],
    };

    leftPanelLokaleBox = <InfoBox data={data} />;
  }

  const leieforholdList = Array.isArray(currentLokale.leieforholds)
    ? currentLokale.leieforholds.map((it, i) => (
        <div key={i} className={styles.leieforholdList}>
          <DataItem
            metaData={polyglot.t('newContract.step1.name')}
            data={it.navn}
          />
        </div>
      ))
    : [];

  return (
    <div>
      <Heading level={0} bottomMargin>
        {step < 3
          ? polyglot.t('newContract.nyttlokale')
          : polyglot.t('newContract.nyttleieforhold')}
      </Heading>
      <div className={styles.wrapper}>
        <Panel className={styles.leftPanel}>
          <WizardStepsNew
            boxLabel="Lokalet"
            steps={2}
            currentStep={step}
            labels={[
              polyglot.t('newContract.step1.title'),
              polyglot.t('newContract.step2'),
            ]}
          />
          {step < 3 ? getCurrentStep() : leftPanelLokaleBox}
        </Panel>
        <div className={styles.rightPanel}>
          {leieforholdList.length > 0 && (
            <Panel>
              <Heading
                level={3}
                green
                bottomMargin
                className={styles.subHeader}
              >
                {'Eksisterende leieforhold'}
              </Heading>
              {leieforholdList}
            </Panel>
          )}
          <Panel className={styles.newContract}>
            <div className={styles.contractWizard}>
              <WizardStepsNew
                boxLabel={polyglot.t('newContract.title')}
                steps={4}
                currentStep={step > 2 ? step - 2 : 0}
                labels={[
                  polyglot.t('newContract.step3.title'),
                  polyglot.t('newContract.step4.title'),
                  polyglot.t('newContract.step5'),
                  polyglot.t('newContract.step6.title'),
                ]}
              />
              {step > 2 && getCurrentStep()}
            </div>
          </Panel>
        </div>
      </div>
    </div>
  );
};

LokaleWizardLayout.propTypes = {
  currentLokale: PropTypes.objectOf(PropTypes.any),
  step: PropTypes.number.isRequired,
};

LokaleWizardLayout.defaultProps = {
  currentLokale: {},
};

export default LokaleWizardLayout;
