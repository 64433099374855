// @flow

import React from 'react';
import GenericList from 'containers/GenericList';
import { TableTemplate, AddProjectModal } from 'components';
import { bindActionCreators } from 'redux';
import type { UserData, TableState, RouterHistory } from 'types';
import { connect } from 'react-redux';
import * as searchActions from 'redux/modules/search/search';
import * as tableActions from 'redux/modules/search/table';
import { createProject } from 'redux/modules/projects/projects';
import polyglot from 'i18n';
import searchSelector from 'redux/modules/search/searchSelector';
import userSelector from 'redux/modules/user/userSelector';

type Props = {
  data: {
    result: [{}],
    idArray: [{}],
  },
  actions: {
    createProject({ navn: string }): void,
    updateTable(string, {}): void,
    searchRequest({}): void,
  },
  loading: boolean,
  userData: UserData,
  table: TableState,
  history: RouterHistory,
  location: {
    query: {},
    search: string,
    pathname: string,
  },
  postSuccess: boolean,
};

type State = {
  addProjectModal: boolean,
  navn: string,
  prosjektnummer: string,
};

class ProjectList extends React.Component<Props, State> {
  state = {
    addProjectModal: false,
    navn: '',
    prosjektnummer: '',
  };

  componentDidUpdate(prevProps) {
    const { postSuccess, actions, table } = this.props;
    if (postSuccess && !prevProps.postSuccess) {
      actions.updateTable('prosjektTable', { toggle: !table.toggle });
    }
  }

  toggleModal = () => {
    this.setState(prevState => ({
      addProjectModal: !prevState.addProjectModal,
    }));
  };

  saveAction = () => {
    const { actions } = this.props;
    const { navn, prosjektnummer } = this.state;
    actions.createProject({ navn, prosjektnummer });
    this.setState({ addProjectModal: false });
  };

  onInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { addProjectModal } = this.state;
    return (
      <GenericList
        type="prosjekt"
        {...this.props}
        removeFilters
        render={(props: Props) => (
          <TableTemplate
            {...props}
            enableSearch
            newItemButton={
              props.userData.tilganger.indexOf('ADMINISTRER_UTVALG') > -1 && (
                <AddProjectModal
                  toggleModal={this.toggleModal}
                  showModal={addProjectModal}
                  saveProps={{ onClick: this.saveAction }}
                  {...this.state}
                  onInputChange={this.onInputChange}
                  buttonTitle={polyglot.t('leideLokaler.addProject')}
                />
              )
            }
          />
        )}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    data: searchSelector('prosjekt')(state),
    loading: state.search.loading,
    error: state.search.error,
    userData: userSelector(state),
    table: state.table.prosjektTable,
    postSuccess: state.projects.postSuccess,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...searchActions, ...tableActions, createProject },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectList);
