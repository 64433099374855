import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'components';
import classNames from 'classnames';
import styles from './Alert.css';

const Alert = ({ kind, header, modal, children }) => {
  const classes = classNames(styles.default, {
    [styles.success]: kind === 'success',
    [styles.warning]: kind === 'warning',
    [styles.danger]: kind === 'danger',
    [styles.info]: kind === 'info',
    [styles.header]: header,
    [styles.modal]: modal,
  });
  const alertIcon = () => {
    if (kind === 'success') {
      return 'check-box';
    }
    if (kind === 'warning') {
      return 'alert';
    }
    if (kind === 'danger') {
      return 'close';
    }
    return 'info-alt';
  };

  const alertIconColor = () => {
    if (kind === 'success') {
      return { white: true };
    }
    if (kind === 'warning') {
      return { warning: true };
    }
    if (kind === 'danger') {
      return { white: true };
    }
    return { info: true };
  };

  const getLabel = () => {
    if (kind === 'success') {
      return 'Suksess ikon';
    }
    if (kind === 'warning') {
      return 'Advarsel ikon';
    }
    if (kind === 'danger') {
      return 'Fare ikon';
    }
    return 'Informasjon ikon';
  };

  return (
    <div className={classes}>
      <Icon
        size="5"
        icon={alertIcon()}
        {...alertIconColor()}
        aria-label={getLabel()}
      />
      {children}
    </div>
  );
};

Alert.propTypes = {
  kind: PropTypes.oneOf(['success', 'warning', 'danger', 'info']),
  header: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.element,
  ]),
};

Alert.defaultProps = {
  kind: 'info',
  header: false,
  children: '',
};

export default Alert;
