exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".CommentBox__wrapper___1UcdJ {\n  overflow: hidden;\n  padding: 40px;\n  width: 80vw;\n}\n\n.CommentBox__input___1pmdS textarea {\n  min-height: 100px;\n  max-width: 100%;\n}\n\n.CommentBox__buttons___1u0B- {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex\n}\n\n.CommentBox__buttons___1u0B- > button {\n  margin: 10px;\n}\n\n.CommentBox__header___e8kLg {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: end;\n      -ms-flex-align: end;\n          align-items: flex-end;\n}\n", ""]);

// exports
exports.locals = {
	"wrapper": "CommentBox__wrapper___1UcdJ",
	"input": "CommentBox__input___1pmdS",
	"buttons": "CommentBox__buttons___1u0B-",
	"header": "CommentBox__header___e8kLg"
};