import React from 'react';
import PropTypes from 'prop-types';
import styles from './LoadingOverlay.css';

const LoadingOverlay = ({ relative }) => {
  const loader = (
    <div className={styles.spinner}>
      <div className={styles.bounce1} />
      <div className={styles.bounce2} />
      <div className={styles.bounce3} />
    </div>
  );
  return (
    <div className={!relative ? styles.wrapper : undefined}>
      <div className={styles.loading}>{loader}</div>
    </div>
  );
};

LoadingOverlay.propTypes = {
  relative: PropTypes.bool,
};

export default LoadingOverlay;
