import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import polyglot from 'i18n';
import { Heading, Icon } from 'components';
import styles from './TabButton.css';

const TabButton = ({
  title,
  emptyTitle,
  className,
  active,
  children,
  panel,
  showToggle,
  type,
  description,
  name,
  ...props
}) => (
  <div className={classnames(styles.wrapper, panel && styles.panel)}>
    {title && (
      <Heading id={name + '_label'} green level={1}>
        {title}
      </Heading>
    )}
    <div
      {...props}
      aria-controls="kpiPanelContent"
      aria-expanded={active}
      role="button"
      tabIndex={0}
      onKeyPress={e => {
        if (e.key === 'Enter') props.onClick(e);
      }}
      className={classnames(
        styles.button,
        active && styles.active,
        emptyTitle && styles.emptyTitle,
        panel && styles.panelButton,
        type === 'lokale' && styles.lokale,
        className,
      )}
    >
      {children}
      {showToggle && (
        <div className={styles.toggle}>
          <label id={name + '_toggle'}>{polyglot.t('kpi.toggle')}</label>
          <Icon icon={active ? 'angle-up' : 'angle-down'} small />
        </div>
      )}
    </div>
  </div>
);

TabButton.propTypes = {
  title: PropTypes.string,
  emptyTitle: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  active: PropTypes.bool,
  showToggle: PropTypes.bool,
  panel: PropTypes.bool,
  children: PropTypes.any,
};

export default TabButton;
