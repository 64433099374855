import React from 'react';
import GenericList from 'containers/GenericList';
import { TableTemplate } from 'components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as searchActions from 'redux/modules/search/search';
import * as tableActions from 'redux/modules/search/table';
import searchSelector from 'redux/modules/search/searchSelector';
import userSelector from 'redux/modules/user/userSelector';

const PropertyList = ({ ...props }) => (
  <GenericList
    type="property"
    {...props}
    render={genericProps => <TableTemplate {...genericProps} enableSearch />}
  />
);
function mapStateToProps(state) {
  return {
    data: searchSelector('property')(state),
    loading: state.search.loading,
    error: state.search.error,
    userData: userSelector(state),
    table: state.table.propertyTable,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...searchActions, ...tableActions },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PropertyList);
