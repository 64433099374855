import React from 'react';
import PropTypes from 'prop-types';
import polyglot from 'i18n';
import { Heading, Panel, Table, ModalButton, Field, Button } from 'components';
import styles from './KpiAdminTemplate.css';
import { setDefault, validateNumber } from 'utils/utils';
import Dialog from '../../../containers/Dialog';

const KpiAdminTemplate = ({
  editMode,
  toggleEdit,
  kpi,
  onChange,
  inputs,
  onSubmit,
}) => {
  const headerRow = [
    polyglot.t('kpi.kpi'),
    polyglot.t('kpi.maal'),
    polyglot.t('kpi.grense'),
    polyglot.t('kpi.gjeldende'),
  ];
  const kpiTable = kpi.map(it => [
    polyglot.t(`kpi.${it.kpi}`),
    it.maal,
    it.grense,
    it.gjeldende || '-',
  ]);
  const kpiEditTable = firstRef =>
    kpi.map((it, i) => [
      polyglot.t(`kpi.${it.kpi}`),
      <Field
        refFunc={i === 0 ? firstRef : undefined}
        value={setDefault(inputs[it.kpi] && inputs[it.kpi].target, it.maal)}
        name="target"
        onChange={e => onChange(e, it.kpi)}
        onKeyPress={e => (e.key === 'Enter' ? onSubmit() : '')}
        validationState={validateNumber(
          inputs[it.kpi] && inputs[it.kpi].target,
        )}
        feedback={polyglot.t('kpi.feedback')}
      />,
      <Field
        value={setDefault(inputs[it.kpi] && inputs[it.kpi].limit, it.grense)}
        name="limit"
        onChange={e => onChange(e, it.kpi)}
        onKeyPress={e => (e.key === 'Enter' ? onSubmit() : '')}
        validationState={validateNumber(inputs[it.kpi] && inputs[it.kpi].limit)}
        feedback={polyglot.t('kpi.feedback')}
      />,
      it.gjeldende || '-',
    ]);
  return (
    <div>
      <Heading level={0}>{polyglot.t('kpi.header')}</Heading>
      <Panel>
        <Table dataRows={kpiTable} headerRow={headerRow} />
        <div className={styles.buttonRow}>
          <ModalButton
            showModal={editMode}
            toggle={toggleEdit}
            buttonTitle={polyglot.t('kpi.edit')}
          >
            <Dialog
              onExit={toggleEdit}
              render={({ firstRef, lastRef }) => (
                <div className={styles.modalWrapper}>
                  <Table
                    headerRow={headerRow}
                    dataRows={kpiEditTable(firstRef)}
                    feedbackMargin
                  />
                  <Button kind="primary" refFunc={lastRef} onClick={onSubmit}>
                    {'Save'}
                  </Button>
                </div>
              )}
            />
          </ModalButton>
        </div>
      </Panel>
    </div>
  );
};

KpiAdminTemplate.propTypes = {
  editMode: PropTypes.bool,
  toggleEdit: PropTypes.func,
  kpi: PropTypes.arrayOf(
    PropTypes.shape({
      kpi: PropTypes.string,
      maal: PropTypes.number,
      grense: PropTypes.number,
      gjeldende: PropTypes.number,
    }),
  ),
  onChange: PropTypes.func,
  inputs: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default KpiAdminTemplate;
