import React from 'react';
import PropTypes from 'prop-types';
import {
  Field,
  Button,
  Link,
  Bubbles,
  LeieObjektInput,
  HelpToolTipButton,
} from 'components';
import { validateNumber } from 'utils/utils';
import { validateAreaSum } from 'utils/validationHelpers';
import polyglot from 'i18n';
import styles from './ContractWizard.css';

const ContractWizardStep6 = ({
  step6,
  addObject,
  onChange,
  buttonClick,
  toggleInput,
  removeObject,
  error,
  periodestart,
  loadingSaveContract,
  lokale,
}) => {
  const helpToolTipButton = text => {
    return <HelpToolTipButton title={text} />;
  };
  const valiateCurrenLeieobjekt =
    (step6.currentObject.bruttoareal || step6.currentObject.parkeringsareal) &&
    !step6.currentObject.navn;
  return (
    <div className={styles.wrapper}>
      <div className={styles.propertyLine}>
        <Field
          name="husleie"
          value={step6.husleie || ''}
          onChange={onChange}
          label={polyglot.t('newContract.step6.husleie')}
          placeholder=""
          validationState={validateNumber(step6.husleie)}
          feedback="Må være et tall"
          helpToolTipButton={helpToolTipButton(
            polyglot.t('helpToolTipButton.newLokale.step6.husleie'),
          )}
        />
        <Field
          name="framleiereduksjon"
          value={step6.framleiereduksjon || ''}
          onChange={onChange}
          label={polyglot.t('newContract.step6.framleiereduksjon')}
          placeholder=""
          validationState={validateNumber(step6.framleiereduksjon)}
          feedback="Må være et tall"
          helpToolTipButton={helpToolTipButton(
            polyglot.t('helpToolTipButton.newLokale.step6.framleiereduksjon'),
          )}
        />
      </div>

      {step6.objects.map((it, i) => (
        <div className={styles.result} key={it.navn}>
          {polyglot.t('newContract.step6.lagretObjekt') +
            (it.navn ? it.navn : '')}
          <Link onClick={() => removeObject(i)}>
            {polyglot.t('newContract.step6.fjernObjektet')}
          </Link>
        </div>
      ))}
      {step6.showInput && (
        <LeieObjektInput
          defaultValues={{
            kontor: lokale.kontor,
            ...step6.currentObject,
          }}
          {...step6.currentObject}
          onChange={onChange}
          buttonClick={addObject}
          abortClick={() => {
            toggleInput(6);
          }}
          periodestart={periodestart}
        />
      )}
      {!step6.showInput && (
        <div>
          <Link onClick={() => toggleInput(6)}>
            {polyglot.t('newContract.step6.leggTilObjekt')}
          </Link>
        </div>
      )}
      <div className={styles.buttonWrapper}>
        <Button id="back" secondaryButton onClick={buttonClick}>
          {polyglot.t('newContract.tilbake')}
        </Button>
        <Button
          id="fullfør"
          kind="primary"
          disabled={valiateCurrenLeieobjekt}
          onClick={buttonClick}
        >
          {loadingSaveContract ? (
            <Bubbles />
          ) : (
            polyglot.t('newContract.step6.fullfor')
          )}
        </Button>
      </div>
      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
};

ContractWizardStep6.propTypes = {
  step6: PropTypes.shape({
    husleie: PropTypes.string,
    framleiereduksjon: PropTypes.string,
    objects: PropTypes.array,
    showInput: PropTypes.bool,
    currentObject: PropTypes.object,
    kostnaderFraObj: PropTypes.object,
  }).isRequired,
  addObject: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  buttonClick: PropTypes.func.isRequired,
  toggleInput: PropTypes.func.isRequired,
  removeObject: PropTypes.func.isRequired,
  error: PropTypes.string,
  loadingSaveContract: PropTypes.bool,
  periodestart: PropTypes.string,
  lokale: PropTypes.shape({ kontor: PropTypes.bool }),
};

export default ContractWizardStep6;
