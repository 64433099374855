import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { GroupConnectionInfo, GroupEdit } from 'components';
import polyglot from 'i18n';

import * as realestateActions from 'redux/modules/realestate/realestate';
import * as propertyActions from 'redux/modules/property/property';
import * as widgetsActions from 'redux/modules/widgets/widgets';

class GroupEditForm extends PureComponent {
  state = {
    currentInput: '',
    realestateLink: '',
    originalValue: '',
    validationState: 'error', // this.props.validationState,
    isChanged: false,
    isConnected: false,
  };

  componentDidMount() {
    if (
      this.props.connectedRealestate &&
      this.props.propertyId &&
      !this.props.isLoading
    )
      this.setNewRealestate(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Container gets property-related props from parent (PropertyDetails) instead of directly from state
    // Since the parent container makes sure no old property-data is passed down

    const { propertyOwner, isLoading, connectedRealestate } = nextProps;
    const propOwnerOrgnr = propertyOwner
      ? propertyOwner.organisasjon
        ? propertyOwner.organisasjon.organisasjonsnummer
        : propertyOwner.nr
      : '';

    if (!isLoading && this.props.isLoading)
      this.props.actions.getAllRealEstate({ orgnr: propOwnerOrgnr });
    if (
      !isLoading &&
      connectedRealestate &&
      (!this.props.connectedRealestate ||
        connectedRealestate.id !== this.props.connectedRealestate.id)
    ) {
      // either got realestate for first time or realestate changed
      this.setNewRealestate(nextProps);
    }
    if (
      nextProps.newRealestateId &&
      this.props.newRealestateId !== nextProps.newRealestateId
    ) {
      if (this.props.newRealestateId)
        console.log('Knyttet ny eiendom, hadde en fra før?');
      this.props.actions.displayAlert({
        kind: 'success',
        text: polyglot.t('alert.updateSuccess'),
      });
      this.props.actions.getProperty(nextProps.matrikkelId);
      this.setState({
        isConnected: true,
      });
    }
  }

  componentWillUnmount() {
    if (this.state.isChanged && !this.state.isConnected) {
      if (confirm(polyglot.t('warning.unsavedName'))) this.handleSubmit();
      else this.setState({ currentInput: '', currentInputId: '' });
    }
  }

  setNewRealestate = props => {
    console.log('setting new value');
    this.setState({
      currentInput: '',
      realestateLink: `/d${props.connectedRealestate._links.self}`,
      originalValue: props.connectedRealestate.navn,
      validationState: null,
      isConnected: true,
      isChanged: false,
    });
  };

  handleSubmit = e => {
    if (e) {
      e.preventDefault();
    }
    this.props.actions.connectRealEstate({
      eiendomName: this.state.currentInput,
      grunneiendomId: this.props.propertyId,
    });
    this.setState({
      currentInput: '',
      isChanged: false,
    });
  };

  handleRemove = () => {
    this.props.actions.disconnectRealEstate({
      eiendomId: this.props.connectedRealestate.id,
      eiendomName: this.props.connectedRealestate.navn,
      grunneiendomId: this.props.propertyId,
    });
    this.setState({
      currentInput: '',
      currentInputId: undefined,
      isConnected: false,
    });
  };

  handleKeyPress = e => {
    const key = e.which || e.keyCode;
    if (key === 13 && this.state.isChanged) {
      e.stopPropagation();
      this.handleSubmit();
    }
  };

  handleChange = e => {
    if (e.length < 1) this.setState({ validationState: 'error' });
    else if (e.length > 0) this.setState({ validationState: null });
    this.setState({
      currentInput: e,
      isChanged: e !== this.state.originalValue,
    });
  };

  handleSelectChange = e => {
    if (e) {
      this.setState({
        currentInput: e.label,
        currentInputId: e.value,
        isChanged: true,
        validationState: null,
      });
    } else {
      this.setState({
        currentInput: '',
        currentInputId: '',
        isChanged: false,
        validationState: 'error',
      });
    }
  };

  render() {
    const { all, isLoading, connectedRealestate, editAccess } = this.props;

    if (this.state.isConnected) {
      return (
        <GroupConnectionInfo
          to={isLoading ? '' : this.state.realestateLink}
          value={connectedRealestate ? connectedRealestate.navn : ''}
          handleRemove={this.handleRemove}
          editAccess={editAccess}
        />
      );
    }
    return (
      <GroupEdit
        maxLength="255"
        name="connectGroup"
        placeholder={polyglot.t('connectionInfo.placeholder')}
        validationState={this.state.validationState}
        onInputChange={this.handleChange}
        onChange={this.handleSelectChange}
        onKeyPress={this.handleKeyPress}
        value={this.state.currentInputId || undefined}
        onSubmit={this.handleSubmit}
        handleSubmit={this.handleSubmit}
        choices={all}
        editAccess={editAccess}
      />
    );
  }
}

GroupEditForm.propTypes = {
  propertyId: PropTypes.number,
  newRealestateId: PropTypes.string,
  propertyOwner: PropTypes.objectOf(PropTypes.any),
  isLoading: PropTypes.bool,
  connectedRealestate: PropTypes.objectOf(PropTypes.any),
  actions: PropTypes.objectOf(PropTypes.any),
  editAccess: PropTypes.bool,
  all: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ),
};

function mapStateToProps(state) {
  return {
    all: state.realestates.all,
    newRealestateId: state.realestates.realestateId,
    isLoading: state.properties.loading,
    editAccess: state.properties.property.editable,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...realestateActions, ...propertyActions, ...widgetsActions },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GroupEditForm);
