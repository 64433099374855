exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PeriodicWizard__buttonWrapper___zcDvg {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: end;\n      -ms-flex-pack: end;\n          justify-content: flex-end\n}\n\n.PeriodicWizard__buttonWrapper___zcDvg > button {\n  margin: 10px;\n}\n\n.PeriodicWizard__flex___2Qb6I {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex\n}\n\n.PeriodicWizard__flex___2Qb6I > div {\n  margin-right: 100px;\n}\n\n.PeriodicWizard__required___1xzZM {\n  color: #b1b1b1;\n  margin-left: 3px;\n  font-weight: 300;\n}\n\n.PeriodicWizard__requiredText___1R9r9 {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: end;\n      -ms-flex-pack: end;\n          justify-content: flex-end;\n  font-style: italic;\n  font-size: 0.8rem;\n}\n", ""]);

// exports
exports.locals = {
	"buttonWrapper": "PeriodicWizard__buttonWrapper___zcDvg",
	"flex": "PeriodicWizard__flex___2Qb6I",
	"required": "PeriodicWizard__required___1xzZM",
	"requiredText": "PeriodicWizard__requiredText___1R9r9"
};