import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ProfilePage } from 'components';
import userSelector from 'redux/modules/user/userSelector';

class UserProfile extends PureComponent {
  render() {
    return <ProfilePage user={this.props.user} />;
  }
}

UserProfile.propTypes = {
  user: PropTypes.objectOf(PropTypes.any),
};

function mapStateToProps(state) {
  return {
    user: userSelector(state),
  };
}

export default connect(mapStateToProps)(UserProfile);
