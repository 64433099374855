exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".SearchBox__wrapper___3mt5l {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n  margin-top: 10px;\n}\n\n.SearchBox__toggleWrapperLeft___2ik0P {\n  margin-left: auto;\n  padding-left: 10px;\n  margin-right: 50px;\n}\n\n.SearchBox__toggleWrapper___KcfRk {\n  margin-right: 50px;\n}\n\n.SearchBox__inputField___19L6N {\n  margin-bottom: 10px;\n}\n\n.SearchBox__filterButton___3x2Sx {\n  margin-left: 15px;\n  padding-left: 40px;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n}\n\n.SearchBox__searchButton___3Ni76 {\n  margin-left: 10px;\n  height: 45px;\n}\n\n.SearchBox__hiddenSpan___2f1nF {\n  position: absolute;\n  top: 0;\n  left: -2000px;\n}\n", ""]);

// exports
exports.locals = {
	"wrapper": "SearchBox__wrapper___3mt5l",
	"toggleWrapperLeft": "SearchBox__toggleWrapperLeft___2ik0P",
	"toggleWrapper": "SearchBox__toggleWrapper___KcfRk",
	"inputField": "SearchBox__inputField___19L6N",
	"filterButton": "SearchBox__filterButton___3x2Sx",
	"searchButton": "SearchBox__searchButton___3Ni76",
	"hiddenSpan": "SearchBox__hiddenSpan___2f1nF"
};