// @flow

import React from 'react';
import { PropertyInput, Heading, ModalButton, Link, Button } from 'components';
import polyglot from 'i18n';
import Dialog from '../../../containers/Dialog';
import styles from './propertyData.css';
import EditSelectedBuildings from './EditSelectedBuildings';
import BuildingPicker from '../../organisms/BuildingPicker/BuildingPicker';

type Props = {
  grunneiendommer: Array<{
    navn: string,
    matrikkelident: string,
    matrikkelId: number,
  }>,
  hasEditAccess: boolean,
  modals: {},
  index: number,
  toggleModal(string): void,
  onPropertyChange(): void,
  alert: {},
  propertyInput: {},
};

class PropertyData extends React.Component<Props> {
  state = {
    showModal: false,
    expandedBuildings: -1,
  };

  toggleModal = () =>
    this.setState(prevState => ({ showModal: !prevState.showModal }));

  sortBygninger = (a, b) => a.bygningsnr - b.bygningsnr;

  render() {
    const {
      grunneiendommer,
      hasEditAccess,
      onPropertyChange,
      alert,
      removeGrunneiendom,
      propertyInput,
      matrikkelResult,
      submitProperty,
      isWizard,
      loadingSaveContract,
      ...props
    } = this.props;
    const { showModal, expandedBuildings } = this.state;
    return (
      <div className={styles.properties}>
        <Heading green bottomMargin level={4}>
          {'Grunneiendommer'}
        </Heading>
        {grunneiendommer.map((grunneiendom, i) => (
          <div className={styles.resultWrapper}>
            <div key={grunneiendom.matrikkelId} className={styles.result}>
              <Link
                target="_blank"
                to={`/d/grunneiendom/${grunneiendom.matrikkelId}`}
              >
                {`${grunneiendom.navn} - ${grunneiendom.matrikkelident}`}
              </Link>
              {hasEditAccess && (
                <EditSelectedBuildings
                  submitProperty={submitProperty}
                  removeGrunneiendom={removeGrunneiendom}
                  grunneiendom={grunneiendom}
                  isWizard={isWizard}
                  loading={loadingSaveContract}
                  allBuildings={grunneiendom.allBuildings}
                />
              )}
            </div>
            <div
              className={`${styles.buildingWrapper} ${
                expandedBuildings === i ? styles.expand : ''
              }`}
            >
              {grunneiendom.bygninger.map(it => (
                <div className={styles.bygninger}>
                  <Link target="_blank" to={`/d/bygning/${it.bygningsnr}`}>
                    {`${it.visningsnavn} - ${it.bygningtype}`}
                  </Link>
                </div>
              ))}
            </div>
            {grunneiendom.bygninger.length > 4 &&
              (expandedBuildings !== i ? (
                <Button
                  className={styles.expandButton}
                  onClick={() =>
                    this.setState({
                      expandedBuildings: i,
                    })
                  }
                >
                  ...vis alle
                </Button>
              ) : (
                <Button
                  className={styles.expandButton}
                  onClick={() =>
                    this.setState({
                      expandedBuildings: -1,
                    })
                  }
                >
                  ...vis mindre
                </Button>
              ))}
          </div>
        ))}
        {hasEditAccess && (
          <ModalButton
            className={styles.modal}
            showModal={showModal}
            toggle={this.toggleModal}
            link
            buttonTitle={polyglot.t('leideLokaler.addProperty')}
            {...alert}
          >
            <Dialog
              onExit={this.toggleModal}
              render={dialogProps => (
                <React.Fragment>
                  <PropertyInput
                    {...props}
                    {...dialogProps}
                    {...propertyInput}
                    onChange={onPropertyChange}
                  />
                  {!!matrikkelResult.bygninger && (
                    <BuildingPicker
                      property={{ ...matrikkelResult, bygninger: [] }}
                      notEditMode
                      submitProperty={e => {
                        this.toggleModal();
                        submitProperty(e);
                      }}
                      loading={loadingSaveContract}
                      allBuildings={matrikkelResult.bygninger.sort(
                        this.sortBygninger,
                      )}
                      closeModal={() => {
                        this.toggleModal();
                      }}
                    />
                  )}
                </React.Fragment>
              )}
            />
          </ModalButton>
        )}
      </div>
    );
  }
}

PropertyData.defaultProps = {
  grunneiendommer: [],
};

export default PropertyData;
