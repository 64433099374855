import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table, Button, FormikField, Bubbles } from 'components';
import polyglot from 'i18n';
import { Formik, Form } from 'formik';
import { validateNumber } from 'utils/utils';
import { validateDecimal } from 'utils/validationHelpers';
import styles from './PeriodicWizard.css';

const fieldNames = ['antallAnsatte', 'antallAarsverk', 'antallInnleide'];

const validation = values => {
  const errors = {};
  if (validateNumber(values.antallAnsatte) === 'error')
    errors.antallAnsatte = 'Ikke et gyldig heltall';
  if (validateDecimal(values.antallAarsverk) === 'error')
    errors.antallAarsverk = 'Ikke et gyldig desimaltall';
  if (validateNumber(values.antallInnleide) === 'error')
    errors.antallInnleide = 'Ikke et gyldig heltall';
  /* 
  if (!values.antallAarsverk || values.antallAarsverk === '0')
    errors.antallAarsverk = 'Du må angi en verdi for årsverk'; */
  return errors;
};

const PeriodicWizard3 = ({
  lokale,
  actions,
  buttonClick,
  showHelpText,
  savePeriodicReport,
  getSavedReport,
}) => {
  if (!lokale.lokaleperiode) return null;

  const formRef = useRef();

  useEffect(() => {
    const saveInterval = setInterval(() => {
      savePeriodicReport(formRef.current.values);
    }, 60000);
    return () => clearInterval(saveInterval);
  }, []);

  const initValues = () => {
    const report = getSavedReport();
    if (report) {
      return report;
    }

    return fieldNames.reduce((acc, field) => {
      acc[field] = lokale.lokaleperiode[field];
      return acc;
    }, {});
  };

  const onSubmit = values => {
    const newLokalePeriode = {
      ...lokale.lokaleperiode,
      ...values,
    };
    if (typeof values.antallAarsverk === 'string') {
      newLokalePeriode.antallAarsverk = values.antallAarsverk.replace(',', '.');
    }
    savePeriodicReport(values);
    actions.setLokalePeriode(newLokalePeriode, lokale.id);
  };

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize
      validateOnChange
      initialValues={initValues()}
      onSubmit={onSubmit}
      validate={validation}
    >
      {({ isSubmitting, errors, values }) => (
        <Form>
          <Table
            drilldown={false}
            dataRows={[
              fieldNames.map(field => (
                <FormikField
                  name={field}
                  errors={errors}
                  orange={
                    (field === 'antallAarsverk' || field === 'antallAnsatte') &&
                    values[field] == null
                  }
                />
              )),
            ]}
            headerRow={[
              polyglot.t('leideLokaler.numberEmp'),
              <span>{polyglot.t('leideLokaler.numberYears')}</span>,
              polyglot.t('leideLokaler.numberConsultants'),
            ]}
            helpToolButton={[
              polyglot.t('helpToolTipButton.periodicReport.step3.numberEmp'),
              polyglot.t('helpToolTipButton.periodicReport.step3.numberYears'),
              polyglot.t(
                'helpToolTipButton.periodicReport.step3.numberConsultants',
              ),
            ]}
          />
          <div className={styles.buttonWrapper}>
            <Button secondaryButton id="cancel" onClick={buttonClick}>
              {'Avbryt'}
            </Button>
            <Button secondaryButton id="prev" onClick={buttonClick}>
              {polyglot.t('leideLokaler.periodic.back')}
            </Button>
            <Button
              kind="primary"
              type="submit"
              disabled={isSubmitting || Object.keys(errors).length > 0}
            >
              {isSubmitting ? (
                <Bubbles />
              ) : (
                polyglot.t('leideLokaler.periodic.saveAndCont')
              )}
            </Button>
          </div>
          {showHelpText && (
            <span className={styles.requiredText}>
              {polyglot.t('leideLokaler.periodic.requiredText')}
            </span>
          )}
        </Form>
      )}
    </Formik>
  );
};

PeriodicWizard3.propTypes = {
  lokale: PropTypes.shape({ lokaleperiode: PropTypes.object }).isRequired,
  buttonClick: PropTypes.func.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  showHelpText: PropTypes.bool,
  savePeriodicReport: PropTypes.func,
  getSavedReport: PropTypes.func,
  removeSavedReport: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    lokale: state.leideLokaler.lokaler,
  };
}

export default connect(mapStateToProps)(PeriodicWizard3);
