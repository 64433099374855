// @flow

import * as React from 'react';
import { createPortal } from 'react-dom';
import { Alert } from 'components';
import classnames from 'classnames';
import styles from './Lightbox.css';

type Props = {
  close: () => void,
  content: React.Node,
  children: React.Node,
  enabled: boolean,
  kind: string,
  text: string,
  fullScreen: boolean,
};

const Lightbox = ({
  close,
  fullScreen,
  content,
  children,
  enabled,
  kind,
  text,
}: Props) => {
  const closeModal = e => {
    e.stopPropagation();
    if (!e) close();
    else if (e.target.id === 'wrapper') {
      close();
    }
  };

  return (
    <Portal>
      <div
        id="wrapper"
        role="button"
        tabIndex={0}
        onMouseDown={close ? e => closeModal(e) : undefined}
        className={classnames(
          styles.modalWrapper,
          fullScreen && styles.fullScreen,
        )}
      >
        {enabled && <Alert kind={kind}>{text}</Alert>}
        {content || children}
      </div>
    </Portal>
  );
};

export default Lightbox;

class Portal extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    document.body.appendChild(this.el);
    document.body.style.overflow = 'hidden';
  }

  componentWillUnmount() {
    document.body.removeChild(this.el);
    document.body.style.overflow = 'auto';
  }

  render() {
    return createPortal(this.props.children, this.el);
  }
}
