import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { HelpContent, Link } from 'components';
import styles from './HelpModal.css';

const HelpModal = ({ helpRef, toggleHelp, ...props }) => (
  <div
    ref={helpRef}
    className={classnames(
      styles.wrapper,
      props.open ? styles.open : styles.close,
    )}
  >
    <Link onClick={toggleHelp} tabIndex={props.open ? 0 : -1} to="/d/hjelp">
      {'Vis all hjelp'}
    </Link>
    <HelpContent {...props} />
  </div>
);

HelpModal.propTypes = {
  helpRef: PropTypes.func,
  toggleHelp: PropTypes.func,
  open: PropTypes.bool,
  canEdit: PropTypes.bool,
  toggleEdit: PropTypes.func,
  helpObj: PropTypes.shape({
    overskrift: PropTypes.string,
    hjelpetekst: PropTypes.string,
    videoUrl: PropTypes.string,
  }).isRequired,
  editMode: PropTypes.bool,
  submitHelp: PropTypes.func,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
};

export default HelpModal;
