// Using ducks pattern
// Export action constants
export const CREATE_LOKALER = 'CREATE_LOKALER';
export const CREATE_LOKALER_SUCCESS = 'CREATE_LOKALER_SUCCESS';
export const CREATE_LOKALER_FAILED = 'CREATE_LOKALER_FAILED';

export const GET_LOKALE = 'GET_LOKALE';
export const GET_LOKALE_SUCCESS = 'GET_LOKALE_SUCCESS';
export const GET_LOKALE_FAILED = 'GET_LOKALE_FAILED';

export const DELETE = 'DELETE';
export const DELETE_SUCCESS = 'DELETE_SUCCESS';
export const DELETE_FAILED = 'DELETE_FAILED';

export const DELETE_PERIOD = 'DELETE_PERIOD';
export const DELETE_PERIOD_SUCCESS = 'DELETE_PERIOD_SUCCESS';
export const DELETE_PERIOD_FAILED = 'DELETE_PERIOD_FAILED';

export const SET_LOKALE_NAME = 'SET_LOKALE_NAME';
export const SET_LOKALE_NAME_SUCCESS = 'SET_LOKALE_NAME_SUCCESS';
export const SET_LOKALE_NAME_FAILED = 'SET_LOKALE_NAME_FAILED';

export const SET_LOKALE_STATUS = 'SET_LOKALE_STATUS';
export const SET_LOKALE_STATUS_SUCCESS = 'SET_LOKALE_STATUS_SUCCESS';
export const SET_LOKALE_STATUS_FAILED = 'SET_LOKALE_STATUS_FAILED';

export const SET_LOKALE_PERIODE = 'SET_LOKALE_PERIODE';
export const SET_LOKALE_PERIODE_SUCCESS = 'SET_LOKALE_PERIODE_SUCCESS';
export const SET_LOKALE_PERIODE_FAILED = 'SET_LOKALE_PERIODE_FAILED';

export const GET_DATES = 'GET_DATES';
export const GET_DATES_SUCCESS = 'GET_DATES_SUCCESS';
export const GET_DATES_FAILED = 'GET_DATES_FAILED';

// Export action creators
export const createLokaler = payload => ({
  type: CREATE_LOKALER,
  path: '/lokale/lagre',
  payload,
});
export const createLokalerSuccess = lokaler => ({
  type: CREATE_LOKALER_SUCCESS,
  lokaler,
});
export const createLokalerFailed = error => ({
  type: CREATE_LOKALER_FAILED,
  error,
});

export const getLokale = id => ({ type: GET_LOKALE, path: `/lokale/${id}` });

export const getLokaleSuccess = lokale => ({
  type: GET_LOKALE_SUCCESS,
  lokale,
});
export const getLokaleFailed = error => ({ type: GET_LOKALE_FAILED, error });

export const setLokaleName = (id, query) => ({
  type: SET_LOKALE_NAME,
  path: `/lokale/${id}/navn`,
  payload: query,
});
export const setLokaleNameSuccess = () => ({
  type: SET_LOKALE_NAME_SUCCESS,
});
export const setLokaleNameFailed = error => ({
  type: SET_LOKALE_NAME_FAILED,
  error,
});

export const setLokaleStatus = (id, status) => ({
  type: SET_LOKALE_STATUS,
  path: `/lokale/${id}/aktiv/${status}`,
});
export const setLokaleStatusSuccess = () => ({
  type: SET_LOKALE_STATUS_SUCCESS,
});
export const setLokaleStatusFailed = error => ({
  type: SET_LOKALE_STATUS_FAILED,
  error,
});

export const setLokalePeriode = (payload, id) => ({
  type: SET_LOKALE_PERIODE,
  path: `/lokale/${id}/lokaleperiode`,
  payload,
});
export const setLokalePeriodeSuccess = query => ({
  type: SET_LOKALE_PERIODE_SUCCESS,
  query,
});
export const setLokalePeriodeFailed = error => ({
  type: SET_LOKALE_PERIODE_FAILED,
  error,
});

export const deleteItem = (type, id, bool) => ({
  item: type,
  type: DELETE,
  path: `/${type}/${id}/aktiv/${bool}`,
});
export const deleteSuccess = () => ({
  type: DELETE_SUCCESS,
});
export const deleteFailed = () => ({
  type: DELETE_FAILED,
});

export const deletePeriod = (type, id) => ({
  type: DELETE_PERIOD,
  path: `/${type}/${type}periode/${id}`,
});
export const deletePeriodSuccess = () => ({ type: DELETE_PERIOD_SUCCESS });
export const deletePeriodFailed = () => ({ type: DELETE_PERIOD_FAILED });

export const getDates = () => ({
  type: GET_DATES,
  path: '/rapport/datoer',
});
export const getDatesSuccess = dates => ({
  type: GET_DATES_SUCCESS,
  dates,
});
export const getDatesFailed = error => ({
  type: GET_DATES_FAILED,
  error,
});

// Define initial state for this reducer
export const initialState = {
  lokaler: {
    lokaleperiode: {},
    bruker: {},
  },
  dates: {},
  loadingCreateLokale: false,
  loadingGetLokale: false,
  error: '',
};

// Export the reducer
export default function leideLokalerReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_LOKALER: {
      return Object.assign({}, state, {
        loadingCreateLokale: true,
        createLokaleFailed: false,
        error: '',
      });
    }
    case CREATE_LOKALER_SUCCESS: {
      return Object.assign({}, state, {
        lokaler: action.lokaler,
        loadingCreateLokale: false,
      });
    }
    case CREATE_LOKALER_FAILED: {
      return Object.assign({}, state, {
        error: action.error,
        createLokaleFailed: true,
        loadingCreateLokale: false,
      });
    }
    case GET_LOKALE: {
      return Object.assign({}, state, {
        error: '',
        loadingGetLokale: true,
      });
    }
    case GET_LOKALE_SUCCESS: {
      return Object.assign({}, state, {
        lokaler: action.lokale,
        loadingGetLokale: false,
      });
    }
    case GET_LOKALE_FAILED: {
      return Object.assign({}, state, {
        error: action.error,
        loadingGetLokale: false,
      });
    }
    case SET_LOKALE_NAME: {
      return Object.assign({}, state, {
        error: '',
        settingLokaleName: true,
        lokaler: Object.assign({}, state.lokaler, {
          // server returns only true, should return the new name
          navn: action.query.value,
          visningsnavn: action.query.value,
        }),
      });
    }
    case SET_LOKALE_NAME_SUCCESS: {
      return Object.assign({}, state, { settingLokaleName: false });
    }
    case SET_LOKALE_NAME_FAILED: {
      return Object.assign({}, state, {
        error: action.error,
        settingLokaleName: false,
      });
    }
    case SET_LOKALE_STATUS: {
      return Object.assign({}, state, {
        error: '',
        settingLokaleStatus: true,
      });
    }
    case SET_LOKALE_STATUS_SUCCESS: {
      return Object.assign({}, state, { settingLokaleStatus: false });
    }
    case SET_LOKALE_STATUS_FAILED: {
      return Object.assign({}, state, {
        error: action.error,
        settingLokaleStatus: false,
      });
    }
    case SET_LOKALE_PERIODE: {
      return {
        ...state,
        loadingSetLokalePeriode: true,
        error: '',
        savedLokaleperiode: false,
      };
    }
    case SET_LOKALE_PERIODE_SUCCESS: {
      return {
        ...state,
        loadingSetLokalePeriode: false,
        savedLokaleperiode: true,
      };
    }
    case SET_LOKALE_PERIODE_FAILED: {
      return {
        ...state,
        error: action.error,
        loadingSetLokalePeriode: false,
        savedLokaleperiode: false,
      };
    }
    case DELETE: {
      return {
        ...state,
        error: '',
        itemType: action.item,
        loadingDelete: true,
      };
    }
    case DELETE_SUCCESS: {
      return { ...state, loadingDelete: false };
    }
    case DELETE_FAILED: {
      return { ...state, error: action.error, loadingDelete: false };
    }
    case DELETE_PERIOD: {
      return { ...state, error: '', loadingDelete: true };
    }
    case DELETE_PERIOD_SUCCESS: {
      return { ...state, loadingDelete: false };
    }
    case DELETE_PERIOD_FAILED: {
      return { ...state, error: action.error, loadingDelete: false };
    }
    case GET_DATES: {
      return { ...state, error: '', loadingDates: true };
    }
    case GET_DATES_SUCCESS: {
      return { ...state, loadingDates: false, dates: action.dates };
    }
    case GET_DATES_FAILED: {
      return { ...state, error: action.error, loadingDates: false };
    }
    default:
      return state;
  }
}
