import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import { MenuItem } from 'react-bootstrap';
import polyglot from 'i18n';
import { LogoText as Logo, Dropdown, Icon, Button, Tooltip } from 'components';
import HelpContainer from 'containers/Help/HelpContainer';
import styles from './Masthead.css';

const MastHead = ({
  user: { roles, tilganger },
  user,
  switchHelp,
  helpOpen,
  helpPath,
  history,
  helpRef,
}) => {
  const genReportOption = (
    <MenuItem onSelect={() => history.push('/d/admin/generer')}>
      {polyglot.t('report.header')}
    </MenuItem>
  );
  const adminOrgsOption = (
    <MenuItem onSelect={() => history.push('/d/admin/virksomheter')}>
      {polyglot.t('organization.header')}
    </MenuItem>
  );
  const adminUserOption = (
    <MenuItem onSelect={() => history.push('/d/admin/brukere')}>
      {polyglot.t('users.header')}
    </MenuItem>
  );
  const adminKpiOption = (
    <MenuItem onSelect={() => history.push('/d/admin/kpi')}>
      {polyglot.t('kpi.header')}
    </MenuItem>
  );
  const adminPowerBIReports = (
    <MenuItem onSelect={() => history.push('/d/admin/powerbi-admin')}>
      {polyglot.t('powerbi.header')}
    </MenuItem>
  );
  const adminReportOption = (
    <MenuItem onSelect={() => history.push('/d/admin/rapport')}>
      {polyglot.t('adminRapporter.header')}
    </MenuItem>
  );
  const adminAuditlogtOption = (
    <MenuItem onSelect={() => history.push('/d/admin/auditlog')}>
      {polyglot.t('auditlog.header')}
    </MenuItem>
  );
  const adminEtasjerOption = (
    <MenuItem onSelect={() => history.push('/d/admin/etasje')}>
      {polyglot.t('etasje.header')}
    </MenuItem>
  );
  const adminFrontpageOption = (
    <MenuItem onSelect={() => history.push('/d/admin/framside')}>
      {polyglot.t('frontPage.admin.option')}
    </MenuItem>
  );
  const adminMeldingerOption = (
    <MenuItem onSelect={() => history.push('/d/admin/melding')}>
      {polyglot.t('melding.header')}
    </MenuItem>
  );
  const settings = Array.isArray(tilganger) && (
    <Dropdown
      mastHead
      pullRight
      className={classnames(styles.buttonWithEdge, styles.button)}
      label={polyglot.t('navbar.innstillinger')}
      title={
        <Tooltip title={polyglot.t('navbar.innstillinger')}>
          <Icon icon="settings" size="1" />
        </Tooltip>
      }
      id="profil"
    >
      {tilganger.indexOf('ADMINISTRER_RAPPORT') > -1 && genReportOption}
      {tilganger.indexOf('ADMINISTRER_ORGANISASJON') > -1 && adminOrgsOption}
      {tilganger.indexOf('ADMINISTRER_BRUKER') > -1 && adminUserOption}
      {tilganger.indexOf('ADMINISTRER_KPI') > -1 && adminKpiOption}
      {tilganger.indexOf('ADMINISTRER_RAPPORT') > -1 && adminReportOption}
      {tilganger.indexOf('ADMINISTRER_RAPPORT') > -1 && adminPowerBIReports}
      {tilganger.indexOf('VIS_AUDITLOG') > -1 && adminAuditlogtOption}
      {tilganger.indexOf('VIS_ETASJE') > -1 && adminEtasjerOption}
      {tilganger.indexOf('ADMINISTRER_RAPPORT') > -1 && adminFrontpageOption}
      {tilganger.indexOf('ADMINISTRER_MELDING') > -1 && adminMeldingerOption}
    </Dropdown>
  );
  const mailtoUrl = encodeURI(
    `mailto:support@statenslokaler.no
    ?cc=statenslokaler@statsbygg.no
    &subject=<skriv inn tittel på saken>
    &body=Virksomhet: ${user.organisasjon.navn} - ${
      user.organisasjon.organisasjonsnummer
    }
    Bruker: ${user.name}
    URL: ${window.location}

    Beskrivelse av saken:`,
  );

  const buttons = (
    <div>
      <div className={styles.alignRight}>
        <Button
          aria-label={polyglot.t('navbar.sok')}
          className={styles.buttonWithEdge}
          title={polyglot.t('navbar.sok')}
          to="/d/sok"
        >
          <Tooltip title={polyglot.t('navbar.sok')}>
            <Icon icon="search" size="1" />
          </Tooltip>
        </Button>
        {settings.props &&
          settings.props.children.filter(it => it !== false).length > 0 &&
          settings}
        <div className={styles.buttonDiv}>
          {tilganger.indexOf('VIS_MELDING') > -1 && (
            <Button
              aria-label={polyglot.t('navbar.meldinger')}
              className={styles.buttonWithEdge}
              title={polyglot.t('navbar.meldinger')}
              to="/d/varsling"
            >
              <Tooltip title={polyglot.t('navbar.meldinger')}>
                <Icon icon="bell" size="1" />
              </Tooltip>
            </Button>
          )}
        </div>
        <div className={styles.buttonDiv}>
          <Button
            aria-label={polyglot.t('navbar.support')}
            title={polyglot.t('navbar.support')}
            className={styles.buttonWithEdge}
            onClick={() => (location.href = mailtoUrl)}
          >
            <Tooltip title={polyglot.t('navbar.support')}>
              <Icon icon="write" size="1" />
            </Tooltip>
          </Button>
        </div>
      </div>

      <HelpContainer
        helpRef={helpRef}
        open={helpOpen}
        toggleHelp={switchHelp}
        helpPath={helpPath}
        canEdit={tilganger.indexOf('ADMINISTRER_HJELP') > -1}
      />
    </div>
  );

  return (
    <div>
      <header className={styles.masthead}>
        <div className={styles.logo}>
          <Logo className={styles.logo} dashboard to="/" />
        </div>
        {roles.indexOf('ROLE_PUBLIKUM') === -1 && buttons}
      </header>
    </div>
  );
};

MastHead.propTypes = {
  user: PropTypes.shape({
    tilganger: PropTypes.arrayOf(PropTypes.string),
    roles: PropTypes.array,
  }),
  switchHelp: PropTypes.func,
  helpOpen: PropTypes.bool,
  helpPath: PropTypes.string,
};

MastHead.defaultProps = {
  user: {
    roles: [],
    tilganger: [],
  },
};

export default withRouter(MastHead);
