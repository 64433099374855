import React from 'react';
import PropTypes from 'prop-types';
import { DataItem } from 'components';
import styles from './InfoBox.css';

const InfoBox = ({ data, extraData }) => {
  const firstDataItems = data
    ? data.label.map((_, i) => (
        <DataItem
          key={`${i}_${data.item[i]}`}
          vertical
          wrap
          data={data.item && data.item[i]}
          metaData={data.label[i]}
        />
      ))
    : '';

  const secondDataItems = extraData
    ? extraData.label.map((_, i) => (
        <DataItem
          key={`${i}_${extraData.item[i]}`}
          vertical
          wrap
          data={extraData.item && extraData.item[i]}
          metaData={extraData.label[i]}
        />
      ))
    : '';

  return (
    <div className={styles.container}>
      <div className={styles.firstDataContainer}>{firstDataItems}</div>
      {secondDataItems && (
        <div className={styles.firstDataContainer}>{secondDataItems}</div>
      )}
    </div>
  );
};

InfoBox.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.array,
    item: PropTypes.array,
  }),
  extraData: PropTypes.shape({
    label: PropTypes.array,
    item: PropTypes.array,
  }),
};

export default InfoBox;
