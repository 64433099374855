exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Toggle__wrapper___3qiqf {\n  -webkit-box-sizing: content-box;\n          box-sizing: content-box;\n  -webkit-box-sizing: initial;\n          box-sizing: initial;\n}\n\n.Toggle__SwitchRoot___BkLsc {\n  width: 56px;\n  height: 30px;\n  margin-bottom: 0;\n  display: inline-block;\n  position: relative;\n  overflow: hidden;\n  outline: none;\n  border: none;\n  cursor: pointer;\n  background-color: #939393;\n  -webkit-transition: background-color ease 0.3s;\n  transition: background-color ease 0.3s;\n  -webkit-box-sizing: inherit;\n          box-sizing: inherit;\n}\n\n.Toggle__SwitchRoot___BkLsc:focus {\n  border: 5px solid #147e87;\n  outline: 0;\n  -webkit-box-shadow: none;\n          box-shadow: none;\n  -webkit-transition: all 0.05s ease 0s;\n  transition: all 0.05s ease 0s;\n}\n\n.Toggle__SwitchRoot___BkLsc[data-state='checked'] {\n  background-color: #147e87;\n}\n\n.Toggle__SwitchThumb___2dQ1U {\n  display: block;\n  width: 28px;\n  height: 28px;\n  background: #fff;\n  -webkit-transition: -webkit-transform 100ms;\n  transition: -webkit-transform 100ms;\n  transition: transform 100ms;\n  transition: transform 100ms, -webkit-transform 100ms;\n  -webkit-transform: translateX(0px);\n          transform: translateX(0px);\n  will-change: transform;\n}\n\n.Toggle__SwitchThumb___2dQ1U[data-state='checked'] {\n  -webkit-transform: translateX(32px);\n          transform: translateX(32px);\n}\n\n.Toggle__vertical___YJQVy {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n}\n\n.Toggle__disabled___2wXpR {\n  cursor: not-allowed\n}\n\n.Toggle__disabled___2wXpR:focus {\n  border: none;\n}\n", ""]);

// exports
exports.locals = {
	"wrapper": "Toggle__wrapper___3qiqf",
	"SwitchRoot": "Toggle__SwitchRoot___BkLsc",
	"SwitchThumb": "Toggle__SwitchThumb___2dQ1U",
	"vertical": "Toggle__vertical___YJQVy",
	"disabled": "Toggle__disabled___2wXpR"
};