exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".InlineEdit__wrapper___2rU_r {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n}\n\n.InlineEdit__icon___3UBAc {\n  padding-left: 5px;\n  padding-bottom: 5px;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-item-align: end;\n      align-self: flex-end;\n  cursor: pointer;\n}\n", ""]);

// exports
exports.locals = {
	"wrapper": "InlineEdit__wrapper___2rU_r",
	"icon": "InlineEdit__icon___3UBAc"
};