import React from 'react';
import polyglot from 'i18n';
import { toGuiDate, localize } from 'utils/utils';

import { KpiTarget } from 'components';

const parseArealNumber = number =>
  localize(number, {
    minimumFractionDigits: 1,
  });

const checkArrExist = (data, prop, prop2 = 'not supplied') => {
  if (data && data.hasOwnProperty(prop)) {
    if (Array.isArray(data[prop]) && data[prop].length > 0) {
      if (prop2 !== 'not supplied') {
        if (data[prop][0].hasOwnProperty(prop2)) {
          if (Array.isArray(data[prop][0][prop2]))
            return data[prop][0][prop2].length > 0;
          return true;
        }
        return false;
      }
      return true;
    }
    return false;
  }
  return false;
};

export const apiFields = {
  property: {
    searchTable: [
      { searchable: true, field: 'navn', sort: 'navn.sort' },
      { searchable: true, field: 'kommune.navn', sort: 'kommune.navn.sort' },
      {
        searchable: true,
        field: 'kommunenr',
        sort: 'kommunenr.sort',
        rightAlign: true,
      },
      { searchable: true, field: 'gardsnr', sort: 'gardsnr', rightAlign: true },
      { searchable: true, field: 'bruksnr', sort: 'bruksnr', rightAlign: true },
      {
        searchable: true,
        field: 'seksjonsnr',
        sort: 'seksjonsnr',
        rightAlign: true,
      },
      {
        searchable: true,
        field: 'adresse.adressenavn',
        sort: 'adresse.adressenavn.sort',
      },
      { searchable: true, field: 'eiendom.navn', sort: 'eiendom.navn.sort' },
      {
        searchable: true,
        field: 'eierforhold.organisasjon.navn',
        sort: 'eierforhold.organisasjon.navn.sort',
      },
      {
        searchable: true,
        field: 'eierforhold.eierforholdKode',
        sort: 'eierforhold.eierforholdKode',
      },
    ],
    baseDetails: [
      'untatt',
      'navn',
      'bruksnavn',
      'matrikkelident',
      'adresse.adressenavn',
      'postnr',
      'kommune.navn',
      'kommunenr',
      'gardsnr',
      'bruksnr',
      'festenr',
      'seksjonsnr',
      'areal',
      'eierforhold.organisasjon.navn',
      'eierforhold.eierforholdKode',
      'eierforhold.organisasjonsnummer',
      'grunneiendomstype',
      'eierforhold.fraDato',
    ],
    smallTable: [
      { searchable: true, field: 'navn', sort: 'navn.sort' },
      { searchable: true, field: 'kommunenr', sort: 'kommunenr.sort' },
      { searchable: true, field: 'gardsnr', sort: 'gardsnr' },
      { searchable: true, field: 'bruksnr', sort: 'bruksnr' },
      { searchable: true, field: 'seksjonsnr', sort: 'seksjonsnr' },
      { searchable: true, field: 'festenr', sort: 'festenr' },
      { searchable: true, field: 'areal', sort: 'areal' },
      { searchable: true, field: 'eier.fraDato', sort: 'eier.fraDato' },
    ],
    realestateList: [
      'navn',
      'matrikkelident',
      'adresse.adressenavn',
      'eierforholdKode',
    ],
  },
  building: {
    searchTable: [
      { searchable: true, field: 'navn', sort: 'navn.sort' },
      {
        searchable: true,
        field: 'grunneiendom.kommune.navn',
        sort: 'grunneiendom.kommune.navn.sort',
      },
      {
        searchable: true,
        field: 'grunneiendom.kommunenr',
        sort: 'grunneiendom.kommunenr.sort',
        rightAlign: true,
      },
      {
        searchable: true,
        field: 'grunneiendom.adresse.adressenavn',
        sort: 'grunneiendom.adresse.adressenavn.sort',
      },
      {
        searchable: true,
        field: 'grunneiendom.navn',
        sort: 'grunneiendom.navn.sort',
      },
      {
        searchable: true,
        field: 'grunneiendom.eiendomSort',
        sort: 'grunneiendom.eiendom.navn.sort',
      },
      {
        searchable: true,
        field: 'grunneiendom.eierforhold.navn',
        sort: 'grunneiendom.eiendomSort.sort',
      },
    ],
    baseDetails: [
      'identifikator',
      'bygningsnr',
      'grunneiendom.adresse.adressenavn',
      'postnr',
      'bygningtype',
      'bygningstatus',
      'etasjer',
      'bruksareal',
      'bruttoareal',
      'fotavtrykk',
      'grunneiendom.eierforhold.eierforholdKode',
      'tattIBruk',
      'grunneiendom.eierforhold.navn',
      'grunneiendom.eierforhold.nr',
      'grunneiendom.eiendomSort',
      'kulturminne',
    ],
    hiddenDetails: ['egenBra', 'egenBta', 'fotavtrykkAreal'],
    smallTable: [
      { searchable: true, field: 'bygningsnr', sort: 'bygningsnr' },
      { searchable: true, field: 'navn', sort: 'navn.sort' },
      { searchable: true, field: 'tattIBruk', sort: 'tattIBruk' },
      { searchable: true, field: 'bygningtype', sort: 'bygningtype' },
    ],
    floorTable: [
      { searchable: true, field: 'etasjenr', sort: 'etasjenr' },
      { searchable: true, field: 'etasjeplan', sort: 'etasjeplan' },
      { searchable: true, field: 'bruksareal', sort: 'bruksareal' },
      { searchable: true, field: 'bruttoareal', sort: 'bruttoareal' },
      { searchable: true, field: 'egenBra', sort: 'egenBra' },
      { searchable: true, field: 'egenBta', sort: 'egenBta' },
    ],
    realestateList: ['navn', 'bygningsnr', 'bygningtype', 'bygningtype'],
  },
  realestate: {
    searchTable: [
      { searchable: true, field: 'navn', sort: 'navn.sort' },
      {
        searchable: true,
        field: 'grunneiendom.kommune.navn',
        sort: 'grunneiendom.kommune.navn.sort',
      },
      {
        searchable: true,
        field: 'grunneiendom.kommunenr',
        sort: 'grunneiendom.kommunenr.sort',
        rightAlign: true,
      },
      {
        searchable: true,
        field: 'antallGrunneiendommer',
        sort: 'antallGrunneiendommer',
        rightAlign: true,
      },
      {
        searchable: true,
        field: 'antallBygg',
        sort: 'antallBygg',
        rightAlign: true,
      },
      {
        searchable: true,
        field: 'grunneiendom.eierforhold.navn',
        sort: 'grunneiendom.eierforhold.navn.sort',
      },
    ],
    details: [
      'grunneiendom.eierforhold.navn',
      'kommuneEiendom',
      'antallGrunneiendommer',
    ],
  },
  organization: {
    searchTable: [
      { searchable: true, field: 'navn', sort: 'navn.sort' },
      {
        searchable: true,
        field: 'organisasjonsnummer',
        sort: 'organisasjonsnummer',
        rightAlign: true,
      },
      {
        searchable: true,
        field: 'organisasjonstype',
        sort: 'organisasjonstype',
      },
      {
        searchable: true,
        field: 'forretningsadresse.kommune.navn',
        sort: 'forretningsadresse.kommune.navn.sort',
      },
      {
        searchable: true,
        field: 'antallGrunneiendommer',
        sort: 'antallGrunneiendommer',
        rightAlign: true,
      },
      { searchable: false, field: 'statlig', sort: 'statlig' },
      { searchable: false, field: 'aktiv', sort: 'aktiv' },
    ],
  },
  prosjekt: {
    searchTable: [
      { searchable: true, field: 'navn', sort: 'navn.sort' },
      {
        searchable: true,
        field: 'prosjektnummer',
        sort: 'prosjektnummer.sort',
      },
      { searchable: false, field: 'aktiv', sort: 'aktiv' },
    ],
  },
  user: {
    searchTable: [
      { searchable: true, field: 'firstName', sort: 'firstName.sort' },
      { searchable: true, field: 'middleName', sort: 'middleName.sort' },
      { searchable: true, field: 'lastName', sort: 'lastName' },
      { searchable: true, field: 'roles', sort: 'roles' },
      {
        searchable: true,
        field: 'organisasjon.navn',
        sort: 'organisasjon.navn.sort',
      },
      {
        searchable: true,
        field: 'email',
        sort: 'email.sort',
      },
      { searchable: true, field: 'sistInnlogget', sort: 'sistInnlogget' },
      {
        searchable: true,
        field: 'antallInnlogginger',
        sort: 'antallInnlogginger',
        rightAlign: true,
      },
      { searchable: false, field: 'aktiv', sort: 'aktiv' },
    ],
  },
  matrikkelAlert: {
    searchTable: [
      { searchable: true, field: 'lest', sort: 'lest' },
      {
        searchable: true,
        field: 'organisasjon.navn',
        sort: 'organisasjon.navn.sort',
      },
      { searchable: true, field: 'endringsdato', sort: 'endringsdato' },
      { searchable: true, field: 'matrikkelnavn', sort: 'matrikkelnavn.sort' },
      { searchable: true, field: 'beskrivelse', sort: 'beskrivelse' },
      { searchable: true, field: 'varslingtype', sort: 'varslingtype' },
      { searchable: true, field: 'lagringsdato', sort: 'lagringsdato' },
    ],
  },
  leideLokaler: {
    searchTable: [
      { searchable: true, field: 'navn', sort: 'navn.sort' },
      { searchable: true, field: 'bruker.navn', sort: 'bruker.navn.sort' },
      { searchable: false, field: 'kontor', sort: 'kontor' },
      {
        searchable: true,
        field: 'grunneiendom.adresse.adressenavn',
        sort: 'grunneiendom.adresse.adressenavn.sort',
      },
      {
        searchable: true,
        field: 'grunneiendom.kommune.navn',
        sort: 'grunneiendom.kommune.navn.sort',
      },
      {
        searchable: true,
        field: 'totalareal',
        sort: 'totalareal',
        rightAlign: true,
        sensitive: true,
      },
      {
        searchable: true,
        field: 'rapporteringStatusNy',
        sort: 'rapporteringStatusNy',
        sensitive: true,
      },
    ],
  },
  ledigeLokaler: {
    searchTable: [
      { searchable: true, field: 'navn', sort: 'navn.sort' },
      {
        searchable: true,
        field: 'grunneiendom.adresse.adressenavn',
        sort: 'grunneiendom.adresse.adressenavn.sort',
      },
      {
        searchable: true,
        field: 'grunneiendom.kommune.navn',
        sort: 'grunneiendom.kommune.navn.sort',
      },
      { searchable: true, field: 'bruker.navn', sort: 'bruker.navn.sort' },
      {
        searchable: true,
        field: 'ledigeLokalerAreal',
        sort: 'ledigeLokalerAreal',
        rightAlign: true,
      },
      {
        searchable: false,
        field: 'ledigeLokalerFraDato',
        sort: 'ledigeLokalerFraDato',
      },
      {
        searchable: false,
        field: 'ledigeLokalerTilDato',
        sort: 'ledigeLokalerTilDato',
      },
      {
        searchable: false,
        field: 'ledigeLokalerLedig',
        sort: 'ledigeLokalerLedig',
      },
    ],
  },
  rapporter: {
    searchTable: [
      { searchable: true, field: 'navn', sort: 'navn.sort' },
      { searchable: true, field: 'synlig', sort: 'synlig.sort' },
      { searchable: true, field: 'rolle', sort: 'rolle' },
      { searchable: true, field: 'publiseringsdato', sort: 'publiseringsdato' },
    ],
  },
  adminRapporter: {
    searchTable: [
      { searchable: true, field: 'navn', sort: 'navn.sort' },
      { searchable: true, field: 'synlig', sort: 'synlig.sort' },
      { searchable: true, field: 'rolle', sort: 'rolle' },
      { searchable: true, field: 'publiseringsdato', sort: 'publiseringsdato' },
      { searchable: false, field: 'publisert', sort: 'publisert' },
    ],
  },
  globalSearch: {
    searchTable: [
      {
        searchable: true,
        lokaleField: 'navn',
        propertyField: 'navn',
        sort: 'navn.sort',
      },
      {
        searchable: true,
        lokaleField: 'grunneiendom.adresse.adressenavn',
        propertyField: 'adresse.adressenavn',
        sort: 'adresse.adressenavn.sort',
      },
      {
        searchable: true,
        lokaleField: 'grunneiendom.kommune.navn',
        propertyField: 'kommune.navn',
        sort: 'kommune.navn.sort',
      },
      {
        searchable: true,
        lokaleField: 'bruker.navn',
        propertyField: 'eierforhold.organisasjon.navn',
        sort: 'bruker.navn.sort',
      },
      {
        searchable: true,
        lokaleField: 'totalareal',
        propertyField: 'areal',
        sort: 'totalareal',
        rightAlign: true,
        sensitive: true,
      },
      {
        searchable: false,
        lokaleField: 'kontor',
        propertyField: '',
        sort: 'kontor',
      },
    ],
  },
  auditlog: {
    searchTable: [
      { searchable: true, field: 'name', sort: 'name.sort' },
      { searchable: true, field: 'operasjon', sort: 'operasjon' },
      { searchable: true, field: 'objekttype', sort: 'objekttype' },
      { searchable: true, field: 'objektlink', sort: 'objektlink' },
      { searchable: true, field: 'objektnavn', sort: 'objektnavn.sort' },
      {
        searchable: true,
        field: 'endringstidspunkt',
        sort: 'endringstidspunkt',
      },
    ],
  },
  etasje: {
    searchTable: [
      {
        searchable: true,
        field: 'bygg.grunneiendom.eierforhold.organisasjon.navn',
        sort: 'bygg.grunneiendom.eierforhold.organisasjon.navn.sort',
      },
      {
        searchable: true,
        field: 'bygg.navn',
        sort: 'bygg.navn.sort',
      },
      {
        searchable: true,
        field: 'bygg.bygningsnr',
        sort: 'bygg.bygningsnr',
      },
      {
        searchable: true,
        field: 'etasjeplan',
        sort: 'etasjeplan.sort',
      },
      {
        searchable: true,
        field: 'etasjenr',
        sort: 'etasjenr',
      },
      {
        searchable: true,
        field: 'bruksareal',
        sort: 'bruksareal',
      },
      {
        searchable: true,
        field: 'bruttoareal',
        sort: 'bruttoareal',
      },
      {
        searchable: true,
        field: 'egenBta',
        sort: 'egenBta',
      },
      {
        searchable: true,
        field: 'egenBra',
        sort: 'egenBra',
      },
      {
        searchable: true,
        field: 'sistEndret',
        sort: 'sistEndret',
      },
    ],
  },
  selection: {
    searchTable: [
      { field: 'navn' },
      { field: 'utvalgtype' },
      { field: 'sistOppdatert' },
    ],
  },
  melding: {
    searchTable: [
      { field: 'emne', sort: 'emne.sort' },
      {
        field: 'mottakere',
        sort: 'mottakere.name.sort',
      },
      { field: 'roles', sort: 'roles' },
      {
        searchable: true,
        field: 'organisasjon.navn',
        sort: 'organisasjon.navn.sort',
      },
      {
        field: 'synligForUnderenheter',
        sort: 'synligForUnderenheter',
      },
    ],
  },
};

export const getHeaders = (fields, dataType) =>
  Array.isArray(fields)
    ? fields.map(field => polyglot.t(`${dataType}.${field}`))
    : [];

const mapData = (data, fields) =>
  data && Array.isArray(fields)
    ? fields.map(f => {
        switch (f) {
          case 'tattIBruk':
            return data[f] ? toGuiDate(data[f]) : '';
          case 'etasjer':
            return data.etasjer ? data.etasjer.length : '';
          case 'eierforhold.organisasjon.navn':
            return data.eierforhold && data.eierforhold.organisasjon
              ? data.eierforhold.organisasjon.navn
              : '';
          case 'eierforhold.eierforholdKode':
            return data.eierforhold
              ? polyglot.t(
                  `eierforholdkoder.${data.eierforhold.eierforholdKode}`,
                )
              : '';
          case 'eierforhold.organisasjonsnummer':
            return data.eierforhold && data.eierforhold.organisasjon
              ? data.eierforhold.organisasjon.organisasjonsnummer
              : '';
          case 'eierforhold.fraDato':
            return data.eierforhold ? toGuiDate(data.eierforhold.fraDato) : '';
          case 'kommune.navn':
            return data.kommune ? data.kommune.navn : '';
          case 'eiendom.navn':
            return data.eiendom ? data.eiendom.navn : '';
          case 'adresse.adressenavn':
            return data.adresse ? data.adresse.adressenavn : '';
          case 'aktiv':
            return data[f]; // We need boolean here, not ''
          case 'lest':
            return data[f]; // We need boolean here, not ''
          case 'statlig':
            return data[f] ? 'Ja' : 'Nei';
          case 'eierforholdKode':
            return data.eierforhold
              ? polyglot.t(
                  `eierforholdkoder.${data.eierforhold.eierforholdKode}`,
                )
              : '';
          case 'areal':
            return parseArealNumber(data[f]);
          case 'bruksareal':
            return parseArealNumber(data[f]);
          case 'bruttoareal':
            return parseArealNumber(data[f]);
          case 'egenBra':
            return parseArealNumber(data[f]);
          case 'egenBta':
            return parseArealNumber(data[f]);
          case 'fotavtrykk':
            return parseArealNumber(data[f]);
          case 'fotavtrykkAreal':
            return parseArealNumber(data[f]);
          case 'eier.fraDato':
            return checkArrExist(data, 'eier')
              ? toGuiDate(data.eier[0].fraDato)
              : '';

          // mapping for bygninger
          case 'grunneiendom.kommunenr':
            return data.grunneiendom ? data.grunneiendom.kommunenr : '';
          case 'grunneiendom.navn':
            return data.grunneiendom ? data.grunneiendom.navn : '';
          case 'grunneiendom.kommune.navn':
            return data.grunneiendom && data.grunneiendom.kommune
              ? data.grunneiendom.kommune.navn
              : '';
          case 'grunneiendom.adresse.adressenavn':
            return data.grunneiendom && data.grunneiendom.adresse
              ? data.grunneiendom.adresse.adressenavn
              : '';
          case 'grunneiendom.eierforhold.navn':
            return data.grunneiendom &&
              data.grunneiendom.eierforhold &&
              data.grunneiendom.eierforhold.organisasjon
              ? data.grunneiendom.eierforhold.organisasjon.navn
              : '';
          case 'grunneiendom.eierforhold.eierforholdKode':
            return data.grunneiendom &&
              data.grunneiendom.eierforhold &&
              data.grunneiendom.eierforhold.organisasjon
              ? polyglot.t(
                  `eierforholdkoder.${
                    data.grunneiendom.eierforhold.eierforholdKode
                  }`,
                )
              : '';
          case 'grunneiendom.eierforhold.nr':
            return data.grunneiendom &&
              data.grunneiendom.eierforhold &&
              data.grunneiendom.eierforhold.organisasjon
              ? data.grunneiendom.eierforhold.organisasjon.organisasjonsnummer
              : '';
          case 'grunneiendom.eiendomSort':
            return data.grunneiendom ? data.grunneiendom.eiendomSort : '';
          case 'grunneiendom.eiendom.navn':
            return data.grunneiendom && data.grunneiendom.eiendom
              ? data.grunneiendom.eiendom.navn
              : '';
          case 'identifikator':
            return data.grunneiendom
              ? `${data.grunneiendom.matrikkelident} ${data.bygningsnr}`
              : '';
          case 'kulturminne':
            return data.kulturminne
              ? polyglot.t('mapData.true')
              : polyglot.t('mapData.false');
          case 'untatt':
            return data.untatt && data.editable
              ? polyglot.t('mapData.true')
              : undefined;

          // mapping from eiendom
          case 'kommuneEiendom':
            return checkArrExist(data, 'grunneiendommer', 'kommune')
              ? data.grunneiendommer[0].kommune.navn
              : '';
          case 'kommunenrEiendom':
            return checkArrExist(data, 'grunneiendommer')
              ? data.grunneiendommer[0].kommunenr
              : '';
          case 'adresseEiendom':
            return checkArrExist(data, 'grunneiendommer', 'adresse')
              ? data.grunneiendommer[0].adresse.poststed
              : '';
          case 'grunneiendommer.eierforhold.organisasjon.navn':
            return checkArrExist(
              data,
              'grunneiendommer',
              'eierforhold',
              'organisasjon',
            )
              ? data.grunneiendommer[0].eierforhold.organisasjon
                ? data.grunneiendommer[0].eierforhold.organisasjon.navn
                : ''
              : '';

          // Mapping for organizations
          case 'harGrunneiendommer':
            return data[f]
              ? polyglot.t('mapData.true')
              : polyglot.t('mapData.false');
          case 'forretningsadresse.kommune.navn':
            return data.forretningsadresse && data.forretningsadresse.kommune
              ? data.forretningsadresse.kommune.navn
              : data.postadresse && data.postadresse.kommune
                ? data.postadresse.kommune.navn
                : '';

          // Mapping for users
          case 'roles':
            return checkArrExist(data, 'roles')
              ? polyglot.t(`Rolle.${data.roles[0]}`)
              : '';
          case 'organisasjon.navn':
            return data.organisasjon && data.organisasjon.navn
              ? data.organisasjon.navn
              : '';
          case 'sistInnlogget':
            return data.sistInnlogget
              ? toGuiDate(data.sistInnlogget, true)
              : '';

          // Mapping for varsel
          case 'endringsdato':
            return data.endringsdato ? toGuiDate(data.endringsdato, true) : '';
          case 'lagringsdato':
            return data.lagringsdato ? toGuiDate(data.lagringsdato, true) : '';

          // Mapping for leide lokaler
          case 'bruker.navn':
            return data.bruker ? data.bruker.navn : '';
          case 'fraDato':
            return data.fraDato ? toGuiDate(data.fraDato) : '';
          case 'tilDato':
            return data.tilDato ? toGuiDate(data.tilDato) : '';
          case 'kontor':
            return data.kontor
              ? polyglot.t('mapData.true')
              : polyglot.t('mapData.false');

          case 'totalareal':
            return localize(data.totalareal);

          // mapping for reports
          case 'publisert':
            return data.publisert
              ? polyglot.t('mapData.published')
              : polyglot.t('mapData.draft');
          case 'synlig':
            return data.synlig && data.synlig.some(it => it === 'alle')
              ? 'Alle'
              : data.virksomhet && data.virksomhet.navn;
          case 'publiseringsdato':
            return data.publiseringsdato
              ? toGuiDate(data.publiseringsdato)
              : '';
          case 'rolle':
            return checkArrExist(data, 'rolle')
              ? data.rolle.map(it => polyglot.t(`Rolle.${it}`)).join(', ')
              : data.rolle;

          // mapping for auditlog
          case 'endringstidspunkt':
            return data.endringstidspunkt
              ? toGuiDate(data.endringstidspunkt, true)
              : '';

          // mapping for meldinger
          case 'mottakere':
            return data.mottakere
              ? data.mottakere.map(m => m.name).join(', ')
              : '';

          // mapping for floors
          case 'antallBoenheter':
            return data.antallBoenheter.toString();
          case 'bygg.grunneiendom.eierforhold.organisasjon.navn':
            return (
              data.bygg &&
              data.bygg.grunneiendom &&
              data.bygg.grunneiendom.eierforhold &&
              data.bygg.grunneiendom.eierforhold.organisasjon &&
              data.bygg.grunneiendom.eierforhold.organisasjon.navn
            );
          case 'bygg.bygningsnr':
            return data.bygg && data.bygg.bygningsnr;
          case 'bygg.navm':
            return data.bygg && data.bygg.navn;
          case 'sistEndret':
            return data.sistEndret ? toGuiDate(data.sistEndret) : '';

          // mapping for selection
          case 'sistOppdatert':
            return toGuiDate(data[f], true);
          case 'rapporteringStatusNy':
            return <KpiTarget status color={data[f]} list />;
          case 'ledigeLokalerLedig':
            return data.ledigeLokalerAreal > 0 ? (
              <KpiTarget ledigStatus color="green" list />
            ) : (
              <KpiTarget ledigStatus color="red" list />
            );
          case 'harLedigeLokaler':
            return data.harLedigeLokaler
              ? polyglot.t('mapData.true')
              : polyglot.t('mapData.false');
          default:
            return data[f] || '';
        }
      })
    : [];

export default mapData;
