// @flow

import React from 'react';
import { Field, Button, Bubbles, Link } from 'components';
import { validateNumber } from 'utils/utils';

import styles from './PropertyInput.css';

type Props = {
  kommune: string,
  gardsnr: string,
  bruksnr: string,
  festenr: string,
  seksjonsnr: string,
  onChange(): void,
  buttonClick(): void,
  loadingMatrikkel: boolean,
  loadingSaveContract: boolean,
  matrikkelResult: Array<{
    navn: string,
    kommunenr: number,
    gardsnr: number,
    bruksnr: number,
  }>,
  removeHit(number): void,
  firstRef: string,
  lastRef: string,
};

const PropertyInput = ({
  kommune,
  gardsnr,
  bruksnr,
  festenr,
  seksjonsnr,
  onChange,
  buttonClick,
  loadingMatrikkel,
  loadingSaveContract,
  firstRef,
  lastRef,
}: Props) => {
  const disabled =
    validateNumber(kommune) === 'error' ||
    validateNumber(gardsnr) === 'error' ||
    validateNumber(bruksnr) === 'error';
  return (
    <div className={styles.wrapper}>
      <div className={styles.propertyLine}>
        <Field
          className={styles.fieldOne}
          name="kommune"
          value={kommune}
          onChange={onChange}
          validationState={validateNumber(kommune)}
          feedback="Må være et tall"
          label="Kommunenr"
          maxLength="5"
          placeholder="0"
          refFunc={firstRef}
        />
        <Field
          className={styles.fieldOne}
          name="gardsnr"
          value={gardsnr}
          validationState={validateNumber(gardsnr)}
          feedback="Må være et tall"
          onChange={onChange}
          label="Gårdsnummer"
          maxLength="5"
          placeholder="0"
        />
        <Field
          className={styles.fieldOne}
          name="bruksnr"
          maxLength="5"
          validationState={validateNumber(bruksnr)}
          feedback="Må være et tall"
          value={bruksnr}
          onChange={onChange}
          label="Bruksnummer"
          placeholder="0"
        />
        <Field
          className={styles.fieldOne}
          name="festenr"
          validationState={validateNumber(festenr)}
          feedback="Må være et tall"
          maxLength="5"
          value={festenr}
          onChange={onChange}
          label="Festenummer"
          placeholder="0"
        />
        <Field
          className={styles.fieldOne}
          name="seksjonsnr"
          maxLength="5"
          validationState={validateNumber(seksjonsnr)}
          feedback="Må være et tall"
          value={seksjonsnr}
          onChange={onChange}
          label="Seksjonsnummer"
          placeholder="0"
          refFunc={disabled ? lastRef : undefined}
        />
        <Button
          id="hentMatrikkelen"
          kind="primary"
          onClick={buttonClick}
          disabled={disabled}
          refFunc={!disabled ? lastRef : undefined}
        >
          {loadingMatrikkel || loadingSaveContract ? <Bubbles /> : 'Hent'}
        </Button>
      </div>
    </div>
  );
};

export default PropertyInput;
