exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ExpandableList__wrapper___k4UTU {\n  width: 100%;\n}\n\n.ExpandableList__listItem___G5-yF {\n  border: 1px solid #e3e3e3;\n}\n\n.ExpandableList__listHeader___1VBoK {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  width: 100%;\n  min-height: 85px;\n  background-color: #fff;\n  border: none;\n}\n\n.ExpandableList__collapsePanel___2kB26 {\n  max-height: 0;\n  -webkit-transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);\n  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);\n  overflow: hidden;\n  display: none;\n}\n\n.ExpandableList__collapsePanel___2kB26.ExpandableList__openItem___38vNJ {\n  max-height: 2000px;\n  display: contents;\n  -webkit-transition: max-height 0.6s ease-in-out;\n  transition: max-height 0.6s ease-in-out\n}\n\n.ExpandableList__collapsePanel___2kB26.ExpandableList__openItem___38vNJ.ExpandableList__overflow___2YeKd {\n  overflow: scroll;\n}\n\n.ExpandableList__padding___3Bp6_ {\n  padding: 25px;\n}\n\n.ExpandableList__expired___vuZoh {\n  position: absolute;\n  right: 0;\n  margin-top: 0;\n  margin-right: 10px;\n  background-color: #fff;\n  color: #000;\n}\n", ""]);

// exports
exports.locals = {
	"wrapper": "ExpandableList__wrapper___k4UTU",
	"listItem": "ExpandableList__listItem___G5-yF",
	"listHeader": "ExpandableList__listHeader___1VBoK",
	"collapsePanel": "ExpandableList__collapsePanel___2kB26",
	"openItem": "ExpandableList__openItem___38vNJ",
	"overflow": "ExpandableList__overflow___2YeKd",
	"padding": "ExpandableList__padding___3Bp6_",
	"expired": "ExpandableList__expired___vuZoh"
};