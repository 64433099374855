exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* VIEWPORTS */\n\n:root {\n}\n\n.Lightbox__modalWrapper___2PkZE {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  z-index: 9998;\n  background: rgba(0, 0, 0, .3);\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  padding: 40px;\n}\n\n@media (max-width: 800px) {\n\n  .Lightbox__modalWrapper___2PkZE {\n    padding: 0;\n  }\n}\n\n.Lightbox__modalWrapper___2PkZE > * {\n  background-color: #fff;\n  overflow-y: scroll;\n  -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, .2);\n          box-shadow: 0 0 30px rgba(0, 0, 0, .2);\n}\n\n.Lightbox__fullScreen___12A_h {\n  padding: 20px;\n}\n", ""]);

// exports
exports.locals = {
	"modalWrapper": "Lightbox__modalWrapper___2PkZE",
	"fullScreen": "Lightbox__fullScreen___12A_h"
};