// @flow

import React, { PureComponent } from 'react';
import {
  Button,
  Field,
  Icon,
  FilterBox,
  Toggle,
  HelpToolTipButton,
} from 'components';
import type { TableTypes } from 'types';
import polyglot from 'i18n';
import { Collapse } from 'react-bootstrap';
import styles from './SearchBox.css';

type Props = {
  value: string,
  onTextChange(string): void,
  searchPlaceholder: string,
  type: TableTypes,
  handleFilterChange({
    field: string,
    value: string,
  }): void,
  isGlobal: boolean,
  searchButton: boolean,
  table: {
    filters: {
      visMine: {
        value: boolean,
      },
    },
  },
  onSubmit(): void,
  fylkeFilter: number,
  organisasjonFilter: number,
  kommuneFilter: number,
  departementFilter: number,
  tempRole: number,
  aktivFilter: number,
  rapporteringsStatusFilter: number,
};

class SearchBox extends PureComponent<Props, { open: boolean }> {
  constructor(props: Props) {
    super(props);
    const {
      fylkeFilter,
      organisasjonFilter,
      kommuneFilter,
      departementFilter,
      tempRole,
      rapporteringsStatusFilter,
      type,
    } = this.props;
    this.state = {
      open:
        !!fylkeFilter ||
        !!organisasjonFilter ||
        !!kommuneFilter ||
        !!departementFilter ||
        !!tempRole ||
        !!rapporteringsStatusFilter ||
        type === 'globalSearch',
    };
  }

  render() {
    const { open } = this.state;
    const {
      onTextChange,
      searchPlaceholder,
      type,
      isGlobal,
      searchButton,
      onSubmit,
      table: { query, filters },
      handleFilterChange,
      handleMultiFilterChange,
      removeFilters,
      isSysAdm,
      loading,
      hits,
      ...props
    } = this.props;
    const helpToolTipButton = text => <HelpToolTipButton title={text} />;
    return (
      <form id="lokaleForm" role="search">
        <div className={styles.hiddenSpan} aria-live="polite">
          {!loading && <span>{hits} treff</span>}
        </div>
        <div className={styles.wrapper}>
          <Field
            maxLength="255"
            name="search"
            placeholder={searchPlaceholder}
            lg
            value={query}
            onChange={onTextChange}
            onKeyPress={e => e.key === 'Enter' && onSubmit()}
            className={styles.inputField}
          />
          {!removeFilters && (
            <Button
              secondaryButton
              className={styles.filterButton}
              onClick={() => this.setState({ open: !open })}
              aria-controls="filtervalg"
              aria-expanded={open ? 'true' : 'false'}
              aria-label={polyglot.t('helpToolTipButton.lokale.filterValg')}
            >
              {'Filter'}
              <Icon icon={open ? 'angle-up' : 'angle-down'} spaced size="1" />
            </Button>
          )}

          {searchButton && (
            <Button
              kind="primary"
              onClick={onSubmit}
              aria-label={polyglot.t('helpToolTipButton.lokale.sokeknapp')}
              className={styles.searchButton}
            >
              <Icon icon="search" size="1" />
            </Button>
          )}

          {type === 'leideLokaler' &&
            type !== 'globalSearch' && (
              <div className={styles.toggleWrapperLeft}>
                <Toggle
                  helpToolTipButton={helpToolTipButton(
                    polyglot.t('helpToolTipButton.lokale.visKunUtgaatte'),
                  )}
                  label={polyglot.t('helpToolTipButton.lokale.visUtgaatte')}
                  id="visUtgatteLokaler"
                  onChange={() => {
                    handleFilterChange({
                      field: 'visUtgaatte',
                      value: !filters.visUtgaatte.value,
                    });
                  }}
                  value={filters.visUtgaatte.value}
                />
              </div>
            )}

          {type === 'leideLokaler' &&
            type !== 'globalSearch' && (
              <div className={styles.toggleWrapper}>
                <Toggle
                  helpToolTipButton={helpToolTipButton(
                    polyglot.t('helpToolTipButton.lokale.visKunLedige'),
                  )}
                  label={polyglot.t('helpToolTipButton.lokale.visLedige')}
                  id="harLedigeLokaler"
                  onChange={() => {
                    if (filters.visLedige.value) {
                      handleFilterChange({
                        field: 'visLedige',
                        value: false,
                      });
                    } else {
                      handleMultiFilterChange({
                        visLedige: {
                          value: true,
                        },
                        visMine: {
                          value: false,
                        },
                      });
                    }
                  }}
                  value={filters.visLedige.value}
                />
              </div>
            )}
          {!isGlobal &&
            type !== 'rapporter' &&
            type !== 'globalSearch' && (
              <div>
                <Toggle
                  helpToolTipButton={helpToolTipButton(
                    polyglot.t('helpToolTipButton.lokale.visMine'),
                  )}
                  label={`Vis bare mine ${polyglot.t(`${type}.shortTitle`)}?`}
                  id="visMine"
                  onChange={() =>
                    handleFilterChange({
                      field: 'visMine',
                      value: !filters.visMine.value,
                    })
                  }
                  value={filters.visMine.value}
                />
              </div>
            )}
          {isSysAdm &&
            type === 'leideLokaler' && (
              <div>
                <Toggle
                  helpToolTipButton={helpToolTipButton(
                    polyglot.t('helpToolTipButton.lokale.visKunAktive'),
                  )}
                  label={polyglot.t('helpToolTipButton.lokale.visAktive')}
                  id="visAktiveLokaler"
                  onChange={() =>
                    handleFilterChange({
                      field: 'visAktive',
                      value: !filters.visAktive.value,
                    })
                  }
                  value={filters.visAktive.value}
                  defaultChecked
                />
              </div>
            )}
        </div>
        <Collapse
          id="filtervalg"
          role="region"
          aria-label="Filter valg"
          in={open}
        >
          <div>
            <FilterBox
              {...{
                isGlobal,
                onSubmit,
                handleFilterChange,
                handleMultiFilterChange,
                type,
                filters,
                isSysAdm,
                open,
                ...props,
              }}
              visFylkeFilter={
                type !== 'rapporter' &&
                type !== 'adminRapporter' &&
                type !== 'user' &&
                type !== 'organization'
              }
              auditLog={type === 'auditlog'}
            />
          </div>
        </Collapse>
      </form>
    );
  }
}

export default SearchBox;
