import React from 'react';
import PropTypes from 'prop-types';
import { Field, ModalButton, GenericModal, Heading } from 'components';
import polyglot from 'i18n';
import SelectField from '../SelectField/SelectField';

class SaveSelection extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      saveSelectionModal: false,
      selectionName: '',
      selectionType: '',
    };
  }

  toggleModal = () =>
    this.setState(prevState => ({
      saveSelectionModal: !prevState.saveSelectionModal,
    }));

  handleChange = e => {
    if (e && e.target) {
      this.setState({
        selectionName: e.target.value,
      });
    } else this.setState({ selectionType: e });
  };

  handleSubmit = () => {
    const { selectionName, selectionType } = this.state;
    const { saveSelection, currentSelection } = this.props;
    saveSelection(
      selectionName || currentSelection.navn,
      selectionType.value || currentSelection.utvalgtype,
    );
    this.toggleModal();
  };

  render() {
    const { selectionName, saveSelectionModal, selectionType } = this.state;
    const { currentSelection } = this.props;
    const updateMode = currentSelection.navn;
    return (
      <ModalButton
        showModal={saveSelectionModal}
        aria-label="Lagreutvalg"
        toggle={this.toggleModal}
        buttonTitle={polyglot.t(
          `selection.${updateMode ? 'updateSelection' : 'saveSelection'}`,
        )}
      >
        <GenericModal
          renderInputs={({ firstRef }) => (
            <React.Fragment>
              <Heading level={3}>
                {polyglot.t(
                  `selection.${updateMode ? 'updateHeader' : 'saveHeader'}`,
                )}
              </Heading>
              <Field
                name="selectionName"
                refFunc={firstRef}
                label={polyglot.t('selection.navn')}
                onChange={this.handleChange}
                required
                value={selectionName || currentSelection.navn || ''}
              />
              <SelectField
                optionUrl="/utvalg/utvalgtype"
                label={polyglot.t('selection.type')}
                required
                name="selectionType"
                onChange={this.handleChange}
                selectedValue={selectionType || currentSelection.utvalgtype}
                labelFunc={value =>
                  polyglot.t(`selection.selectionTypes.${value}`)
                }
              />
            </React.Fragment>
          )}
          onCancel={this.toggleModal}
          saveProps={{
            disabled: !updateMode && (!selectionName || !selectionType),
            onClick: this.handleSubmit,
          }}
        />
      </ModalButton>
    );
  }
}

SaveSelection.propTypes = {
  saveSelection: PropTypes.func,
  currentSelection: PropTypes.shape({
    sok: PropTypes.object,
    navn: PropTypes.string,
    utvalgtype: PropTypes.string,
  }),
};

export default SaveSelection;
