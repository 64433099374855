// @flow

import React, { Fragment } from 'react';
import { TabButton, Lightbox } from 'components';
import classnames from 'classnames';
import polyglot from 'i18n';
import PowerBIReport from '../../../containers/admin/PowerBI/PowerBIReport';
import styles from './PowerBIButtonModal.css';

import PowerBILogo from '../../../assets/powerbi-logo.png';

type Props = {
  id: string,
  token: string,
  embedUrl: string,
  toggle: () => void,
  showModal: boolean,
  panel: boolean,
  fullScreen: boolean,
};

const PowerBIButtonModal = ({
  id,
  embedUrl,
  token,
  showModal,
  toggle,
  fullScreen,
  panel,
}: Props) => (
  <Fragment>
    <TabButton
      id="powerBIButton"
      onClick={toggle}
      panel={panel}
      emptyTitle={!panel}
    >
      <img height="50px" src={PowerBILogo} alt={polyglot.t('powerbi.button')} />
      {polyglot.t('powerbi.button')}
    </TabButton>
    {showModal && (
      <Lightbox fullScreen={fullScreen} close={toggle}>
        <div
          className={classnames(styles.modal, fullScreen && styles.fullScreen)}
        >
          <PowerBIReport
            {...{
              id,
              embedUrl,
              token,
            }}
            close={toggle}
          />
        </div>
      </Lightbox>
    )}
  </Fragment>
);

export default PowerBIButtonModal;
