exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PropertyInput__wrapper___3gn-6 {\n  width: 100%;\n}\n\n.PropertyInput__wrapper___3gn-6 > div {\n  margin: 34px;\n}\n\n.PropertyInput__propertyLine___3zniF {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n  -webkit-box-align: end;\n      -ms-flex-align: end;\n          align-items: flex-end;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n}\n\n.PropertyInput__propertyLine___3zniF > div {\n  padding: 5px 10px;\n}\n\n.PropertyInput__propertyLine___3zniF > button {\n  margin: 5px 0;\n}\n\n.PropertyInput__fieldOne___2eGfy {\n  width: 120px;\n}\n\n.PropertyInput__fieldTwo___1_U9W {\n  width: 100px;\n}\n", ""]);

// exports
exports.locals = {
	"wrapper": "PropertyInput__wrapper___3gn-6",
	"propertyLine": "PropertyInput__propertyLine___3zniF",
	"fieldOne": "PropertyInput__fieldOne___2eGfy",
	"fieldTwo": "PropertyInput__fieldTwo___1_U9W"
};