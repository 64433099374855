import { combineReducers } from 'redux';
import createAsyncRedux from '../../../utils/ReduxHelpers';

const powerBIReportsRedux = createAsyncRedux({
  constantName: 'POWERBI_REPORTS',
  path: '/powerbi/report',
  responseName: 'reports',
  initialState: {
    reports: [],
  },
});
const powerBIReportRedux = createAsyncRedux({
  constantName: 'POWERBI_REPORT',
  path: '/powerbi/report',
  responseName: 'report',
  initialState: {
    report: {},
  },
});

const powerBIReportTokenRedux = createAsyncRedux({
  constantName: 'POWERBI_REPORT_TOKEN',
  path: '/powerbi/reporttoken',
  responseName: 'token',
  initialState: {
    token: '',
  },
});

const powerBIReportAdminRedux = createAsyncRedux({
  constantName: 'POWERBI_REPORT_ADMIN',
  path: '/rapportnivaa',
  responseName: 'rapportnivaa',
  initialState: {
    rapportnivaa: [],
  },
});

// Export action creators
export const getAllPowerBIReports = powerBIReportsRedux.get;
export const getPowerBIReport = powerBIReportRedux.getWithId;
export const getPowerBIReportToken = powerBIReportTokenRedux.getWithId;
export const getPowerBIReportsAdminList = powerBIReportAdminRedux.get;
export const postPowerBIReportsAdminList = powerBIReportAdminRedux.post;

// Export the reducer
export const powerBIReducer = combineReducers({
  powerBIReports: powerBIReportsRedux.reducer,
  powerBIReport: powerBIReportRedux.reducer,
  powerBIReportToken: powerBIReportTokenRedux.reducer,
  powerBIReportsAdminList: powerBIReportAdminRedux.reducer,
});

export const powerBIReportsSaga = powerBIReportsRedux.sagas;
export const powerBIReportSaga = powerBIReportRedux.sagas;
export const powerBIReportTokenSaga = powerBIReportTokenRedux.sagas;
export const powerBIReportAdminSaga = powerBIReportAdminRedux.sagas;

export default powerBIReducer;
