import React from 'react';
import styles from './Bubbles.css';

const Bubbles = () => (
  <div className={styles.spinner}>
    <div className={styles.bounce1} />
    <div className={styles.bounce2} />
    <div className={styles.bounce3} />
  </div>
);

export default Bubbles;
