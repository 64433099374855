// @flow

import * as React from 'react';
import { MapLayout, MarkerCluster, BuildingPickerCluster } from 'components';
import { Marker, GeoJSON, FeatureGroup } from 'react-leaflet';

import { withRouter } from 'react-router-dom';
import getFeaturesFromWFS, {
  zoomToClusterBounds,
  fitFeaturesToMap,
} from 'utils/leafletHelpers';

import type { Latlng, Property, Lokale } from 'types';

type Props = {
  search?: Array<Property | Lokale>,
  property: Property,
  plassering?: { lat: number, lon: number },
  myOrg?: number,
  tall?: boolean,
  wide?: boolean,
};

type State = {
  center: Latlng,
  map: ?{
    leafletElement: any,
  },
  cluster: ?{
    leafletElement: any,
  },
  zoom: number,
  marker?: Latlng,
  markers?: Array<React.Node>,
  fullscreen: boolean,
  feature?: { bbox: Array<string> },
  viewport: { center: string, zoom: number },
};

class LeafletMap extends React.PureComponent<Props, State> {
  static defaultProps = {
    property: {},
    markers: [],
  };

  state: Object = {
    viewport: {
      center: [65.03, 12.19],
      zoom: 3,
    },
    fullscreen: false,
  };

  constructor() {
    super();

    this.mapRef = React.createRef();
  }

  static getDerivedStateFromProps = (props, state) => {
    const plassering = props.plassering || props.property.plassering;

    if (plassering) {
      const pos = [parseFloat(plassering.lat), parseFloat(plassering.lon)];
      if (
        state.viewport.center[0] !== pos[0] ||
        state.viewport.center[1] !== pos[1]
      ) {
        return {
          marker: pos,
          viewport: {
            center: pos,
            zoom: 15,
          },
        };
      }
    }
    return null;
  };

  componentDidMount() {
    const { property, markers } = this.props;

    if (property.plassering) {
      this.loadProperty();
    }
    if (markers && this.mapRef.current) {
      this.mapRef.current.leafletElement._onResize();
      zoomToClusterBounds(markers, this.mapRef.current);
    }
  }

  componentDidUpdate(prevProps) {
    const { property, markers } = this.props;

    if (
      property.plassering &&
      property.plassering !== prevProps.property.plassering
    ) {
      this.loadProperty();
    }
    if (markers.length !== prevProps.markers.length && this.mapRef.current) {
      zoomToClusterBounds(markers, this.mapRef.current);
    }
  }

  loadProperty = async () => {
    const { kommunenr, gardsnr, bruksnr, festenr } = this.props.property;
    const propValues = [{ kommunenr }, { gardsnr }, { bruksnr }, { festenr }];
    const features = await getFeaturesFromWFS('TEIGWFS', propValues);
    if (features && features.totalFeatures > 0) {
      if (this.mapRef.current) {
        fitFeaturesToMap(features, this.mapRef.current);
      }
      this.setState({
        feature: features,
      });
    }
  };

  fullscreen = () => {
    const { fullscreen } = this.state;
    this.setState({ fullscreen: !fullscreen }, () => {
      if (this.mapRef) this.mapRef.current.leafletElement._onResize();
    });
  };

  exitFullscreen = e => {
    const { fullscreen } = this.state;
    e.stopPropagation();
    if (e.target.id === 'wrapper' && fullscreen)
      this.setState({ fullscreen: false }, () => {
        if (this.mapRef.current) this.mapRef.current.leafletElement._onResize();
      });
  };

  panTo = () => {
    const { plassering, markers } = this.props;
    const { marker, feature } = this.state;
    if (plassering) {
      this.setState({
        viewport: {
          center: marker,
          zoom: 15,
        },
      });
    } else if (feature) {
      fitFeaturesToMap(feature, this.mapRef.current);
    } else if (markers) {
      zoomToClusterBounds(markers, this.mapRef.current);
    }
  };

  render() {
    const { myOrg, onBuildingPick, markers } = this.props;
    const { feature, marker } = this.state;
    const featureGroup = feature && (
      <FeatureGroup>
        <GeoJSON
          data={feature}
          style={{
            color: '#ff7800',
            weight: 5,
            opacity: 0.65,
          }}
        />
      </FeatureGroup>
    );

    return (
      <MapLayout
        exitFullscreen={this.exitFullscreen}
        enterFullscreen={this.fullscreen}
        {...this.state}
        {...this.props}
        panTo={this.panTo}
        mapRef={this.mapRef}
      >
        {marker && <Marker position={marker} />}
        {markers && onBuildingPick ? (
          <BuildingPickerCluster
            markerArray={markers}
            onBuildingPick={onBuildingPick}
          />
        ) : (
          <MarkerCluster myOrg={myOrg} markerArray={markers} />
        )}
        {featureGroup}
      </MapLayout>
    );
  }
}

export default withRouter(LeafletMap);
