import React from 'react';
import polyglot from 'i18n';
import { Panel, WizardSteps, Heading, Button, Icon, Bubbles } from 'components';
import styles from './WizardStep3.css';

const WizardStep3 = ({
  onClick,
  onSubmit,
  loadingReport,
  waitingForPublish,
}) => (
  <Panel packet>
    <WizardSteps steps={3} currentStep={3} onClick={onClick} />
    <div className={styles.wrapper}>
      <div className={styles.leftBlock} xs={12} sm={2}>
        <Button
          className={styles.backButton}
          onClick={onClick}
          name="prev"
          secondaryButton
        >
          <Icon icon="arrow-left" spaced size="1" />
          {polyglot.t('report.back')}
        </Button>
      </div>
      <div className={styles.rightBlock} smOffset={6} xs={12} sm={4}>
        <Heading level={1} green>
          {' '}
          {polyglot.t('report.step3Header')}
        </Heading>
        <div className={styles.buttonWrapper}>
          <Button
            name="download"
            className={styles.previewBtn}
            onClick={onClick}
            secondaryButton
          >
            {loadingReport ? <Bubbles /> : polyglot.t('report.download')}
          </Button>
          <Button
            className={styles.publishBtn}
            onClick={onSubmit}
            name="publish"
            kind="primary"
          >
            {waitingForPublish ? <Bubbles /> : polyglot.t('report.publish')}
          </Button>
        </div>
      </div>
    </div>
  </Panel>
);

export default WizardStep3;
