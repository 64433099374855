import React from 'react';
import GenericList from 'containers/GenericList';
import { TableTemplate } from 'components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as searchActions from 'redux/modules/search/search';
import * as tableActions from 'redux/modules/search/table';
import searchSelector from 'redux/modules/search/searchSelector';
import userSelector from 'redux/modules/user/userSelector';

const RealestateList = reduxProps => (
  <GenericList
    type="realestate"
    {...reduxProps}
    render={props => <TableTemplate {...props} enableSearch />}
  />
);

function mapStateToProps(state) {
  return {
    data: searchSelector('realestate')(state),
    loading: state.search.loading,
    error: state.search.error,
    userData: userSelector(state),
    table: state.table.realestateTable,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...searchActions, ...tableActions },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RealestateList);
