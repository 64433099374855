exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* STATENS LOKALER COLOR PALETTE */\n\n:root {\n\n  /* colors for input fields */\n\n  /* Button colors */\n\n  /* Anchor colors */\n\n  /* STATSBYGG PRIMARY COLORS */\n\n  /* Packet colors */\n  /* Statsbygg Glassblå 01-03 */\n  /* SECONDARY COLORS - NEUTRAL */\n  /* Statsbygg Nøytral grå 01-02 */\n  /* Statsbygg Stein */\n  /* Statsbygg Jord */\n  /* TERTIARY COLORS - MARKER COLORS */\n  /* Statsbygg Sennep */\n  /* Statsbygg Multe */\n  /* Statsbygg Laks */\n  /* Statsbygg Lyng */\n}\n\n/* FONTS */\n\n:root {\n}\n\n.Label__label___2A0bj {\n  display: block;\n  color: rgb(35, 47, 58);\n  font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;\n  font-weight: 600;\n  padding-right: 10px;\n  margin-bottom: 5px;\n}\n", ""]);

// exports
exports.locals = {
	"label": "Label__label___2A0bj"
};