// @flow
import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as powerBIActions from 'redux/modules/report/powerbi';
import { PowerBIAdminTemplate } from 'components';
import type { PowerBI } from 'types';

type Props = {
  actions: {
    getAllPowerBIReports(): void,
    getPowerBIReportsAdminList(): void,
    postPowerBIReportsAdminList(PowerBI.reportLevels): void,
  },
  reports: PowerBI.reports,
  reportLevels: PowerBI.reportLevels,
  loadingReports: boolean,
  loadingReportLevels: boolean,
};

type State = {
  initialReportlLevels: PowerBI.reportLevels,
  reportLevels: PowerBI.reportLevels,
};

class PowerBIAdmin extends PureComponent<Props, State> {
  state = {
    initialReportlLevels: [],
    reportLevels: [],
  };

  static getDerivedStateFromProps(props, state) {
    if (
      state.reportLevels.length === 0 &&
      props.reportLevels !== state.reportLevels
    ) {
      return {
        initialReportlLevels: props.reportLevels,
        reportLevels: props.reportLevels,
      };
    }
    return null;
  }

  async componentDidMount() {
    const { actions } = this.props;
    await Promise.all([
      actions.getAllPowerBIReports(),
      actions.getPowerBIReportsAdminList(),
    ]);

    this.setState({
      initialReportlLevels: this.props.reportLevels,
      reportLevels: this.props.reportLevels,
    });
  }

  onChange = (choice, key, type) => {
    const value = choice && choice.value ? choice.value : choice;

    this.setState(prevState => {
      const reportLevels = prevState.reportLevels.map(level => {
        const newLevel = { ...level };
        if (level.aggregeringsnivaa === key) {
          newLevel[type] = value;
        }
        return newLevel;
      });
      return { reportLevels };
    });
  };

  onSubmit = e => {
    e.preventDefault();
    const { reportLevels } = this.state;
    const { actions } = this.props;
    actions.postPowerBIReportsAdminList(reportLevels);
    this.setState({ initialReportlLevels: reportLevels });
  };

  render() {
    const { initialReportlLevels, reportLevels } = this.state;
    const { reports, loadingReports, loadingReportLevels } = this.props;

    return (
      <PowerBIAdminTemplate
        handleOnChange={this.onChange}
        handleOnSubmit={this.onSubmit}
        {...{
          initialReportlLevels,
          reportLevels,
          reports,
          loadingReports,
          loadingReportLevels,
        }}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    reports: state.powerbi.powerBIReports.reports,
    reportLevels: state.powerbi.powerBIReportsAdminList.rapportnivaa,
    loadingReports: state.powerbi.powerBIReports.loading,
    loadingReportLevels: state.powerbi.powerBIReportsAdminList.loading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(powerBIActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PowerBIAdmin);
