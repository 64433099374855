var escape = require("../../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* STATENS LOKALER COLOR PALETTE */\n\n:root {\n\n  /* colors for input fields */\n\n  /* Button colors */\n\n  /* Anchor colors */\n\n  /* STATSBYGG PRIMARY COLORS */\n\n  /* Packet colors */\n  /* Statsbygg Glassblå 01-03 */\n  /* SECONDARY COLORS - NEUTRAL */\n  /* Statsbygg Nøytral grå 01-02 */\n  /* Statsbygg Stein */\n  /* Statsbygg Jord */\n  /* TERTIARY COLORS - MARKER COLORS */\n  /* Statsbygg Sennep */\n  /* Statsbygg Multe */\n  /* Statsbygg Laks */\n  /* Statsbygg Lyng */\n}\n\n/* FONTS */\n\n:root {\n}\n\n/* VIEWPORTS */\n\n:root {\n}\n\n.FrontPageTemplate__backgroundWrapper___MAcei {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  background-color: rgba(60, 112, 171, 1);\n  content: '';\n  top: 0;\n  left: 0;\n  position: absolute;\n  width: 100%;\n  min-height: 100%;\n  z-index: -1;\n  background-image: url(" + escape(require("../../../assets/frontpagebackground.jpg")) + ");\n  background-position: center center;\n  background-repeat: no-repeat;\n  background-size: cover;\n  font-family: Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;\n  color: rgb(35, 47, 58);\n}\n\n.FrontPageTemplate__contentWrapper___2h3e9 {\n  max-width: 1000px;\n}\n\n@media (max-width: 500px) {\n\n  .FrontPageTemplate__contentWrapper___2h3e9 {\n    width: 100%;\n  }\n}\n\n.FrontPageTemplate__contentWrapper___2h3e9 {\n  -webkit-box-flex: 1;\n      -ms-flex: 1 1 auto;\n          flex: 1 1 auto;\n}\n\n.FrontPageTemplate__footer___1C4Rn {\n  height: 150px;\n  -webkit-box-flex: 0;\n      -ms-flex: 0 1 100px;\n          flex: 0 1 100px;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  background-color: #242f3a;\n}\n", ""]);

// exports
exports.locals = {
	"backgroundWrapper": "FrontPageTemplate__backgroundWrapper___MAcei",
	"contentWrapper": "FrontPageTemplate__contentWrapper___2h3e9",
	"footer": "FrontPageTemplate__footer___1C4Rn"
};