import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Heading, WizardStep1, WizardStep2, WizardStep3 } from 'components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import polyglot from 'i18n';
import * as searchActions from 'redux/modules/search/search';
import * as tableActions from 'redux/modules/search/table';
import * as reportActions from 'redux/modules/report/vedleggsrapport';
import * as widgetsActions from 'redux/modules/widgets/widgets';

class ReportWizard extends Component {
  constructor() {
    super();
    this.state = {
      step: 1,
      fieldValues: {
        innledning: '',
        kapittel: '',
      },
    };
    this.buttonClick = this.buttonClick.bind(this);
    this.submit = this.submit.bind(this);
    this.onTextInput = this.onTextInput.bind(this);
  }

  componentDidMount() {
    this.props.actions.getStats({ type: 'lokale' });
    this.props.actions.getReportText();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { text, previewLink, loadingReport } = nextProps;
    if (
      text.innledning !== this.state.fieldValues.innledning ||
      text.kapittel !== this.state.fieldValues.kapittel
    ) {
      this.setState({
        fieldValues: { kapittel: text.kapittel, innledning: text.innledning },
      });
    }
    if (
      previewLink &&
      previewLink !== this.props.previewLink &&
      !loadingReport
    ) {
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(previewLink);
      } else {
        const link = URL.createObjectURL(previewLink);
        window.open(link);
      }
    }
    if (nextProps.setTextOk && !this.props.setTextOk) {
      this.setState({ step: 3 });
    }
    if (nextProps.publishOk && !this.props.publishOk)
      this.props.actions.displayAlert({
        kind: 'success',
        text: polyglot.t('report.success'),
      });
    if (nextProps.error && !this.props.error)
      this.props.actions.displayAlert({
        kind: 'danger',
        text: `There was an error: ${nextProps.error}`,
      });
  }

  onTextInput(e) {
    if (e.target.id === 'introText')
      this.setState({
        fieldValues: { ...this.state.fieldValues, innledning: e.target.value },
      });
    if (e.target.id === 'mainText')
      this.setState({
        fieldValues: { ...this.state.fieldValues, kapittel: e.target.value },
      });
  }

  buttonClick(i) {
    if (i.target.name === 'prev') {
      this.setState({ step: this.state.step - 1 });
    } else if (i.target.name === 'next') {
      if (this.state.step === 2) {
        this.props.actions.setReportText(this.state.fieldValues);
      } else {
        this.setState({ step: this.state.step + 1 });
      }
    } else if (!isNaN(i.target.name)) {
      this.setState({ step: parseInt(i.target.name, 10) });
    } else if (i.target.nodeName === 'svg') {
      this.setState({ step: this.state.step - 1 });
    } else if (i.target.name === 'download') {
      this.props.actions.previewReport();
    } else console.log('not a valid button click');
  }

  submit(e) {
    e.preventDefault();
    this.props.actions.publishReport();
  }

  render() {
    const currentStep = () => {
      const { grunneiendom, bygg, eiendom } = this.props.stats;
      const {
        waitingForPublish,
        loadingReport,
        waitingForSetText,
      } = this.props;
      switch (this.state.step) {
        case 1:
          return (
            <WizardStep1
              eiendommer={eiendom ? eiendom.global : 0}
              bygninger={bygg ? bygg.global : 0}
              grunneiendommer={grunneiendom ? grunneiendom.global : 0}
              buttonClick={this.buttonClick}
            />
          );
        case 2:
          return (
            <WizardStep2
              fieldValues={this.state.fieldValues}
              onTextInput={this.onTextInput}
              onClick={this.buttonClick}
              waitingForSetText={waitingForSetText}
            />
          );
        case 3:
          return (
            <WizardStep3
              onClick={this.buttonClick}
              loadingReport={loadingReport}
              waitingForPublish={waitingForPublish}
              onSubmit={this.submit}
            />
          );
        default:
          return 'wrong step state';
      }
    };
    return (
      <div>
        <Heading bottomMargin level={0}>
          {polyglot.t('report.header')}
        </Heading>
        {currentStep()}
      </div>
    );
  }
}

ReportWizard.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func),
  stats: PropTypes.objectOf(PropTypes.object),
  text: PropTypes.objectOf(PropTypes.any),
  loadingReport: PropTypes.bool,
  waitingForPublish: PropTypes.bool,
  waitingForSetText: PropTypes.bool,
  publishOk: PropTypes.bool,
  setTextOk: PropTypes.bool,
  previewLink: PropTypes.string,
  error: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    stats: state.search.stats,
    text: state.vedleggsrapport.reportText,
    previewLink: state.vedleggsrapport.previewLink,
    loadingReport: state.vedleggsrapport.waitingForReport,
    waitingForPublish: state.vedleggsrapport.waitingForPublish,
    waitingForSetText: state.vedleggsrapport.waitingForSetText,
    publishOk: state.vedleggsrapport.publishOk,
    setTextOk: state.vedleggsrapport.setTextOk,
    error: state.vedleggsrapport.error,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...reportActions,
        ...searchActions,
        ...tableActions,
        ...widgetsActions,
      },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportWizard);
