exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Privacy__wrapper___GZwd5 {\n  padding: 30px 40px;\n}\n\n.Privacy__inline___2V4I5 {\n  display: inline;\n}\n", ""]);

// exports
exports.locals = {
	"wrapper": "Privacy__wrapper___GZwd5",
	"inline": "Privacy__inline___2V4I5"
};