exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".BuildingPicker__result___1kEdl {\n  margin-bottom: 5px;\n  padding: 30px;\n  border: 1px solid #e3e3e3\n}\n\n.BuildingPicker__result___1kEdl #wrapper {\n  width: 650px;\n  height: 400px;\n}\n\n.BuildingPicker__property___1_K5I {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n  margin-bottom: 30px;\n}\n\n.BuildingPicker__buttonWrapper___5c-Ch {\n  margin-top: 20px;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n}\n\n.BuildingPicker__bygning___262BO {\n  margin: 10px;\n}\n\n.BuildingPicker__tinyWrapper___K2dJ5 {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex\n}\n\n.BuildingPicker__tinyWrapper___K2dJ5 > button {\n  margin: 10px;\n}\n", ""]);

// exports
exports.locals = {
	"result": "BuildingPicker__result___1kEdl",
	"property": "BuildingPicker__property___1_K5I",
	"buttonWrapper": "BuildingPicker__buttonWrapper___5c-Ch",
	"bygning": "BuildingPicker__bygning___262BO",
	"tinyWrapper": "BuildingPicker__tinyWrapper___K2dJ5"
};