exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n.FieldEdit__form____apMw {\n  width: 100%;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  padding: 5px 0px;\n}\n\n.FieldEdit__buttonWrapper___1YoLy {\n  margin-left: 10px;\n  -ms-flex-item-align: end;\n      align-self: flex-end;\n}\n", ""]);

// exports
exports.locals = {
	"form": "FieldEdit__form____apMw",
	"buttonWrapper": "FieldEdit__buttonWrapper___1YoLy"
};