import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { PageNotFound, Privacy } from 'components';
import FrontPage from 'containers/FrontPage/FrontPage';
import Wrapper from 'containers/Wrapper';
import Dashboard from 'containers/Dashboard/Dashboard';
import PropertyList from 'containers/EideLokaler/Property/PropertyList';
import BuildingList from 'containers/EideLokaler/Building/BuildingList';
import RealestateList from 'containers/EideLokaler/Realestate/RealestateList';
import OrganizationList from 'containers/admin/Organization/OrganizationList';
import UserList from 'containers/admin/Users/UserList';
import MatrikkelAlertList from 'containers/MatrikkelAlert/MatrikkelAlertList';
import PropertyDetails from 'containers/EideLokaler/Property/PropertyDetails';
import BuildingDetails from 'containers/EideLokaler/Building/BuildingDetails';
import RealestateDetails from 'containers/EideLokaler/Realestate/RealestateDetails';
import ReportWizard from 'containers/admin/Reports/ReportWizard';
import EditUser from 'containers/admin/Users/EditUser';
import MeldingList from 'containers/admin/Meldinger/MeldingList';
import NewMelding from 'containers/admin/Meldinger/NewMelding';
import NewLokaleWizard from 'containers/LeideLokaler/NewLokaleWizard';
import LeideLokaler from 'containers/LeideLokaler/LeideLokalerFrontPage';
import LeideLokalerDetails from 'containers/LeideLokaler/LeideLokalerDetails';
import ContractWizard from 'containers/LeideLokaler/ContractWizard';
import LedigeLokaler from 'containers/LedigeLokaler/LedigeLokaler';
import LedigeLokalerList from 'containers/LedigeLokaler/LedigeLokalerList';
import GlobalSearch from 'containers/GlobalSearch/GlobalSearch';
import KpiAdmin from 'containers/admin/KpiAdmin/KpiAdmin';
import PowerBIAdmin from 'containers/admin/PowerBI/PowerBIAdmin';
import ReportList from 'containers/Reports/ReportList';
import NewReport from 'containers/Reports/NewReport';
import ReportView from 'containers/Reports/ReportView';
import AdminReportList from 'containers/Reports/AdminReportList';
import AuditlogList from 'containers/admin/Auditlog/AuditlogList';
import PeriodicReporting from 'containers/LeideLokaler/PeriodicReporting';
import HelpPage from 'containers/Help/HelpPage';
import PeriodicReportingFromSearch from './containers/LeideLokaler/PeriodicReportingFromSearch';
import EtasjerList from './containers/admin/Etasjer/EtasjerList';
import UserProfile from './containers/UserProfile/UserProfile';
import SelectionList from './containers/admin/SelectionList';
import ProjectList from './containers/Projects/ProjectList';
import ProjectDetails from './containers/Projects/ProjectDetails';
import FrontPageAdmin from './containers/admin/FrontPage/FrontPageAdmin';

const Routes = () => (
  <Switch>
    <Route exact path="/" component={FrontPage} />
    <Route exact path="/personvern" component={Privacy} />
    <Wrapper>
      <Switch>
        <Route exact path="/d" render={() => <Redirect to="/d/hjem" />} />
        <Route path="/d/hjem" component={Dashboard} />
        <Route exact path="/d/grunneiendom" component={PropertyList} />
        <Route
          exact
          path="/d/grunneiendom/:grunneiendomId"
          component={PropertyDetails}
        />
        <Route exact path="/d/bygning" component={BuildingList} />
        <Route exact path="/d/bygning/:byggId" component={BuildingDetails} />
        <Route exact path="/d/eiendom" component={RealestateList} />
        <Route
          exact
          path="/d/eiendom/:eiendomId"
          component={RealestateDetails}
        />
        <Route exact path="/d/personvern" component={Privacy} />
        <Route
          exact
          path="/d/admin/virksomheter"
          component={OrganizationList}
        />
        <Route exact path="/d/admin/brukere" component={UserList} />
        <Route exact path="/d/admin/brukere/:uuid" component={EditUser} />
        <Route exact path="/d/admin/generer" component={ReportWizard} />
        <Route exact path="/d/admin/kpi" component={KpiAdmin} />
        <Route exact path="/d/admin/powerbi-admin" component={PowerBIAdmin} />
        <Route exact path="/d/admin/rapport" component={AdminReportList} />
        <Route exact path="/d/admin/rapport/nyrapport" component={NewReport} />
        <Route exact path="/d/admin/rapport/:id" component={NewReport} />
        <Route exact path="/d/admin/auditlog" component={AuditlogList} />
        <Route exact path="/d/admin/etasje" component={EtasjerList} />
        <Route exact path="/d/admin/utvalg" component={SelectionList} />
        <Route exact path="/d/admin/framside" component={FrontPageAdmin} />
        <Route exact path="/d/admin/melding" component={MeldingList} />
        <Route exact path="/d/admin/melding/nymelding" component={NewMelding} />
        <Route
          exact
          path="/d/admin/melding/:meldingId"
          component={NewMelding}
        />
        <Route exact path="/d/varsling" component={MatrikkelAlertList} />
        <Route exact path="/d/sok/:selectionName?" component={GlobalSearch} />
        <Route exact path="/d/hjelp" component={HelpPage} />
        <Route exact path="/d/rapport" component={ReportList} />
        <Route exact path="/d/rapport/:id" component={ReportView} />
        <Route exact path="/d/lokale" component={LeideLokaler} />
        <Route exact path="/d/lediglokale" component={LedigeLokalerList} />
        <Route exact path="/d/prosjekt" component={ProjectList} />
        <Route
          exact
          path="/d/prosjekt/:prosjektId"
          component={ProjectDetails}
        />
        <Route exact path="/d/lokale/nyttlokale" component={NewLokaleWizard} />
        <Route
          exact
          path="/d/lokale/:lokalerId"
          component={LeideLokalerDetails}
        />
        <Route
          exact
          path="/d/lokale/:lokalerId/nyttleieforhold"
          component={ContractWizard}
        />
        <Route
          exact
          path="/d/lokale/:lokalerId/periodisk"
          component={PeriodicReporting}
        />
        <Route
          exact
          path="/d/lokale/:lokalerId/ledigeLokaler"
          component={LedigeLokaler}
        />
        <Route
          exact
          path="/d/lokale/periodisk/:lokalerId"
          component={PeriodicReportingFromSearch}
        />
        <Route exact path="/d/profil" component={UserProfile} />
        <Route title="404 - Ukjent side" path="*" component={PageNotFound} />
      </Switch>
    </Wrapper>
    <Route title="404 - Ukjent side" path="*" component={PageNotFound} />
  </Switch>
);

export default Routes;
