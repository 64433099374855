import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as userActions from 'redux/modules/user/user';
import checkForHash from 'utils/authHelpers';
import Routes from '../routes';

class App extends Component {
  state = {};

  UNSAFE_componentWillMount() {
    if (!this.props.user.waitingForUser)
      checkForHash({ ...this.props, setState: obj => this.setState(obj) });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.user.waitingForUser && !nextProps.user.isAuthenticated)
      checkForHash({
        ...nextProps,
        setState: obj => this.setState(obj),
      });

    if (
      nextProps.user.isAuthenticated &&
      !this.props.user.isAuthenticated &&
      this.state.processingHash
    ) {
      console.log(this.state.state);
      this.props.history.replace(this.state.state);
      this.setState({ processingHash: false });
    }
  }

  render() {
    if (!this.state.processingHash) return <Routes />;
    return null;
  }
}

App.propTypes = {
  user: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
    waitingForUser: PropTypes.bool,
  }),
  history: PropTypes.shape({
    replace: PropTypes.func,
  }),
};

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(userActions, dispatch),
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(App),
);
